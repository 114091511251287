/* eslint-disable @typescript-eslint/no-explicit-any */
import { IParamsGetReport15 } from 'app/api/report/model/report-15'
import { IParamsGetReport16 } from 'app/api/report/model/report-16'
import { IParamsGetReport18 } from 'app/api/report/model/report-18'
import { IParamsGetReport20 } from 'app/api/report/model/report-20'
import { IParamsGetReport21 } from 'app/api/report/model/report-21'
import { IParamsGetReport24 } from 'app/api/report/model/report-24'
import { ParamsList } from 'parkway-web-common'

export const ReportKeys = {
  allPNL: ['PNL'] as const,
  reportPNLLists: () => [...ReportKeys.allPNL, 'ListPNL'] as const,
  reportPNLList: (filters?: ParamsList & { [key: string]: any }) =>
    [...ReportKeys.reportPNLLists(), { filters }] as const,

  allUnit: ['Unit'] as const,
  unitLists: () => [...ReportKeys.allUnit, 'ListUnit'] as const,
  unitList: (filters?: ParamsList & { [key: string]: any }) =>
    [...ReportKeys.unitLists(), { filters }] as const,

  allFilterReport014s: ['FilterReport014s'] as const,
  listFilterReport014s: () =>
    [...ReportKeys.allFilterReport014s, 'FilterReport014s'] as const,
  listFilterReport014: () =>
    [...ReportKeys.listFilterReport014s(), {}] as const,

  allReportBookingWebsites: ['ReportBookingWebsites'] as const,
  listReportBookingWebsites: () =>
    [...ReportKeys.allReportBookingWebsites, 'ReportBookingWebsites'] as const,
  listReportBookingWebsite: (filters?: ParamsList & { [key: string]: any }) =>
    [...ReportKeys.listReportBookingWebsites(), { filters }] as const,

  allFilterReport015s: ['FilterReport015s'] as const,
  listFilterReport015s: () =>
    [...ReportKeys.allFilterReport015s, 'FilterReport015s'] as const,
  listFilterReport015: (filter?: IParamsGetReport15) =>
    [...ReportKeys.listFilterReport015s(), { filter }] as const,

  allFilterReport016s: ['FilterReport016s'] as const,
  listFilterReport016s: () =>
    [...ReportKeys.allFilterReport016s, 'FilterReport016s'] as const,
  listFilterReport016: (filter?: IParamsGetReport16) =>
    [...ReportKeys.listFilterReport016s(), { filter }] as const,

  allFilterReport018s: ['FilterReport018s'] as const,
  listFilterReport018s: () =>
    [...ReportKeys.allFilterReport018s, 'FilterReport018s'] as const,
  listFilterReport018: (filter?: IParamsGetReport18) =>
    [...ReportKeys.listFilterReport018s(), { filter }] as const,

  allFilterReport020s: ['FilterReport020s'] as const,
  listFilterReport020s: () =>
    [...ReportKeys.allFilterReport020s, 'FilterReport020s'] as const,
  listFilterReport020: (filter?: IParamsGetReport20) =>
    [...ReportKeys.listFilterReport020s(), { filter }] as const,

  allFilterReport021s: ['FilterReport021s'] as const,
  listFilterReport021s: () =>
    [...ReportKeys.allFilterReport021s, 'FilterReport021s'] as const,
  listFilterReport021: (filter?: IParamsGetReport21) =>
    [...ReportKeys.listFilterReport021s(), { filter }] as const,

  allFilterReport024s: ['FilterReport024s'] as const,
  listFilterReport024s: () =>
    [...ReportKeys.allFilterReport024s, 'FilterReport024s'] as const,
  listFilterReport024: (filter?: IParamsGetReport24) =>
    [...ReportKeys.listFilterReport024s(), { filter }] as const,

  allFilterReferralSource: ['FilterReferralSource'] as const,
  listFilterReferralSource: () =>
    [...ReportKeys.allFilterReferralSource, 'FilterReferralSource'] as const,

  allFilterReport023s: ['FilterReport023s'] as const,
  listFilterReport023s: () =>
    [...ReportKeys.allFilterReport023s, 'FilterReport023s'] as const,
  listFilterReport023: (filter?: IParamsGetReport20) =>
    [...ReportKeys.listFilterReport023s(), { filter }] as const,
}
