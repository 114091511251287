import { CheckCircleOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { usePagination } from 'app/hook'
import {
  useGetTotalNotificationHistory,
  useHandleReadNotification,
} from 'app/react-query/hook/notification'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ListNotificationHistory } from './layouts'
import * as S from './NotificationsOverlay.styles'
import { Loading } from 'app/components/common/Loading'

enum TabEnum {
  READ = 'read',
  NOT_READ = 'not_read',
  ALL = 'all',
}

export const NotificationsOverlay = ({
  hideModal,
}: {
  hideModal?: () => void
}) => {
  const { t } = useTranslation()
  const [tabKey, setTabKey] = useState(TabEnum.NOT_READ)

  const { data } = useGetTotalNotificationHistory()

  const { flattenDataList } = usePagination()

  const dataFlatten: { total: { read: string; notRead: string } } =
    useMemo(() => {
      return flattenDataList(data)
    }, [data])

  const { mutateAsync, isLoading: isLoadingReadNotification } =
    useHandleReadNotification()

  const onPressReadAll = () => {
    mutateAsync?.({})
  }

  return (
    <S.NoticesOverlayMenu>
      <Tabs
        defaultActiveKey={TabEnum.NOT_READ}
        tabPosition={'top'}
        onChange={tab => setTabKey(tab as TabEnum)}
        tabBarExtraContent={{
          right: (
            <RightComponentWrapper gutter={16} wrap={false} align={'middle'}>
              {isEqual(tabKey, TabEnum.NOT_READ) ? (
                <BaseCol>
                  <ButtonReadAll
                    gutter={8}
                    align={'middle'}
                    onClick={onPressReadAll}
                  >
                    <BaseCol>
                      <CheckCircleOutlined
                        rev={undefined}
                        style={{
                          color: convertedVariables.collapseBackgroundColor,
                        }}
                      />
                    </BaseCol>

                    <BaseCol>
                      <BaseText
                        children={'Đánh dấu tất cả là đã đọc'}
                        colorText="collapseBackgroundColor"
                        fontWeight="medium"
                      />
                    </BaseCol>

                    {isLoadingReadNotification ? (
                      <Loading isAbsolute size="small" />
                    ) : null}
                  </ButtonReadAll>
                </BaseCol>
              ) : null}

              <BaseCol>
                <BaseText
                  children={t(R.strings.read_notification)}
                  fontWeight="medium"
                  opacity="0.7"
                />
              </BaseCol>
            </RightComponentWrapper>
          ),
        }}
        items={[
          {
            label: (
              <BaseRow gutter={[8, 8]} align={'middle'}>
                <BaseCol />
                <BaseCol>
                  <BaseText
                    fontWeight={
                      isEqual(tabKey, TabEnum.READ) ? 'medium' : 'regular'
                    }
                    children={t(R.strings.not_read_notification)}
                    colorText={
                      isEqual(tabKey, TabEnum.NOT_READ)
                        ? 'collapseBackgroundColor'
                        : 'primaryColor'
                    }
                  />
                </BaseCol>
                <BaseCol>
                  <NumberWrapper $active={isEqual(tabKey, TabEnum.NOT_READ)}>
                    <BaseText
                      children={dataFlatten?.total?.notRead ?? 0}
                      fontWeight="semibold"
                      colorText={
                        isEqual(tabKey, TabEnum.NOT_READ)
                          ? 'backgroundColor'
                          : 'primary1Color'
                      }
                      opacity={isEqual(tabKey, TabEnum.NOT_READ) ? '1' : '0.7'}
                    />
                  </NumberWrapper>
                </BaseCol>
                <BaseCol />
              </BaseRow>
            ),
            key: TabEnum.NOT_READ,
            children: (
              <ListNotificationHistory hideModal={hideModal} isRead={false} />
            ),
          },
          {
            label: (
              <BaseRow gutter={[8, 8]} align={'middle'}>
                <BaseCol />
                <BaseCol>
                  <BaseText
                    fontWeight={
                      isEqual(tabKey, TabEnum.ALL) ? 'medium' : 'regular'
                    }
                    children={t(R.strings.all)}
                    colorText={
                      isEqual(tabKey, TabEnum.ALL)
                        ? 'collapseBackgroundColor'
                        : 'primaryColor'
                    }
                  />
                </BaseCol>
                <BaseCol>
                  <NumberWrapper $active={isEqual(tabKey, TabEnum.ALL)}>
                    <BaseText
                      children={dataFlatten?.total?.read ?? 0}
                      fontWeight="semibold"
                      colorText={
                        isEqual(tabKey, TabEnum.ALL)
                          ? 'backgroundColor'
                          : 'primary1Color'
                      }
                      opacity={isEqual(tabKey, TabEnum.ALL) ? '1' : '0.7'}
                    />
                  </NumberWrapper>
                </BaseCol>
                <BaseCol />
              </BaseRow>
            ),
            key: TabEnum.ALL,
            children: <ListNotificationHistory hideModal={hideModal} />,
          },
        ]}
      />
    </S.NoticesOverlayMenu>
  )
}

const NumberWrapper = styled.div<{ $active?: boolean }>`
  padding: 2px 8px;
  background-color: ${props =>
    props?.$active ? convertedVariables.collapseBackgroundColor : '#EBEEF2'};
  border-radius: 100px;
`

const ButtonReadAll = styled(BaseRow)`
  cursor: pointer;
  position: relative;
`

const RightComponentWrapper = styled(BaseRow)`
  padding-right: 20px;
`
