import { ApiClient } from 'app/service/api-service'
import { ReportEndPoint } from './constant'
import { IParamsGetReport01 } from './model/report-01'
import { IParamsGetReport02 } from './model/report-02'
import { IParamsGetReport04 } from './model/report-04'
import { IParamsGetReport05 } from './model/report-05'
import { IParamsGetReport06 } from './model/report-06'
import { IParamsGetReport07 } from './model/report-07'
import { IParamsGetReport08 } from './model/report-08'
import { IParamsGetReport09 } from './model/report-09'
import { IParamsGetReport10 } from './model/report-10'
import { IParamsGetReport11 } from './model/report-11'
import { IParamsGetReport14 } from './model/report-14'
import { IParamsGetReport15 } from './model/report-15'
import { IParamsGetReport16 } from './model/report-16'
import { IParamsGetReport18 } from './model/report-18'
import { IParamsGetReport20 } from './model/report-20'
import { IParamsGetReport23 } from './model/report-23'
import { IParamsGetPNL } from './model/report-PNL'
import { IParamsExportReport21, IParamsGetReport21 } from './model/report-21'
import { IParamsGetReport24 } from './model/report-24'

export const requestExportExcelReport24 = (body: IParamsGetReport24) =>
  ApiClient.Post({
    url: ReportEndPoint.Report24.exportExcel,
    body,
  })
export const requestGetReport24 = (body?: IParamsGetReport24) =>
  ApiClient.Post({
    url: ReportEndPoint.Report24.requestGetData,
    body,
  })

export const requestGetReport23 = (params?: IParamsGetReport23) =>
  ApiClient.Get({
    url: ReportEndPoint.Report23.requestGetData,
    params: { params },
  })

export const requestGetReport21 = (body?: IParamsGetReport21) =>
  ApiClient.Post({
    url: ReportEndPoint.Report21.requestGetData,
    body,
  })
export const requestExportExcelReport21 = (body: IParamsExportReport21) =>
  ApiClient.Post({
    url: ReportEndPoint.Report21.exportExcel,
    body,
  })
export const requestGetReferralSource = () =>
  ApiClient.Get({
    url: ReportEndPoint.ReferralSource.requestGetData,
    params: {},
  })
export const requestExportExcelReport23 = (params: IParamsGetReport23) =>
  ApiClient.Get({
    url: ReportEndPoint.Report23.exportExcel,
    params: { params },
  })

export const requestGetReport18 = (params?: IParamsGetReport18) =>
  ApiClient.Get({
    url: ReportEndPoint.Report18.requestGetData,
    params: { params },
  })
export const requestExportExcelReport18 = (params: IParamsGetReport18) =>
  ApiClient.Get({
    url: ReportEndPoint.Report18.requestExportExcel,
    params: { params },
  })

export const requestGetReport20 = (params?: IParamsGetReport20) =>
  ApiClient.Get({
    url: ReportEndPoint.Report20.requestGetData,
    params: { params },
  })

export const requestExportExcelReport20 = (params: IParamsGetReport20) =>
  ApiClient.Get({
    url: ReportEndPoint.Report20.exportExcel,
    params: { params },
  })

export const requestGetReport16 = (params?: IParamsGetReport16) =>
  ApiClient.Get({
    url: ReportEndPoint.Report16.requestGetData,
    params: { params },
  })

export const requestGetReport15 = (params?: IParamsGetReport15) =>
  ApiClient.Get({
    url: ReportEndPoint.Report15.requestGetData,
    params: { params },
  })

export const requestGetReport14 = (params: IParamsGetReport14) =>
  ApiClient.Get({
    url: ReportEndPoint.Report14.requestGetData,
    params: { params },
  })

export const requestExportExcelReport14 = (params: IParamsGetReport14) =>
  ApiClient.Get({
    url: ReportEndPoint.Report14.export,
    params: { params },
  })

export const requestGetPNL = (params: IParamsGetPNL) =>
  ApiClient.Get({
    url: ReportEndPoint.PNL.requestGetData,
    params: { params },
  })

export const requestExportExcelPNL = (params: IParamsGetPNL) =>
  ApiClient.Get({
    url: ReportEndPoint.PNL.excelFileReportPNL,
    params: { params },
  })

export const requestGetReport11 = (params: IParamsGetReport11) =>
  ApiClient.Get({
    url: ReportEndPoint.Report11.requestGetData,
    params: { params },
  })

export const requestExportExcelReport11 = (params: IParamsGetReport11) =>
  ApiClient.Get({
    url: ReportEndPoint.Report11.exportExcelTopPatientPayment,
    params: { params },
  })

export const requestGetReport10 = (params: IParamsGetReport10) =>
  ApiClient.Get({
    url: ReportEndPoint.Report10.requestGetData,
    params: { params },
  })

export const requestExportExcelPatient = (params: IParamsGetReport10) =>
  ApiClient.Get({
    url: ReportEndPoint.Report10.exportExcelPatient,
    params: { params },
  })

export const requestGetReport09 = (params: IParamsGetReport09) =>
  ApiClient.Get({
    url: ReportEndPoint.Report09.requestGetData,
    params: { params },
  })

export const requestExportExcelPatientProduct = (params: IParamsGetReport09) =>
  ApiClient.Get({
    url: ReportEndPoint.Report09.exportExcelPatientProduct,
    params: { params },
  })

export const requestGetReport08 = (params: IParamsGetReport08) =>
  ApiClient.Get({
    url: ReportEndPoint.Report08.requestGetData,
    params: { params },
  })

export const requestExportExcelTopTenProduct = (params: IParamsGetReport08) =>
  ApiClient.Get({
    url: ReportEndPoint.Report08.exportExcelTopTenProduct,
    params: { params },
  })

export const requestGetReport07 = (params: IParamsGetReport07) =>
  ApiClient.Get({
    url: ReportEndPoint.Report07.requestGetData,
    params: { params },
  })

export const requestExportExcelRefererByArea = (params: IParamsGetReport07) =>
  ApiClient.Get({
    url: ReportEndPoint.Report07.exportExcelRefererByArea,
    params: { params },
  })

export const requestGetReport06 = (params: IParamsGetReport06) =>
  ApiClient.Get({
    url: ReportEndPoint.Report06.requestGetData,
    params: { params },
  })

export const requestExportExcelBrandByProduct = (params: IParamsGetReport06) =>
  ApiClient.Get({
    url: ReportEndPoint.Report06.exportExcelBrandByProduct,
    params: { params },
  })

export const requestGetReport05 = (params: IParamsGetReport05) =>
  ApiClient.Get({
    url: ReportEndPoint.Report05.requestGetData,
    params: { params },
  })

export const requestExportExcelRevenueOfPaid = (params: IParamsGetReport05) =>
  ApiClient.Get({
    url: ReportEndPoint.Report05.exportExcelRevenueOfPaid,
    params: { params },
  })

export const requestGetReport04 = (params: IParamsGetReport04) =>
  ApiClient.Get({
    url: ReportEndPoint.Report04.requestGetData,
    params: { params },
  })

export const requestGetFilterReport04 = () =>
  ApiClient.Get({
    url: ReportEndPoint.Report04.filter,
    params: {},
  })

export const requestExportExcelReport04 = (params: IParamsGetReport05) =>
  ApiClient.Get({
    url: ReportEndPoint.Report04.export,
    params: { params },
  })

export const requestGetReport03 = (params: IParamsGetReport02) =>
  ApiClient.Get({
    url: ReportEndPoint.Report02.requestGetData,
    params: { params },
  })

export const requestExportExcelReport02And03 = (params: IParamsGetReport02) =>
  ApiClient.Get({
    url: ReportEndPoint.Report02.export,
    params: { params },
  })

export const requestGetReport02 = (params: IParamsGetReport02) =>
  ApiClient.Get({
    url: ReportEndPoint.Report02.requestGetData,
    params: { params },
  })

export const requestGetReport01 = (params: IParamsGetReport01) =>
  ApiClient.Get({
    url: ReportEndPoint.Report01.requestGetData,
    params: { params },
  })

export const requestExportExcelReport01 = (params: IParamsGetReport01) =>
  ApiClient.Get({
    url: ReportEndPoint.Report01.export,
    params: { params },
  })
