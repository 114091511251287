import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { useDebounce, usePagination } from 'app/hook'
import { IUser } from 'app/model/user.model'
import { useGetProfileList } from 'app/react-query/hook/profile'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'

export const useModalHook = () => {
  // OM = Office Manager
  // TM = Tech Manager
  // RM = Responsibility Manager

  const { t } = useTranslation()

  const [loadMoreOM, setLoadMoreOM] = useState(0)
  const [loadMoreTM, setLoadMoreTM] = useState(0)
  const [loadMoreRM, setLoadMoreRM] = useState(0)
  const [OMKeyword, setOMKeyword] = useState('')
  const [TMKeyword, setTMKeyword] = useState('')
  const [RMKeyword, setRMKeyword] = useState('')

  const loadMoreOMDebounce = useDebounce(loadMoreOM, 200)
  const loadMoreTMDebounce = useDebounce(loadMoreTM, 200)
  const loadMoreRMDebounce = useDebounce(loadMoreRM, 200)

  const { flattenData, flattenDataList } = usePagination()
  const [paginationDS, setPaginationDS] = useState<Pagination>({
    current: 1,
    pageSize: 4,
  })
  const [paginationIS, setPaginationIS] = useState<Pagination>({
    current: 1,
    pageSize: 4,
  })

  const onOMKeywordChange = (value: string) => {
    setOMKeyword(value)
  }
  const OMKeywordDebounce = useDebounce(OMKeyword, 200)
  const onTMKeywordChange = (value: string) => {
    setTMKeyword(value)
  }
  const TMKeywordDebounce = useDebounce(TMKeyword, 200)
  const onRMKeywordChange = (value: string) => {
    setRMKeyword(value)
  }
  const RMKeywordDebounce = useDebounce(RMKeyword, 200)

  const {
    data: dataOfficeManager,
    isLoading: loadingOfficeManager,
    hasNextPage: hasNextPageOM,
    fetchNextPage: fetchNextPageOM,
  } = useGetProfileList({
    keyword: OMKeywordDebounce,
    page: initialPagination?.current,
    pageSize: 50,
    pagesize: 50,
  })

  const officeManagers = useMemo(() => {
    return flattenData(dataOfficeManager)
  }, [dataOfficeManager])

  const onPopupOfficeManagerScroll = e => {
    const heightChange = e?.target?.scrollTop + e?.target?.clientHeight ?? 0
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPageOM) {
      setLoadMoreOM(randomMoney())
    }
  }

  const {
    data: dataTechManager,
    isLoading: loadingTechManager,
    hasNextPage: hasNextPageTM,
    fetchNextPage: fetchNextPageTM,
  } = useGetProfileList({
    keyword: TMKeywordDebounce,
    page: initialPagination?.current,
    pageSize: 50,
    pagesize: 50,
  })

  const techManagers = useMemo(() => {
    return flattenData(dataTechManager)
  }, [dataTechManager])

  const onPopupTechManagerScroll = e => {
    const heightChange = e?.target?.scrollTop + e?.target?.clientHeight ?? 0
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPageTM) {
      setLoadMoreTM(randomMoney())
    }
  }

  const {
    data: dataResponsibilityManager,
    isLoading: loadingResponsibilityManager,
    hasNextPage: hasNextPageRM,
    fetchNextPage: fetchNextPageRM,
  } = useGetProfileList({
    keyword: RMKeywordDebounce,
    page: initialPagination?.current,
    pageSize: 50,
    pagesize: 50,
  })

  const responsibilityManagers = useMemo(() => {
    return flattenData(dataResponsibilityManager)
  }, [dataResponsibilityManager])

  const onPopupResponsibilityManagerScroll = e => {
    const heightChange = e?.target?.scrollTop + e?.target?.clientHeight ?? 0
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPageRM) {
      setLoadMoreRM(randomMoney())
    }
  }

  const { data: dataDS, isLoading: isLoadingDirectSubordinate } =
    useGetProfileList({
      keyword: RMKeywordDebounce,
      page: paginationDS?.current,
      pageSize: paginationDS?.pageSize,
      pagesize: paginationDS?.pageSize,
    })

  const dataFlattenDirectSubordinate = useMemo(() => {
    return flattenDataList(dataDS)
  }, [dataDS])

  const dataDirectSubordinate = useMemo(() => {
    return dataFlattenDirectSubordinate?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [dataFlattenDirectSubordinate])

  const columnsDS = useMemo(() => {
    const option = [
      convertColumnTable<IUser>({
        title: t(R.strings.name),
        key: 'name',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IUser) => {
          return (
            <BaseAvatarProfile
              name={record?.name}
              src={record?.avatar}
              jobTitle={record?.jobTitleName}
            />
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableDSChange = (newPagination: Pagination) => {
    setPaginationDS(newPagination)
  }
  const { data: dataIS, isLoading: isLoadingIndirectSubordinate } =
    useGetProfileList({
      keyword: RMKeywordDebounce,
      page: paginationIS?.current,
      pageSize: paginationIS?.pageSize,
      pagesize: paginationIS?.pageSize,
    })

  const dataFlattenIndirectSubordinate = useMemo(() => {
    return flattenDataList(dataIS)
  }, [dataIS])

  const dataIndirectSubordinate = useMemo(() => {
    return dataFlattenIndirectSubordinate?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [dataFlattenIndirectSubordinate])

  const columnsIS = useMemo(() => {
    const option = [
      convertColumnTable<IUser>({
        title: t(R.strings.name),
        key: 'name',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IUser) => {
          return (
            <BaseAvatarProfile
              name={record?.name}
              src={record?.avatar}
              jobTitle={record?.jobTitleName}
            />
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableISChange = (newPagination: Pagination) => {
    setPaginationIS(newPagination)
  }

  useEffect(() => {
    if (loadMoreOMDebounce) {
      fetchNextPageOM()
    }
  }, [loadMoreOMDebounce])

  useEffect(() => {
    if (loadMoreTMDebounce) {
      fetchNextPageTM()
    }
  }, [loadMoreTMDebounce])

  useEffect(() => {
    if (loadMoreRMDebounce) {
      fetchNextPageRM()
    }
  }, [loadMoreRMDebounce])

  return {
    officeManagers,
    loadingOfficeManager,
    onPopupOfficeManagerScroll,
    onOMKeywordChange,
    techManagers,
    loadingTechManager,
    onPopupTechManagerScroll,
    onTMKeywordChange,
    responsibilityManagers,
    loadingResponsibilityManager,
    onPopupResponsibilityManagerScroll,
    onRMKeywordChange,
    columnsDS,
    dataDirectSubordinate,
    paginationDS: {
      ...paginationDS,
      total: dataFlattenDirectSubordinate?.metadata?.totalItem ?? 0,
    },
    isLoadingDirectSubordinate,
    handleTableDSChange,
    columnsIS,
    dataIndirectSubordinate,
    paginationIS: {
      ...paginationIS,
      total: dataFlattenIndirectSubordinate?.metadata?.totalItem ?? 0,
    },
    isLoadingIndirectSubordinate,
    handleTableISChange,
  }
}
