import { useGetQuestionMedicalHistory } from 'app/react-query/hook/medical-record-and-information'

export const useHook = () => {
  const { data: dataQuestion, isLoading: isLoadingQuestion } =
    useGetQuestionMedicalHistory()

  return {
    dataQuestion,
    isLoadingQuestion,
  }
}
