import * as S from './styles'
import { IFormWorkInfoAkaModalData } from './WorkInfoAkaModal/type'
import WorkInfoAkaItem from './WorkInfoAkaItem'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'

interface IProps {
  jobTitleOptions: IJobTitle[]
  workInfo?: IFormWorkInfoAkaModalData[]
  onRemove?: (key: number) => void
  onUpdate?: (key: number) => void
}

export const WorkInfoAkaList = ({
  workInfo,
  jobTitleOptions,
  onRemove,
  onUpdate,
}: IProps) => {
  return (
    <S.WorkInfoAkaWrapper>
      {workInfo?.map((item, index) => {
        const jobTitle = jobTitleOptions?.find(
          jobTitleOption => jobTitleOption._id === item.jobtitleid,
        )

        return (
          <WorkInfoAkaItem
            jobTitle={jobTitle}
            start={item?.startdate?.$d}
            end={item?.enddate?.$d}
            key={index}
            index={index}
            onRemove={onRemove}
            onUpdate={onUpdate}
          />
        )
      })}
    </S.WorkInfoAkaWrapper>
  )
}
