import { DeleteOutlined, MoreOutlined, PrinterOutlined } from '@ant-design/icons'
import { requestGetPDFPatientInfo } from 'app/api/dental-treatment/survey-dynamic'
import {
  DefineTypeEnum,
  SurveyDynamicMessageCode,
} from 'app/api/dental-treatment/survey-dynamic/constant'
import {
  ISurveyDynamic,
  StatusApproveEnum,
  TypeEnum,
} from 'app/api/dental-treatment/survey-dynamic/type'
import { IPatient } from 'app/api/patient/model/patient'
import R from 'app/assets/R'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import axios from 'axios'
import { isEqual } from 'lodash'
import {
  BORDER_RADIUS,
  normalizePhone,
  PADDING,
  ResponseType,
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalDetail } from '../../modal'
import { BaseDivider } from 'app/components/common/BaseDivider'

export const ItemPatientComp = ({
  item,
  status,
}: {
  item: ISurveyDynamic
  status: StatusApproveEnum
}) => {
  const { t } = useTranslation()

  const typeData = useMemo(() => {
    if (!item?.type) return

    if (isEqual(item?.type, TypeEnum.SURVEY_DYNAMIC) && item?.typeSurvey) {
      return DefineTypeEnum?.[item?.typeSurvey]
    }

    return DefineTypeEnum?.[item?.type]
  }, [item?.type, item?.typeSurvey])

  const handleOpenPrint = async () => {
    try {
      if (!item?.patient?._id) return
      const res: ResponseType<IPatient> = await requestGetPDFPatientInfo({
        id: item?.patient?._id,
      })

      if (
        isEqual(res?.msgcode, SurveyDynamicMessageCode.Survey.successGetData) &&
        res?.data?.pdfUrl
      ) {
        if (!res?.data?.pdfUrl) return
        // Tải file PDF về
        const response = await axios.get(res?.data?.pdfUrl, {
          responseType: 'blob', // Định dạng blob để tải file
        })

        // Tạo URL blob từ file PDF đã tải
        const fileURL = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        )

        // Mở file PDF trong tab mới
        window.open(fileURL, '_blank')
      }
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <ItemWrapper align={'middle'} justify={'space-between'} gutter={[16, 16]}>
      <BaseCol>
        <BaseSpace size={4}>
          <BaseText
            children={item?.patient?.name}
            fontSize="xs"
            fontWeight="medium"
          />
          <BaseText
            children={normalizePhone(item?.patient?.contact ?? '')}
            fontSize="xxs"
            style={{ color: 'rgba(133, 140, 148, 1)' }}
          />
        </BaseSpace>
      </BaseCol>

      <BaseCol>
        <BaseRow gutter={[16, 16]} align={'middle'}>
          <BaseCol>
            <BaseText
              children={t(typeData?.keyI18n ?? '')}
              fontSize="xxs"
              style={{ color: 'rgba(109, 117, 128, 1)' }}
            />
          </BaseCol>

          <BaseCol>
            <div className="line-vertical" />
          </BaseCol>
          <BaseCol>
            <ModalDetail item={item} status={status} />
          </BaseCol>

          <BaseCol>
            {(() => {
              switch (status) {
                case StatusApproveEnum.PENDING:
                case StatusApproveEnum.REJECTED:
                  return (
                    <BaseButton type="text" icon={<SvgDeleteDesignIcon />} />
                  )
                case StatusApproveEnum.APPROVED:
                  return (
                    <BasePopover
                      trigger={'click'}
                      placement="rightTop"
                      content={
                        <BaseSpace
                          split={
                            <BaseDivider
                              style={{ marginTop: 0, marginBottom: 0 }}
                            />
                          }
                          size={8}
                        >
                          {isEqual(item?.type, TypeEnum.INFO_PATIENT) ? (
                            <BaseButton
                              children={t(
                                R.strings
                                  .print_the_consent_for_the_information_privacy_policy,
                              )}
                              onClick={handleOpenPrint}
                              type="text"
                              style={{ width: '100%' }}
                              icon={<PrinterOutlined rev={undefined} />}
                            />
                          ) : null}

                          <BaseButton
                            children={t(R.strings.delete)}
                            type="text"
                            style={{ width: '100%', justifyContent: 'left', color: 'red' }}
                            icon={<DeleteOutlined rev={undefined}/>}
                          />
                        </BaseSpace>
                      }
                    >
                      <BaseButton
                        icon={<MoreOutlined rev={undefined} />}
                        type="default"
                      />
                    </BasePopover>
                  )
              }
            })()}
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </ItemWrapper>
  )
}

const ItemWrapper = styled(BaseRow)`
  border: 1px solid rgba(244, 246, 249, 1);
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xs};
  background-color: ${convertedVariables.backgroundColor};
  margin-left: 0px !important;
  margin-right: 0px !important;

  .line-vertical {
    width: 1px;
    height: 20px;
    background-color: ${convertedVariables.neutralBlack2Color};
  }
`
