/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Popover } from 'antd'
import { PopoverProps } from 'antd'
interface IProps extends PopoverProps {
  padding?: number
}
export const BasePopover: React.FC<IProps> = ({
  content,
  padding = 12,
  ...props
}) => {
  return (
    <Popover
      {...props}
      content={<div style={{ padding: `${padding}px` }}>{content as any}</div>}
    />
  )
}
