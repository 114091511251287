/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import { requestGetDetailCampaign } from 'app/api/marketing/discount-campaign'
import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import {
  CampaignReferralEnum,
  CampaignTypeEnum,
  DiscountTypeEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { YesNoEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { _DEV_ } from 'app/constant'
import { IUnit } from 'app/model/unit.model'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { getRandomUuid, ResponseType } from 'parkway-web-common'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useBundleHook } from './hook/bundle-hook'
import { useBuyOneGetOneHook } from './hook/buy-one-get-one-hook'
import { useHookInfo } from './hook/info-hook'
import { useTreatmentTypeHook } from './hook/treatment-type-hook'
import {
  BuyOneGetOneItem,
  IBillDiscountInTable,
  IBundleServiceItem,
  IFormData,
  ITreatmentInTable,
} from './type'
import { useTotalBillTypeHook } from './hook/total-bill-type-hook'
import { useBundleBillDiscountHook } from './hook/bundle-bill-discount-hook'
import R from 'app/assets/R'
import { IStackedWithCampaign } from '../CloneCampaign/type'

interface IDetailCampaignContext {
  form?: FormInstance<IFormData>
  handleSubmit?: (values: IFormData) => void
  areaList?: IUnit[]
  isLoadingArea?: boolean
  keywordSearchArea?: string
  setKeywordSearchArea?: Dispatch<SetStateAction<string>>
  filterTreeNode?: (value?: string, treeNode?: any) => any
  onChangeClinic?: (ids: string[]) => void
  selectedClinic?: string[]
  campaignType?: CampaignTypeEnum
  onChangeCampaignType?: (value: CampaignTypeEnum) => void

  onChangeService?: (service: ITreatmentInTable[]) => void
  listService?: ITreatmentInTable[]
  onChangeDiscountType?: (value: DiscountTypeEnum, id?: string) => void
  onChangeDiscountValue?: (value: number, id?: string) => void
  onDeleteService?: (id?: string) => void

  isDisableSubmit?: boolean

  onAddNewBuyOneGetOne?: () => void
  listBuyOneGetOne?: BuyOneGetOneItem[]
  onRemoveBuyOneGetOneByIndex?: (index: number) => void
  onChangeNameByIndex?: (index: number, name: string) => void
  onChangeSelectedTreatmentByIndex?: (
    index: number,
    selectedTreatment: ITreatmentInTable[],
  ) => void
  onChangeDiscountTreatmentByIndex?: (
    index: number,
    discountTreatment: ITreatmentInTable[],
  ) => void
  onChangeItemByIndex?: (index: number, item: BuyOneGetOneItem) => void
  onChangeDiscountTypeInDiscountTreatment?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueInDiscountTreatment?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void

  onAddNewBundleService?: () => void
  listBundleService?: IBundleServiceItem[]
  onRemoveBundleServiceByIndex?: (index: number) => void
  onChangeNameBundleByIndex?: (index: number, name: string) => void
  onChangeDiscountTypeItemBundleService?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueItemBundleService?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void
  onChangeItemBundleServiceByIndex?: (
    index: number,
    item: IBundleServiceItem,
  ) => void
  onChangeQuantityItemBundleService?: (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => void
  onDeleteItemInSelectedTreatment?: (
    index: number,
    treatmentIndex: number,
  ) => void

  isLoadingSubmit?: boolean

  handleGoBack?: () => void
  isLoading?: boolean

  onChangeTotalBill?: (service: IBillDiscountInTable[]) => void
  listTotalBill?: IBillDiscountInTable[]
  onChangeDiscountTypeTotalBill?: (value: DiscountTypeEnum, id?: string) => void
  onChangeDiscountValueTotalBill?: (value: number, id?: string) => void
  onDeleteTotalBill?: (id?: string) => void
  onAddTotalBill?: (bill: IBillDiscountInTable) => void
  onInitTotalBill?: (data: IBillDiscountInTable[]) => void

  // bundle total bill
  onAddNewBundleBillDiscountService?: () => void
  listBundleBillDiscountService?: IBundleServiceItem[]
  onRemoveBundleBillDiscountServiceByIndex?: (index: number) => void
  onChangeNameBundleBillDiscountByIndex?: (index: number, name: string) => void
  onChangeDiscountTypeItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void
  onChangeItemBundleBillDiscountServiceByIndex?: (
    index: number,
    item: IBundleServiceItem,
  ) => void
  onChangeQuantityItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => void
  onDeleteItemInSelectedTreatmentBundleBillDiscount?: (
    index: number,
    treatmentIndex: number,
  ) => void

  handleGetImportTemplate?: () => void
  isApplyWithOtherCampaign?: YesNoEnum | undefined
  setIsApplyWithOtherCampaign?: Dispatch<SetStateAction<YesNoEnum | undefined>>

  stackedWithCampaigns?: IDiscountCampaign[]
  campaignApplyCommon?: string

  stackedWithCampaignGroups?: IStackedWithCampaign[]
  onChangeStackedWithCampaignsGroup?: (groups: IStackedWithCampaign[]) => void
  onDeleteGroupInStackedCampaignGroup?: ({
    groupCode,
  }: {
    groupCode: string
  }) => void

  info?: IFormData
}
export const DetailCampaignContext = createContext<IDetailCampaignContext>({})

export const DetailCampaignProvider = ({ children }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [form] = BaseForm.useForm<IFormData>()
  const [isLoading, setIsLoading] = useState(false)

  const hookInfo = useHookInfo()

  const hookTreatmentType = useTreatmentTypeHook()

  const hookBuyOneGetOne = useBuyOneGetOneHook()

  const hookBundle = useBundleHook()

  const hookTotalBill = useTotalBillTypeHook()

  const hookBundleBillDiscount = useBundleBillDiscountHook()

  const getDetail = async () => {
    try {
      setIsLoading(true)
      const res: ResponseType<IDiscountCampaign> =
        await requestGetDetailCampaign(state?.item?._id)

      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.successGetData,
        )
      ) {
        const data = res?.data

        const campaignApplyCommon = data?.stackedWithCampaigns?.length
          ? R.strings.campaign_list
          : R.strings.all

        const newForm: IFormData = {
          applyWithOtherCampaign: data?.isStackedDiscount
            ? YesNoEnum.Yes
            : YesNoEnum.No,
          activeType: data?.applyType,
          clinics: data?.clinics?.map(item => item._id),
          startDate: dayjs(data?.start),
          endDate: dayjs(data?.end),
          campaignType: data?.campaignType,
          campaigns: data?.stackedWithCampaigns?.map(item => item._id ?? ''),
          campaign_apply_common: campaignApplyCommon,
          referral: data?.referralType ?? CampaignReferralEnum.Normal,
          priority: data?.priority,
          group_campaign: data?.campaignGroupCode,
          name: data?.name,
        }

        if (data?.campaignType) {
          hookInfo.onChangeCampaignType(data?.campaignType)
        }

        hookInfo?.setIsApplyWithOtherCampaign(
          newForm.applyWithOtherCampaign ?? YesNoEnum.No,
        )
        form.setFieldsValue(newForm)
        hookInfo.onChangeClinic(data?.clinics?.map(item => item._id) ?? [])

        hookInfo?.initInfo(newForm)
        hookInfo?.onChangeCampaignApplyCommon(campaignApplyCommon)
        hookInfo?.onChangeStackedWithCampaigns(data?.stackedWithCampaigns ?? [])
        hookInfo.onChangeStackedWithCampaignsGroup(
          data?.stackedWithCampaignsConfig?.map(item => {
            return {
              group: {
                code: item?.campaignGroupCode,
                totalCampaign: item?.totalCampaign,
              },
              excludes: item?.excludes?.map(itm => ({ _id: itm })) ?? [],
              includes: item?.includes?.map(itm => ({ _id: itm })) ?? [],
              isSelectedAll: isEqual(
                item?.selectedCampaign,
                item?.totalCampaign,
              ),
              isSelectedAllRemember:
                !!item?.excludes?.length || !item?.includes?.length,
            }
          }) ?? [],
        )

        switch (data?.campaignType) {
          case CampaignTypeEnum.Treatments:
            hookTreatmentType?.onInitListService(
              data?.discounts?.map(item => ({
                _id: getRandomUuid(),
                name: item?.treatment?.name,
                discountValue: item?.discount,
                discountType: item?.dType,
                quantity: item?.amount,
                regionalPrice: item?.treatment?.regionalPrice,
              })) ?? [],
            )
            break
          case CampaignTypeEnum.Bundle:
            hookBundle.onAddBundleItem(
              data?.boughtTreatmentsDiscount?.map(item => ({
                id: getRandomUuid(),
                name: item?.name,
                selectedTreatment: item?.buyTreatment?.map(itm => ({
                  _id: itm?.treatment?._id,
                  name: itm?.treatment?.name,
                  discountValue: itm?.discount,
                  discountType: itm?.dType,
                  quantity: itm?.amount,
                  regionalPrice: itm?.treatment?.regionalPrice,
                })) as ITreatmentInTable[],
              })) ?? [],
            )
            break
          case CampaignTypeEnum.BuyOneGetOne:
            hookBuyOneGetOne.initBuyOneGetOne(
              data?.boughtTreatmentsDiscount?.map(item => ({
                id: getRandomUuid(),
                name: item?.name,
                selectedTreatment: item?.buyTreatment?.map(itm => ({
                  _id: itm?.treatment?._id,
                  name: itm?.treatment?.name,
                  discountValue: itm?.discount,
                  discountType: itm?.dType,
                  regionalPrice: itm?.treatment?.regionalPrice,
                })) as ITreatmentInTable[],
                discountTreatment: item?.discountTreatment?.map(itm => ({
                  _id: itm?.treatment?._id,
                  name: itm?.treatment?.name,
                  discountValue: itm?.discount,
                  discountType: itm?.dType,
                  regionalPrice: itm?.treatment?.regionalPrice,
                })) as ITreatmentInTable[],
              })) ?? [],
            )
            break
          case CampaignTypeEnum.TotalBill:
            hookTotalBill.onInitTotalBill(
              data?.billDiscounts?.map(item => ({
                _id: item?._id,
                totalBill: item?.totalBill,
                discount: item?.discount,
                dType: item?.dType,
              })) ?? [],
            )
            hookBundleBillDiscount.onInitBundleBillDiscountItem(
              data?.boughtTreatmentsDiscount?.map(item => ({
                id: item?._id,
                name: item?.name,
                selectedTreatment: item?.buyTreatment?.map(itm => ({
                  _id: itm?.treatment?._id,
                  name: itm?.treatment?.name,
                  regionalPrice: itm?.treatment?.regionalPrice,
                })) as ITreatmentInTable[],
              })) ?? [],
            )
            break
        }
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const item = state?.item as IDiscountCampaign
      if (item) {
        getDetail()
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [state])

  const handleGoBack = () => {
    navigate?.(-1)
  }

  return (
    <DetailCampaignContext.Provider
      value={{
        form,
        isLoading,
        handleGoBack,
        ...hookInfo,
        ...hookTreatmentType,
        ...hookBuyOneGetOne,
        ...hookBundle,
        ...hookTotalBill,
        ...hookBundleBillDiscount,
      }}
    >
      {children}
    </DetailCampaignContext.Provider>
  )
}

export function useDetailCampaignContext() {
  const context = useContext(DetailCampaignContext)

  if (context === undefined) {
    throw new Error(
      'useDetailCampaign must be used within a DetailCampaignProvider',
    )
  }
  return context
}
