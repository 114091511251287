import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { IProfileUser } from 'app/api/profile/model/profile'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  ItemLabel,
  ItemValue,
  SectionBaseWrapper,
  SectionDescription,
  SectionTitle,
} from './styles'
import R from 'app/assets/R'

interface IProps {
  profile?: IProfileUser
}
interface IItem {
  label: string
  value?: string
}

export const PersonalInformation = (props: IProps) => {
  const { profile } = props
  const { t } = useTranslation()

  const personalInfoList: IItem[] = [
    {
      label: t(R.strings.full_name),
      value: profile?.name ?? '-',
    },
    {
      label: t(R.strings.gender),
      value: profile?.gender ?? '-',
    },
    {
      label: t(R.strings.birthday),
      value: profile?.dob ?? '-',
    },
    {
      label: t(R.strings.profile_content_employee_old_id),
      value: profile?.employeeOldId ?? '-',
    },
    {
      label: t(R.strings.employee_id),
      value: profile?.employeeid ?? '-',
    },
    {
      label: t(R.strings.phone),
      value: profile?.mobile ?? '-',
    },
    {
      label: t(R.strings.email),
      value: profile?.email,
    },
  ]
  console.log('===============================================')
  console.log('profile', profile)
  console.log('===============================================')
  const PersonalInfoList = () => {
    const renderItem = (item: IItem, index: number) => {
      return (
        <BaseCol xl={8}>
          <ItemLabel>{item?.label}</ItemLabel>
          <ItemValue>{item?.value}</ItemValue>
        </BaseCol>
      )
    }
    return (
      <BaseRow gutter={[0, 40]}>{personalInfoList?.map(renderItem)}</BaseRow>
    )
  }
  return (
    <SectionBaseWrapper>
      <SectionTitle>{t(R.strings.personal_info)}</SectionTitle>
      <SectionDescription>
        {t(R.strings.personal_info_description)}
      </SectionDescription>
      <Divider />
      <PersonalInfoList />
    </SectionBaseWrapper>
  )
}
