import R from 'app/assets/R'
import {
  EYesNo,
  PaymentMethodEnum,
  PurchaseOrderActionEnum,
  PurchaseOrderStatusEnum,
  SEARCH_APPOINTMENT_ACTION,
  StatusEnum,
  YesNoEnum
} from './enum'
import {
  SvgFileIcon,
  SvgPrinterIcon,
  SvgPurchaseOrderCancelIcon,
  SvgPurchaseOrderConfirmedIcon,
  SvgPurchaseOrderDoneReceivedIcon,
  SvgPurchaseOrderEditIcon,
  SvgPurchaseOrderPendingIcon,
  SvgPurchaseOrderReturnedIcon,
  SvgPurchaseOrderSentToSupplierIcon,
  SvgReOrderIcon,
  SvgReceiveIcon,
} from 'app/assets/svg-assets'
import {
  INVENTORY_WAREHOUSE_PURCHASE_GOODS_RECEIPT_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_PRINT_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_RE_ORDER_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_UPDATE_PATH,
  INVENTORY_WAREHOUSE_PURCHASE_VIEW_PATH,
} from 'app/components/router/route-path'

const YesNoArray: EYesNo[] = [EYesNo.no, EYesNo.yes]

const DATE = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  MM: 'MM',
  YYYY: 'YYYY',
  YYYYMMDD: 'YYYYMMDD',
  DDDD_D: 'dddd D',
  LT_A: 'LT A',
  LT: 'LT',
  HH_MM: 'HH:mm',
  DDDD: 'dddd',
  DD: 'DD',
  CH: 'CH',
  SA: 'SA',
  AM: 'AM',
  PM: 'PM',
  DD_MM_YYYY_HH_MM: 'DD/MM/YYYY HH:mm',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD HH:mm',
  DD_MM_YYYY: 'DD/MM/YYYY',
}

const PAYMENT_METHOD_CONTENT = {
  [PaymentMethodEnum.Cash]: {
    i18nKeyTitle: R.strings.cash,
  },
  [PaymentMethodEnum.Bank]: {
    i18nKeyTitle: R.strings.transfer,
  },
  [PaymentMethodEnum.EWallet]: {
    i18nKeyTitle: R.strings.e_wallet,
  },
}

const TREATMENT_FILTER_DEFAULT = [
  '5c4ed45219b2900ecda55034',
  '5c4ed3c319b2900ecda5502d',
  '5c4ed0fb19b2900ecda55020',
]

const DefineStatus = {
  [StatusEnum.ACTIVE]: R.strings.active,
  [StatusEnum.INACTIVE]: R.strings.inactive,
  [StatusEnum.BLOCK]: R.strings.block,
  [StatusEnum.DELETED]: R.strings.deleted,
}

const DefineStatusWithoutDeleted = {
  [StatusEnum.ACTIVE]: R.strings.active,
  [StatusEnum.INACTIVE]: R.strings.inactive,
  [StatusEnum.BLOCK]: R.strings.block,
}

const DefineStatusColor = {
  [StatusEnum.ACTIVE]: {
    background: 'linear-gradient(317deg, #629DAA 0%, #6DAEBD 100%)',
    color: '#FFFFFF',
  },
  [StatusEnum.INACTIVE]: {
    background: 'linear-gradient(137deg, #FFAE49 0%, #FF7617 100%)',
    color: '#FFFFFF',
  },
  [StatusEnum.BLOCK]: {
    background: 'linear-gradient(136.55deg, #FFAE49 0%, #FF7617 100%)',
    color: '#FFFFFF',
  },
  [StatusEnum.DELETED]: {
    background: 'linear-gradient(95deg, #FF6B6B 0%, #F44949 99.59%)',
    color: '#FFFFFF',
  },
}

const PurchaseOrderFromStatus = {
  [PurchaseOrderStatusEnum.DRAFT]: [PurchaseOrderStatusEnum.PENDING],
  [PurchaseOrderStatusEnum.PENDING]: [PurchaseOrderStatusEnum.CONFIRMED],
  [PurchaseOrderStatusEnum.CONFIRMED]: [
    PurchaseOrderStatusEnum.CANCEL,
    PurchaseOrderStatusEnum.SENT_TO_SUPPLIER,
  ],
  [PurchaseOrderStatusEnum.CANCEL]: [],
  [PurchaseOrderStatusEnum.SENT_TO_SUPPLIER]: [
    PurchaseOrderStatusEnum.RECEIVED,
  ],
  [PurchaseOrderStatusEnum.DONE]: [],
  [PurchaseOrderStatusEnum.RECEIVED]: [PurchaseOrderStatusEnum.DONE],
  [PurchaseOrderStatusEnum.RETURNED]: [],
}

const PurchaseOrderStatusName = {
  [PurchaseOrderStatusEnum.DRAFT]: R.strings.draft,
  [PurchaseOrderStatusEnum.PENDING]: R.strings.purchase_order_pending,
  [PurchaseOrderStatusEnum.CONFIRMED]: R.strings.purchase_order_confirmed,
  [PurchaseOrderStatusEnum.CANCEL]: R.strings.purchase_order_cancel,
  [PurchaseOrderStatusEnum.SENT_TO_SUPPLIER]:
    R.strings.purchase_order_sent_to_supplier,
  [PurchaseOrderStatusEnum.DONE]: R.strings.purchase_order_done,
  [PurchaseOrderStatusEnum.RECEIVED]: R.strings.purchase_order_received,
  [PurchaseOrderStatusEnum.RETURNED]: R.strings.purchase_order_returned,
}

const PurchaseOrderStatusColor = {
  [PurchaseOrderStatusEnum.DRAFT]: {
    background: '#FFFFFF',
    color: '#002244',
  },
  [PurchaseOrderStatusEnum.PENDING]: {
    background: 'linear-gradient(136.55deg, #FFAE49 0%, #FF7617 100%)',
    color: '#FFFFFF',
  },
  [PurchaseOrderStatusEnum.CONFIRMED]: {
    background: 'linear-gradient(316.55deg, #2F7EFF 0%, #659FFF 100%)',
    color: '#FFFFFF',
  },
  [PurchaseOrderStatusEnum.CANCEL]: {
    background: 'linear-gradient(94.8deg, #FF6B6B 0%, #F44949 99.59%)',
    color: '#FFFFFF',
  },
  [PurchaseOrderStatusEnum.SENT_TO_SUPPLIER]: {
    background: 'linear-gradient(92.83deg, #3882CA 0%, #1A4D7E 100%)',
    color: '#FFFFFF',
  },
  [PurchaseOrderStatusEnum.DONE]: {
    background: 'linear-gradient(316.55deg, #629DAA 0%, #6DAEBD 100%)',
    color: '#FFFFFF',
  },
  [PurchaseOrderStatusEnum.RECEIVED]: {
    background: '#00CC6A',
    color: '#FFFFFF',
  },
  [PurchaseOrderStatusEnum.RETURNED]: {
    background: 'linear-gradient(93.05deg, #FD7B59 0%, #F75428 100%)',
    color: '#FFFFFF',
  },
}

const PurchaseOrderStatusIcon = {
  [PurchaseOrderStatusEnum.PENDING]: SvgPurchaseOrderPendingIcon,
  [PurchaseOrderStatusEnum.CONFIRMED]: SvgPurchaseOrderConfirmedIcon,
  [PurchaseOrderStatusEnum.CANCEL]: SvgPurchaseOrderCancelIcon,
  [PurchaseOrderStatusEnum.SENT_TO_SUPPLIER]:
    SvgPurchaseOrderSentToSupplierIcon,
  [PurchaseOrderStatusEnum.DONE]: SvgPurchaseOrderDoneReceivedIcon,
  [PurchaseOrderStatusEnum.RECEIVED]: SvgPurchaseOrderDoneReceivedIcon,
  [PurchaseOrderStatusEnum.RETURNED]: SvgPurchaseOrderReturnedIcon,
}

const DefineYesNoData = {
  [YesNoEnum.No]: {
    keyI18n: R.strings.no,
  },
  [YesNoEnum.Yes]: {
    keyI18n: R.strings.yes
  }
}


const SearchAppointmentMenuAction = [
  {
    type: SEARCH_APPOINTMENT_ACTION.ADD,
    label: R.strings.add_schedule,
  },
  {
    type: SEARCH_APPOINTMENT_ACTION.UPDATE,
    label: R.strings.update,
  },
  {
    type: SEARCH_APPOINTMENT_ACTION.SEND,
    label: R.strings.send_sms,
  },
  {
    type: SEARCH_APPOINTMENT_ACTION.CANCEL,
    label: R.strings.cancel_booking,
  },
]

const PurchaseOrderActionFromStatus = {
  [PurchaseOrderStatusEnum.DRAFT]: [PurchaseOrderActionEnum.UPDATE],
  [PurchaseOrderStatusEnum.PENDING]: [
    PurchaseOrderActionEnum.RE_ORDER,
    PurchaseOrderActionEnum.VIEW_DETAIL,
    PurchaseOrderActionEnum.PRINT,
  ],
  [PurchaseOrderStatusEnum.CONFIRMED]: [
    PurchaseOrderActionEnum.VIEW_DETAIL,
    PurchaseOrderActionEnum.RE_ORDER,
    PurchaseOrderActionEnum.PRINT,
  ],

  [PurchaseOrderStatusEnum.CANCEL]: [
    PurchaseOrderActionEnum.VIEW_DETAIL,
    PurchaseOrderActionEnum.RE_ORDER,
  ],
  [PurchaseOrderStatusEnum.SENT_TO_SUPPLIER]: [
    PurchaseOrderActionEnum.VIEW_DETAIL,
    PurchaseOrderActionEnum.RE_ORDER,
    PurchaseOrderActionEnum.PRINT,
    PurchaseOrderActionEnum.RECEIVE,
  ],
  [PurchaseOrderStatusEnum.DONE]: [
    PurchaseOrderActionEnum.VIEW_DETAIL,
    PurchaseOrderActionEnum.RE_ORDER,
    PurchaseOrderActionEnum.PRINT,
  ],
  [PurchaseOrderStatusEnum.RECEIVED]: [
    PurchaseOrderActionEnum.VIEW_DETAIL,
    PurchaseOrderActionEnum.RE_ORDER,
    PurchaseOrderActionEnum.PRINT,
  ],
  [PurchaseOrderStatusEnum.RETURNED]: [
    PurchaseOrderActionEnum.VIEW_DETAIL,
    PurchaseOrderActionEnum.RE_ORDER,
    PurchaseOrderActionEnum.PRINT,
  ],
}

const PurchaseOrderActionName = {
  [PurchaseOrderActionEnum.UPDATE]: R.strings.update,
  [PurchaseOrderActionEnum.VIEW_DETAIL]: R.strings.view_detail,
  [PurchaseOrderActionEnum.RECEIVE]: R.strings.purchase_order_receive,
  [PurchaseOrderActionEnum.RE_ORDER]: R.strings.purchase_order_re_order,
  [PurchaseOrderActionEnum.PRINT]: R.strings.purchase_order_print,
}

const PurchaseOrderActionIcon = {
  [PurchaseOrderActionEnum.UPDATE]: SvgPurchaseOrderEditIcon,
  [PurchaseOrderActionEnum.VIEW_DETAIL]: SvgFileIcon,
  [PurchaseOrderActionEnum.RECEIVE]: SvgReceiveIcon,
  [PurchaseOrderActionEnum.RE_ORDER]: SvgReOrderIcon,
  [PurchaseOrderActionEnum.PRINT]: SvgPrinterIcon,
}

const PurchaseOrderActionPath = {
  [PurchaseOrderActionEnum.UPDATE]: INVENTORY_WAREHOUSE_PURCHASE_UPDATE_PATH,
  [PurchaseOrderActionEnum.VIEW_DETAIL]: INVENTORY_WAREHOUSE_PURCHASE_VIEW_PATH,
  [PurchaseOrderActionEnum.RECEIVE]:
    INVENTORY_WAREHOUSE_PURCHASE_GOODS_RECEIPT_PATH,
  [PurchaseOrderActionEnum.RE_ORDER]:
    INVENTORY_WAREHOUSE_PURCHASE_RE_ORDER_PATH,
  [PurchaseOrderActionEnum.PRINT]: INVENTORY_WAREHOUSE_PURCHASE_PRINT_PATH,
}

const NOTIFICATION_TYPE = {
  APPOINTMENT: 'APPOINTMENT',
  EXPORT_DAILY_INVOICE: 'EXPORT_DAILY_INVOICE',
  EXPORT_PATIENT_DEBT: 'EXPORT_PATIENT_DEBT',
  EXPORT_PATIENT_DEBT_CONTRACT: 'EXPORT_PATIENT_DEBT_CONTRACT',
  OTHER: 'OTHER',
  ZNS_SURVEY: 'ZNS_SURVEY'
}

export {
  DATE,
  YesNoArray,
  PAYMENT_METHOD_CONTENT,
  TREATMENT_FILTER_DEFAULT,
  DefineStatus,
  DefineStatusColor,
  DefineStatusWithoutDeleted,
  PurchaseOrderStatusColor,
  PurchaseOrderStatusName,
  PurchaseOrderStatusIcon,
  DefineYesNoData,
  SearchAppointmentMenuAction,
  PurchaseOrderFromStatus,
  PurchaseOrderActionName,
  PurchaseOrderActionIcon,
  PurchaseOrderActionFromStatus,
  PurchaseOrderActionPath,
  NOTIFICATION_TYPE
}
