import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseDivider } from 'app/components/common/BaseDivider'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SectionBaseWrapper = styled(SectionBase)`
  padding: ${PADDING.xl} !important;
`
export const SectionTitle = styled(BaseText)`
  font-size: 16px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: ${convertedVariables.primaryColor};
`
export const SectionDescription = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: ${convertedVariables.primaryColor};
  margin-top: 8px;
`
export const ItemLabel = styled(BaseText)`
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: #858c94;
`
export const ItemValue = styled(BaseText)`
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: ${convertedVariables.primaryColor};
  margin-top: 8px;
`
export const Divider = styled(BaseDivider)`
  margin: 20px 0;
`
