/* eslint-disable @typescript-eslint/no-explicit-any */

import { ParamsList, ResponseType } from 'parkway-web-common'

interface IPatientSurveyDynamic {
  id?: string
  name?: string
  city?: string
  district?: string
  dob: string
  contact: string
  _id: string

  email?: string
  address?: string

  emergency?: {
    name?: string
    contact?: string
  }

  reason: EVisitReason[]
  reason_other: string

  signatureUrl?: string
}

export interface IAnswerSurveyDynamic {
  id: string
  value?: string
  note?: string
  _id?: string
  items: IAnswerSurveyDynamic[]
}

export enum TypeEnum {
  'INFO_PATIENT' = 'info-patient',
  'SURVEY_DYNAMIC' = 'survey-dynamic',
}

export enum EVisitReason {
  GeneralCheckup = 'Khám điều trị tổng quát',
  WisdomToothExtraction = 'Nhổ răng khôn',
  TeethWhitening = 'Tẩy trắng răng',
  Orthodontics = 'Chỉnh nha / niềng răng',
  Implant = 'Trồng răng giả / implant',
  CosmeticDentistry = 'Răng sứ thẩm mỹ / veneer',
  Other = 'Khác',
}

export interface ISurveyDynamic {
  _id?: string
  date?: string
  patientId?: string
  patient?: IPatientSurveyDynamic
  typeSurvey?: TypeSurveyEnum
  signatureUrl?: string
  answers?: IAnswerSurveyDynamic[]
  createdAt?: string
  updatedAt?: string
  type?: TypeEnum
}

export enum EOptionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  TEXT_INPUT = 'textInput',
  RADIO_INPUT = 'radioInput',
}

export interface IQuestionSurveyDynamic {
  id: string
  index: number
  name: string
  options?: { id: string; name: string; showNote?: boolean }[]
  required: boolean
  type: EOptionType
  items?: IQuestionSurveyDynamic[]
}

export interface ITotalSurveyDynamic {
  total?: number
  _id?: StatusApproveEnum | null
}

export type IResponseGetSurveyDynamic = ResponseType<ISurveyDynamic[]>

export enum StatusApproveEnum {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export enum TypeSurveyEnum {
  TEETH_HEALTH = 'teeth-health',
  MEDICAL_HISTORY = 'medical-history',
  INITIAL_DENTAL_EXAMINATION = 'initial-dental-examination',
}

export enum CustomerSourceEnum {
  NoInfo = 'Không có thông tin',
  WalkIn = 'Walk in (do tv tạo)',
  Advertising = 'Quảng cáo (do CSKH tạo)',
  KOL = 'KOL giới thiệu',
  StaffSuggested = 'NV phòng khám đề xuất',
  OldCustomerReferral = 'KH cũ giới thiệu',
  BOMReferral = 'Người quen BOM',
  Website = 'Website',
  CompanyStaff = 'Nhân viên công ty',
  StaffRelative = 'Người thân nhân viên',
  EventCorporate = 'Event Corporate',
}

export interface IParamsGetSurveyDynamic extends ParamsList {
  statusApprove: StatusApproveEnum
  typeSurvey?: TypeSurveyEnum[]
}

export interface IBodyApproveSurveyDynamic {
  statusApprove?: StatusApproveEnum
  referer?: CustomerSourceEnum
  id: string
}

export interface IParamsGetDetailSurveyDynamic {
  id: string
}
