import {
  ApiClient,
  ApiClientCRM
} from 'app/service/api-service'
import { NotificationEndPoint } from './constant'
import { IParamsGetNotificationHistory, IPayloadReadNotification } from './type'

export const requestGetNotificationHistory = (
  params: IParamsGetNotificationHistory,
) =>
  ApiClientCRM.Get({
    url: NotificationEndPoint.History.request,
    params: { params },
  })

export const requestPutReadNotification = (body?: IPayloadReadNotification) =>
  ApiClient.Put({
    url: NotificationEndPoint.History.read,
    body,
  })
