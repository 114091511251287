import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHookTable } from './hook'
import { CampaignListLayout, FilterLayout } from './layouts'
import { IDataGroupTable } from './type'
const DiscountCampaignContainer = () => {
  const { t } = useTranslation()
  const {
    data,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    isLoading,
    pagination,
    navigateCreate,
    isHavePermission,
    expandedRowKeys,
    handleExpand
  } = useHookTable()

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.discount_campaign_management}
        rightComponent={
          isHavePermission?.isHavePermissionCreate ? (
            <ButtonWrapper
              align={'middle'}
              wrap={false}
              gutter={8}
              onClick={navigateCreate}
            >
              <BaseCol>
                <PlusOutlined rev={undefined} />
              </BaseCol>
              <BaseCol>
                <BaseText fontWeight="medium" colorText="backgroundColor">
                  {t(R.strings.create_campaign)}
                </BaseText>
              </BaseCol>
            </ButtonWrapper>
          ) : null
        }
      />
      <RootWrapper size={8}>
        <FilterLayout formData={filter} onChange={handleChangeFilter} />
        <BaseTableWrapper
          columns={columns}
          dataSource={data}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            expandIcon: () => null,
            showExpandColumn: false,
            expandedRowRender: (record: IDataGroupTable) => (
              <CampaignListLayout filter={filter} campaignGroupCode={record?.code} />
            ),
          }}
        />
      </RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default DiscountCampaignContainer

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
`

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-left-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
  }

  .status-column {
    min-width: 125px !important;
    justify-content: center !important;
    align-items: center !important;
  }
`

const ButtonWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxxs};
  background-image: linear-gradient(90deg, #fd7b59, #f75428);
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  svg {
    fill: ${convertedVariables.backgroundColor};
  }
`
