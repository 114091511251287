import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { ModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`
export const ButtonModal = styled(BaseButton)`
  color: var(--primary-color) !important;
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  border: 1px solid #e1e7ea;
  border-radius: 8px;
  padding: 4px 12px;
  opacity: 0.8 !important;
  cursor: pointer;
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const FormInputPassword = styled(InputPassword)``

export const SelectBaseStyled = styled(BaseSelect)``

export const ButtonSkillAction = styled(BaseButton)`
  border-color: transparent;
  transition: all 0.5s ease;
  color: var(--primary-color) !important;
  box-shadow: none;
  border: 1px solid #e1e7ea;
  font-size: ${FONT_SIZE.xxs} !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  opacity: 0.8;
  padding: 4px 8px;

  &:hover {
    background-color: var(--other-blue-color);
    color: var(--background-color) !important;
    transition: all 0.5s ease;
    border-color: transparent !important;
    box-shadow: none;
  }
`
export const ModalWrapper = styled(ModalComponent)`
  background: red;
`
export const BaseTableReport = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    text-align: left !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: ${convertedVariables.primaryColor};
    opacity: 0.7;
  }
`
export const ClassificationWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;

  & span {
    font-size: ${FONT_SIZE.xxs};
    color: var(--text-primary2-color);
  }

  .tag {
    background-color: #f8f8f8;
    border-radius: 100px;
    padding: 2px 1rem;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .excellent {
    background-color: #fffbed;
    color: #fa9f34;
  }

  .good {
    background-color: #ecfdf2;
    color: #28ba59;
  }

  .average_good {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .below_average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .weak {
    background-color: #ffe6e6;
    color: #ff7373;
  }
`
