import { IWorkInfoUserInfo } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { DateUtil, FORMAT_DD_MM_YYYY } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import {
  ClassificationWrapper,
  DateText,
  Divider,
  ListLabel,
  SectionBaseWrapper,
  SectionDescription,
  SectionTitle,
  StatusTextWrapper,
  TitleFormList,
  WorkInfoItem,
} from './styles'
import { StatusKey, StatusName } from './type'
import BaseText from 'app/components/common/BaseText'

interface IProps {
  listWorkInfo?: IWorkInfoUserInfo[]
  listWorkInfoAka?: IWorkInfoUserInfo[]
}

export const WorkInfo = (props: IProps) => {
  const { listWorkInfo, listWorkInfoAka } = props

  const { t } = useTranslation()
  const WorkInfos = () => {
    return (
      <BaseSpace>
        <ListLabel>
          {t(R.strings.profile_content_job_title_main_column)}
        </ListLabel>
        {listWorkInfo?.map((item: IWorkInfoUserInfo) => {
          return (
            <WorkInfoItem>
              <BaseRow align={'middle'}>
                <TitleFormList>{item?.jobtitle?.name}</TitleFormList>
                <StatusTextWrapper>
                  <span className={StatusKey[item?.status || '']}>
                    {t(StatusName[item?.status || ''])}
                  </span>
                </StatusTextWrapper>
              </BaseRow>
              <ClassificationWrapper>
                {item?.startdate && (
                  <DateText>
                    {t(R.strings.start_date)}:{' '}
                    {DateUtil.formatDate(item?.startdate, FORMAT_DD_MM_YYYY)}
                  </DateText>
                )}
                {item?.enddate && (
                  <DateText>
                    {t(R.strings.end_date)}:{' '}
                    {DateUtil.formatDate(item?.enddate, FORMAT_DD_MM_YYYY)}
                  </DateText>
                )}
              </ClassificationWrapper>
            </WorkInfoItem>
          )
        })}
      </BaseSpace>
    )
  }

  const WorkInfosAka = () => {
    return (
      <BaseSpace>
        <ListLabel>
          {t(R.strings.profile_content_job_title_secondary_column)}
        </ListLabel>
        {listWorkInfoAka?.map((item: IWorkInfoUserInfo) => {
          return (
            <WorkInfoItem>
              <BaseRow align={'middle'}>
                <TitleFormList>{item?.jobtitle?.name}</TitleFormList>
                <StatusTextWrapper>
                  <span className={StatusKey[item?.status || '']}>
                    {t(StatusName[item?.status || ''])}
                  </span>
                </StatusTextWrapper>
              </BaseRow>
              <ClassificationWrapper>
                {item?.startdate && (
                  <DateText>
                    {t(R.strings.start_date)}:{' '}
                    {DateUtil.formatDate(item?.startdate, FORMAT_DD_MM_YYYY)}
                  </DateText>
                )}
                {item?.enddate && (
                  <DateText>
                    {t(R.strings.end_date)}:{' '}
                    {DateUtil.formatDate(item?.enddate, FORMAT_DD_MM_YYYY)}
                  </DateText>
                )}
              </ClassificationWrapper>
            </WorkInfoItem>
          )
        })}
      </BaseSpace>
    )
  }

  return (
    <SectionBaseWrapper>
      <SectionTitle>{t(R.strings.section_job_title)}</SectionTitle>
      <SectionDescription>
        {t(R.strings.job_title_description)}
      </SectionDescription>
      <Divider />
      <WorkInfos />
      <Divider />
      <WorkInfosAka />
    </SectionBaseWrapper>
  )
}
