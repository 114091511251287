import {
  IUnWorkingDay,
  LEAVE_SHIFT_NAME,
  LeaveShiftDayOffEnum,
} from 'app/api/hr/models/approve-day-off'
import R from 'app/assets/R'
import { convertToDayOfWeek } from 'app/common/helpers'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { DateUtil, moment } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  data: IUnWorkingDay[]
}
export const ReferenceItems = (props: IProps) => {
  const { data } = props
  const { t } = useTranslation()

  return (
    <Wrapper>
      {data?.map(i => {
        const timeRange =
          moment(i?.unWorkingDateEnd)
            .utc(true)
            .diff(moment(i?.unWorkingDateStart).utc(true), 'days') > 0
            ? `${convertToDayOfWeek(
                moment(i?.unWorkingDateStart).utc(true)?.day(),
              )}, ${DateUtil?.formatDate(
                i?.unWorkingDateStart || '',
                'DD/MM/YYYY',
              )} - ${convertToDayOfWeek(
                moment(i?.unWorkingDateStart).utc(true)?.day(),
              )}, ${DateUtil?.formatDate(
                i?.unWorkingDateEnd || '',
                'DD/MM/YYYY',
              )}`
            : `${convertToDayOfWeek(
                moment(i?.unWorkingDateStart).utc(true)?.day(),
              )}, ${DateUtil?.formatDate(
                i?.unWorkingDateStart || '',
                'DD/MM/YYYY',
              )}`

        const totalDays = `${i?.unWorkingDayAmount} ${t(R.strings._day)}`
        const shift =
          i?.unWorkingShift === LeaveShiftDayOffEnum.F
            ? ''
            : ` (${LEAVE_SHIFT_NAME[i?.unWorkingShift || '']})`
        return (
          <BaseRow align={'middle'}>
            <BaseText
              children={timeRange}
              fontWeight="medium"
              fontSize="xs"
              colorText="primaryColor"
            />
            <Dot />

            <BaseText
              children={`${totalDays}${shift}`}
              fontWeight="medium"
              fontSize="xs"
              colorText="primaryColor"
            />
          </BaseRow>
        )
      })}
    </Wrapper>
  )
}
const Wrapper = styled(BaseSpace)`
  background: #f9fafb;
  border: 1px solid #f4f6f9;
  border-radius: 8px;
  padding: 16px;
`
const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #b0bac5;
  margin: 0 10px;
`
