import { SearchOutlined } from '@ant-design/icons'
import { ICampaignGroup } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePagination } from 'app/components/common/BasePagination'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Loading } from 'app/components/common/Loading'
import { ModalComponent } from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import {
  convertColumnTable,
  ExpandedTableValue,
} from 'app/components/tables/BaseTableReport/hook'
import { IDataGroupTable } from 'app/containers/Marketing/DiscountCampaignManagement/type'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { formatMoney, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { useHook } from './hook'
import { ListCampaignLayout } from './layouts'

export const ModalAddCampaignGroup = () => {
  const {
    t,
    modalRef,
    pagination,
    searchCampaign,
    isLoading,
    dataFlatten,
    openModal,
    handleSubmit,
    onChangePagination,
    onChangeSearchCampaign,
    expandedRowKeys,
    handleExpand,
    selectedStackedCampaign,
    isSelectedAll,
    onChangeSelectedAll,
    indeterminateSelectedAll,
    onChangeGroupSelected,
    onTabSelectCampaign,
  } = useHook()

  return (
    <ModalComponent
      titleModal={t(R.strings.add_campaign)}
      ref={modalRef}
      buttonOpenCustom={
        <BaseButton onClick={openModal}>{t(R.strings.add_campaign)}</BaseButton>
      }
      handleSubmit={handleSubmit}
      paddingContent="0px"
      widthModal={1000}
      renderContent={
        <RootWrapper>
          <BaseRow className="head">
            <BaseCol xl={24}>
              <BaseForm.Item hiddenLabel>
                <BaseInput
                  placeholder={t(R.strings.enter_campaign_name)}
                  value={searchCampaign}
                  onChange={e =>
                    onChangeSearchCampaign?.(e?.target?.value ?? '')
                  }
                  allowClear
                  prefix={<SearchOutlined rev={undefined} />}
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>

          <BaseTableWrapper
            columns={[
              convertColumnTable<IDataGroupTable>({
                title: t(R.strings.group_campaign),
                key: 'name',
                fixed: 'left',
                render: (_, record) => {
                  const isExpanded = expandedRowKeys.includes(record.key ?? 0)
                  const _handleExpand = () => {
                    handleExpand(!isExpanded, record?.key)
                  }

                  return (
                    <ExpandedTableValue
                      isExpanded={isExpanded}
                      handleExpand={_handleExpand}
                      value={record?.name}
                      isHaveAreaWhenNotHaveActionExpanded
                    />
                  )
                },
              }),
              convertColumnTable<IDataGroupTable>({
                title: t(R.strings.total),
                key: 'totalCampaign',
                width: '10%',
                classNameWidthColumnOverwrite: 'number-column',
                render: (value?: number) => {
                  return (
                    <BaseText
                      children={formatMoney(value ?? 0, '')}
                      fontWeight="medium"
                      opacity="0.7"
                    />
                  )
                },
              }),
              convertColumnTable<IDataGroupTable>({
                key: 'code',
                width: '12%',
                classNameWidthColumnOverwrite: 'number-column',
                title: (
                  <BaseRow gutter={16} justify={'center'}>
                    <BaseCheckbox
                      value={isSelectedAll}
                      checked={isSelectedAll}
                      onChange={e => onChangeSelectedAll(e?.target?.checked)}
                      indeterminate={indeterminateSelectedAll}
                    />
                  </BaseRow>
                ),
                render: (_, record) => {
                  const itemGroupExist = selectedStackedCampaign?.find(item =>
                    isEqual(item?.group?.code, record?.code),
                  )

                  let indeterminateSelectedGroup = false

                  if (itemGroupExist) {
                    if (
                      itemGroupExist?.excludes?.length &&
                      itemGroupExist?.excludes?.length <
                        (itemGroupExist?.group?.totalCampaign ?? 0)
                    ) {
                      indeterminateSelectedGroup = true
                    } else if (
                      itemGroupExist?.includes?.length &&
                      itemGroupExist?.includes?.length <
                        (itemGroupExist?.group?.totalCampaign ?? 0)
                    ) {
                      indeterminateSelectedGroup = true
                    }
                  }

                  return (
                    <BaseRow gutter={16} justify={'center'}>
                      <BaseCheckbox
                        value={itemGroupExist?.isSelectedAll}
                        checked={itemGroupExist?.isSelectedAll}
                        onChange={() =>
                          onChangeGroupSelected?.({ group: record })
                        }
                        indeterminate={indeterminateSelectedGroup}
                      />
                    </BaseRow>
                  )
                },
              }),
            ]}
            dataSource={dataFlatten?.data ?? []}
            pagination={false}
            loading={isLoading}
            rowClassName="row-overwrite-style"
            scroll={{ x: true }}
            expandable={{
              expandedRowKeys,
              onExpand: handleExpand,
              expandIcon: () => null,
              showExpandColumn: false,
              expandedRowRender: (item: ICampaignGroup) => {
                const stackedCampaignSelected = selectedStackedCampaign?.find(
                  itm => isEqual(itm?.group?.code, item?.code),
                )
                return (
                  <ListCampaignLayout
                    group={item}
                    stackedCampaign={stackedCampaignSelected}
                    onTabSelectCampaign={onTabSelectCampaign}
                  />
                )
              },
            }}
          />

          <BaseRow
            className="footer"
            justify={'space-between'}
            gutter={[16, 8]}
            align={'middle'}
          >
            <BaseCol>
              <BaseRow gutter={8} align={'middle'}>
                <BaseCol>
                  <BaseText
                    children={`${t(R.strings.total_group_selected)}:`}
                    fontWeight="medium"
                  />
                </BaseCol>
                <BaseCol>
                  <BaseText
                    children={selectedStackedCampaign?.length}
                    colorText="statesGreenColor"
                    fontWeight="medium"
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
            <BaseCol>
              <BasePagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={dataFlatten?.metadata?.totalItem ?? 0}
                onChange={(page, pageSize) => {
                  onChangePagination({
                    ...pagination,
                    current: page,
                    pageSize,
                  })
                }}
              />
            </BaseCol>
          </BaseRow>

          {isLoading ? <Loading /> : null}
        </RootWrapper>
      }
    />
  )
}

const RootWrapper = styled(BaseSpace)`
  .head {
    padding: 0px ${PADDING.md};
  }

  .footer {
    padding: 0px ${PADDING.md};
  }
`

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-left-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
  }

  .status-column {
    min-width: 125px !important;
    justify-content: center !important;
    align-items: center !important;
  }
`
