import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import MedicalRecordAndInformationContainer from 'app/containers/DentalTreatmentManagement/MedicalRecordAndInformation'

const MedicalRecordAndInformationPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.dental_treatment_management)}</PageTitle>
      <MedicalRecordAndInformationContainer />
    </>
  )
}
const MedicalRecordAndInformationPage = memo(
  MedicalRecordAndInformationPageCpn,
  isEqual,
)

export default MedicalRecordAndInformationPage
