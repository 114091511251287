import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, HeadPageWrapper, RootWrapper } from './styles'

export const Report024Container = () => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    handleExportExcel,
    isLoadingExport,
  } = useTable()
  const { isHavePermissionFunction } = useVerifyPermissionAndRedirect()
  return (
    <RootWrapper>
      <HeadPageWrapper
        titleI18nKey={R.strings.report_24}
        isExport={isHavePermissionFunction(
          ModulePermissionEnum.REPORT,
          FunctionPermissionEnum.REPORT_EXPORT_REPORT_24,
        )}
        onPressExport={handleExportExcel}
        isLoadingExport={isLoadingExport}
      />
      <SectionBase>
        <BaseSpace>
          <FilterLayout filter={filter} onChangeFilter={handleChangeFilter} />

          <BaseTableReport
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={isLoading}
            onChange={handleTableChange}
            rowClassName="row-overwrite-style"
            scroll={{
              x: true,
              y: data?.length && data?.length > 10 ? 570 : undefined,
            }}
          />
        </BaseSpace>
      </SectionBase>
    </RootWrapper>
  )
}
