/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import { requestGetImportTemplate } from 'app/api/marketing/discount-campaign'
import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import {
  CampaignTypeEnum,
  DiscountTypeEnum,
  IDiscountCampaign,
  IPayloadCreateCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { YesNoEnum } from 'app/common/enum'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { _DEV_ } from 'app/constant'
import { IUnit } from 'app/model/unit.model'
import { useCreateDiscountCampaign } from 'app/react-query/hook/campaign'
import { Dayjs } from 'dayjs'
import { isEmpty, isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router'
import { useBundleHook } from './hook/bundle-hook'
import { useBuyOneGetOneHook } from './hook/buy-one-get-one-hook'
import { useHookInfo } from './hook/info-hook'
import { useTreatmentTypeHook } from './hook/treatment-type-hook'
import { IResponseImportBundle } from './RootLayout/Contents/layouts/BundleTypeLayout/ImportModal/type'
import { IResponseImportBuyOneGetOne } from './RootLayout/Contents/layouts/BuyOneGetOneTypeLayout/ImportModal/type'
import { IResponseImportFile } from './RootLayout/Contents/layouts/TreatmentTypeLayout/layouts/ImportModal/type'
import {
  BuyOneGetOneItem,
  IBillDiscountInTable,
  IBundleServiceItem,
  IFormData,
  ITreatmentInTable,
} from './type'
import { useTotalBillTypeHook } from './hook/total-bill-type-hook'
import { useBundleBillDiscountHook } from './hook/bundle-bill-discount-hook'
import R from 'app/assets/R'
import { IStackedWithCampaign } from '../CloneCampaign/type'

interface ICreateCampaignContext {
  form?: FormInstance<IFormData>
  handleSubmit?: (values: IFormData) => void
  areaList?: IUnit[]
  isLoadingArea?: boolean
  keywordSearchArea?: string
  setKeywordSearchArea?: Dispatch<SetStateAction<string>>
  filterTreeNode?: (value?: string, treeNode?: any) => any
  onChangeClinic?: (ids: string[]) => void
  selectedClinic?: string[]
  campaignType?: CampaignTypeEnum
  onChangeCampaignType?: (value: CampaignTypeEnum) => void
  onSuccessImportTreatment?: (values: IResponseImportFile[]) => void

  onChangeService?: (service: ITreatmentInTable[]) => void
  listService?: ITreatmentInTable[]
  onChangeDiscountType?: (value: DiscountTypeEnum, id?: string) => void
  onChangeDiscountValue?: (value: number, id?: string) => void
  onDeleteService?: (id?: string) => void

  isDisableSubmit?: boolean

  onAddNewBuyOneGetOne?: () => void
  listBuyOneGetOne?: BuyOneGetOneItem[]
  onRemoveBuyOneGetOneByIndex?: (index: number) => void
  onChangeNameByIndex?: (index: number, name: string) => void
  onChangeSelectedTreatmentByIndex?: (
    index: number,
    selectedTreatment: ITreatmentInTable[],
  ) => void
  onChangeDiscountTreatmentByIndex?: (
    index: number,
    discountTreatment: ITreatmentInTable[],
  ) => void
  onChangeItemByIndex?: (index: number, item: BuyOneGetOneItem) => void
  onChangeDiscountTypeInDiscountTreatment?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueInDiscountTreatment?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void

  onAddNewBundleService?: () => void
  listBundleService?: IBundleServiceItem[]
  onRemoveBundleServiceByIndex?: (index: number) => void
  onChangeNameBundleByIndex?: (index: number, name: string) => void
  onChangeDiscountTypeItemBundleService?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueItemBundleService?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void
  onChangeItemBundleServiceByIndex?: (
    index: number,
    item: IBundleServiceItem,
  ) => void
  onChangeQuantityItemBundleService?: (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => void
  onDeleteItemInSelectedTreatment?: (
    index: number,
    treatmentIndex: number,
  ) => void

  isLoadingSubmit?: boolean

  handleGoBack?: () => void

  onChangeNameInfo?: (
    value: string | number | string[] | undefined | null | Dayjs,
    key: keyof IFormData,
  ) => void

  info?: IFormData
  handleGetImportTemplate?: () => void
  loading?: boolean

  onSuccessImportBuyOneGetOne?: (values: IResponseImportBuyOneGetOne[]) => void
  onSuccessImportBundle?: (values: IResponseImportBundle[]) => void

  // total bill
  onChangeTotalBill?: (service: IBillDiscountInTable[]) => void
  listTotalBill?: IBillDiscountInTable[]
  onChangeDiscountTypeTotalBill?: (value: DiscountTypeEnum, id?: string) => void
  onChangeDiscountValueTotalBill?: (value: number, id?: string) => void
  onDeleteTotalBill?: (id?: string) => void
  onAddTotalBill?: (bill: IBillDiscountInTable) => void

  // bundle total bill
  onAddNewBundleBillDiscountService?: () => void
  listBundleBillDiscountService?: IBundleServiceItem[]
  onRemoveBundleBillDiscountServiceByIndex?: (index: number) => void
  onChangeNameBundleBillDiscountByIndex?: (index: number, name: string) => void
  onChangeDiscountTypeItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    discountType: DiscountTypeEnum,
  ) => void
  onChangeDiscountValueItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    discountValue: number,
  ) => void
  onChangeItemBundleBillDiscountServiceByIndex?: (
    index: number,
    item: IBundleServiceItem,
  ) => void
  onChangeQuantityItemBundleBillDiscountService?: (
    index: number,
    treatmentIndex: number,
    quantity: number,
  ) => void
  onDeleteItemInSelectedTreatmentBundleBillDiscount?: (
    index: number,
    treatmentIndex: number,
  ) => void
  onSuccessImportBundleBillDiscount?: (values: IResponseImportBundle[]) => void
  isApplyWithOtherCampaign?: YesNoEnum
  setIsApplyWithOtherCampaign?: Dispatch<SetStateAction<YesNoEnum>>

  stackedWithCampaigns?: IDiscountCampaign[]
  onChangeStackedWithCampaigns?: (campaigns: IDiscountCampaign[]) => void

  stackedWithCampaignGroups?: IStackedWithCampaign[]
  onChangeStackedWithCampaignsGroup?: (groups: IStackedWithCampaign[]) => void
  onDeleteGroupInStackedCampaignGroup?: ({
    groupCode,
  }: {
    groupCode: string
  }) => void

  campaignApplyCommon?: string
  onChangeCampaignApplyCommon?: (value: string) => void
}
export const CreateCampaignContext = createContext<ICreateCampaignContext>({})

export const CreateCampaignProvider = ({ children }) => {
  const navigate = useNavigate()
  const [form] = BaseForm.useForm<IFormData>()

  const [loading, setLoading] = useState<boolean>(false)

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingSubmit } =
    useCreateDiscountCampaign()

  const hookInfo = useHookInfo({ form })

  const hookTreatmentType = useTreatmentTypeHook()

  const hookBuyOneGetOne = useBuyOneGetOneHook()

  const hookBundle = useBundleHook()

  const hookTotalBill = useTotalBillTypeHook()

  const hookBundleBillDiscount = useBundleBillDiscountHook()

  const isDisableSubmit = useMemo(() => {
    switch (hookInfo.campaignType) {
      case CampaignTypeEnum.Treatments:
        return (
          !!hookTreatmentType.listService?.find(
            item => !item.discountValue || !item.discountType,
          ) ||
          !!hookTreatmentType.listService?.find(
            item =>
              item?.discountValue &&
              item?.discountValue > 100 &&
              isEqual(DiscountTypeEnum.Percent, item?.discountType),
          )
        )
      case CampaignTypeEnum.TotalBill:
        return (
          !hookTotalBill.listTotalBill?.length ||
          !!hookTotalBill.listTotalBill?.find(
            item => !item.discount || !item.dType,
          ) ||
          !!hookTotalBill.listTotalBill?.find(
            item =>
              item?.discount &&
              item?.discount > 100 &&
              isEqual(DiscountTypeEnum.Percent, item?.dType),
          ) ||
          hookTotalBill.listTotalBill?.length !==
            new Set(hookTotalBill.listTotalBill?.map(item => item.totalBill))
              .size ||
          !!hookBundleBillDiscount?.listBundleBillDiscountService?.find(
            item => isEmpty(item?.name) || !item.selectedTreatment?.length,
          )
        )
      case CampaignTypeEnum.BuyOneGetOne:
        return (
          !hookBuyOneGetOne?.listBuyOneGetOne?.length ||
          !!hookBuyOneGetOne?.listBuyOneGetOne?.find(
            item =>
              isEmpty(item?.name) ||
              !item.selectedTreatment?.length ||
              !item.discountTreatment?.length ||
              !!item.discountTreatment?.find(
                itm => !itm.discountValue || !itm.discountType,
              ),
          )
        )
      case CampaignTypeEnum.Bundle:
        return (
          !hookBundle?.listBundleService?.length ||
          !!hookBundle?.listBundleService?.find(
            item =>
              isEmpty(item?.name) ||
              !item.selectedTreatment?.length ||
              !!item.selectedTreatment?.find(
                itm =>
                  itm.discountValue === undefined ||
                  !itm.discountType ||
                  !itm.quantity,
              ) ||
              !item.selectedTreatment?.find(item => item?.discountValue),
          )
        )
    }
  }, [
    hookTreatmentType?.listService,
    hookInfo?.campaignType,
    hookBuyOneGetOne?.listBuyOneGetOne,
    hookBundle?.listBundleService,
    hookTotalBill.listTotalBill,
    hookBundleBillDiscount?.listBundleBillDiscountService,
  ])

  const handleGoBack = () => {
    navigate?.(-1)
  }

  const handleSubmit = (values: IFormData) => {
    let payload: IPayloadCreateCampaign = {
      name: values.name,
      campaignType: hookInfo.campaignType,
      clinics: hookInfo.selectedClinic,
      start: values?.startDate?.toISOString(),
      end: values?.endDate?.toISOString(),
      priority: values?.priority,
      isStackedDiscount: !!isEqual(
        YesNoEnum.Yes,
        values?.applyWithOtherCampaign,
      ),
      applyType: values?.activeType,
      boughtTreatmentsDiscount: [],
      discounts: [],
      referralType: values?.referral,
      campaignGroupCode: values?.group_campaign,
    }

    if (
      isEqual(hookInfo?.isApplyWithOtherCampaign, YesNoEnum.Yes) &&
      hookInfo?.stackedWithCampaignGroups?.length &&
      isEqual(values?.campaign_apply_common, R.strings.campaign_list)
    ) {
      payload = {
        ...payload,
        stackedWithCampaigns: hookInfo?.stackedWithCampaignGroups?.map(item => {
          return {
            campaignGroupCode: item?.group?.code ?? '',
            excludes: item?.excludes?.map(itm => itm?._id ?? ''),
            includes: item?.includes?.map(itm => itm?._id ?? ''),
          }
        }),
      }
    }

    switch (hookInfo.campaignType) {
      case CampaignTypeEnum.Treatments:
        payload = {
          ...payload,
          discounts: hookTreatmentType?.listService?.map(item => ({
            treatment: item?._id ?? '',
            discount: item?.discountValue ?? 0,
            dType: item?.discountType,
            amount: item?.quantity ?? 1,
          })),
        }
        break
      case CampaignTypeEnum.TotalBill:
        payload = {
          ...payload,
          billDiscounts: hookTotalBill?.listTotalBill?.map(item => ({
            totalBill: item?.totalBill ?? 0,
            dType: item?.dType,
            discount: item?.discount,
          })),
          boughtTreatmentsDiscount:
            hookBundleBillDiscount?.listBundleBillDiscountService?.map(
              item => ({
                buyTreatment:
                  item?.selectedTreatment?.map(itm => ({
                    treatment: itm?._id,
                    amount: 1,
                  })) ?? [],
                name: item?.name,
              }),
            ) ?? [],
        }
        break
      case CampaignTypeEnum.BuyOneGetOne:
        payload = {
          ...payload,
          boughtTreatmentsDiscount:
            hookBuyOneGetOne?.listBuyOneGetOne?.map(item => ({
              buyTreatment:
                item?.selectedTreatment?.map(itm => ({
                  treatment: itm?._id,
                  discount: itm?.discountValue,
                  dType: itm?.discountType,
                  amount: itm?.quantity ?? 1,
                })) ?? [],
              discountTreatment:
                item?.discountTreatment?.map(itm => ({
                  treatment: itm?._id,
                  discount: itm?.discountValue,
                  dType: itm?.discountType,
                  amount: itm?.quantity ?? 1,
                })) ?? [],
              name: item?.name,
            })) ?? [],
        }
        break
      case CampaignTypeEnum.Voucher:
        break
      case CampaignTypeEnum.Bundle:
        payload = {
          ...payload,
          boughtTreatmentsDiscount:
            hookBundle?.listBundleService?.map(item => ({
              buyTreatment:
                item?.selectedTreatment?.map(itm => ({
                  treatment: itm?._id,
                  discount: itm?.discountValue,
                  dType: itm?.discountType,
                  amount: itm?.quantity ?? 1,
                })) ?? [],
              discountTreatment: [],
              name: item?.name,
            })) ?? [],
        }
        break
    }

    mutateAsyncCreate?.(payload)
  }

  const onSuccessImportTreatment = (values: IResponseImportFile[]) => {
    hookTreatmentType?.onChangeService?.(
      values.map(item => ({
        name: item.name,
        discountType: item?.dType,
        discountValue: +(item?.discount ?? '0'),
        quantity: 1,
        _id: item._id,
      })),
    )
  }

  const onSuccessImportBuyOneGetOne = (
    values: IResponseImportBuyOneGetOne[],
  ) => {
    hookBuyOneGetOne.onChangeBuyOneGetOneList(
      values.map(item => ({
        name: item.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          name: itm.name,
          discountType: itm?.dType,
          discountValue: +(itm?.discount ?? '0'),
          quantity: itm?.amount,
          _id: itm._id,
        })),
        discountTreatment: item?.discountTreatment?.map(itm => ({
          ...itm,
          name: itm.name,
          discountType: itm?.dType,
          discountValue: +(itm?.discount ?? '0'),
          quantity: itm?.amount,
          _id: itm._id,
        })),
      })),
    )
  }

  const onSuccessImportBundle = (values: IResponseImportBundle[]) => {
    hookBundle.onChangeBundleList(
      values.map(item => ({
        name: item.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          name: itm.name,
          discountType: itm?.dType,
          discountValue: +(itm?.discount ?? '0'),
          quantity: itm?.amount,
          _id: itm._id,
        })),
        discountTreatment: [],
      })),
    )
  }

  const onSuccessImportBundleBillDiscount = (
    values: IResponseImportBundle[],
  ) => {
    hookBundleBillDiscount.onChangeBundleBillDiscountList(
      values.map(item => ({
        name: item.setName,
        selectedTreatment: item?.buyTreatment?.map(itm => ({
          ...itm,
          name: itm.name,
          discountType: itm?.dType,
          discountValue: +(itm?.discount ?? '0'),
          quantity: itm?.amount,
          _id: itm._id,
        })),
        discountTreatment: [],
      })),
    )
  }

  const handleGetImportTemplate = async () => {
    try {
      setLoading(true)
      const response: ResponseType<{ url?: string }> =
        await requestGetImportTemplate({
          campaignType: hookInfo.campaignType,
        })

      if (
        isEqual(
          response?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.success,
        ) &&
        response?.data?.url
      ) {
        const url = response?.data?.url

        window.open(url, '_blank', 'noopener,noreferrer')
      }
    } catch (error) {
      _DEV_ && console.error('handleGetImportTemplate', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateCampaignContext.Provider
      value={{
        form,
        handleSubmit,
        isDisableSubmit,
        isLoadingSubmit,
        handleGoBack,
        onSuccessImportTreatment,
        handleGetImportTemplate,
        onSuccessImportBuyOneGetOne,
        onSuccessImportBundle,
        onSuccessImportBundleBillDiscount,
        loading,
        ...hookInfo,
        ...hookTreatmentType,
        ...hookBuyOneGetOne,
        ...hookBundle,
        ...hookTotalBill,
        ...hookBundleBillDiscount,
      }}
    >
      {children}
    </CreateCampaignContext.Provider>
  )
}

export function useCreateCampaignContext() {
  const context = useContext(CreateCampaignContext)

  if (context === undefined) {
    throw new Error(
      'useCreateCampaign must be used within a CreateCampaignProvider',
    )
  }
  return context
}
