import {
  StatusApproveEnum,
  TypeSurveyEnum,
} from 'app/api/dental-treatment/survey-dynamic/type'
import { initPagination } from 'app/constant'
import { useGetSurveyDynamicList } from 'app/react-query/hook/medical-record-and-information'
import { Pagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'

export const useHook = ({
  statusApprove,
}: {
  statusApprove: StatusApproveEnum
}) => {
  const [pagination, setPagination] = useState<Pagination>(initPagination)

  const { data, isLoading } = useGetSurveyDynamicList({
    page: pagination.current,
    pageSize: pagination.pageSize,
    statusApprove,
    typeSurvey: [TypeSurveyEnum?.MEDICAL_HISTORY, TypeSurveyEnum?.TEETH_HEALTH],
  })

  const dataFlatten = useMemo(() => {
    return data
  }, [data])

  const onChangePagination = (current: number, pageSize: number) => {
    setPagination({ pageSize, current })
  }

  return {
    data: dataFlatten?.data ?? [],
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    isLoading,
    onChangePagination,
  }
}
