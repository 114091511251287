import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { IProfileUser } from 'app/api/profile/model/profile'
import { useTranslation } from 'react-i18next'
import { SectionBaseWrapper, SectionDescription, SectionTitle } from './styles'
import R from 'app/assets/R'
import { DirectSubordinateModal } from './DSModal'

interface IProps {
  profile?: IProfileUser
}

export const DirectSubordinate = (props: IProps) => {
  const { profile } = props
  const { t } = useTranslation()

  return (
    <SectionBaseWrapper>
      <BaseRow align={'middle'} justify={'space-between'}>
        <BaseCol>
          <SectionTitle>{t(R.strings.direct_subordinate)}</SectionTitle>
          <SectionDescription>
            {t(R.strings.direct_subordinate_description)}
          </SectionDescription>
        </BaseCol>
        <BaseCol>
          <DirectSubordinateModal
            listDirectSubordinate={Array(50)?.fill(profile) || []}
          />
        </BaseCol>
      </BaseRow>
    </SectionBaseWrapper>
  )
}
