import R from 'app/assets/R'
import { TypeEnum, TypeSurveyEnum } from './type'

// Message code define
export const SurveyDynamicMessageCode = {
  Survey: {
    successGetData: '20000',
  },
}

// Endpoint
export const SurveyDynamicEndPoint = {
  surveyDynamic: {
    request: 'survey/v1/survey-dynamic',
    detail: 'survey/v1/survey-dynamic/:id',
    approveOnlyPatient: 'olddb/v1/patient/:id/approve',
    getPDFPatient: 'olddb/v1/patient/:id/file-pdf',
    approveOther: 'survey/v1/survey-dynamic/:id/approval',
    requestTotal: 'survey/v1/survey-dynamic/total',
    questionTeethHealth: 'survey/v1/survey-dynamic/question/teeth-health',
    questionMedicalHistory: 'survey/v1/survey-dynamic/question/medical-history',
  },
}

interface DefineType {
  keyI18n: string
}

interface DefineTypeEnum {
  [key: TypeEnum | TypeSurveyEnum | string]: DefineType
}

export const DefineTypeEnum: DefineTypeEnum = {
  [TypeEnum.INFO_PATIENT]: {
    keyI18n: R.strings.info_patient,
  },
  [TypeSurveyEnum.TEETH_HEALTH]: {
    keyI18n: R.strings.survey_teeth_health,
  },
  [TypeSurveyEnum.MEDICAL_HISTORY]: {
    keyI18n: R.strings.medical_dental_history_survey,
  },
}
