import { DefineUnWorkingDayTypeEnum } from 'app/api/hr/constant'
import {
  IUnWorkingDay,
  LeaveShiftDayOffEnum,
  StatusDayOffEnum,
  UnWorkingDayTypeEnum,
} from 'app/api/hr/models/approve-day-off'
import { BaseCol } from 'app/components/common/BaseCol'
import { Loading } from 'app/components/common/Loading'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { useMemo, useRef } from 'react'
import { ItemWrapper } from '../../hook'
import { DayOffItem } from '../DayOffItem'

const getColorUnWorkingDay = ({
  itemUnWorkingDay,
}: {
  itemUnWorkingDay?: IUnWorkingDay
}) => {
  if (itemUnWorkingDay?.unWorkingDayType === UnWorkingDayTypeEnum.PH) {
    return {
      borderColor: '#F9F5FF',
      backgroundColor: '#F9F5FF',
    }
  }

  switch (itemUnWorkingDay?.status) {
    case StatusDayOffEnum.APPROVED:
      return {
        borderColor: convertedVariables.statesGreenColor,
        backgroundColor: convertedVariables.statesGreenLightColor,
      }
    case StatusDayOffEnum.REJECTED:
      return {
        borderColor: convertedVariables.statesRedColor,
        backgroundColor: convertedVariables.statesRedLightColor,
      }
    case StatusDayOffEnum.WAITING_CANCEL:
    case StatusDayOffEnum.WAITING_BOM_APPROVE:
    case StatusDayOffEnum.WAITING_FOR_BOM_APPROVE_CANCEL:
    case StatusDayOffEnum.WAITING_FOR_BOM_APPROVE_REJECT:
    case StatusDayOffEnum.CREATED:
      return {
        borderColor: convertedVariables.statesOrangeColor,
        backgroundColor: convertedVariables.statesOrangeLightColor,
      }
    default:
      return {
        borderColor: undefined,
        backgroundColor: undefined,
      }
  }
}

const DayOffItemOverwrite = ({
  item,
  typeLayout,
}: {
  item: IUnWorkingDay
  typeLayout: 'full' | 'half'
}) => {
  const dataColor = useMemo(() => {
    return getColorUnWorkingDay({
      itemUnWorkingDay: item,
    })
  }, [item])

  return (
    <DayOffItem
      typeLayout={typeLayout}
      value={item?.unWorkingDayType ?? ''}
      colorValue={
        DefineUnWorkingDayTypeEnum[item?.unWorkingDayType ?? ''].color
      }
      borderColor={dataColor.borderColor}
      backgroundColor={dataColor.backgroundColor}
      data={item}
    />
  )
}

export const ColumnDate = ({
  unWorkingDays,
  fetchData,
}: {
  unWorkingDays: IUnWorkingDay[]
  fetchData?: number
}) => {
  const columnRef = useRef<any>(null)

  const unWorkingDaysRejected = unWorkingDays?.filter(
    i => i?.status === StatusDayOffEnum?.REJECTED,
  )
  const unWorkingDaysCreatedAndApproved = unWorkingDays?.filter(
    i => i?.status !== StatusDayOffEnum?.REJECTED,
  )
  const isDividedHeight =
    unWorkingDaysRejected?.length !== unWorkingDays?.length &&
    unWorkingDaysRejected?.length > 0

  const heightColumn = useMemo(() => {
    return isDividedHeight
      ? columnRef?.current?.offsetParent?.offsetHeight / 2
      : columnRef?.current?.offsetParent?.offsetHeight
  }, [columnRef, unWorkingDays])

  return (
    <BaseCol ref={columnRef}>
      {/* Rejected */}
      {(() => {
        if (!fetchData) return <Loading size="small" />

        const itemF = unWorkingDaysRejected.find(
          item => item?.unWorkingShift === LeaveShiftDayOffEnum.F,
        )

        if (itemF) {
          return (
            <ItemWrapper $height={heightColumn}>
              <DayOffItemOverwrite typeLayout={'full'} item={itemF} />
            </ItemWrapper>
          )
        }

        const itemL = unWorkingDaysRejected.find(
          item => item?.unWorkingShift === LeaveShiftDayOffEnum.L,
        )

        const itemE = unWorkingDaysRejected.find(
          item => item?.unWorkingShift === LeaveShiftDayOffEnum.E,
        )

        if (itemE) {
          return (
            <ItemWrapper $height={heightColumn}>
              <DayOffItemOverwrite
                typeLayout={'half'}
                item={itemE}
              />
              <DayOffItem typeLayout={'half'} value={'W'} />
            </ItemWrapper>
          )
        }

        if (itemL) {
          return (
            <ItemWrapper $height={heightColumn}>
              <DayOffItem typeLayout={'half'} value={'W'} />
              <DayOffItemOverwrite
                typeLayout={'half'}
                item={itemL}
              />
            </ItemWrapper>
          )
        }
      })()}
      {/* Create and Approve */}
      {(() => {
        if (!fetchData) return <Loading size="small" />

        if (
          unWorkingDaysCreatedAndApproved?.length <= 0 &&
          unWorkingDaysRejected?.length > 0
        ) {
          return
        }
        if (!unWorkingDaysCreatedAndApproved?.length) {
          return (
            <ItemWrapper $height={heightColumn}>
              <DayOffItem typeLayout={'full'} value={'W'} />
            </ItemWrapper>
          )
        }

        const itemF = unWorkingDaysCreatedAndApproved.find(
          item => item?.unWorkingShift === LeaveShiftDayOffEnum.F,
        )

        if (itemF) {
          return (
            <ItemWrapper $height={heightColumn}>
              <DayOffItemOverwrite typeLayout={'full'} item={itemF} />
            </ItemWrapper>
          )
        }

        const itemL = unWorkingDaysCreatedAndApproved.find(
          item => item?.unWorkingShift === LeaveShiftDayOffEnum.L,
        )

        const itemE = unWorkingDaysCreatedAndApproved.find(
          item => item?.unWorkingShift === LeaveShiftDayOffEnum.E,
        )

        if (itemL && itemE) {
          return (
            <ItemWrapper $height={heightColumn}>
              <DayOffItemOverwrite
                typeLayout={'half'}
                item={itemE}
              />
              <DayOffItemOverwrite
                typeLayout={'half'}
                item={itemL}
              />
            </ItemWrapper>
          )
        }

        if (itemE) {
          return (
            <ItemWrapper $height={heightColumn}>
              <DayOffItemOverwrite
                typeLayout={'half'}
                item={itemE}
              />
              <DayOffItem typeLayout={'half'} value={'W'} />
            </ItemWrapper>
          )
        }

        if (itemL) {
          return (
            <ItemWrapper $height={heightColumn}>
              <DayOffItem typeLayout={'half'} value={'W'} />
              <DayOffItemOverwrite
                typeLayout={'half'}
                item={itemL}
              />
            </ItemWrapper>
          )
        }

        return (
          <ItemWrapper $height={heightColumn}>
            <DayOffItem typeLayout={'full'} value={'W'} />
          </ItemWrapper>
        )
      })()}
    </BaseCol>
  )
}
