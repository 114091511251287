import { DefineUnWorkingDayTypeEnum } from 'app/api/hr/constant'
import {
  IDataGetTotalDashboardDayOff,
  UnWorkingDayTypeEnum,
} from 'app/api/hr/models/approve-day-off'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { moment, PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'

const ItemStatusHeader = ({
  type,
  value,
}: {
  type: UnWorkingDayTypeEnum
  value?: number
}) => {
  return (
    <TypeDayOffWrapperStyled
      $color={convertedVariables.backgroundColor}
      $backgroundColor={DefineUnWorkingDayTypeEnum[type].backgroundSecond}
    >
      {`${DefineUnWorkingDayTypeEnum[type].text}: ${value ?? 0}`}
    </TypeDayOffWrapperStyled>
  )
}

export const HeadDayOffTableComponent = ({
  totalData,
  isLoadingTotal,
  dateMoment,
}: {
  totalData?: IDataGetTotalDashboardDayOff
  isLoadingTotal?: boolean
  dateMoment: moment.Moment
}) => {
  const data = useMemo(() => {
    const DOData = totalData?.data?.find(itm =>
      isEqual(itm?.unWorkingDayType, UnWorkingDayTypeEnum.DO),
    )

    const ULData = totalData?.data?.find(itm =>
      isEqual(itm?.unWorkingDayType, UnWorkingDayTypeEnum.UL),
    )

    const ALData = totalData?.data?.find(itm =>
      isEqual(itm?.unWorkingDayType, UnWorkingDayTypeEnum.AL),
    )

    const SLData = totalData?.data?.find(itm =>
      isEqual(itm?.unWorkingDayType, UnWorkingDayTypeEnum.SL),
    )

    const HLData = totalData?.data?.find(itm =>
      isEqual(itm?.unWorkingDayType, UnWorkingDayTypeEnum.HL),
    )

    const PLData = totalData?.data?.find(itm =>
      isEqual(itm?.unWorkingDayType, UnWorkingDayTypeEnum.PL),
    )

    const ALCData = totalData?.data?.find(itm =>
      isEqual(itm?.unWorkingDayType, UnWorkingDayTypeEnum.ALC),
    )

    return {
      DOData,
      ULData,
      ALData,
      SLData,
      HLData,
      PLData,
      ALCData,
    }
  }, [totalData])

  return (
    <BaseSpace>
      <BaseText
        children={`${dateMoment.format('ddd DD/MM')}`}
        fontWeight="semibold"
        opacity="0.7"
        textAlign="center"
      />
      <BaseRow justify={'center'} align={'middle'} gutter={[8, 8]} wrap={false}>
        <BaseCol>
          <TypeDayOffWrapperStyled
            $color={DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO].color}
            $backgroundColor={
              DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO]
                .backgroundLight
            }
          >
            {`${DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO].text}: ${
              data.DOData?.total ?? 0
            }`}
          </TypeDayOffWrapperStyled>
        </BaseCol>
        <BaseCol>
          <TypeDayOffWrapperStyled
            $color={DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL].color}
            $backgroundColor={
              DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL]
                .backgroundLight
            }
          >
            {`${DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL].text}: ${
              data.ULData?.total ?? 0
            }`}
          </TypeDayOffWrapperStyled>
        </BaseCol>
        <BasePopover
          placement="bottomRight"
          content={
            <BaseSpace>
              <BaseRow
                justify={'space-between'}
                align={'middle'}
                gutter={[8, 8]}
              >
                <BaseCol>
                  <ItemStatusHeader
                    type={UnWorkingDayTypeEnum.DO}
                    value={data.DOData?.total}
                  />
                </BaseCol>
                <BaseCol>
                  <ItemStatusHeader
                    type={UnWorkingDayTypeEnum.UL}
                    value={data.ULData?.total}
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow
                justify={'space-between'}
                align={'middle'}
                gutter={[8, 8]}
              >
                <BaseCol>
                  <ItemStatusHeader
                    type={UnWorkingDayTypeEnum.AL}
                    value={data.ALData?.total}
                  />
                </BaseCol>
                <BaseCol>
                  <ItemStatusHeader
                    type={UnWorkingDayTypeEnum.SL}
                    value={data.SLData?.total}
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow
                justify={'space-between'}
                align={'middle'}
                gutter={[8, 8]}
              >
                <BaseCol>
                  <ItemStatusHeader
                    type={UnWorkingDayTypeEnum.PL}
                    value={data.PLData?.total}
                  />
                </BaseCol>
                <BaseCol>
                  <ItemStatusHeader
                    type={UnWorkingDayTypeEnum.ALC}
                    value={data.ALCData?.total}
                  />
                </BaseCol>
              </BaseRow>
            </BaseSpace>
          }
          trigger={'click'}
        >
          <BaseButton icon={<SvgTableCloseIcon />} type="text" />
        </BasePopover>

        {isLoadingTotal ? <Loading isAbsolute size="small" /> : null}
      </BaseRow>
    </BaseSpace>
  )
}

export const TypeDayOffWrapperStyled = styled.div<{
  $backgroundColor?: string
  $color?: string
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  padding: 4px ${PADDING.md};
  border-radius: ${PADDING.xs};
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
`
