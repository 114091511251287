import { CloseOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { SvgBellIcon } from 'app/assets/svg-assets'
import { BaseBadge } from 'app/components/common/BaseBadge'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { NotificationsOverlay } from 'app/components/header/components/NotificationsDropdown/NotificationsOverlay/NotificationsOverlay'
import { HeaderActionWrapper } from 'app/components/header/styles'
import { usePagination } from 'app/hook'
import { useGetTotalNotificationHistory } from 'app/react-query/hook/notification'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const NotificationsDropdown: React.FC = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const { data } = useGetTotalNotificationHistory()

  const { flattenDataList } = usePagination()

  const dataFlatten: { total: { read: string; notRead: string } } =
    useMemo(() => {
      return flattenDataList(data)
    }, [data])

  return (
    <BasePopoverWrapper
      trigger="click"
      content={
        <BaseSpace size={8}>
          <BaseRow
            gutter={[16, 16]}
            align={'middle'}
            justify={'space-between'}
            style={{ padding: 12 }}
          >
            <BaseCol>
              <BaseText
                children={t(R.strings.notification)}
                fontWeight="semibold"
                fontSize="xs"
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                onClick={hide}
                type="text"
                icon={<CloseOutlined rev={undefined} />}
              />
            </BaseCol>
          </BaseRow>
          <NotificationsOverlay hideModal={hide} />
        </BaseSpace>
      }
      padding={0}
      placement="bottomLeft"
      open={open}
      onOpenChange={handleOpenChange}
      className="notify-popover"
    >
      <HeaderActionWrapper>
        <BaseButton
          type={'text'}
          icon={
            dataFlatten?.total?.notRead ? (
              <BaseBadge
                style={{ borderRadius: 10, backgroundColor: '#FD4444' }}
                count={dataFlatten?.total?.notRead}
              >
                <SvgBellIcon />
              </BaseBadge>
            ) : (
              <SvgBellIcon />
            )
          }
        />
      </HeaderActionWrapper>
    </BasePopoverWrapper>
  )
}

const BasePopoverWrapper = styled(BasePopover)`
  .ant-popover-content .ant-popover-inner {
    background-color: red;
  }
`
