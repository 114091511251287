import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import WorkInfoItem from './WorkInfoItem'
import { IFormWorkInfoModalData } from './WorkInfoModal/type'
import * as S from './styles'

interface IProps {
  jobTitleOptions: IJobTitle[]
  workInfo?: IFormWorkInfoModalData[]
  onRemove?: (key: number) => void
  onUpdate?: (key: number) => void
}

export const WorkInfoList = ({
  workInfo,
  jobTitleOptions,
  onRemove,
  onUpdate,
}: IProps) => {
  return (
    <S.WorkInfoWrapper>
      {workInfo?.map((item, index) => {
        const jobTitle = jobTitleOptions?.find(
          jobTitleOption => jobTitleOption._id === item.jobtitleid,
        )

        return (
          <WorkInfoItem
            jobTitle={jobTitle}
            start={item?.startdate?.$d}
            end={item?.enddate?.$d}
            key={index}
            index={index}
            onRemove={onRemove}
            onUpdate={onUpdate}
          />
        )
      })}
    </S.WorkInfoWrapper>
  )
}
