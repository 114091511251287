import { IProfileUser, ISkillProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useModalHook } from './hook'
import { BaseTableReport, ButtonModal } from './styles'
import { IFormSkillModalData } from './type'

export const initValues: IFormSkillModalData = {
  name: '',
}
interface IProps {
  listIndirectSubordinate: IProfileUser[]
}
export const IndirectSubordinateModal = (props: IProps) => {
  const { listIndirectSubordinate } = props
  const { t } = useTranslation()
  const { data, columns, pagination, handleTableChange } = useModalHook({
    listIndirectSubordinate,
  })
  console.log({ data, columns, pagination })

  const modalRef = useRef<IRefModal>(null)
  const onClick = () => {
    modalRef?.current?.open?.()
  }
  return (
    <ModalComponent
      titleModal={t(R.strings.indirect_subordinate)}
      titleOpenModal={t(R.strings.indirect_subordinate)}
      renderContent={
        <BaseTableReport
          columns={columns}
          dataSource={data}
          pagination={pagination}
          onChange={handleTableChange}
          rowClassName="row-overwrite-style"
          scroll={{
            x: true,
          }}
        />
      }
      confirmKeyI18n={R.strings.indirect_subordinate}
      ref={modalRef}
      borderRadiusButtonOpen={BORDER_RADIUS}
      isShowCancel={false}
      isShowConfirm={false}
      buttonOpenCustom={
        <ButtonModal onClick={onClick}>
          {t(R.strings.employee_list)}
        </ButtonModal>
      }
      widthModal={1000}
    />
  )
}
