import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const FooterModalWrapper = styled(BaseRow)``

export const FormInput = styled(BaseInput)``

export const FormItem = styled(BaseForm.Item)`
  .form-item {
    color: #858c94 !important;
    font-size: ${FONT_SIZE.xxxs}!important;
    font-weight: ${FONT_WEIGHT.regular}!important;
  }
`
export const ButtonFooterModal = styled(BaseButton)`
  color: ${convertedVariables.primaryColor} !important;
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.medium}!important;
`
export const ButtonConfirmModal = styled(BaseButton)`
  background: linear-gradient(315deg, #629daa 0%, #6daebd 100%);
  color: var(--white);
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.medium}!important;
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
  }
`

export const ServiceTitleRow = styled(BaseRow)`
  justify-content: space-between;
  background: #ebeef2;
  padding: 16px 20px 16px 20px;
`
export const ServiceContainer = styled(BaseSpace)`
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px solid #ebeef2;
  margin: 0 0 20px 0;
`
export const ServiceRow = styled(BaseRow)`
  justify-content: space-between;
  padding: 16px 20px 16px 20px;
  border-top: 0.5px solid #ebeef2;
`
export const ServiceTitle = styled(BaseText)`
  color: #6d7580 !important;
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium}!important;
`
export const ServiceText = styled(BaseText)`
  color: ${convertedVariables.primaryColor} !important;
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.medium}!important;
`
export const TitleText = styled(BaseText)`
  color: ${convertedVariables.primaryColor} !important;
  font-size: 16px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  margin: 0 0 20px 0;
`
export const FormContainer = styled(BaseForm)``
export const EmptyList = styled.div`
  display: flex;
  color: #858c94 !important;
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium}!important;
  align-items: center;
  justify-content: center;
  height: 95px;
  width: 100%;
`
