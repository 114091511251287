import { BaseDivider } from 'app/components/common/BaseDivider'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SectionBaseWrapper = styled(SectionBase)`
  padding: ${PADDING.xl} !important;
`
export const SectionTitle = styled(BaseText)`
  font-size: 16px !important;
  font-weight: ${FONT_WEIGHT.semibold}!important;
  color: ${convertedVariables.primaryColor};
`
export const SectionDescription = styled(BaseText)`
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.regular}!important;
  color: ${convertedVariables.primaryColor};
  margin-top: 8px;
`

export const TextDegreeName = styled(BaseText)`
  color: var(--primary-color) !important;
  font-size: 14px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
`

export const TextStartDateDegree = styled(BaseText)`
  color: var(--primary-color) !important;
  font-size: 12px !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  opacity: 0.7;
`

export const TextClassification = styled.span`
  border-radius: 200px;
  font-size: 10px !important;
  font-weight: ${FONT_WEIGHT.bold} !important;
  padding: 4px 12px;
`
export const Divider = styled(BaseDivider)`
  margin: 20px 0;
`
export const ClassificationWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;

  & span {
    font-size: ${FONT_SIZE.xxs};
    color: var(--text-primary2-color);
  }

  .tag {
    background-color: #f8f8f8;
    border-radius: 100px;
    padding: 2px 1rem;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .excellent {
    background-color: #fffbed;
    color: #fa9f34;
  }

  .good {
    background-color: #ecfdf2;
    color: #28ba59;
  }

  .average_good {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .below_average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .weak {
    background-color: #ffe6e6;
    color: #ff7373;
  }
`
