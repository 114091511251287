import {
  getCountExportBillTabs,
  requestExportExcelExportBill,
} from 'app/api/accounting'
import { usePagination } from 'app/hook'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, ITabsCount } from './type'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import { notification } from 'antd'
import R from 'app/assets/R'
import {
  EBillStatus,
  IFilterGetExportBill,
} from 'app/api/accounting/model/export-bill'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'

export const useModalHook = ({ reloadCount }: { reloadCount: number }) => {
  const { t } = useTranslation()
  const [data, setData] = useState<ITabsCount[]>([])

  const handleExportExcel = async (
    body: IFilterGetExportBill,
    setLoading: Dispatch<SetStateAction<boolean>>,
  ) => {
    try {
      setLoading(true)
      const response = await requestExportExcelExportBill(body)
      if (
        isEqual(
          response?.msgcode,
          AccountingMessageCode.ExportBill.successExportExcel,
        )
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')
        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    } finally {
      setLoading(false)
    }
  }

  const pendingCount =
    data?.find(i => i?.key === EBillStatus.PENDING)?.count || 0
  const completeCount =
    data?.find(i => i?.key === EBillStatus.COMPLETED)?.count || 0
  const needUpdateCount =
    data?.find(i => i?.key === EBillStatus.NEED_UPDATE)?.count || 0
  const canceledCount =
    data?.find(i => i?.key === EBillStatus.CANCELLED)?.count || 0

  useEffect(() => {
    const getDataFn = async () => {
      try {
        const response = await getCountExportBillTabs()
        if (
          response?.msgcode === AccountingMessageCode.ExportBill.getDataSuccess
        ) {
          setData(response?.data)
        } else {
          setData([])
        }
      } catch (error) {
        notification?.error({
          message: t(R.strings.error),
          description: t(R.strings.with_error_please_contact_with_admin),
        })
      }
    }
    getDataFn()
  }, [reloadCount])

  return {
    pendingCount,
    completeCount,
    needUpdateCount,
    canceledCount,
    handleExportExcel,
  }
}
