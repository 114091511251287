// Message code define
export const DashboardMessageCode = {
  RevenueToday: {
    successGetData: '20001',
  },
  RevenueClinicChart: {
    successGetData: '20001',
  },
  GeneralInfoDashboard: {
    successGetData: '20001',
  },
  MyAppointment :{
    successGetData: '80000',
  },
  Doctor: {
    doctorReportSuccess: '20000',
  }
}

// Endpointcủa anh quân
export const DashboardEndPoint = {
  RevenueToday: {
    requestGetData: 'report/v1/revenue/getRevenueAndPercentage',
  },
  RevenueClinicChart: {
    requestGetData: 'report/v1/revenue/getRevenueClinic',
  },
  GeneralInfoDashboard: {
    requestGetData: 'report/v1/revenue/getGeneralInfoDashboard',
  },
  MyAppointment: {
    requestData: 'olddb/v1/appointment/my',
  },
  Doctor: {
    GetReportMonthly: 'report/v1/doctor-reports/monthly',
  }
}
