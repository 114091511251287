import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import dayjs from 'dayjs'
import { FORMAT_ONLY_MONTH, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { IFilter } from '../../type'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { NotePopover } from './layouts'

interface IProps {
  filter: IFilter
  onChange?: (value: IFilter) => void
}

export const FilterLayout = ({ filter, onChange }: IProps) => {
  const { t } = useTranslation()

  const onChangeYear = (_, year: string) => {
    onChange && onChange({ ...filter, year })
  }

  const onChangeMonth = (_, month: string) => {
    onChange && onChange({ ...filter, month })
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({ ...filter, keyword: e.target.value })
  }

  const onChangeClinic = (clinicIds: string[]) => {
    onChange && onChange({ ...filter, clinicIds })
  }

  const onChangeJobTitle = (jobTitleIds: string[]) => {
    onChange && onChange({ ...filter, jobTitleIds })
  }

  return (
    <RootWrapper>
      <BaseForm>
        <BaseRow gutter={[16, 8]} align={'middle'} justify={'start'}>
          <BaseCol xl={5}>
            <BaseFormItem hiddenLabel>
              <BaseDatePicker
                $prefix="Year *"
                picker="year"
                placeholder="Select year"
                value={dayjs(`${filter?.year}-01-01`)}
                onChange={onChangeYear}
                $prefixWidth={80}
                allowClear={false}
              />
            </BaseFormItem>
          </BaseCol>
          <BaseCol xl={5}>
            <BaseFormItem hiddenLabel>
              <BaseDatePicker
                $prefix="Month *"
                picker="month"
                format={FORMAT_ONLY_MONTH}
                placeholder="Select month"
                value={dayjs(
                  `${filter?.year}-${
                    +filter?.month < 10 ? `0${filter?.month}` : filter?.month
                  }-01`,
                )}
                onChange={onChangeMonth}
                $prefixWidth={100}
                allowClear={false}
              />
            </BaseFormItem>
          </BaseCol>

          <BaseCol xl={5}>
            <BaseFormItem hiddenLabel>
              <BaseSelect
                $prefix={t(R.strings.select_clinic)}
                placeholder={t(R.strings.all)}
                value={filter?.clinicIds}
                $prefixWidth={120}
                allowClear={false}
                options={[
                  { label: 'Phòng khám 1', value: '1' },
                  { label: 'Phòng khám 2', value: '2' },
                ]}
                onChange={value => onChangeClinic(value as string[])}
                showSearch
              />
            </BaseFormItem>
          </BaseCol>

          <BaseCol xl={6}>
            <BaseFormItem hiddenLabel>
              <BaseSelect
                $prefix={t(R.strings.item_management_content_select_job_title)}
                placeholder={t(R.strings.all)}
                value={filter?.clinicIds}
                $prefixWidth={120}
                allowClear={false}
                options={[
                  { label: 'Phòng khám 1', value: '1' },
                  { label: 'Phòng khám 2', value: '2' },
                ]}
                onChange={value => onChangeJobTitle(value as string[])}
                showSearch
              />
            </BaseFormItem>
          </BaseCol>

          <BaseCol xl={3}>
            <BaseRow justify={'end'} align={'middle'}>
              <BaseCol>
                <NotePopover />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 8]} align={'top'} justify={'start'}>
          <BaseCol xl={5}>
            <BaseFormItem hiddenLabel>
              <BaseSelect
                $prefix={'Loại đăng kí'}
                placeholder={t(R.strings.all)}
                value={filter?.status}
                $prefixWidth={95}
                allowClear={false}
                options={[
                  { label: 'Có đăng kí', value: 1 },
                  { label: 'Không đăng kí', value: 2 },
                ]}
                onChange={value => onChangeJobTitle(value as string[])}
              />
            </BaseFormItem>
          </BaseCol>

          <BaseCol xl={16}>
            <BaseFormItem hiddenLabel>
              <BaseInput
                placeholder={t(R.strings.enter_name)}
                value={filter?.keyword}
                onChange={onChangeSearch}
                allowClear
              />
            </BaseFormItem>
          </BaseCol>
        </BaseRow>
      </BaseForm>
    </RootWrapper>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const RootWrapper = styled.div`
  padding: ${PADDING.md};
`
