import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { Report024Container } from 'app/containers/Report/Report024'

const Report024PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_24)}</PageTitle>
      <Report024Container />
    </>
  )
}
const Report024Page = memo(Report024PageCpn, isEqual)

export default Report024Page
