import {
  DoctorMonthlyMeasureUnitsEnum,
  DoctorMonthlyReportStatusEnum,
  IDoctorMonthlyReport,
} from 'app/api/dashboard/model/doctor'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import moment from 'moment'
import { formatMoney, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { useHook } from './hook'
import { Loading } from 'app/components/common/Loading'

const currentMonth = moment().month() + 1

const ItemInfo = ({ item }: { item: IDoctorMonthlyReport }) => {
  const { name, measureUnit, status, total } = item
  return (
    <BaseSpace size={16}>
      <ItemWrapper justify={'space-between'} align={'middle'} wrap={false}>
        <BaseCol>
          <BaseText
            children={name}
            opacity={(() => {
              if (item?.children?.length) {
                return '0.7'
              }

              if (item?.isMain) {
                return '1'
              }

              return '0.7'
            })()}
            fontWeight="medium"
          />
        </BaseCol>

        <BaseCol>
          <BaseRow gutter={0} wrap={false} align={'middle'}>
            {/* <BaseCol className="see-more">
              <BaseRow align={'middle'}>
                <BaseCol>
                  <BaseText
                    children={t(R.strings.see)}
                    fontWeight="medium"
                    colorText="collapseBackgroundColor"
                    style={{ cursor: 'pointer' }}
                  />
                </BaseCol>
                <BaseCol>
                  <BaseDivider type="vertical" />
                </BaseCol>
              </BaseRow>
            </BaseCol> */}
            <BaseCol>
              <NumberWrapper
                $type={(() => {
                  if (item?.children?.length) {
                    return DoctorMonthlyReportStatusEnum.Secondary
                  }

                  return status
                })()}
              >
                <BaseText
                  children={formatMoney(
                    total ?? '0',
                    isEqual(
                      measureUnit,
                      DoctorMonthlyMeasureUnitsEnum.Percentage,
                    )
                      ? '%'
                      : '',
                  )}
                  fontWeight="semibold"
                  style={{
                    color: (() => {
                      if (item?.children?.length) {
                        return convertedVariables.statesGreenColor
                      }

                      switch (status) {
                        case DoctorMonthlyReportStatusEnum.Danger:
                          return convertedVariables.backgroundColor
                        case DoctorMonthlyReportStatusEnum.Primary:
                          return convertedVariables.backgroundColor
                        case DoctorMonthlyReportStatusEnum.Warning:
                          return convertedVariables.backgroundColor
                        case DoctorMonthlyReportStatusEnum.Secondary:
                          return `rgba(0, 34, 68, 0.5)`
                      }
                    })(),
                  }}
                />
              </NumberWrapper>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </ItemWrapper>
      {item?.children?.length ? (
        <BaseSpace style={{ paddingLeft: PADDING.xxxxs }}>
          {item?.children?.map((itm, idx) => {
            return <ItemInfo item={itm} key={idx} />
          })}
        </BaseSpace>
      ) : null}
    </BaseSpace>
  )
}

export const StatisticsDoctorLayout = () => {
  const { data, isLoading } = useHook()
  return (
    <SectionBase>
      <BaseSpace size={16}>
        <BaseRow justify={'space-between'} align={'middle'} gutter={[15, 10]}>
          <BaseCol>
            <BaseText
              children={t(R.strings.statistic_month_number, {
                month: currentMonth,
              })}
              fontSize="md"
              fontWeight="semibold"
            />
          </BaseCol>
        </BaseRow>

        {data?.map((item, idx) => {
          return <ItemInfo item={item} key={idx} />
        })}

        {/* <BaseRow justify={'end'}>
          <div style={{ cursor: 'pointer' }}>
            <BaseText
              children={t(R.strings.see_all)}
              fontWeight="medium"
              fontSize="xs"
              colorText="collapseBackgroundColor"
            />
          </div>
        </BaseRow> */}

        {isLoading ? <Loading size="small" /> : null}
      </BaseSpace>
    </SectionBase>
  )
}

const NumberWrapper = styled.div<{ $type?: DoctorMonthlyReportStatusEnum }>`
  background-image: ${props => {
    switch (props?.$type) {
      case DoctorMonthlyReportStatusEnum.Primary:
        return 'linear-gradient(90deg, #629DAA, #6DAEBD)'
      case DoctorMonthlyReportStatusEnum.Danger:
        return 'linear-gradient(90deg, #F1F1F1, #F1F1F1)'
      case DoctorMonthlyReportStatusEnum.Warning:
        return 'linear-gradient(90deg, #FD7B59, #F75428)'
      case DoctorMonthlyReportStatusEnum.Secondary:
        return 'linear-gradient(90deg, #FFFFFF, #FFFFFF)'
    }
  }};
  border-radius: ${PADDING.md};
  width: fit-content;
  display: flex;
  min-width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  padding: 0px 4px;
`

const ItemWrapper = styled(BaseRow)`
  .see-more {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover .see-more {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
`
