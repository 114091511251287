import { DefineUnWorkingDayTypeEnum } from 'app/api/hr/constant'
import { UnWorkingDayTypeEnum } from 'app/api/hr/models/approve-day-off'
import R from 'app/assets/R'
import { SvgDownArrowIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { TypeDayOffWrapperStyled } from 'app/containers/HumanResources/ApproveDayOff/components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const NotePopover = () => {
  const { t } = useTranslation()
  return (
    <BasePopover
      trigger={'click'}
      content={
        <BaseSpace>
          <BaseRow align={'middle'} justify={'start'} gutter={[8, 8]}>
            <BaseCol xl={8}>
              <TypeDayOffWrapperStyled
                $backgroundColor={
                  DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO]
                    .backgroundLight
                }
                $color={
                  DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO].color
                }
              >
                {`${
                  DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.DO].text
                }: 2`}
              </TypeDayOffWrapperStyled>
            </BaseCol>

            <BaseCol xl={16}>
              <BaseText
                children={'Số người đăng ký DO đã duyệt'}
                fontWeight="medium"
                fontSize="xs"
              />
            </BaseCol>
          </BaseRow>
          <BaseRow align={'middle'} justify={'start'} gutter={[8, 8]}>
            <BaseCol xl={8}>
              <TypeDayOffWrapperStyled
                $backgroundColor={
                  DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL]
                    .backgroundLight
                }
                $color={
                  DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL].color
                }
              >
                {`${
                  DefineUnWorkingDayTypeEnum[UnWorkingDayTypeEnum.UL].text
                }: 2`}
              </TypeDayOffWrapperStyled>
            </BaseCol>

            <BaseCol xl={16}>
              <BaseText
                children={'Số người đăng ký UL đã duyệt'}
                fontWeight="medium"
                fontSize="xs"
              />
            </BaseCol>
          </BaseRow>

          <BaseRow align={'middle'} justify={'start'} gutter={[8, 8]}>
            <BaseCol xl={8}>
              <TagWrapper>
                <BaseText
                  children={'Sáng'}
                  fontWeight="semibold"
                  colorText="backgroundColor"
                  fontSize="xxs"
                />
              </TagWrapper>
            </BaseCol>
            <BaseCol xl={16}>
              <BaseText
                children={'Buổi làm việc ( Sáng / Chiều)'}
                fontWeight="medium"
                fontSize="xs"
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      }
      placement="bottomLeft"
    >
      <Button>
        <BaseCol>
          <BaseText
            children={t(R.strings.appointment_note)}
            fontWeight="medium"
            fontSize="xs"
            opacity="0.7"
          />
        </BaseCol>
        <BaseCol style={{ paddingTop: 8, marginLeft: 4 }}>
          <SvgDownArrowIcon />
        </BaseCol>
      </Button>
    </BasePopover>
  )
}

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const TagWrapper = styled.div`
  background-color: ${convertedVariables.collapseBackgroundColor};
  padding: 1px 8px;
  border-radius: ${PADDING.xs};
  width: fit-content;
`
