import { IPayLoadGetList } from 'app/common/interface'
import { IService } from 'app/containers/CustomerCare/AppointmentPage/type'
import { ResponseType } from 'parkway-web-common'

export enum EBillStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  NEED_UPDATE = 'NEED_UPDATE',
  CANCELLED = 'CANCELLED',
}

export interface IFilterGetExportBill extends IPayLoadGetList {
  fromDate?: string
  toDate?: string
  keyword?: string
  clinicIds?: string[]
  clinicLegalNameIds?: string[]
  status?: string
}

export interface IValuesCreatedExportBill {
  paymentCode: string
  taxId: string
  companyName: string
  address: string
  email: string
  selectedServices: IService[]
}
export interface IPayloadCreatedExportBill {
  paymentCodes: string[]
  taxId: string
  companyName: string
  address: string
  email: string
  selectedServices: IService[]
}
export interface IPayloadUpdateExportBill {
  taxId?: string
  companyName?: string
  address?: string
  email?: string
  selectedServices?: IService[]
  isInvoiceExported?: boolean
  isMailSent?: boolean
  status?: EBillStatus
}
export interface IGetExportBill {
  _id?: string
  createdAt?: string
  companyName?: string
  taxId?: string
  paymentIds?: string[]
  selectedServices?: IService[]
  usedServices?: IService[]
  email?: string
  address?: string
  isMailSent?: boolean
  isInvoiceExported?: boolean
  paymentDetails?: {
    code?: string
    patientId?: string
    patientName?: string
    patientCode?: string
    patientAddress?: string
    clinicName?: string
    clinicNameId?: string
    clinicLegalName?: string
    clinicAddress?: string
    createdAt?: string
    paid?: number
    cash?: number
    card?: number
  }[]
}

export type ResponseExportBill = ResponseType<IGetExportBill[]>
