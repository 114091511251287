import R from 'app/assets/R'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_FLOW_PATH,
  DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_PLAN_PATH,
  DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_STAGE_PATH,
  DENTAL_TREATMENT_MANAGEMENT_MEDICAL_RECORD_AND_INFORMATION,
  DENTAL_TREATMENT_MANAGEMENT_PATIENT_SEARCH_PATH,
  DENTAL_TREATMENT_PATIENT_ORTHODONTIC_WITHOUT_PAID_PATH,
  DENTAL_TREATMENT_PATIENT_RECORD_MANAGEMENT,
  DENTAL_TREATMENT_PATIENT_SCHEDULE_WITHOUT_PAID,
} from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'

export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.dental_treatment_management,
    reports: [
      {
        id: 11,
        label: '001',
        descriptionI18nKey: R.strings.patient_record_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DENTAL_TREATMENT_PATIENT_RECORD_MANAGEMENT,
      },
      {
        id: 12,
        label: '002',
        descriptionI18nKey: R.strings.approve_flow,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_FLOW_PATH,
      },
      {
        id: 13,
        label: '003',
        descriptionI18nKey: R.strings.approve_treatment_plan,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_PLAN_PATH,
      },
      {
        id: 14,
        label: '004',
        descriptionI18nKey: R.strings.approve_treatment_phase,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_STAGE_PATH,
      },
      {
        id: 15,
        label: '005',
        descriptionI18nKey: R.strings.patient_search,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DENTAL_TREATMENT_MANAGEMENT_PATIENT_SEARCH_PATH,
        permission: FunctionPermissionEnum.READ_PATIENT_SEARCH,
      },
      {
        id: 16,
        label: '006',
        descriptionI18nKey: R.strings.medical_record_and_information,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DENTAL_TREATMENT_MANAGEMENT_MEDICAL_RECORD_AND_INFORMATION,
      },
    ],
  },
  {
    id: 2,
    titleI18nKey: R.strings.report_group_report,
    reports: [
      {
        id: 21,
        descriptionI18nKey: R.strings.orthodontic_patient_list_zero_payment,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DENTAL_TREATMENT_PATIENT_SCHEDULE_WITHOUT_PAID,
        isHideLabel: true,
        permission: FunctionPermissionEnum.READ_ORTHODONTIC_WITHOUT_PAID,
      },
      {
        id: 22,
        descriptionI18nKey: R.strings.orthodontic_patient_scheduling_density,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: DENTAL_TREATMENT_PATIENT_ORTHODONTIC_WITHOUT_PAID_PATH,
        isHideLabel: true,
        permission: FunctionPermissionEnum.READ_ORTHODONTIC_WITHOUT_PAID,
      },
    ],
  },
]
