import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'
import { IProfileUser } from 'app/api/profile/model/profile'

interface IPropsUseModalHook {
  listDirectSubordinate: IProfileUser[]
}

export const useModalHook = ({ listDirectSubordinate }: IPropsUseModalHook) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<Pagination>({
    pageSize: 5,
    current: 1,
  })

  const data = useMemo(() => {
    return listDirectSubordinate?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [listDirectSubordinate])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IProfileUser>({
        title: t(R.strings.name),
        key: 'name',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IProfileUser) => {
          return (
            <BaseAvatarProfile
              name={record?.name}
              src={record?.avatar}
              jobTitle={record?.jobTitleName}
            />
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableChange = (newPagination: Pagination) => {
    setPagination(newPagination)
  }

  return {
    data,
    columns,
    handleTableChange,
    pagination: {
      ...pagination,
      total: listDirectSubordinate?.length,
    },
  }
}
