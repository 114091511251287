import { ApiClient } from 'app/service/api-service'
import { SurveyDynamicEndPoint } from './constant'
import {
  IBodyApproveSurveyDynamic,
  IParamsGetDetailSurveyDynamic,
  IParamsGetSurveyDynamic,
  TypeSurveyEnum,
} from './type'

export const requestGetSurveyDynamic = (params: IParamsGetSurveyDynamic) =>
  ApiClient.Get({
    url: `${SurveyDynamicEndPoint.surveyDynamic.request}`,
    params: { params },
  })

export const requestPutApproveSurveyDynamic = (
  body: IBodyApproveSurveyDynamic,
) =>
  ApiClient.Put({
    url: `${SurveyDynamicEndPoint.surveyDynamic.approveOther}`?.replace(
      ':id',
      body?.id,
    ),
    body,
  })

export const requestPutApprovePatientInfo = (body: IBodyApproveSurveyDynamic) =>
  ApiClient.Put({
    url: `${SurveyDynamicEndPoint.surveyDynamic.approveOnlyPatient}`?.replace(
      ':id',
      body?.id,
    ),
    body,
  })

export const requestGetPDFPatientInfo = (body: IBodyApproveSurveyDynamic) =>
  ApiClient.Get({
    url: `${SurveyDynamicEndPoint.surveyDynamic.getPDFPatient}`?.replace(
      ':id',
      body?.id,
    ),
    params: {},
  })

export const requestGetDetailSurveyDynamic = (
  params: IParamsGetDetailSurveyDynamic,
) =>
  ApiClient.Get({
    url: `${SurveyDynamicEndPoint.surveyDynamic.detail}`?.replace(
      ':id',
      params?.id,
    ),
    params: {},
  })

export const requestGetTotalSurveyDynamic = (params: {
  typeSurvey?: TypeSurveyEnum[]
}) =>
  ApiClient.Get({
    url: SurveyDynamicEndPoint.surveyDynamic.requestTotal,
    params: { params },
  })

export const requestGetQuestionTeethHealth = () =>
  ApiClient.Get({
    url: SurveyDynamicEndPoint.surveyDynamic.questionTeethHealth,
    params: {},
  })

export const requestGetQuestionMedicalHistory = () =>
  ApiClient.Get({
    url: SurveyDynamicEndPoint.surveyDynamic.questionMedicalHistory,
    params: {},
  })
