export enum EStatus {
  'ACTIVE' = 1,
  'DEACTIVE' = -1,
}

export const StatusName = {
  [EStatus?.ACTIVE]: 'active',
  [EStatus?.DEACTIVE]: 'deactive',
}
export const StatusKey = {
  [EStatus?.ACTIVE]: 'active',
  [EStatus?.DEACTIVE]: 'de-active',
}
