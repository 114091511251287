/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, DownOutlined, FilterOutlined } from '@ant-design/icons'
import {
  DefinePaymentMethod,
  EPaymentMethod,
  IParamsGetReport24,
} from 'app/api/report/model/report-24'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { useDebounce, usePagination } from 'app/hook'
import { IClinic } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { useGetTreatmentGroupList } from 'app/react-query/hook/old-db'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  initialPagination,
  Pagination,
} from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

interface IProps {
  onChangeFilter?: (value: IParamsGetReport24) => void
  filter?: IParamsGetReport24
}

export const AdvanceFilterLayout: React.FC<IProps> = ({
  filter,
  onChangeFilter,
}) => {
  const [open, setOpen] = useState(false)
  const [filterMore, setFilterMore] = useState<IParamsGetReport24 | undefined>(
    filter,
  )

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'clinic',
    status: StatusEnum.ACTIVE,
  })

  const { flattenDataList, flattenData } = usePagination()

  const clinics: IClinic[] = useMemo(() => {
    return flattenDataList(areaApi) ?? []
  }, [areaApi])

  const [keywordSearchTreatment, setKeywordSearchTreatment] = useState<
    string | undefined
  >(undefined)
  const [loadMoreTreatment, setLoadMoreTreatment] = useState(0)
  const loadMoreTreatmentDebounce = useDebounce(loadMoreTreatment, 200)
  const keywordSearchTreatmentDebounce = useDebounce(
    keywordSearchTreatment,
    200,
  )

  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 30,
  })

  const {
    data: treatmentGroupApi,
    isLoading,
    hasNextPage: hasNextPageTreatment,
    fetchNextPage: fetchNextPageTreatment,
    isFetchingNextPage,
  } = useGetTreatmentGroupList({
    keyword: keywordSearchTreatmentDebounce,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const dataTreatmentFlatten = useMemo(() => {
    return flattenData(treatmentGroupApi)
  }, [treatmentGroupApi])

  const dataTreatmentGroup = useMemo(() => {
    return dataTreatmentFlatten?.map(i => ({
      label: i?.name,
      value: i?._id,
    }))
  }, [treatmentGroupApi])

  const onPopupTreatmentScroll = (e: any) => {
    const heightChange =
      (e?.target?.scrollTop ?? 0) + (e?.target?.clientHeight ?? 0)
    const scrollHeight = e?.target?.scrollHeight ?? 0
    const isEndOfList = heightChange >= 0.7 * scrollHeight

    if (isEndOfList && hasNextPageTreatment) {
      setLoadMoreTreatment(randomMoney())
    }
  }

  useEffect(() => {
    if (loadMoreTreatmentDebounce) {
      fetchNextPageTreatment()
    }
  }, [loadMoreTreatmentDebounce])

  // action change
  const onChangeClinic = (patientDefaultLocations: string[]) => {
    setFilterMore?.({ ...filterMore, patientDefaultLocations })
  }

  const onChangePaymentMethod = (paymentMethods: EPaymentMethod[]) => {
    setFilterMore?.({ ...filterMore, paymentMethods })
  }

  const onChangeTreatmentSearch = (keyword: string) => {
    setKeywordSearchTreatment(keyword)
    setPagination({ ...pagination, current: 1 })
  }

  const onChangeTreatment = (treatmentIds: string[]) => {
    setFilterMore?.({
      ...filterMore,
      treatmentIds,
    })
  }

  const onHide = () => {
    setOpen(false)
  }

  const onPressApply = () => {
    onHide()
    onChangeFilter?.({
      ...filter,
      patientDefaultLocations: filterMore?.patientDefaultLocations,
      paymentMethods: filterMore?.paymentMethods,
      treatmentIds: filterMore?.treatmentIds,
    })
  }

  const onPressReset = () => {
    setFilterMore({})
    onChangeFilter?.({
      ...filter,
      patientDefaultLocations: [],
      paymentMethods: [],
      treatmentIds: [],
    })
  }

  return (
    <BasePopoverWrapper
      trigger={'click'}
      placement="bottomRight"
      content={
        <BaseSpace
          split={<BaseDivider style={{ marginBottom: 0, marginTop: 0 }} />}
          size={12}
        >
          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontSize="xs"
                fontWeight="semibold"
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                icon={<CloseOutlined rev={undefined} />}
                onClick={onHide}
                type="text"
              />
            </BaseCol>
          </BaseRow>

          <BaseForm>
            <BaseSpace style={{ width: 320 }} size={4}>
              <BaseFormItem label={'Cơ sở'}>
                <BaseSelectWrapper
                  mode="multiple"
                  showSearch
                  placeholder={t(R.strings.all)}
                  allowClear
                  onChange={ids => onChangeClinic?.(ids as string[])}
                  options={clinics?.map(item => ({
                    value: item.oldid,
                    label: item.name,
                  }))}
                  loading={isLoadingArea}
                  maxTagCount={0}
                  value={filterMore?.patientDefaultLocations}
                />
              </BaseFormItem>
              <BaseFormItem label={t(R.strings.package)}>
                <BaseSelectWrapper
                  allowClear
                  mode="multiple"
                  value={filterMore?.treatmentIds}
                  placeholder={t(R.strings.all)}
                  onSearch={keyword => onChangeTreatmentSearch(keyword)}
                  options={dataTreatmentGroup}
                  loading={isLoading || isFetchingNextPage}
                  onChange={ids => onChangeTreatment(ids as string[])}
                  onPopupScroll={onPopupTreatmentScroll}
                  maxTagCount={0}
                  filterSort={() => 0}
                />
              </BaseFormItem>
              <BaseFormItem label={'Phương thức thanh toán'}>
                <BaseSelectWrapper
                  mode="multiple"
                  showSearch
                  placeholder={t(R.strings.all)}
                  allowClear
                  onChange={methods =>
                    onChangePaymentMethod?.(methods as EPaymentMethod[])
                  }
                  value={filterMore?.paymentMethods}
                  options={[
                    {
                      label: DefinePaymentMethod?.[EPaymentMethod.FULL].text,
                      value: EPaymentMethod.FULL,
                    },
                    {
                      label:
                        DefinePaymentMethod?.[EPaymentMethod.INSTALLMENT].text,
                      value: EPaymentMethod.INSTALLMENT,
                    },
                    {
                      label:
                        DefinePaymentMethod?.[EPaymentMethod.INSTALLMENT_CREDIT]
                          .text,
                      value: EPaymentMethod.INSTALLMENT_CREDIT,
                    },
                    {
                      label:
                        DefinePaymentMethod?.[
                          EPaymentMethod.INSTALLMENT_FUNDIIN
                        ].text,
                      value: EPaymentMethod.INSTALLMENT_FUNDIIN,
                    },
                    {
                      label:
                        DefinePaymentMethod?.[EPaymentMethod.INSTALLMENT_PW]
                          .text,
                      value: EPaymentMethod.INSTALLMENT_PW,
                    },
                  ]}
                  maxTagCount={0}
                />
              </BaseFormItem>
            </BaseSpace>
          </BaseForm>

          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseButton
                onClick={onPressReset}
                children={t(R.strings.report_24_content_reset)}
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                onClick={onPressApply}
                type="primary"
                children={t(R.strings.report_24_content_apply)}
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      }
      open={open}
      onOpenChange={setOpen}
    >
      <ButtonOpen
        children={
          <BaseRow align={'middle'} justify={'center'} gutter={[8, 8]}>
            <BaseCol>
              <FilterOutlined rev={undefined} />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
            </BaseCol>
            <BaseCol>
              <DownOutlined
                rev={undefined}
                rotate={open ? 0 : 180}
                size={14}
                style={{ width: 12, height: 12 }}
              />
            </BaseCol>
          </BaseRow>
        }
      />
    </BasePopoverWrapper>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const BaseSelectWrapper = styled(BaseSelect)`
  width: 100%;
  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }

  .ant-select-selection-item {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.9;
  }
`

const ButtonOpen = styled(BaseButton)`
  border-color: #f0f1f3;

  &:hover {
    .anticon {
      color: ${convertedVariables.primaryColor};
      opacity: 0.7;
    }
  }
`

const BasePopoverWrapper = styled(BasePopover)``
