import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'

import {
  EBillStatus,
  IFilterGetExportBill,
  IGetExportBill,
} from 'app/api/accounting/model/export-bill'
import { getDetailPatientUrl } from 'app/common/helpers'
import { BaseCol } from 'app/components/common/BaseCol'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { useDebounce, usePagination } from 'app/hook'
import { useGetDataExportBill } from 'app/react-query/hook/accounting'
import { DateUtil, Pagination, formatMoney } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from '../../type'
import { ListItemText } from '../../styles'
import { uniq, uniqBy } from 'lodash'

export const useTable = ({ reloadTable }: { reloadTable: number }) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)

  const [filter, setFilter] = useState<IFilterGetExportBill>({})

  const { flattenDataList } = usePagination()

  const [isLoading, setIsLoading] = useState(false)

  const filterDebounce = useDebounce<IFilterGetExportBill>(filter, 500)

  const {
    data: dataInfinity,
    isLoading: isLoadingData,
    refetch: refetchData,
  } = useGetDataExportBill({
    keyword: filterDebounce?.keyword ?? '',
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    fromDate: filterDebounce?.fromDate,
    toDate: filterDebounce?.toDate,
    clinicIds: filterDebounce?.clinicIds,
    clinicLegalNameIds: filterDebounce?.clinicLegalNameIds,
    status: EBillStatus?.CANCELLED,
  })

  const dataFlatten = useMemo(() => {
    const res = flattenDataList(dataInfinity)
    return res
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      } as IDataTable
    })
  }, [dataFlatten])

  const totalItem = useMemo(() => {
    return dataFlatten?.data?.total ?? 0
  }, [dataFlatten])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.request_export_bill_date),
        key: 'request_export_bill_date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IGetExportBill) => {
          return (
            <BaseText
              children={
                record?.createdAt
                  ? DateUtil.formatHHssDDMMYYYY(record?.createdAt)
                  : '-'
              }
              fontWeight="medium"
              opacity="0.9"
              textAlign="center"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.payment_code),
        key: 'payment_code',
        className: 'payment-code-column',
        render: (_, record: IGetExportBill) => {
          return (
            <BaseCol>
              {record?.paymentDetails?.map((i, index) => (
                <ListItemText
                  key={`${index}`}
                  children={`${i?.code}`}
                  fontWeight="medium"
                  opacity="0.9"
                  textAlign="center"
                />
              ))}
            </BaseCol>
          )
        },
      }),
      {
        title: t(R.strings.customer_info),
        children: [
          convertColumnTable<IDataTable>({
            title: t(R.strings.full_name),
            key: 'full_name',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <RenderValueTable
                  value={
                    record?.paymentDetails
                      ? record?.paymentDetails[0]?.patientName
                      : '-'
                  }
                  type="OtherLink"
                  hrefOtherLink={getDetailPatientUrl(
                    record?.paymentDetails
                      ? record?.paymentDetails[0]?.patientId
                      : '',
                  )}
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings._patient_code),
            key: '_patient_code',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseText
                  children={
                    record?.paymentDetails
                      ? record?.paymentDetails[0]?.patientCode
                      : '-'
                  }
                  fontWeight="medium"
                  opacity="0.9"
                  textAlign="center"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.branch),
            key: 'branch',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {uniqBy(record?.paymentDetails, 'clinicName')?.map(
                    (i, index) => (
                      <ListItemText
                        key={`${index}`}
                        children={`${i?.clinicName}`}
                        fontWeight="medium"
                        opacity="0.9"
                      />
                    ),
                  )}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.company_by_branch),
            key: 'company_by_branch',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {uniqBy(record?.paymentDetails, 'clinicLegalName')?.map(
                    (i, index) => (
                      <ListItemText
                        key={`${index}`}
                        children={`${i?.clinicLegalName}`}
                        fontWeight="medium"
                        opacity="0.9"
                      />
                    ),
                  )}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.address),
            key: 'address',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {uniqBy(record?.paymentDetails, 'clinicAddress')?.map(
                    (i, index) => (
                      <ListItemText
                        key={`${index}`}
                        children={`${i?.clinicAddress}`}
                        fontWeight="medium"
                        opacity="0.9"
                      />
                    ),
                  )}
                </BaseCol>
              )
            },
          }),
        ],
      },
      {
        title: t(R.strings.export_bill_info),
        children: [
          convertColumnTable<IDataTable>({
            title: t(R.strings.service_use),
            key: 'service_use',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {record?.usedServices?.map((i, index) => (
                    <BaseText
                      key={`${index}`}
                      children={`- ${i?.name}`}
                      fontWeight="medium"
                      opacity="0.9"
                    />
                  ))}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.service_use_date),
            key: 'service_use_date',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              const createdAtList = uniq(
                record?.paymentDetails?.map(i => i?.createdAt),
              )
              return (
                <BaseCol>
                  {createdAtList?.map((i, index) => (
                    <ListItemText
                      key={`${index}`}
                      children={i ? DateUtil.formatDDMMYYY(i) : ''}
                      fontWeight="medium"
                      opacity="0.9"
                    />
                  ))}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.total_amount_receivable),
            key: 'total_amount_receivable',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              const price =
                record?.usedServices?.reduce(
                  (sum, item) => (item?.price ? sum + item?.price : sum),
                  0,
                ) || 0
              return (
                <BaseText
                  children={price > 0 ? formatMoney(price, 'đ') : '0đ'}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.tax_code),
            key: 'tax_code',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseText
                  children={record?.taxId}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.company_name_customer_name),
            key: 'company_name_customer_name',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseText
                  children={record?.companyName}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.address),
            key: 'address_customer',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseText
                  children={record?.address}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.email_receive_bill),
            key: 'email_receive_bill',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseText
                  children={record?.email}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.service_request_export_bill),
            key: 'service_request_export_bill',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              return (
                <BaseCol>
                  {record?.selectedServices?.map((i, index) => (
                    <BaseText
                      key={`${index}`}
                      children={`- ${i?.name}`}
                      fontWeight="medium"
                      opacity="0.9"
                    />
                  ))}
                </BaseCol>
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: t(R.strings.total_amount_export_bill),
            key: 'total_amount_export_bill',
            classNameWidthColumnOverwrite: 'normal-column',
            render: (_, record: IGetExportBill) => {
              const price =
                record?.selectedServices?.reduce(
                  (sum, item) => (item?.price ? sum + item?.price : sum),
                  0,
                ) || 0
              return (
                <BaseText
                  children={price > 0 ? formatMoney(price, 'đ') : '0đ'}
                  fontWeight="medium"
                  opacity="0.9"
                />
              )
            },
          }),
        ],
      },
    ]
    return option
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterGetExportBill) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  useEffect(() => {
    if (reloadTable > 0) {
      refetchData()
    }
  }, [reloadTable])

  return {
    data,
    columns,
    filter,
    isLoading,
    setIsLoading,
    isLoadingData,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    handleChangeFilter,
    totalItem,
  }
}
