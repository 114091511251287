import R from 'app/assets/R'
import HeaderPage from 'app/components/header/components/HeaderPage'
import {
  Avatar,
  Degree,
  DirectManager,
  DirectSubordinate,
  IndirectSubordinate,
  PersonalInformation,
  Skill,
  WorkInfo,
} from './components'
import { useModalHook } from './hook'
import * as S from './styles'

const ProfileContainer = () => {
  const { profile, listWorkInfo, listWorkInfoAka } = useModalHook()

  return (
    <S.BaseActionWrapper>
      <HeaderPage titleI18nKey={R.strings.profile} />
      <S.BaseFormWrapper layout="vertical" requiredMark="optional">
        <Avatar profile={profile} />
        <PersonalInformation profile={profile} />
        <Degree profile={profile} />
        <Skill profile={profile} />
        <WorkInfo
          listWorkInfo={listWorkInfo}
          listWorkInfoAka={listWorkInfoAka}
        />
        <DirectManager profile={profile} />
        <DirectSubordinate profile={profile} />
        <IndirectSubordinate profile={profile} />
      </S.BaseFormWrapper>
    </S.BaseActionWrapper>
  )
}

export default ProfileContainer
