import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseDivider } from 'app/components/common/BaseDivider'
import BaseText from 'app/components/common/BaseText'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const CollapseWrapper = styled(BaseCollapse)`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: var(--white);
    .ant-collapse-content-box {
      padding: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;

      .ant-space-item {
        .ant-row {
          margin: 0 !important;
        }
      }
    }
    padding: 24px 0;
  }

  .ant-collapse-header {
    padding: 16px 20px !important;
    align-items: center !important;
    background-color: var(--white);
    border-radius: 8px !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
    background-color: ${convertedVariables.backgroundColor};
  }
`
export const BaseTitle = styled(BaseText)`
  font-size: ${FONT_SIZE.md}!important;
  font-weight: ${FONT_WEIGHT.bold}!important;
`
export const BaseSelectWrapper = styled(BaseSelect)`
  width: 405px !important;
  height: 60px !important;
  .ant-select-selector {
    .ant-select-selection-placeholder {
      font-size: ${FONT_SIZE.xs}!important;
      font-weight: ${FONT_WEIGHT.medium}!important;
    }
  }
`

export const LeftCol = styled(BaseCol)``
export const Divider = styled(BaseDivider)`
  margin: 12px 0;
`
export const BaseTableReport = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    text-align: left !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: ${convertedVariables.primaryColor};
    opacity: 0.7;
  }
`
