import { withLoading } from 'app/hocs/with-loading.hoc'
import SurveyReportPage from 'app/page/menu/customer-care/survey-report'
import ReportPage from 'app/page/menu/report/report'
import Report001Page from 'app/page/menu/report/report-001'
import Report002Page from 'app/page/menu/report/report-002'
import Report003Page from 'app/page/menu/report/report-003'
import Report004Page from 'app/page/menu/report/report-004'
import Report005Page from 'app/page/menu/report/report-005'
import Report006Page from 'app/page/menu/report/report-006'
import Report007Page from 'app/page/menu/report/report-007'
import Report008Page from 'app/page/menu/report/report-008'
import Report009Page from 'app/page/menu/report/report-009'
import Report010Page from 'app/page/menu/report/report-010'
import Report011Page from 'app/page/menu/report/report-011'
import Report012Page from 'app/page/menu/report/report-012'
import Report014Page from 'app/page/menu/report/report-014'
import { Route } from 'react-router-dom'
import {
  CUSTOMER_SURVEY_REPORT_PATH,
  REPORT_001_FULL_PATH,
  REPORT_002_FULL_PATH,
  REPORT_003_FULL_PATH,
  REPORT_004_FULL_PATH,
  REPORT_005_FULL_PATH,
  REPORT_006_FULL_PATH,
  REPORT_007_FULL_PATH,
  REPORT_008_FULL_PATH,
  REPORT_009_FULL_PATH,
  REPORT_010_FULL_PATH,
  REPORT_011_FULL_PATH,
  REPORT_012_FULL_PATH,
  REPORT_014_FULL_PATH,
  REPORT_PATH,
  REPORT_REFERER_FULL_PATH,
  REPORT_015_FULL_PATH,
  REPORT_016_FULL_PATH,
  REPORT_018_FULL_PATH,
  REPORT_020_FULL_PATH,
  REPORT_021_FULL_PATH,
  REPORT_023_FULL_PATH,
  REPORT_024_FULL_PATH
} from './route-path'
import RefererReportPage from 'app/page/menu/marketing/referer-report'
import Report015Page from 'app/page/menu/report/report-015'
import Report016Page from 'app/page/menu/report/report-016'
import Report018Page from 'app/page/menu/report/report-018'
import Report020Page from 'app/page/menu/report/report-020'
import Report021Page from 'app/page/menu/report/report-021'
import Report23Page from 'app/page/menu/marketing/referer-023'
import Report024Page from 'app/page/menu/report/report-024'

const ReportDashboard = withLoading(ReportPage)
const Report001 = withLoading(Report001Page)
const Report002 = withLoading(Report002Page)
const Report003 = withLoading(Report003Page)
const Report004 = withLoading(Report004Page)
const Report005 = withLoading(Report005Page)
const Report006 = withLoading(Report006Page)
const Report007 = withLoading(Report007Page)
const Report008 = withLoading(Report008Page)
const Report009 = withLoading(Report009Page)
const Report010 = withLoading(Report010Page)
const Report011 = withLoading(Report011Page)
const Report012 = withLoading(Report012Page)
const Report014 = withLoading(Report014Page)
const Report015 = withLoading(Report015Page)
const Report016 = withLoading(Report016Page)
const Report018 = withLoading(Report018Page)
const Report020 = withLoading(Report020Page)
const Report021 = withLoading(Report021Page)
const Report024 = withLoading(Report024Page)
const SurveyReport = withLoading(SurveyReportPage)
const RefererReport = withLoading(RefererReportPage)
const Report23 = withLoading(Report23Page)

export const ReportRouter = () => {
  return (
    <>
      <Route path={REPORT_PATH} element={<ReportDashboard />} />
      <Route path={REPORT_001_FULL_PATH} element={<Report001 />} />
      <Route path={REPORT_002_FULL_PATH} element={<Report002 />} />
      <Route path={REPORT_003_FULL_PATH} element={<Report003 />} />
      <Route path={REPORT_004_FULL_PATH} element={<Report004 />} />
      <Route path={REPORT_005_FULL_PATH} element={<Report005 />} />
      <Route path={REPORT_006_FULL_PATH} element={<Report006 />} />
      <Route path={REPORT_007_FULL_PATH} element={<Report007 />} />
      <Route path={REPORT_008_FULL_PATH} element={<Report008 />} />
      <Route path={REPORT_009_FULL_PATH} element={<Report009 />} />
      <Route path={REPORT_010_FULL_PATH} element={<Report010 />} />
      <Route path={REPORT_011_FULL_PATH} element={<Report011 />} />
      <Route path={REPORT_012_FULL_PATH} element={<Report012 />} />
      <Route path={REPORT_014_FULL_PATH} element={<Report014 />} />
      <Route path={CUSTOMER_SURVEY_REPORT_PATH} element={<SurveyReport />} />
      <Route path={REPORT_REFERER_FULL_PATH} element={<RefererReport />} />
      <Route path={REPORT_023_FULL_PATH} element={<Report23 />} />
      <Route path={REPORT_015_FULL_PATH} element={<Report015 />} />
      <Route path={REPORT_016_FULL_PATH} element={<Report016 />} />
      <Route path={REPORT_018_FULL_PATH} element={<Report018 />} />
      <Route path={REPORT_020_FULL_PATH} element={<Report020 />} />
      <Route path={REPORT_021_FULL_PATH} element={<Report021 />} />
      <Route path={REPORT_024_FULL_PATH} element={<Report024 />} />
    </>
  )
}
