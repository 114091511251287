import { ModulePermissionEnum } from 'app/common/permission-module'
import { Dayjs } from 'dayjs'

export interface IDataTable {
  [key: string]: string
}

export interface IFormFilter {
  locations?: string[]
  stars?: number[]
  keyword?: string
  startDate?: Dayjs | null
  endDate?: Dayjs | null
}

export enum FeedbackSurveyReport23Enum {
  PROFESSIONAL_COMPETENCE = 'Năng lực chuyên môn',
  SERVICE_EXPERIENCE = 'Trải nghiệm dịch vụ',
  FACILITIES = 'Cơ sở vật chất',
}


export interface IProps {
  module: ModulePermissionEnum
}