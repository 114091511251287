import {
  CampaignStatusEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import BaseText from 'app/components/common/BaseText'
import { MARKETING_CREATE_CAMPAIGN_PATH } from 'app/components/router/route-path'
import {
  convertColumnTable,
  ExpandedTableValue,
} from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { useDebounce, usePagination } from 'app/hook'
import { useGetCampaignGroupList } from 'app/react-query/hook/campaign'
import { formatMoney, Pagination, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { IDataGroupTable, IFilterDataTableReport } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])
  const [pagination, setPagination] = useState(initPagination)

  const [filter, setFilter] = useState<IFilterDataTableReport>({
    campaignStatus: [CampaignStatusEnum.OnGoing, CampaignStatusEnum.UpComing],
  })

  const { user, isHavePermissionFunction, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.READ_DISCOUNT_CAMPAIGN,
      R.strings.discount_campaign_management,
    )
  }, [user])

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IFilterDataTableReport>(filter, 500)

  const { data: dataApi, isLoading } = useGetCampaignGroupList({
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    campaignType: filterDebounce?.campaignType,
    keyword: filterDebounce?.name,
    clinics: filterDebounce?.clinics,
    priority: filterDebounce?.priority,
    isStackedDiscount:
      filterDebounce?.applyWithOtherCampaign !== undefined
        ? filterDebounce?.applyWithOtherCampaign === true
          ? 1
          : 0
        : undefined,
    start: filterDebounce?.startDate,
    end: filterDebounce?.endDate,
    campaignStatus: filterDebounce?.campaignStatus,
  })

  const dataFlatten = useMemo(() => {
    const res: ResponseType<IDiscountCampaign[]> = flattenDataList(dataApi)
    return res
  }, [dataApi])

  const isHavePermission = useMemo(() => {
    const isHavePermissionCreate = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.CREATE_DISCOUNT_CAMPAIGN,
    )

    return {
      isHavePermissionCreate,
    }
  }, [user])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataGroupTable>({
        title: t(R.strings.group_campaign),
        key: 'name',
        fixed: 'left',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.name}
              isHaveAreaWhenNotHaveActionExpanded
            />
          )
        },
      }),
      convertColumnTable<IDataGroupTable>({
        title: t(R.strings.total),
        key: 'totalCampaign',
        width: '10%',
        classNameWidthColumnOverwrite: 'number-column',
        render: (value?: number) => {
          return (
            <BaseText
              children={formatMoney(value ?? 0, '')}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
    ]
    return option
  }, [t, isHavePermission, expandedRowKeys])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterDataTableReport) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  const navigateCreate = () => {
    navigate(MARKETING_CREATE_CAMPAIGN_PATH)
  }

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      } as IDataGroupTable
    })
  }, [dataFlatten?.data])

  return {
    data,
    columns,
    isLoading,
    filter,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.metadata?.totalItem ?? 0,
    },
    handleChangeFilter,
    navigateCreate,
    isHavePermission,
    expandedRowKeys,
    handleExpand,
  }
}
