import { BaseButton } from 'app/components/common/BaseButton'
import BaseText from 'app/components/common/BaseText'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { FONT_SIZE, FONT_WEIGHT, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const UploadAvatar = styled(BaseUpload)`
  display: flex;

  .ant-upload-list {
    display: flex !important;
    flex-direction: row;
    animation: none !important;
  }

  .ant-upload-animate-inline-leave {
    display: none !important;
  }

  & .ant-upload-list-item-container {
    animation: none !important;
    transition: none !important;
  }

  & .ant-upload-list-item-container,
  .ant-upload.ant-upload-select {
    display: flex;
    width: unset !important;
    height: unset !important;
    background-color: transparent !important;
    border: unset !important;

    & .avatar-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        border-radius: 50%;
        width: 98px;
        height: 98px;
      }
    }
  }
`

export const ButtonUpload = styled(BaseButton)`
  gap: 0;
  position: absolute;
  left: 120px;
  top: 20px;
  height: 32px;
  background-color: #639aff;
  color: #fff;
  font-weight: ${FONT_WEIGHT.medium} !important;
  font-size: ${FONT_SIZE.xxs} !important;

  &:hover {
    color: #fff !important;
  }
`

export const AvatarDescription = styled(BaseText)`
  color: ${colors.primary} !important;
  font-weight: ${FONT_WEIGHT.regular} !important;
  font-size: ${FONT_SIZE.xxxs} !important;
  opacity: 0.7;
  margin-top: 12px;
`
export const ButtonRemove = styled(BaseButton)`
  gap: 0;
  width: 80px;
  height: 32px;
  font-weight: ${FONT_WEIGHT.medium} !important;
  font-size: ${FONT_SIZE.xxs} !important;
  background-color: var(--neutral-black-16-color);
  color: var(--text-primary2-color);
  margin-left: 160px;

  &:hover {
    color: var(--text-primary2-color) !important;
  }
`
