/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import deVi from 'antd/lib/locale/vi_VN'
import { requestUpdateMessageToken } from 'app/api/profile'
import { AppRouter } from 'app/components/router/app-router'
import { JobTitleProvider } from 'app/context/JobTitleContext'
import { ProfileProvider } from 'app/context/ProfileContext'
import { TreatmentGroupProvider } from 'app/context/TreatmentGroupContext'
import { UnitProvider } from 'app/context/UnitContext'
import { LanguageSupportType } from 'app/i18n/constant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfo } from 'app/redux/slices/profileSlice'
import {
  getCurrentLanguage,
  getToken,
} from 'app/service/storage/storage-service'
import GlobalStyle from 'app/styles/GlobalStyle'
import { themeObject } from 'app/styles/themes/themeVariables'
import { getMessagingToken } from 'app/utils/firebase'
import { useEffect, useRef } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import NotificationSound from './app/assets/sound/notification-sound.mp3'
import './app/i18n'
import { isEqual } from 'lodash'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const language = getCurrentLanguage() as LanguageSupportType
  const theme = useAppSelector(state => state.theme.theme)
  const profile = useAppSelector(state => state.profileSlice)?.profile
  const user = useAppSelector(state => state.user)?.user
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const token = getToken()
  const audioPlayer = useRef<any>(null)

  const getLanguage = () => {
    if (language) {
      i18n.changeLanguage(language)
      return
    }
  }

  const onChangeMessageToken = async () => {
    try {
      const profileId = profile?._id || user?._id || user?.id
      const token = await getMessagingToken()

      if (!token || !profileId) return

      const currentMessageToken = profile?.messagingToken

      await requestUpdateMessageToken({
        id: String(profileId),
        messagingToken: token,
      })

      if (!isEqual(currentMessageToken, token)) {
        await dispatch(getUserInfo())
      }
    } catch (error) {
      console.log('error')
    }
  }

  const getData = async () => {
    if (token) {
      await dispatch(getUserInfo())
      await onChangeMessageToken()
    }
  }

  useEffect(() => {
    getData()
    getLanguage()
  }, [token])

  return (
    <QueryClientProvider client={queryClient}>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <audio ref={audioPlayer} src={NotificationSound} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deVi}>
          <ProfileProvider>
            <JobTitleProvider>
              <UnitProvider>
                <TreatmentGroupProvider>
                  <AppRouter />
                </TreatmentGroupProvider>
              </UnitProvider>
            </JobTitleProvider>
          </ProfileProvider>
        </ConfigProvider>
      </HelmetProvider>
    </QueryClientProvider>
  )
}
export default App
