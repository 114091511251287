import { ICampaignGroup } from 'app/api/marketing/discount-campaign/model'
import { IDataGroupTable } from 'app/containers/Marketing/DiscountCampaignManagement/type'
import { usePagination } from 'app/hook'
import { useGetCampaignGroupList } from 'app/react-query/hook/campaign'
import { isEqual } from 'lodash'
import { initialPagination, Pagination, ResponseType } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateCampaignContext } from '../../../../context'

export const useHook = () => {
  const { t } = useTranslation()
  const { stackedWithCampaignGroups, info } = useCreateCampaignContext()

  const [pagination, setPagination] = useState<Pagination>({
    current: initialPagination.current,
    pageSize: 100,
  })

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const { flattenDataList } = usePagination()

  const { data: dataApi, isLoading } = useGetCampaignGroupList(
    {
      page: pagination?.current,
      pageSize: pagination?.pageSize,
      pagesize: pagination?.pageSize,
    },
    true,
    {
      isFetch: true,
      params: {
        start: info?.startDate?.toISOString(),
        end: info?.endDate?.toISOString(),
        clinics: info?.clinics,
      },
    },
  )

  const dataFlatten = useMemo(() => {
    const res: ResponseType<ICampaignGroup[]> = flattenDataList(dataApi)

    let data: IDataGroupTable[] = []

    res?.data?.forEach((item, index) => {
      const findItemInStacked = stackedWithCampaignGroups?.find(itm =>
        isEqual(item?.code, itm?.group?.code),
      )

      if (findItemInStacked) {
        data = data.concat([
          {
            ...item,
            key: index + 1,
          },
        ])
      }
    })

    return {
      ...res,
      data,
    }
  }, [dataApi, stackedWithCampaignGroups])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const onChangePagination = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const indeterminateSelectedAll = useMemo(() => {
    if (!stackedWithCampaignGroups?.length) return false

    if (dataFlatten?.data?.length > stackedWithCampaignGroups?.length) {
      return true
    }
    return false
  }, [dataFlatten?.data, stackedWithCampaignGroups])

  return {
    t,
    pagination,
    isLoading,
    dataFlatten,
    onChangePagination,
    expandedRowKeys,
    handleExpand,
    stackedWithCampaignGroups,
    indeterminateSelectedAll,
  }
}
