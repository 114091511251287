import { BaseRow } from 'app/components/common/BaseRow'
import {
  AvatarProfile,
  SectionBaseWrapper,
  StatusChip,
  TextJobTitleProfile,
  TextNameProfile,
} from './styles'
import { BaseCol } from 'app/components/common/BaseCol'
import { useTranslation } from 'react-i18next'
import { convertStatusToFilterStatus } from 'app/common/helpers'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { IUser } from 'app/model/user.model'
import { IProfileUser } from 'app/api/profile/model/profile'

interface IProps {
  profile?: IProfileUser
}

export const Avatar = (props: IProps) => {
  const { profile } = props
  const { t } = useTranslation()
  return (
    <SectionBaseWrapper>
      <BaseRow gutter={20} align={'bottom'} justify={'start'}>
        <BaseCol>
          <AvatarProfile
            src={profile?.avatar}
            alt={`${profile?.firstname ?? ''} ${profile?.lastname ?? ''} `}
          />
        </BaseCol>
        <BaseCol>
          <BaseRow gutter={[20, 20]} align={'middle'}>
            <BaseCol>
              <TextNameProfile>{`${profile?.firstname ?? ''} ${
                profile?.lastname ?? ''
              } `}</TextNameProfile>
            </BaseCol>
            <BaseCol>
              <StatusChip>
                {t(convertStatusToFilterStatus(profile?.status))}
              </StatusChip>
            </BaseCol>
          </BaseRow>
          <TextJobTitleProfile>
            {profile?.jobTitleName ?? '-'}
          </TextJobTitleProfile>
        </BaseCol>
      </BaseRow>
    </SectionBaseWrapper>
  )
}
