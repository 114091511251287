import { BaseSpace } from 'app/components/common/BaseSpace'
import { useHook } from '../hook'
import { StatusApproveEnum } from 'app/api/dental-treatment/survey-dynamic/type'
import { Loading } from 'app/components/common/Loading'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePagination } from 'app/components/common/BasePagination'
import { ItemPatientComp } from '../../components'
import styled from 'styled-components'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { BaseEmpty } from 'app/components/common/BaseEmpty'

export const ApprovedTab = () => {
  const { data, isLoading, onChangePagination, pagination } = useHook({
    statusApprove: StatusApproveEnum.APPROVED,
  })

  return (
    <RootWrapper>
      <BaseSpace className="list">
        {data?.length ? (
          data?.map((item, index) => {
            return (
              <ItemPatientComp
                status={StatusApproveEnum.APPROVED}
                item={item}
                key={index}
              />
            )
          })
        ) : (
          <BaseEmpty />
        )}
      </BaseSpace>

      {data?.length ? (
        <BaseRow align={'middle'} justify={'end'} className="footer">
          <BaseCol>
            <BasePagination
              pageSize={pagination.pageSize}
              current={pagination.current}
              total={pagination.total}
              onChange={onChangePagination}
            />
          </BaseCol>
        </BaseRow>
      ) : null}
      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  flex: 1;
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.xs};
  border-radius: ${BORDER_RADIUS};
  .list {
    height: 60vh;
    overflow-x: auto;
  }

  .footer {
    background-color: ${convertedVariables.backgroundColor};
    padding: ${PADDING.xs};
    border-radius: ${BORDER_RADIUS};
  }
`
