/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestGetNotificationHistory,
  requestPutReadNotification,
} from 'app/api/company/notification'
import { NotificationMessageCode } from 'app/api/company/notification/constant'
import {
  INotificationHistoryOfUser,
  IParamsGetNotificationHistory,
  IPayloadReadNotification,
  ResponseGetNotificationHistoryOfUserType,
} from 'app/api/company/notification/type'
import { usePagination } from 'app/hook'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { changeRefetchNotificationValue } from 'app/redux/slices/profileSlice'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { NotificationKeys } from '../query-key/notification'
export function useGetNotificationHistory(
  params: IParamsGetNotificationHistory,
) {
  const { getNextPageParam } = usePagination()
  const userData = useAppSelector(state => state?.user)
  const newParams: IParamsGetNotificationHistory = {
    ...params,
    profileId: userData?.user?._id,
  }
  const token = getToken()

  return useInfiniteQuery<
    ResponseType<INotificationHistoryOfUser[]>,
    undefined
  >(
    NotificationKeys.getListHistory(newParams), // queryKey để nhận biết cache dữ liệu
    async data => {
      const res: ResponseGetNotificationHistoryOfUserType =
        await requestGetNotificationHistory({
          ...newParams,
          ...data?.pageParam,
        })

      if (
        isEqual(res?.msgcode, NotificationMessageCode.History.successGetData)
      ) {
        return res
      }

      return Promise.reject(res)
    },
    {
      enabled: !!params && !!token,
      staleTime: 0,
      getNextPageParam,
    },
  )
}

export function useGetTotalNotificationHistory() {
  const token = getToken()
  const userData = useAppSelector(state => state?.user)
  return useInfiniteQuery<
    { total: { read: number; notRead: number } },
    undefined
  >(
    NotificationKeys.allHistories,
    async () => {
      try {
        const [resNotRead, resAll]: [
          ResponseGetNotificationHistoryOfUserType,
          ResponseGetNotificationHistoryOfUserType,
        ] = await Promise.all([
          requestGetNotificationHistory({
            isRead: false,
            profileId: userData?.user?._id,
          }),
          requestGetNotificationHistory({
            profileId: userData?.user?._id,
          }),
        ])

        if (
          isEqual(
            resNotRead?.msgcode,
            NotificationMessageCode.History.successGetData,
          ) &&
          isEqual(
            resAll?.msgcode,
            NotificationMessageCode.History.successGetData,
          )
        ) {
          return {
            total: {
              read: resAll?.metadata?.totalItem ?? 0,
              notRead: resNotRead?.metadata?.totalItem ?? 0,
            },
          }
        }

        return {
          total: {
            read: 0,
            notRead: 0,
          },
        }
      } catch (error: any) {
        return {
          total: {
            read: 0,
            notRead: 0,
          },
        }
      }
    },
    {
      enabled: !!token,
    },
  )
}

export const useHandleReadNotification = () => {
  const userData = useAppSelector(state => state?.user)
  const dispatch = useAppDispatch()
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadReadNotification, unknown>(
    async (body?: IPayloadReadNotification) => {
      if (!userData?.user?._id) return

      await requestPutReadNotification({
        profileId: userData?.user?._id,
        notificationId: body?.notificationId,
        isRead: body?.isRead,
      })
    },
    {
      onSuccess: () => {
        dispatch(changeRefetchNotificationValue(new Date().getTime()))

        query.invalidateQueries({
          queryKey: NotificationKeys.allHistories,
        })
      },
    },
  )
}
