import { ApiClient, ApiClientHr } from 'app/service/api-service'
import { HrEndPoint } from './constant'
import { IParamsGetUnit } from './model'
import { IParamsGetApproveDayOff } from './models/approve-day-off'
import { IBodyImport } from 'app/containers/HumanResources/ProfileManagement/components/ImportModal/type'

export const requestGetUnit = (params: IParamsGetUnit) =>
  ApiClientHr.Get({
    url: HrEndPoint.Unit.requestGetData,
    params: { params },
  })

// day off
export const requestGetTotalDashboardDayOff = (
  params?: IParamsGetApproveDayOff,
) =>
  ApiClientHr.Get({
    url: HrEndPoint.DayOff.requestTotal,
    params: { params },
  })

export const requestGetDashboardDayOff = (params?: IParamsGetApproveDayOff) =>
  ApiClientHr.Get({
    url: HrEndPoint.DayOff.requestList,
    params: { params },
  })
export const requestGetLeavesByLinkedId = (id: string) =>
  ApiClientHr.Get({
    url: `${HrEndPoint.Leave.linked}/${id}`,
    params: {},
  })
export const approveDayOff = (id: string) =>
  ApiClientHr.Put({
    url: `${HrEndPoint.DayOff.mainUrl}/approve/${id}`,
    body: {},
  })
export const rejectDayOff = (body: { id: string; rejectReason: string }) =>
  ApiClientHr.Put({
    url: `${HrEndPoint.DayOff.mainUrl}/reject/${body?.id}`,
    body,
  })
export const approveLeave = (id: string) =>
  ApiClientHr.Put({
    url: `${HrEndPoint.Leave.mainUrl}/approve/${id}`,
    body: {},
  })
export const rejectLeave = (body: { id: string; rejectReason: string }) =>
  ApiClientHr.Put({
    url: `${HrEndPoint.Leave.mainUrl}/reject/${body?.id}`,
    body,
  })

export const importEmployee = (body: FormData) =>
  ApiClient.Post({
    url: HrEndPoint.Employee.import,
    body,
  })
export const updateEmployee = (body: IBodyImport) =>
  ApiClient.Put({
    url: HrEndPoint.Employee.update,
    body,
  })
