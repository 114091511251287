import { requestDoctorReportMonthly, requestGetGeneralInfo, requestGetMyAppointment } from 'app/api/dashboard'
import { DashboardMessageCode } from 'app/api/dashboard/constant'
import {
  IGeneralInfo,
  IParamsGeneralInfo,
  IResponseGeneralInfo,
} from 'app/api/dashboard/model/bod'
import { isEqual } from 'lodash'
import { useQuery } from 'react-query'
import { DashboardKeys } from '../query-key/dashboard'
import { IParamsDoctorMonthlyReport, IParamsMyAppointmentDashboard, ResponseDoctorReportMonthly, ResponseMyAppointmentDashboard } from 'app/api/dashboard/model/doctor'

export function useGetGeneralInfo(params?: IParamsGeneralInfo, enabled = true) {
  return useQuery<IGeneralInfo, IParamsGeneralInfo>(
    DashboardKeys.generalInfo(params),
    async () => {
      try {
        const response: IResponseGeneralInfo = await requestGetGeneralInfo(
          params,
        )
        if (
          isEqual(
            response?.msgcode,
            DashboardMessageCode.GeneralInfoDashboard.successGetData,
          )
        ) {
          return response?.data
        }
        return {}
      } catch (error) {
        return {}
      }
    },
    {
      enabled,
    },
  )
}


export function useGetMyAppointment(params?: IParamsMyAppointmentDashboard, enabled = true) {
  return useQuery<ResponseMyAppointmentDashboard | undefined, IParamsMyAppointmentDashboard>(
    DashboardKeys.myAppointment(params),
    async () => {
      try {
        const response: ResponseMyAppointmentDashboard = await requestGetMyAppointment(
          params,
        )
        if (
          isEqual(
            response?.msgcode,
            DashboardMessageCode.MyAppointment.successGetData
          )
        ) {
          return response
        }
        return undefined
      } catch (error) {
        return undefined
      }
    },
    {
      enabled,
    },
  )
}




export function useGetDoctorReportMonthly(params?: IParamsDoctorMonthlyReport, enabled = true) {
  return useQuery<ResponseDoctorReportMonthly | undefined, IParamsDoctorMonthlyReport>(
    DashboardKeys.doctorReportMonthly(params),
    async () => {
      try {
        const response: ResponseDoctorReportMonthly = await requestDoctorReportMonthly(
          params,
        )
        if (
          isEqual(
            response?.msgcode,
            DashboardMessageCode.Doctor.doctorReportSuccess
          )
        ) {
          return response
        }
        return undefined
      } catch (error) {
        return undefined
      }
    },
    {
      enabled,
    },
  )
}
