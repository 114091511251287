import { DashOutlined } from '@ant-design/icons'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import R from 'app/assets/R'
import { BasePopover } from 'app/components/common/BasePopover'
import { t } from 'i18next'
import { DateUtil, FORMAT_DD_MM_YYYY } from 'parkway-web-common'
import React from 'react'
import * as S from './styles'

interface WorkInfoItemProps {
  jobTitle?: IJobTitle
  start?: Date
  end?: Date
  key: number
  index: number
  onRemove?: (key: number) => void
  onUpdate?: (key: number) => void
}

const WorkInfoItem: React.FC<WorkInfoItemProps> = ({
  jobTitle,
  key,
  start,
  end,
  index,
  onRemove,
}) => {
  return (
    <S.BaseRowStyled key={key}>
      <S.WorkInfoItem xl={6}>
        <S.TitleFormList>{jobTitle?.name}</S.TitleFormList>
        <S.ClassificationWrapper>
          {start && (
            <S.DateText>
              {t(R.strings.start_date)}:{' '}
              {DateUtil.formatDate(start, FORMAT_DD_MM_YYYY)}
            </S.DateText>
          )}
          {end && (
            <S.DateText>
              {t(R.strings.end_date)}:{' '}
              {DateUtil.formatDate(end, FORMAT_DD_MM_YYYY)}
            </S.DateText>
          )}
        </S.ClassificationWrapper>
      </S.WorkInfoItem>
      <S.ButtonActionGroup>
        <BasePopover
          placement="bottomLeft"
          trigger="click"
          content={
            <>
              <S.ButtonStyled onClick={() => onRemove && onRemove(index)}>
                {t(R.strings.delete)}
              </S.ButtonStyled>
            </>
          }
        >
          <DashOutlined rev={undefined} rotate={90} />
        </BasePopover>
      </S.ButtonActionGroup>
    </S.BaseRowStyled>
  )
}

export default WorkInfoItem
