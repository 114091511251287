import { IDiscountVoucher } from 'app/api/marketing/discount-voucher/model'
import { ISkillProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { usePagination } from 'app/hook'
import {
  useGetMyVoucherList,
  useGetReferralVoucherList,
} from 'app/react-query/hook/profile'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfo } from 'app/redux/slices/profileSlice'
import 'dayjs/locale/vi'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VoucherCard } from './components'

export const useModalHook = () => {
  const profile = useAppSelector(state => state.profileSlice.profile)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const { flattenDataList } = usePagination()
  const [myVCPageSize, setMyVCPageSize] = useState(10)
  const [myVCCurrentPage, setMyVCCurrentPage] = useState(1)
  const [rVCPageSize, setRVCPageSize] = useState(10)
  const [rVCCurrentPage, setRVCCurrentPage] = useState(1)

  const onChangeMyVCPageSize = (newPageSize: number) => {
    setMyVCPageSize(newPageSize)
  }

  const onChangeMyVCPage = (page: number) => {
    setMyVCCurrentPage(page)
  }

  const onChangeRVCPageSize = (newPageSize: number) => {
    setRVCPageSize(newPageSize)
  }

  const onChangeRVCPage = (page: number) => {
    setRVCCurrentPage(page)
  }

  console.log({ myVCCurrentPage })

  const { data: dataMyVCInfinity, isLoading: loadingMyVC } =
    useGetMyVoucherList({
      page: myVCCurrentPage,
      pageSize: myVCPageSize,
      pagesize: myVCPageSize,
    })

  const flattenMyVoucherData = useMemo(() => {
    return flattenDataList(dataMyVCInfinity)
  }, [dataMyVCInfinity])

  const { data: dataRVCInfinity, isLoading: loadingRVC } =
    useGetReferralVoucherList({
      page: rVCCurrentPage,
      pageSize: rVCPageSize,
      pagesize: rVCPageSize,
    })
  const flattenReferralVoucherData = useMemo(() => {
    return flattenDataList(dataRVCInfinity)
  }, [dataRVCInfinity])

  const ReferralList = () => {
    const renderItem = (item: IDiscountVoucher, index: number) => {
      return (
        <BaseCol key={`referral-voucher-${index}`}>
          <VoucherCard data={item} codeTitle={t(R.strings.referral_code)} />
        </BaseCol>
      )
    }
    return (
      <BaseRow gutter={[20, 20]}>
        {flattenReferralVoucherData?.data?.map(renderItem)}
      </BaseRow>
    )
  }

  const MyVoucherList = () => {
    const renderItem = (item: IDiscountVoucher, index: number) => {
      return (
        <BaseCol key={`my-voucher-${index}`}>
          <VoucherCard data={item} codeTitle={t(R.strings.voucher_code)} />
        </BaseCol>
      )
    }
    return (
      <BaseRow gutter={[20, 20]}>
        {flattenMyVoucherData?.data?.map(renderItem)}
      </BaseRow>
    )
  }

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  const skills = useMemo(() => {
    return groupByIndustry(profile?.skills ?? [])
  }, [profile])

  const listWorkInfo = useMemo(() => {
    return profile?.workinfos?.filter(item => item?.isMain) ?? []
  }, [profile])

  const listWorkInfoAka = useMemo(() => {
    return profile?.workinfos?.filter(item => !item?.isMain) ?? []
  }, [profile])

  return {
    form,
    t,
    skills,
    profile,
    listWorkInfo,
    listWorkInfoAka,
    myVoucherList: flattenMyVoucherData,
    referralVoucherList: flattenReferralVoucherData,
    myVCPageSize,
    myVCCurrentPage,
    rVCCurrentPage,
    rVCPageSize,
    onChangeMyVCPage,
    onChangeMyVCPageSize,
    onChangeRVCPage,
    onChangeRVCPageSize,
    loadingMyVC,
    loadingRVC,
    ReferralList,
    MyVoucherList,
  }
}

function groupByIndustry(
  data: Array<ISkillProfileUser>,
): Array<{ nameType: string; children: Array<ISkillProfileUser> }> {
  const groups = data.reduce((acc, item) => {
    const group = acc.find(group => group.nameType === item.industry?.name)
    if (group) {
      group.children.push(item)
    } else {
      acc.push({
        nameType: item.industry?.name ?? '-',
        children: [item],
      })
    }
    return acc
  }, [] as Array<{ nameType: string; children: Array<ISkillProfileUser> }>)
  return groups
}
