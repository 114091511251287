import {
  EOptionType,
  IAnswerSurveyDynamic,
  IQuestionSurveyDynamic,
  ISurveyDynamic,
} from 'app/api/dental-treatment/survey-dynamic/type'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText, { FontWeightType } from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { isEmpty, isEqual, isNil } from 'lodash'
import { BORDER_RADIUS, normalizePhone, PADDING } from 'parkway-web-common'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { useHook } from './hook'

const InfoWrapper = ({
  children,
  title,
}: {
  title?: string
  children: ReactNode
}) => {
  return (
    <BaseSpace className="info">
      <BaseText children={title} fontWeight="medium" fontSize="xs" />
      <BaseSpace size={8}>{children}</BaseSpace>
    </BaseSpace>
  )
}

const ItemInfo = ({
  label,
  value,
  fontWeight,
}: {
  label?: string
  value?: string
  fontWeight?: FontWeightType
}) => {
  return (
    <BaseRow gutter={[16, 16]} align={'middle'}>
      <BaseCol xl={4}>
        <BaseText children={label} style={{ color: '#858C94' }} />
      </BaseCol>
      <BaseCol xl={20}>
        <BaseText
          children={!isEmpty(value) ? value : '-'}
          fontWeight={fontWeight}
        />
      </BaseCol>
    </BaseRow>
  )
}

const ItemRadio = ({
  question,
  answers,
}: {
  question: IQuestionSurveyDynamic
  answers?: IAnswerSurveyDynamic[]
}) => {
  const itemExist = answers?.find(item => isEqual(item?.id, question?.id))

  const xl = Math.round(24 / (question?.options?.length ?? 1))

  return (
    <BaseSpace>
      <BaseText
        children={`${question?.index}. ${question?.name}`}
        fontSize="xs"
        fontWeight="medium"
      />
      <BaseRow align={'middle'} gutter={[16, 16]}>
        {question?.options?.map((option, idxOption) => {
          return (
            <BaseCol key={idxOption} xl={xl >= 8 ? xl : 8}>
              <BaseCheckbox
                disabled
                className="item-checkbox-area"
                checked={isEqual(itemExist?.value, option?.id)}
              >
                <BaseText
                  children={option?.name}
                  style={{ color: '#6D7580' }}
                />
              </BaseCheckbox>
            </BaseCol>
          )
        })}
      </BaseRow>
    </BaseSpace>
  )
}

const ItemCheckBox = ({
  question,
  answers,
}: {
  question: IQuestionSurveyDynamic
  answers?: IAnswerSurveyDynamic[]
}) => {
  const listExist = answers?.filter(item => isEqual(item?.id, question?.id))

  const xl = Math.round(24 / (question?.options?.length ?? 1))

  return (
    <BaseSpace>
      <BaseText
        children={`${question?.index}. ${question?.name}`}
        fontSize="xs"
        fontWeight="medium"
      />
      <BaseRow align={'middle'} gutter={[16, 16]}>
        {question?.options?.map((option, idxOption) => {
          return (
            <BaseCol key={idxOption} xl={xl >= 8 ? xl : 8}>
              <BaseCheckbox
                disabled
                className="item-checkbox-area"
                checked={!!listExist?.find(itm => isEqual(itm?.id, option?.id))}
              >
                <BaseText
                  children={option?.name}
                  style={{ color: '#6D7580' }}
                />
              </BaseCheckbox>
            </BaseCol>
          )
        })}
      </BaseRow>
    </BaseSpace>
  )
}

const ItemRadioInput = ({
  question,
  isMain = false,
  answers,
}: {
  question: IQuestionSurveyDynamic
  isMain?: boolean
  answers?: IAnswerSurveyDynamic[]
}) => {
  const itemExist = answers?.find(item => isEqual(item?.id, question?.id))

  const listShowNote = question?.options?.filter(itm => itm?.showNote)

  const itemCheckedHaveShowNote = !!listShowNote?.find(option =>
    isEqual(itemExist?.value, option?.id),
  )

  const xl = Math.round(24 / (question?.options?.length ?? 1))

  return (
    <BaseSpace>
      <BaseText
        children={
          isMain ? `${question?.index}. ${question?.name}` : question?.name
        }
        fontSize={isMain ? 'xs' : 'xxs'}
        fontWeight={isMain ? 'medium' : 'regular'}
      />
      <BaseRow align={'middle'} gutter={[16, 16]} wrap={false}>
        {question?.options?.map((option, idxOption) => {
          return (
            <BaseCol key={idxOption} xl={xl >= 8 ? xl : 8}>
              <BaseCheckbox
                disabled
                className="item-checkbox-area"
                checked={isEqual(itemExist?.value, option?.id)}
              >
                <BaseText
                  children={option?.name}
                  style={{ color: '#6D7580' }}
                />
              </BaseCheckbox>
            </BaseCol>
          )
        })}
      </BaseRow>

      {itemCheckedHaveShowNote ? (
        <div className="item-checkbox-area">
          <BaseText
            children={itemExist?.note ?? 'N/A'}
            style={{ color: '#6D7580' }}
          />
        </div>
      ) : null}
    </BaseSpace>
  )
}

const ItemTextInput = ({
  question,
  answers,
}: {
  question: IQuestionSurveyDynamic
  answers?: IAnswerSurveyDynamic[]
}) => {
  const itemExist = answers?.find(item => isEqual(item?.id, question?.id))

  return (
    <BaseSpace>
      <BaseText
        children={`${question?.index}. ${question?.name}`}
        fontSize="xs"
        fontWeight="medium"
      />
      <div className="item-checkbox-area">
        <BaseText
          children={itemExist?.note ?? 'N/A'}
          style={{ color: '#6D7580' }}
        />
      </div>
    </BaseSpace>
  )
}

const ItemQuestion = ({
  question,
  isMain,
  answers,
}: {
  question: IQuestionSurveyDynamic
  isMain?: boolean
  answers?: IAnswerSurveyDynamic[]
}) => {
  switch (question?.type) {
    case EOptionType.RADIO:
      return <ItemRadio question={question} answers={answers} />
    case EOptionType.CHECKBOX:
      return <ItemCheckBox question={question} answers={answers} />
    case EOptionType.TEXT_INPUT:
      return <ItemTextInput question={question} answers={answers} />
    case EOptionType.RADIO_INPUT:
      return (
        <ItemRadioInput question={question} isMain={isMain} answers={answers} />
      )
    default:
      return <div />
  }
}

export const TeethHealthLayout = ({ item }: { item: ISurveyDynamic }) => {
  const { dataQuestion, isLoadingQuestion } = useHook()

  return (
    <BaseSpace>
      <ContentWrapper>
        <InfoWrapper title="Thông tin cá nhân">
          <ItemInfo
            label="Họ và tên"
            value={item?.patient?.name}
            fontWeight="semibold"
          />
          <ItemInfo
            label="Số điện thoại"
            value={normalizePhone(item?.patient?.contact ?? '') ?? ''}
          />
        </InfoWrapper>

        <BaseSpace className="info question">
          {dataQuestion?.map((question, indexQuestion) => {
            if (!isNil(question?.items)) {
              return (
                <BaseSpace key={indexQuestion}>
                  <BaseText
                    children={`${question?.index}. ${question?.name}`}
                    fontWeight="medium"
                    fontSize="xs"
                  />
                  <BaseSpace split={<BaseDivider className="divider" />}>
                    {question?.items?.map((itm, idx) => {
                      return (
                        <ItemQuestion
                          answers={item?.answers}
                          question={itm}
                          key={idx}
                        />
                      )
                    })}
                  </BaseSpace>
                </BaseSpace>
              )
            }

            return (
              <ItemQuestion
                isMain
                question={question}
                key={indexQuestion}
                answers={item?.answers}
              />
            )
          })}
        </BaseSpace>
      </ContentWrapper>

      {isLoadingQuestion ? <Loading isAbsolute size="small" /> : null}
    </BaseSpace>
  )
}

const ContentWrapper = styled(BaseSpace)`
  background-color: #fcfcfc;
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxs};
  border-color: 1px solid #f4f6f9;

  .info {
    background-color: white;
    padding: ${PADDING.md};
    border-radius: ${BORDER_RADIUS};
  }

  .divider {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    border-color: #a5abb3 !important;
    background-color: #a5abb3 !important;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #ebeef2;
    background-color: #ebeef2;
  }

  .item-checkbox-area {
    padding: 8px 8px;
    border-radius: 8px;
    border: 1px solid #ebeef2;
    width: 100%;
  }

  .question {
    height: 500px;
    overflow-y: auto;
  }
`
