import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { SectionBase } from 'app/components/common/SectionBase'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const SectionBaseWrapper = styled(SectionBase)`
  padding: ${PADDING.xl} !important;
`
export const AvatarProfile = styled(BaseAvatar)`
  width: 128px !important;
  height: 128px !important;
  border-radius: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: ${FONT_SIZE.xxxxl};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const TextNameProfile = styled.div`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: 20px;
`

export const TextJobTitleProfile = styled.div`
  color: #858c94;
  font-weight: ${FONT_WEIGHT.regular};
  font-size: 14px;
  margin-top: 8px;
`

export const StatusChip = styled.div`
  background: linear-gradient(317deg, #2f7eff, #659fff);
  padding: 4px 12px;
  color: white;
  border-radius: ${PADDING.xxxl};
  text-align: center;
  width: fit-content;
  margin-top: ${PADDING.xxxxs};
`
