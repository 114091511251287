import { SearchOutlined } from '@ant-design/icons'
import {
  ICampaignGroup,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IStackedWithCampaign } from 'app/containers/Marketing/DiscountCampaignManagement/CloneCampaign/type'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ListCampaignLayout = ({
  group,
  stackedCampaign,
  onTabSelectCampaign,
}: {
  group: ICampaignGroup
  stackedCampaign?: IStackedWithCampaign
  onTabSelectCampaign?: ({
    campaign,
    group,
  }: {
    group: ICampaignGroup
    campaign: IDiscountCampaign
  }) => void
}) => {
  const [searchCampaign, setSearchCampaign] = useState<string>('')

  const { t } = useTranslation()

  const dataFlatten: IDiscountCampaign[] =
    useMemo(() => {
      return group?.campaignAvailable?.filter(item =>
        item?.name?.toLowerCase()?.includes(searchCampaign?.toLowerCase()),
      )
    }, [group?.campaignAvailable, searchCampaign]) ?? []

  const checkSelectedByCampaignId = ({
    campaignId,
  }: {
    campaignId?: string
  }): boolean => {
    if (!stackedCampaign) return false

    if (stackedCampaign?.isSelectedAllRemember) {
      const excludeItem = stackedCampaign?.excludes?.find(item =>
        isEqual(item?._id, campaignId),
      )

      if (excludeItem) {
        return false
      }

      return true
    }

    // find in includes
    const includeItem = stackedCampaign?.includes?.find(item =>
      isEqual(item?._id, campaignId),
    )

    if (includeItem) {
      return true
    }

    return false
  }

  const totalSelected: number = useMemo(() => {
    if (!stackedCampaign) return 0

    if (stackedCampaign?.includes?.length) {
      return stackedCampaign?.includes?.length
    }

    if (stackedCampaign?.excludes?.length) {
      return (
        (dataFlatten?.length ?? 0) - (stackedCampaign?.excludes?.length ?? 0)
      )
    }

    return dataFlatten?.length ?? 0
  }, [stackedCampaign, dataFlatten])

  return (
    <BaseSpace>
      <BaseRow>
        <BaseCol xl={24}>
          <BaseForm.Item hiddenLabel>
            <BaseInput
              placeholder={t(R.strings.enter_campaign_name)}
              value={searchCampaign}
              onChange={e => setSearchCampaign?.(e?.target?.value ?? '')}
              allowClear
              prefix={<SearchOutlined rev={undefined} />}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseTableManagement
        columns={[
          convertColumnTable<IDiscountCampaign>({
            title: t(R.strings.campaign_name),
            key: 'name',
            render: (text, record) => (
              <div
                className="name-column"
                onClick={() =>
                  onTabSelectCampaign?.({
                    campaign: record,
                    group,
                  })
                }
              >
                <BaseText children={text} fontWeight="medium" fontSize="xs" />
              </div>
            ),
          }),
          convertColumnTable<IDiscountCampaign>({
            key: '_id',
            width: '12%',
            classNameWidthColumnOverwrite: 'number-column',
            title: '',
            render: (_, record) => {
              const isSelected = checkSelectedByCampaignId({
                campaignId: record?._id,
              })
              return (
                <BaseRow gutter={12} justify={'center'}>
                  <BaseCol />
                  <BaseCol />
                  <BaseCheckbox
                    value={isSelected}
                    checked={isSelected}
                    onChange={() =>
                      onTabSelectCampaign?.({
                        campaign: record,
                        group,
                      })
                    }
                  />
                </BaseRow>
              )
            },
          }),
        ]}
        dataSource={dataFlatten?.map((item, index) => {
          return {
            ...item,
            key: index + 1,
          }
        })}
        pagination={{ total: dataFlatten?.length ?? 0 }}
        rowClassName="row-overwrite-style"
        scroll={{ x: true }}
      />

      <BaseRow
        className="footer"
        justify={'space-between'}
        gutter={[16, 8]}
        align={'middle'}
      >
        <BaseCol>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol>
              <BaseText
                children={`${t(R.strings.total_selected)}:`}
                fontWeight="medium"
              />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={totalSelected}
                colorText="statesGreenColor"
                fontWeight="medium"
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}
