import { ICampaignGroup } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import {
  convertColumnTable,
  ExpandedTableValue,
} from 'app/components/tables/BaseTableReport/hook'
import { IDataGroupTable } from 'app/containers/Marketing/DiscountCampaignManagement/type'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import styled from 'styled-components'
import { useHook } from './hook'
import { ListCampaignLayout } from './layouts'

export const ListCampaignGroupLayout = () => {
  const {
    t,
    isLoading,
    dataFlatten,
    expandedRowKeys,
    handleExpand,
    stackedWithCampaignGroups,
  } = useHook()

  return stackedWithCampaignGroups?.length ? (
    <BaseTableWrapper
      columns={[
        convertColumnTable<IDataGroupTable>({
          title: t(R.strings.group_campaign),
          key: 'name',
          fixed: 'left',
          render: (_, record) => {
            const isExpanded = expandedRowKeys.includes(record.key ?? 0)
            const _handleExpand = () => {
              handleExpand(!isExpanded, record?.key)
            }

            return (
              <ExpandedTableValue
                isExpanded={isExpanded}
                handleExpand={_handleExpand}
                value={record?.name}
                isHaveAreaWhenNotHaveActionExpanded
              />
            )
          },
        }),

        convertColumnTable<IDataGroupTable>({
          key: 'code',
          width: '12%',
          classNameWidthColumnOverwrite: 'number-column',
          title: '',
          render: (_, record) => {
            const itemGroupExist = stackedWithCampaignGroups?.find(item =>
              isEqual(item?.group?.code, record?.code),
            )

            let indeterminateSelectedGroup = false

            if (itemGroupExist) {
              if (
                itemGroupExist?.excludes?.length &&
                itemGroupExist?.excludes?.length <
                  (itemGroupExist?.group?.totalCampaign ?? 0)
              ) {
                indeterminateSelectedGroup = true
              } else if (
                itemGroupExist?.includes?.length &&
                itemGroupExist?.includes?.length <
                  (itemGroupExist?.group?.totalCampaign ?? 0)
              ) {
                indeterminateSelectedGroup = true
              }
            }

            return (
              <BaseRow gutter={16} justify={'center'}>
                <BaseCheckbox
                  value={itemGroupExist?.isSelectedAll}
                  checked={itemGroupExist?.isSelectedAll}
                  indeterminate={indeterminateSelectedGroup}
                  disabled
                />
              </BaseRow>
            )
          },
        }),
      ]}
      dataSource={dataFlatten?.data ?? []}
      pagination={false}
      loading={isLoading}
      rowClassName="row-overwrite-style"
      scroll={{ x: true }}
      expandable={{
        expandedRowKeys,
        onExpand: handleExpand,
        expandIcon: () => null,
        showExpandColumn: false,
        expandedRowRender: (item: ICampaignGroup) => {
          const stackedCampaignSelected = stackedWithCampaignGroups?.find(itm =>
            isEqual(itm?.group?.code, item?.code),
          )
          return (
            <ListCampaignLayout
              group={item}
              stackedCampaign={stackedCampaignSelected}
            />
          )
        },
      }}
    />
  ) : (
    <BaseEmpty />
  )
}

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-left-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
  }

  .status-column {
    min-width: 125px !important;
    justify-content: center !important;
    align-items: center !important;
  }
`
