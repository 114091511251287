import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, colors } from 'parkway-web-common'
import styled from 'styled-components'

interface IProps {
  title: string
  description?: string
  require?: boolean
}
export const Label = (props: IProps) => {
  const { title, description, require } = props
  return (
    <BaseCol>
      <BaseRow>
        <Title>{title}</Title>
        {require && <Require>{'*'}</Require>}
      </BaseRow>
      <Description>{description}</Description>
    </BaseCol>
  )
}

const Title = styled(BaseText)`
  color: ${colors.primary} !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  font-size: ${FONT_SIZE.xs} !important;
`
const Require = styled(BaseText)`
  color: #fd4444 !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  font-size: ${FONT_SIZE.xs} !important;
`
const Description = styled(BaseText)`
  color: ${colors.primary} !important;
  font-weight: ${FONT_WEIGHT.regular} !important;
  font-size: ${FONT_SIZE.xxxs} !important;
  opacity: 0.8;
  margin-top: 4px;
`
