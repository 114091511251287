import { IUnit } from 'app/api/unit/model/unit-management'
import { IWorkInfo } from 'app/model/user.model'
import { ParamsList, ResponseType } from 'parkway-web-common'

export interface IParamsGetApproveDayOff extends Omit<ParamsList, 'status'> {
  startDate?: string
  endDate?: string
  status?: StatusDayOffEnum[]
}

export enum StatusDayOffEnum {
  CREATED = 1,
  APPROVED = 5,
  REJECTED = 6,
  WAITING_CANCEL = 7,
  WAITING_BOM_APPROVE = 8,
  WAITING_FOR_BOM_APPROVE_CANCEL = 9,
  WAITING_FOR_BOM_APPROVE_REJECT = 10,
}

export const DefineStatusDayOffEnum = {
  [StatusDayOffEnum.CREATED]: {
    text: 'Chờ duyệt',
  },
  [StatusDayOffEnum.WAITING_CANCEL]: {
    text: 'Chờ duyệt yêu cầu hủy',
  },
  [StatusDayOffEnum.WAITING_BOM_APPROVE]: {
    text: 'Chờ BOM duyệt',
  },
  [StatusDayOffEnum.WAITING_FOR_BOM_APPROVE_CANCEL]: {
    text: 'Chờ BOM duyệt yêu cầu hủy',
  },
  [StatusDayOffEnum.WAITING_FOR_BOM_APPROVE_REJECT]: {
    text: 'Chờ BOM duyệt yêu cầu từ chối',
  },
  [StatusDayOffEnum.APPROVED]: {
    text: 'Đã duyệt',
  },
  [StatusDayOffEnum.REJECTED]: {
    text: 'Từ chối',
  },
}

export enum LeaveShiftDayOffEnum {
  E = 'E',
  L = 'L',
  F = 'F',
}

export const LEAVE_SHIFT_NAME: { [key: string]: string } = {
  [LeaveShiftDayOffEnum.E]: 'Sáng',
  [LeaveShiftDayOffEnum.L]: 'Chiều',
  [LeaveShiftDayOffEnum.F]: 'Cả ngày',
  '': '',
}

export interface IDocuments {
  url?: string
  type?: string
  name?: string
}

export interface IUnWorkingDay {
  _id?: string
  profileId?: string
  unWorkingDayAmount?: number
  unWorkingDateStart?: string
  unWorkingDateEnd?: string
  status?: StatusDayOffEnum
  approverId?: string
  followerIds?: string[]
  applyReason?: string
  rejectReason?: string
  cancelReason?: string
  unWorkingDayType?: string
  unWorkingShift?: LeaveShiftDayOffEnum
  dayLeavePolicyId?: string
  documents?: string[]
  isApproverManualSelected?: boolean
  isCarryOver?: boolean
  linkedId?: string
  referenceIds?: string[]
  isPaid?: boolean
  createdBy?: string
  previousUnWorkingDays?: IUnWorkingDay[]
  name?: string
}

export interface IRecordDayOff {
  _id?: string
  name?: string
  employeeOldId?: string
  mobile?: string
  email?: string
  employeeid?: string
  unWorkingDays?: IUnWorkingDay[]
  previousUnWorkingDays?: IUnWorkingDay[]
  references?: IUnWorkingDay[]
  status?: number
  createddate?: string
  createdAt?: string
  updatedAt?: string
  accountid?: string
  firstname?: string
  lastname?: string
  workinfos?: (IWorkInfo & { unit: IUnit })[]
  rejectReason?: string
  documents?: IDocuments[]
}

export type ResponseApproveDayOff = ResponseType<IRecordDayOff[]>

export enum UnWorkingDayTypeEnum {
  DO = 'DO',
  UL = 'UL',
  AL = 'AL',
  SL = 'SL',
  HL = 'HL',
  PL = 'PL',
  PH = 'PH',
  ALC = 'ALc',
}

export interface IDataGetTotalDashboardDayOff {
  _id?: number
  data?: {
    unWorkingDayType?: UnWorkingDayTypeEnum
    total?: number
  }[]
}
