/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestPutApprovePatientInfo,
  requestPutApproveSurveyDynamic,
  requestGetQuestionMedicalHistory,
  requestGetQuestionTeethHealth,
  requestGetSurveyDynamic,
  requestGetTotalSurveyDynamic,
} from 'app/api/dental-treatment/survey-dynamic'
import { SurveyDynamicMessageCode } from 'app/api/dental-treatment/survey-dynamic/constant'
import {
  IBodyApproveSurveyDynamic,
  IParamsGetSurveyDynamic,
  IQuestionSurveyDynamic,
  IResponseGetSurveyDynamic,
  ISurveyDynamic,
  ITotalSurveyDynamic,
  TypeSurveyEnum,
} from 'app/api/dental-treatment/survey-dynamic/type'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { IResponseQueryList } from '../model/common'
import { MedicalRecordAndInformationKeys } from '../query-key/medical-record-and-information'
import { ResponseType } from 'parkway-web-common'

export function useGetSurveyDynamicList(
  params: IParamsGetSurveyDynamic,
  enabled = true,
) {
  const token = getToken()
  return useQuery<IResponseQueryList<ISurveyDynamic[]>, undefined>(
    MedicalRecordAndInformationKeys.getListSurveyDynamic(params),
    async () => {
      try {
        const res: IResponseGetSurveyDynamic = await requestGetSurveyDynamic(
          params,
        )
        if (
          isEqual(res?.msgcode, SurveyDynamicMessageCode.Survey.successGetData)
        ) {
          const data: ISurveyDynamic[] = res?.data || []
          return {
            data,
            total: res?.metadata?.totalItem ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetSurveyDynamicTotal(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<ITotalSurveyDynamic[] | null, undefined>(
    MedicalRecordAndInformationKeys.allSurveyDynamics,
    async () => {
      try {
        const res: ResponseType<ITotalSurveyDynamic[]> =
          await requestGetTotalSurveyDynamic({
            typeSurvey: [
              TypeSurveyEnum.MEDICAL_HISTORY,
              TypeSurveyEnum.TEETH_HEALTH,
            ],
          })
        if (
          isEqual(res?.msgcode, SurveyDynamicMessageCode.Survey.successGetData)
        ) {
          const data: ITotalSurveyDynamic[] = res?.data || []
          return data
        }
        return []
      } catch (error: any) {
        return []
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetQuestionTeethHealth(enabled = true) {
  const token = getToken()
  return useQuery<IQuestionSurveyDynamic[], undefined>(
    MedicalRecordAndInformationKeys.allQuestionTeethHealth,
    async () => {
      try {
        const res = await requestGetQuestionTeethHealth()
        if (
          isEqual(res?.msgcode, SurveyDynamicMessageCode.Survey.successGetData)
        ) {
          return res?.data || []
        }
        return []
      } catch (error: any) {
        return []
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetQuestionMedicalHistory(enabled = true) {
  const token = getToken()
  return useQuery<IQuestionSurveyDynamic[], undefined>(
    MedicalRecordAndInformationKeys.allQuestionMedicalHistory,
    async () => {
      try {
        const res = await requestGetQuestionMedicalHistory()
        if (
          isEqual(res?.msgcode, SurveyDynamicMessageCode.Survey.successGetData)
        ) {
          return res?.data || []
        }
        return []
      } catch (error: any) {
        return []
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useApproveSurveyDynamic = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IBodyApproveSurveyDynamic, unknown>(
    async (body?: IBodyApproveSurveyDynamic) => {
      if (!body) return
      return await requestPutApproveSurveyDynamic(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: MedicalRecordAndInformationKeys.allSurveyDynamics,
        })
      },
    },
  )
}

export const useApprovePatientInfo = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IBodyApproveSurveyDynamic, unknown>(
    async (body?: IBodyApproveSurveyDynamic) => {
      if (!body) return
      return await requestPutApprovePatientInfo(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: MedicalRecordAndInformationKeys.allSurveyDynamics,
        })
      },
    },
  )
}
