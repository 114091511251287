import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import styled from 'styled-components'
import { IFilterDataTableReport } from '../../type'
import { useHookTable } from './hook'

interface IProps {
  filter: IFilterDataTableReport
  campaignGroupCode?: string
}

export const CampaignListLayout = ({ filter, campaignGroupCode }: IProps) => {
  const { data, columns, handleTableChange, isLoading, pagination } =
    useHookTable({ filter, campaignGroupCode })

  return (
    <RootWrapper>
      <BaseTableWrapper
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        rowClassName="row-overwrite-style"
      />
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  background-color: ${convertedVariables.backgroundColor};
`

const BaseTableWrapper = styled(BaseTableManagement)`
  tr.ant-table-expanded-row > td {
    background: ${convertedVariables.backgroundColor};
  }

  tr.ant-table-expanded-row:hover > td {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell {
    border: 0px !important;
    border-bottom: 0.5px solid ${convertedVariables.neutralBlack2Color} !important;
  }

  .all-column:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor};
  }

  .row-overwrite-style .ant-table-cell-row-hover:has(.is-expanded-child) {
    background: ${convertedVariables.backgroundColor} !important;
  }

  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper
    .ant-table {
    margin-block: -16px;
    margin-inline: 32px -16px;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-left-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 34, 68, 0.7) !important;
    font-weight: 600 !important;
  }

  .status-column {
    min-width: 125px !important;
    justify-content: center !important;
    align-items: center !important;
  }
`
