import { requestGetPDFPatientInfo } from 'app/api/dental-treatment/survey-dynamic'
import { SurveyDynamicMessageCode } from 'app/api/dental-treatment/survey-dynamic/constant'
import {
  CustomerSourceEnum,
  ISurveyDynamic,
  StatusApproveEnum,
  TypeEnum,
} from 'app/api/dental-treatment/survey-dynamic/type'
import { IPatient } from 'app/api/patient/model/patient'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import {
  useApprovePatientInfo,
  useApproveSurveyDynamic,
} from 'app/react-query/hook/medical-record-and-information'
import axios from 'axios'
import { isEmpty, isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useMemo, useRef, useState } from 'react'

export const useHook = ({ item }: { item: ISurveyDynamic }) => {
  const modalRef = useRef<IRefModal>({})

  const [form] = BaseForm.useForm()
  const [referral, setReferral] = useState<CustomerSourceEnum | undefined>(
    undefined,
  )

  const {
    mutateAsync: approveSurveyDynamic,
    isLoading: isLoadingApproveSurveyDynamic,
  } = useApproveSurveyDynamic()

  const {
    mutateAsync: approvePatientInfo,
    isLoading: isLoadingApprovePatientInfo,
  } = useApprovePatientInfo()

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  const hideModal = () => {
    modalRef?.current?.hide?.()
  }

  const _handleApprove = async (status: StatusApproveEnum) => {
    try {
      if (isEqual(item?.type, TypeEnum.INFO_PATIENT)) {
        if (!item?.patient?._id) return

        const responseApprovePatient = await approvePatientInfo?.({
          id: item?.patient?._id,
          statusApprove: status,
          referer: referral,
        })

        if (responseApprovePatient) {
          hideModal()
        }

        return
      }

      if (isEqual(item?.type, TypeEnum.SURVEY_DYNAMIC)) {
        if (!item?._id) return

        const responseApproveDynamic = await approveSurveyDynamic?.({
          id: item?._id,
          statusApprove: status,
        })

        if (responseApproveDynamic) {
          hideModal()
        }

        return
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const onConfirm = async () => {
    await _handleApprove(StatusApproveEnum.APPROVED)
  }

  const onReject = async () => {
    await _handleApprove(StatusApproveEnum.REJECTED)
  }

  const isDisable = useMemo(() => {
    switch (item?.type) {
      case TypeEnum.INFO_PATIENT:
        if (isEmpty(referral)) return true
        break
      case TypeEnum.SURVEY_DYNAMIC:
        return false
    }

    return false
  }, [item?.type, referral])

  const handleOpenPrint = async () => {
    try {
      if (!item?.patient?._id) return
      const res: ResponseType<IPatient> = await requestGetPDFPatientInfo({
        id: item?.patient?._id,
      })

      if (
        isEqual(res?.msgcode, SurveyDynamicMessageCode.Survey.successGetData) &&
        res?.data?.pdfUrl
      ) {
        if (!res?.data?.pdfUrl) return
        // Tải file PDF về
        const response = await axios.get(res?.data?.pdfUrl, {
          responseType: 'blob', // Định dạng blob để tải file
        })

        // Tạo URL blob từ file PDF đã tải
        const fileURL = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        )

        // Mở file PDF trong tab mới
        window.open(fileURL, '_blank')
      }
    } catch (error) {
      console.log({ error })
    }
  }

  return {
    form,
    referral,
    setReferral,
    isLoadingApproveSurveyDynamic,
    isLoadingApprovePatientInfo,
    openModal,
    onConfirm,
    onReject,
    isDisable,
    handleOpenPrint,
    modalRef
  }
}
