import { Route } from 'react-router-dom'
import {
  DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_FLOW_PATH,
  DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_PLAN_PATH,
  DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_STAGE_PATH,
  DENTAL_TREATMENT_MANAGEMENT_MEDICAL_RECORD_AND_INFORMATION,
  DENTAL_TREATMENT_MANAGEMENT_PATH,
  DENTAL_TREATMENT_MANAGEMENT_PATIENT_SEARCH_PATH,
  DENTAL_TREATMENT_PATIENT_ORTHODONTIC_WITHOUT_PAID_PATH,
  DENTAL_TREATMENT_PATIENT_PROFILE_TREATMENT_PLAN,
  DENTAL_TREATMENT_PATIENT_RECORD_ADD_PATH,
  DENTAL_TREATMENT_PATIENT_RECORD_MANAGEMENT,
  DENTAL_TREATMENT_PATIENT_RECORD_PROFILE_PATH,
  DENTAL_TREATMENT_PATIENT_SCHEDULE_WITHOUT_PAID,
} from './route-path'

import { withLoading } from 'app/hocs/with-loading.hoc'
import DentalTreatmentManagementPage from 'app/page/menu/dental-treatment-management'
import PatientRecordManagementPage from 'app/page/menu/dental-treatment-management/patient-record-management'
import CreatePatientRecordPage from 'app/page/menu/dental-treatment-management/create-patient-record-management'
import ProfilePatientRecordPage from 'app/page/menu/dental-treatment-management/profile-patient-record-management'
import TreatmentPlanPage from 'app/page/menu/dental-treatment-management/treatment-plan'
import AccountingPatientScheduleWithoutPaid from 'app/page/menu/accounting/accounting-patient-schedule-without-paid'
import AccountingOrthodonticWithoutPaid from 'app/page/menu/accounting/accounting-orthodontic-without-paid'
import ApproveTreatmentPlanPage from 'app/page/menu/dental-treatment-management/approve-treatment-plan'
import ApproveTreatmentPhasePage from 'app/page/menu/dental-treatment-management/approve-treatment-phase'
import ApproveFlowPage from 'app/page/menu/dental-treatment-management/approve-flow'
import PatientSearchPage from 'app/page/menu/dental-treatment-management/patient-search'
import MedicalRecordAndInformationPage from 'app/page/menu/dental-treatment-management/medical-record-and-information'

const DentalTreatmentManagement = withLoading(DentalTreatmentManagementPage)
const PatientRecordManagement = withLoading(PatientRecordManagementPage)
const CreatePatientRecord = withLoading(CreatePatientRecordPage)
const ProfilePatientRecord = withLoading(ProfilePatientRecordPage)
const TreatmentPlan = withLoading(TreatmentPlanPage)
const AccountingPatientScheduleWithoutPaidElement = withLoading(
  AccountingPatientScheduleWithoutPaid,
)
const AccountingOrthodonticWithoutPaidElement = withLoading(
  AccountingOrthodonticWithoutPaid,
)

const ApproveTreatmentPlan = withLoading(ApproveTreatmentPlanPage)
const ApproveTreatmentPhase = withLoading(ApproveTreatmentPhasePage)
const ApproveFlow = withLoading(ApproveFlowPage)
const PatientSearch = withLoading(PatientSearchPage)
const MedicalRecordAndInformation = withLoading(MedicalRecordAndInformationPage)

export const DentalTreatmentManagementRouter = () => {
  return (
    <>
      <Route
        path={DENTAL_TREATMENT_MANAGEMENT_PATH}
        element={<DentalTreatmentManagement />}
      />
      <Route
        path={DENTAL_TREATMENT_PATIENT_RECORD_MANAGEMENT}
        element={<PatientRecordManagement />}
      />
      <Route
        path={DENTAL_TREATMENT_PATIENT_RECORD_ADD_PATH}
        element={<CreatePatientRecord />}
      />
      <Route
        path={DENTAL_TREATMENT_PATIENT_RECORD_PROFILE_PATH}
        element={<ProfilePatientRecord />}
      />
      <Route
        path={DENTAL_TREATMENT_PATIENT_PROFILE_TREATMENT_PLAN}
        element={<TreatmentPlan />}
      />

      <Route
        path={DENTAL_TREATMENT_PATIENT_SCHEDULE_WITHOUT_PAID}
        element={<AccountingPatientScheduleWithoutPaidElement />}
      />

      <Route
        path={DENTAL_TREATMENT_PATIENT_ORTHODONTIC_WITHOUT_PAID_PATH}
        element={<AccountingOrthodonticWithoutPaidElement />}
      />

      <Route
        path={DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_PLAN_PATH}
        element={<ApproveTreatmentPlan />}
      />

      <Route
        path={DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_STAGE_PATH}
        element={<ApproveTreatmentPhase />}
      />

      <Route
        path={DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_FLOW_PATH}
        element={<ApproveFlow />}
      />
      <Route
        path={DENTAL_TREATMENT_MANAGEMENT_PATIENT_SEARCH_PATH}
        element={<PatientSearch />}
      />
      <Route
        path={DENTAL_TREATMENT_MANAGEMENT_MEDICAL_RECORD_AND_INFORMATION}
        element={<MedicalRecordAndInformation />}
      />
    </>
  )
}
