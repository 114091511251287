import { IWorkInfo } from 'app/model/user.model'
import { BaseJobTitleText } from '../ImportModal'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import BaseText from 'app/components/common/BaseText'
import styled from 'styled-components'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { convertedVariables } from 'app/styles/themes/themeVariables'

interface IProps {
  data: IWorkInfo[]
}
export const JobTitleAka = (props: IProps) => {
  const { data } = props
  if (data?.length <= 0) {
    return <BaseText children={''} />
  }
  return (
    <Row gutter={8}>
      <BaseCol>
        <BaseJobTitleText>{data[0]?.jobtitle}</BaseJobTitleText>
      </BaseCol>
      {data[1]?.jobtitle && (
        <BaseCol>
          <BaseJobTitleText>{data[1]?.jobtitle}</BaseJobTitleText>
        </BaseCol>
      )}
      {data?.length >= 3 && (
        <BaseCol>
          <BaseNumberText>{`+${data?.length - 2}`}</BaseNumberText>
        </BaseCol>
      )}
    </Row>
  )
}

const Row = styled(BaseRow)`
  flex-flow: unset;
`
const BaseNumberText = styled.div`
  padding: 6px 12px;
  border-radius: 100px;
  font-weight: ${FONT_WEIGHT.medium} !important;
  font-size: ${FONT_SIZE.xxs} !important;
  color: ${convertedVariables.collapseBackgroundColor} !important;
  background: #f8f8f8 !important;
  width: max-content !important;
  border: 1px solid #f4f6f9;
`
