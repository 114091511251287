/* eslint-disable @typescript-eslint/no-explicit-any */

import { IClinic } from 'app/model/unit.model'
import { ParamsList, ResponseType } from 'parkway-web-common'
import { InfiniteData } from 'react-query'

export interface IParamsGetDiscountCampaign extends ParamsList {
  keyword?: string
  clinics?: string[]
  start?: string
  end?: string
  priority?: number[]
  campaignType?: CampaignTypeEnum[]
  campaignStatus?: CampaignStatusEnum[]
  isStackedDiscount?: 0 | 1
  campaignGroupCode?: string
}

export enum CampaignStatusEnum {
  Deactivated = 'Deactivated',
  UpComing = 'UpComing',
  OnGoing = 'OnGoing',
  Completed = 'Completed',
}

export enum CampaignTypeEnum {
  Treatments = 'Treatments',
  BuyOneGetOne = 'BuyOneGetOne',
  Voucher = 'CashVoucher',
  Bundle = 'Bundle',
  TotalBill = 'TotalBill',
}

export enum DiscountTypeEnum {
  Percent = 'percent',
  Amount = 'amount',
}

export enum ApplyTypeEnum {
  Auto = 'auto',
  Voucher = 'voucher',
}

export enum CampaignReferralEnum {
  Normal = 'non',
  Patient = 'ref_by_patient',
  Staff = 'ref_by_user',
  OnlyUser = 'only_user',
}

export interface IRegionalPrice {
  price?: number
  region?: string
  _id?: string
}

export interface IBuyAndDiscountTreatment {
  treatment?: {
    _id?: string
    name?: string
    regionalPrice?: IRegionalPrice[]
  }
  amount?: number
  dType?: DiscountTypeEnum
  discount?: number
}

export interface IBoughtTreatmentsDiscount {
  _id?: string
  name?: string
  buyTreatment?: IBuyAndDiscountTreatment[]
  discountTreatment?: IBuyAndDiscountTreatment[]
}

export interface IDiscount {
  _id?: string
  treatment?: {
    _id?: string
    name?: string
    regionalPrice?: IRegionalPrice[]
  }
  discount?: number
  dType?: DiscountTypeEnum
  amount?: number
}

export interface IBillDiscount {
  _id?: string
  totalBill?: number
  dType?: DiscountTypeEnum
  discount?: number
}

export interface ICampaignGroup {
  code?: string
  name?: string
  status?: number
  createdAt?: string
  updatedAt?: string
  totalCampaign?: number

  campaignAvailable?: IDiscountCampaign[]
}

export interface IDiscountCampaign {
  _id?: string
  name?: string
  description?: string
  isBaseline?: boolean
  clinics?: IClinic[]
  deactivated?: boolean
  discounts?: IDiscount[]
  start?: Date
  end?: Date
  createdBy?: string
  editedBy?: string
  histories?: any[]
  priority?: number
  boughtTreatmentsDiscount?: IBoughtTreatmentsDiscount[]
  campaignType?: CampaignTypeEnum
  isStackedDiscount?: boolean
  applyType?: ApplyTypeEnum
  createdAt?: Date
  updatedAt?: Date
  campaignStatus?: CampaignStatusEnum
  totalVoucherTemplate?: number
  billDiscounts?: IBillDiscount[]

  stackedWithCampaigns?: {
    _id?: string
    name?: string
  }[]

  isInit?: boolean

  referralType?: CampaignReferralEnum

  campaignGroupCode?: string

  stackedWithCampaignsConfig?: IStackedCampaignInPayload[]
}

export type ResponseGetDiscountCampaignType = ResponseType<IDiscountCampaign[]>

export type IResponseGetUnitReactQuery =
  InfiniteData<ResponseGetDiscountCampaignType>

export interface IStackedCampaignInPayload {
  campaignGroupCode: string
  includes: string[]
  excludes: string[]
  selectedCampaign?: number
  totalCampaign?: number
}

export interface IPayloadCreateCampaign {
  name?: string
  campaignType?: CampaignTypeEnum
  discounts?: {
    _id?: string
    treatment?: string
    discount?: number
    dType?: DiscountTypeEnum
    amount?: number
  }[]
  billDiscounts?: {
    totalBill?: number
    dType?: DiscountTypeEnum
    discount?: number
  }[]
  boughtTreatmentsDiscount?: {
    buyTreatment?: {
      treatment?: string
      amount?: number
      dType?: DiscountTypeEnum
      discount?: number
    }[]
    discountTreatment?: {
      treatment?: string
      amount?: number
      dType?: DiscountTypeEnum
      discount?: number
    }[]
    name?: string
  }[]
  clinics?: string[]
  start?: string
  end?: string
  priority?: number
  isStackedDiscount?: boolean
  applyType?: ApplyTypeEnum
  stackedWithCampaigns?: string[] | IStackedCampaignInPayload[]
  referralType?: CampaignReferralEnum

  campaignGroupCode?: string
}

interface IBoughtTreatmentsDiscountUpdate {
  buyTreatment?: {
    treatment?: string
    amount?: number
    dType?: DiscountTypeEnum
    discount?: number
  }[]
  discountTreatment?: {
    treatment?: string
    amount?: number
    dType?: DiscountTypeEnum
    discount?: number
  }[]
  name?: string
  _id?: string
}

export interface ITreatmentUpdate {
  _id?: string
  treatment?: string
  amount?: number
  dType?: DiscountTypeEnum
  discount?: number
}

export interface IDataUpdateCampaign {
  removes?: string[]
  push?: ITreatmentUpdate[] &
    IBoughtTreatmentsDiscountUpdate[] &
    IBillDiscount[]
  updates?: ITreatmentUpdate[] &
    IBoughtTreatmentsDiscountUpdate[] &
    IBillDiscount[]

  removeTreatments?: string[]
  pushTreatments?: ITreatmentUpdate[] &
    IBoughtTreatmentsDiscountUpdate[] &
    IBillDiscount[]
  updateTreatments?: ITreatmentUpdate[] &
    IBoughtTreatmentsDiscountUpdate[] &
    IBillDiscount[]
}

export interface IPayloadUpdateCampaign extends IDataUpdateCampaign {
  id: string
  name?: string
  campaignType?: CampaignTypeEnum
  discounts?: {
    _id?: string
    treatment?: string
    discount?: number
    dType?: DiscountTypeEnum
    amount?: number
  }[]
  boughtTreatmentsDiscount?: {
    buyTreatment?: {
      treatment?: string
      amount?: number
      dType?: DiscountTypeEnum
      discount?: number
    }[]
    discountTreatment?: {
      treatment?: string
      amount?: number
      dType?: DiscountTypeEnum
      discount?: number
    }[]
    name?: string
    _id?: string
  }[]
  clinics?: string[]
  start?: string
  end?: string
  priority?: number
  isStackedDiscount?: boolean
  isSwapPriority?: boolean
  applyType?: ApplyTypeEnum
  stackedWithCampaigns?: string[]
  referralType?: CampaignReferralEnum

  campaignGroupCode?: string
}

export interface IPayloadUpdateWithInsertOnly {
  id: string
  push?: (ITreatmentUpdate[] & IBoughtTreatmentsDiscountUpdate[]) | string[]
}

export interface IPayloadUpdateEndDateInsertOnly {
  id: string
  end: string
}

export interface IPayloadGetCampaignAvailable {
  clinics?: string[]
  start?: string
  end?: string
}

export interface IParamsGetImportTemplate {
  campaignType: CampaignTypeEnum
}
