import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import BaseText from 'app/components/common/BaseText'
import {
  IUnWorkingDay,
  LEAVE_SHIFT_NAME,
  LeaveShiftDayOffEnum,
} from 'app/api/hr/models/approve-day-off'
import styled from 'styled-components'
import { DateUtil, moment } from 'parkway-web-common'
import { Divider } from 'antd'
import { convertToDayOfWeek } from 'app/common/helpers'

interface IProps {
  data: IUnWorkingDay
}
export const LeaveItem = (props: IProps) => {
  const { data } = props
  const { t } = useTranslation()
  const timeRange =
    moment(data?.unWorkingDateEnd)
      .utc(true)
      .diff(moment(data?.unWorkingDateStart).utc(true), 'days') > 0
      ? `${convertToDayOfWeek(
          moment(data?.unWorkingDateStart).utc(true)?.day(),
        )}, ${DateUtil?.formatDate(
          data?.unWorkingDateStart || '',
          'DD/MM/YYYY',
        )} - ${convertToDayOfWeek(
          moment(data?.unWorkingDateStart).utc(true)?.day(),
        )}, ${DateUtil?.formatDate(data?.unWorkingDateEnd || '', 'DD/MM/YYYY')}`
      : `${convertToDayOfWeek(
          moment(data?.unWorkingDateStart).utc(true)?.day(),
        )}, ${DateUtil?.formatDate(
          data?.unWorkingDateStart || '',
          'DD/MM/YYYY',
        )}`

  const totalDays = `${data?.unWorkingDayAmount} ${t(R.strings._day)}`
  const shift =
    data?.unWorkingShift === LeaveShiftDayOffEnum.F
      ? ''
      : ` (${LEAVE_SHIFT_NAME[data?.unWorkingShift || '']})`

  return (
    <Wrapper gutter={[0, 16]}>
      <BaseCol xl={12}>
        <BaseText
          children={t(R.strings.leave_type)}
          fontWeight="medium"
          fontSize="xxs"
          colorText="primaryColor"
          opacity="0.5"
        />
        <BaseText
          children={data?.name}
          fontWeight="medium"
          fontSize="xs"
          colorText="primaryColor"
        />
      </BaseCol>
      <BaseCol xl={12}>
        <BaseText
          children={t(R.strings.furlough_time)}
          fontWeight="medium"
          fontSize="xxs"
          colorText="primaryColor"
          opacity="0.5"
        />
        <BaseRow align={'middle'}>
          <BaseText
            children={timeRange}
            fontWeight="medium"
            fontSize="xs"
            colorText="primaryColor"
          />

          <Dot />

          <BaseText
            children={`${totalDays}${shift}`}
            fontWeight="medium"
            fontSize="xs"
            colorText="primaryColor"
          />
        </BaseRow>
      </BaseCol>
      {data?.applyReason && (
        <BaseCol xl={24}>
          <Divider dashed style={{ margin: 0 }} />
        </BaseCol>
      )}
      {data?.applyReason && (
        <BaseCol xl={12}>
          <BaseText
            children={t(R.strings.content)}
            fontWeight="medium"
            fontSize="xxs"
            colorText="primaryColor"
            opacity="0.5"
          />

          <BaseText
            children={data?.applyReason}
            fontWeight="medium"
            fontSize="xs"
            colorText="primaryColor"
          />
        </BaseCol>
      )}
    </Wrapper>
  )
}
const Wrapper = styled(BaseRow)`
  background: #f9fafb;
  border: 1px solid #f4f6f9;
  border-radius: 8px;
  padding: 16px;
`
const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #b0bac5;
  margin: 0 10px;
`
