import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { IProfileUser } from 'app/api/profile/model/profile'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  ItemLabel,
  ItemValue,
  SectionBaseWrapper,
  SectionDescription,
  SectionTitle,
} from './styles'
import R from 'app/assets/R'
import { SkillModal } from './SkillModal'

interface IProps {
  profile?: IProfileUser
}

export const Skill = (props: IProps) => {
  const { profile } = props
  const { t } = useTranslation()

  return (
    <SectionBaseWrapper>
      <BaseRow align={'middle'} justify={'space-between'}>
        <BaseCol>
          <SectionTitle>{t(R.strings._skill)}</SectionTitle>
          <SectionDescription>
            {t(R.strings.skill_description)}
          </SectionDescription>
        </BaseCol>
        <BaseCol>
          <SkillModal listSkill={profile?.skills || []} />
        </BaseCol>
      </BaseRow>
    </SectionBaseWrapper>
  )
}
