import { StatusAppointment } from 'app/api/dashboard/model/doctor'
import R from 'app/assets/R'
import { getDetailPatientUrl } from 'app/common/helpers'
import BaseText from 'app/components/common/BaseText'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_HH_MM } from 'app/constant'
import { useGetMyAppointment } from 'app/react-query/hook/dashboard'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  DateUtil,
  FORMAT_CENTER_YYYY_MM_DD,
  initialPagination,
  PADDING,
  Pagination
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IDataTable } from './type'

export const useHook = () => {
  const { t } = useTranslation()

  const [pagination, setPagination] = useState(initialPagination)

  const { data: dataApi, isLoading } = useGetMyAppointment({
    date: DateUtil.formatDate(new Date(), FORMAT_CENTER_YYYY_MM_DD),
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const data = useMemo(() => {
    return dataApi?.data?.map((item, index) => {
      return {
        ...item,
        stt: index + 1,
        key: index + 1,
      }
    })
  }, [dataApi])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        title: t(R.strings.ordinal_number),
        key: 'stt',
        render: (_, record) => (
          <RenderValueTable
            value={record?.stt ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'left',
        className: 'class-left',
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.patient_name),
        key: 'patient',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.patient ?? '-'}
            type="OtherLink"
            hrefOtherLink={getDetailPatientUrl(record?.pid)}
          />
        ),
        fixed: 'left',
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.service),
        key: 'title',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.title ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.clinic),
        key: 'clinic',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.clinic?.name ?? '-'}
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.appointment_date),
        key: 'start',
        render: (_, record) => (
          <RenderValueTable
            value={
              record?.start
                ? DateUtil?.formatDate(record?.start, FORMAT_HH_MM)
                : '-'
            }
            type="Main"
            styleText={{
              opacity: 0.7,
            }}
          />
        ),
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'status',
        align: 'end',
        fixed: 'right',
        render: (_, record) => (
          <StatusWrapper $type={record?.status}>
            <BaseText
              children={(() => {
                switch (record?.status) {
                  case StatusAppointment.open: {
                    return 'Chưa tới'
                  }
                  case StatusAppointment.treating: {
                    return 'Đang điều trị'
                  }
                  case StatusAppointment.done: {
                    return 'Đã điều trị'
                  }
                  case StatusAppointment.closed: {
                    return 'Đã đến'
                  }
                  case StatusAppointment.cancel: {
                    return 'Hủy'
                  }
                }
              })()}
              fontWeight="semibold"
              style={{
                color: (() => {
                  switch (record?.status) {
                    case StatusAppointment.treating: {
                      return '#629DAA'
                    }
                    case StatusAppointment.closed: {
                      return convertedVariables.otherBlueColor
                    }
                    case StatusAppointment.open: {
                      return convertedVariables.statesOrangeColor
                    }
                    case StatusAppointment.cancel: {
                      return '#FD44B3'
                    }
                    case StatusAppointment.done: {
                      return '#858C94'
                    }
                  }
                })(),
              }}
            />
          </StatusWrapper>
        ),
      }),
    ]
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }
  return {
    columns,
    tableData: {
      data,
      loading: isLoading,
      pagination: {
        ...pagination,
        total: dataApi?.metadata?.totalItem ?? data?.length ?? 0,
      },
    },
    handleTableChange,
  }
}

const StatusWrapper = styled.div<{ $type?: StatusAppointment }>`
  background-color: ${props => {
    switch (props?.$type) {
      case StatusAppointment.treating:
        return '#E8F6F9'
      case StatusAppointment.closed:
        return convertedVariables.statesBlueLightColor
      case StatusAppointment.open:
        return convertedVariables.statesOrangeLightColor
      case StatusAppointment.cancel:
        return '#FFEBFD'
      case StatusAppointment.done:
        return convertedVariables.neutralBlack9Color
    }
  }};
  padding: 5px ${PADDING.xxs};
  border-radius: ${PADDING.xl};
  width: fit-content;
`
