/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DefineCampaignReferralEnum,
  DefineDiscountCampaignType,
} from 'app/api/marketing/discount-campaign/constant'
import {
  ApplyTypeEnum,
  CampaignReferralEnum,
  CampaignTypeEnum,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { YesNoEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import dayjs, { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHook } from './hook'
import {
  ListCampaignGroupLayout,
  ModalAddCampaignGroup,
  ModalPriority,
} from './layouts'

export const InfoCampaignLayout = () => {
  const { t } = useTranslation()
  const {
    areaList,
    isLoadingArea,
    filterTreeNode,
    keywordSearchArea,
    setKeywordSearchArea,
    onChangeClinic,
    selectedClinic,
    campaignType,
    onChangeCampaignType,
    info,
    onChangeName,
    isApplyWithOtherCampaign,
    onChangeEnd,
    onChangeStart,
    setIsApplyWithOtherCampaign,
    campaignApplyCommon,
    onChangeCampaignApplyCommon,
    campaignGroupAll,
    loadingCampaignGroupAll,
  } = useHook()

  return (
    <RootWrapper>
      <HeaderWrapper>
        <BaseText
          children={t(R.strings.campaign_info)}
          fontWeight="semibold"
          fontSize="md"
        />
      </HeaderWrapper>
      <ContentWrapper size={4}>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseForm.Item
              name={'name'}
              label={t(R.strings.campaign_name)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.campaign_name),
                  }),
                },
              ]}
            >
              <BaseInput
                placeholder={t(R.strings.enter_campaign_name)}
                onChange={e => onChangeName(e?.target?.value)}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseForm.Item
              name="group_campaign"
              label={t(R.strings.group_campaign)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.group_campaign),
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={t(R.strings.select_group_campaign)}
                options={campaignGroupAll?.map(item => ({
                  label: item?.name,
                  value: item?.code,
                }))}
                loading={loadingCampaignGroupAll}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={12}>
            <BaseForm.Item
              name={'clinics'}
              label={t(R.strings.apply_clinic)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.apply_clinic),
                  }),
                },
              ]}
            >
              <BaseTreeSelect
                placeholder={t(R.strings.select_clinic_apply)}
                loading={isLoadingArea}
                treeData={transformDataSelectTree(areaList ?? [], {
                  title: 'name',
                  value: '_id',
                  children: 'childs',
                })}
                value={selectedClinic}
                multiple
                showSearch
                treeCheckable={true}
                onSearch={keyword => setKeywordSearchArea?.(keyword)}
                filterTreeNode={filterTreeNode}
                searchValue={keywordSearchArea}
                maxTagCount={2}
                onChange={ids => onChangeClinic?.(ids as string[])}
                allowClear
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseRow gutter={[16, 8]} align={'top'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  name={'startDate'}
                  required
                  label={t(R.strings.start_date)}
                  dependencies={['endDate']}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.start_date),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const day = value as Dayjs

                        if (!day || !getFieldValue('endDate')) {
                          return Promise.resolve()
                        }
                        if (
                          day.isSame(getFieldValue('endDate')) ||
                          day.isBefore(getFieldValue('endDate'))
                        ) {
                          return Promise.resolve()
                        }

                        return Promise.reject(
                          t(R.strings.start_date_must_be_before_end_date),
                        )
                      },
                    }),
                  ]}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY HH:mm'}
                    placeholder={t(R.strings.start_date)}
                    showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
                    onChange={onChangeStart}
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseForm.Item
                  name={'endDate'}
                  required
                  label={t(R.strings.end_date)}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.end_date),
                      }),
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const day = value as Dayjs

                        if (!day || !getFieldValue('startDate')) {
                          return Promise.resolve()
                        }
                        if (
                          day.isSame(getFieldValue('startDate')) ||
                          day.isAfter(getFieldValue('startDate'))
                        ) {
                          return Promise.resolve()
                        }

                        return Promise.reject(
                          t(R.strings.end_date_must_be_after_start_date),
                        )
                      },
                    }),
                  ]}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY HH:mm'}
                    placeholder={t(R.strings.end_date)}
                    showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
                    onChange={onChangeEnd}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={12}>
            <BaseRow gutter={[16, 8]} align={'top'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  required
                  label={t(R.strings.active_type)}
                  name={'activeType'}
                  dependencies={['campaignType', 'referral']}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.active_type),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const campaignType = getFieldValue('campaignType')

                        const referral = getFieldValue('referral')

                        if (isEqual(campaignType, CampaignTypeEnum.Voucher)) {
                          if (isEqual(value, ApplyTypeEnum.Voucher)) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            t(R.strings.active_type_must_be_voucher),
                          )
                        }

                        if (
                          isEqual(referral, CampaignReferralEnum.Patient) ||
                          isEqual(referral, CampaignReferralEnum.Staff)
                        ) {
                          if (isEqual(value, ApplyTypeEnum.Voucher)) {
                            return Promise.resolve()
                          }

                          return Promise.reject(
                            t(R.strings.active_type_must_be_voucher),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <BaseSelect
                    placeholder={t(R.strings.select_active_type)}
                    options={[
                      {
                        label: t(R.strings.voucher),
                        value: ApplyTypeEnum.Voucher,
                      },
                      {
                        label: t(R.strings.auto),
                        value: ApplyTypeEnum.Auto,
                      },
                    ]}
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseForm.Item
                  required
                  label={t(R.strings.apply_with_other_campaign)}
                  name={'applyWithOtherCampaign'}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.apply_with_other_campaign),
                      }),
                    },
                  ]}
                >
                  <BaseSelect
                    placeholder={t(R.strings.choose_apply_with_other_campaign)}
                    options={[
                      {
                        label: t(R.strings.yes),
                        value: YesNoEnum.Yes,
                      },
                      {
                        label: t(R.strings.no),
                        value: YesNoEnum.No,
                      },
                    ]}
                    onChange={value =>
                      setIsApplyWithOtherCampaign?.(value as YesNoEnum)
                    }
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol xl={12}>
            <BaseRow gutter={[16, 8]} align={'top'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  required
                  label={t(R.strings.type_of_offer)}
                  name={'campaignType'}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.type_of_offer),
                      }),
                    },
                  ]}
                >
                  <BaseSelect
                    placeholder={t(R.strings.select_type_of_offer)}
                    onChange={value =>
                      onChangeCampaignType?.(value as CampaignTypeEnum)
                    }
                    value={campaignType}
                    defaultValue={campaignType}
                    options={[
                      {
                        label: t(
                          DefineDiscountCampaignType[
                            CampaignTypeEnum.Treatments
                          ].keyI18n,
                        ),
                        value: CampaignTypeEnum.Treatments,
                      },
                      {
                        label: t(
                          DefineDiscountCampaignType[
                            CampaignTypeEnum.BuyOneGetOne
                          ].keyI18n,
                        ),
                        value: CampaignTypeEnum.BuyOneGetOne,
                      },
                      {
                        label: t(
                          DefineDiscountCampaignType[CampaignTypeEnum.Voucher]
                            .keyI18n,
                        ),
                        value: CampaignTypeEnum.Voucher,
                      },
                      {
                        label: t(
                          DefineDiscountCampaignType[CampaignTypeEnum.Bundle]
                            .keyI18n,
                        ),
                        value: CampaignTypeEnum.Bundle,
                      },
                      {
                        label: t(
                          DefineDiscountCampaignType[CampaignTypeEnum.TotalBill]
                            .keyI18n,
                        ),
                        value: CampaignTypeEnum.TotalBill,
                      },
                    ]}
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  required
                  label={t(R.strings.priority)}
                  name={'priority'}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.priority),
                      }),
                    },
                  ]}
                >
                  <BaseRow
                    gutter={16}
                    align={'middle'}
                    style={{
                      backgroundColor: convertedVariables.neutralBlack9Color,
                      border: `1px solid ${convertedVariables.neutralBlack2Color}`,
                      borderRadius: BORDER_RADIUS,
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <ModalPriority />
                    <BaseCol>
                      <BaseText
                        children={`${info?.priority ?? '-'}`}
                        fontWeight="semibold"
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseForm.Item
              name="referral"
              label={t(R.strings.campaign_type)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.campaign_type),
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={t(R.strings.select_campaign_type)}
                options={[
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Normal]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Normal,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Patient]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Patient,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Staff]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Staff,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.OnlyUser]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.OnlyUser,
                  },
                ]}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        {isEqual(isApplyWithOtherCampaign, YesNoEnum.Yes) ? (
          <BaseRow>
            <BaseCol xl={24}>
              <BaseForm.Item
                name="campaign_apply_common"
                label={t(R.strings.campaign_apply_common)}
                required
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: t(R.strings.campaign_apply_common),
                    }),
                  },
                ]}
              >
                <BaseSelect
                  placeholder={t(R.strings.select_campaign_apply_common)}
                  options={[
                    {
                      label: t(R.strings.all),
                      value: R.strings.all,
                    },
                    {
                      label: t(R.strings.campaign_list),
                      value: R.strings.campaign_list,
                    },
                  ]}
                  filterSort={() => 0}
                  onChange={value =>
                    onChangeCampaignApplyCommon?.(value as string)
                  }
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        ) : null}

        <BaseSpace>
          {isEqual(campaignApplyCommon, R.strings.campaign_list) &&
          isEqual(isApplyWithOtherCampaign, YesNoEnum.Yes) &&
          !!info?.endDate &&
          !!info?.startDate &&
          !!info?.clinics?.length ? (
            <BaseSpace>
              <BaseRow
                gutter={[16, 16]}
                align={'middle'}
                justify={'space-between'}
              >
                <BaseCol>
                  <BaseText
                    children={t(R.strings.campaign_list)}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseCol>

                <BaseCol>
                  <ModalAddCampaignGroup />
                </BaseCol>
              </BaseRow>

              <ListCampaignWrapper>
                <ListCampaignGroupLayout />
              </ListCampaignWrapper>
            </BaseSpace>
          ) : null}

          <BaseRow>
            <BaseCol xl={24}>
              <BaseForm.Item
                label={t(R.strings.description)}
                name="description"
              >
                <BaseTextEditor />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`
const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-bottom: 0.75px solid ${convertedVariables.neutralBlack9Color};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #fcfcfc;
  border-end-end-radius: ${BORDER_RADIUS};
`

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`

const ListCampaignWrapper = styled(BaseSpace)`
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.md};

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: lightgray;
  }
`
