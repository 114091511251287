import { Divider } from 'antd'
import { approveLeave, rejectLeave } from 'app/api/hr'
import { HrMessageCode } from 'app/api/hr/constant'
import {
  IUnWorkingDay,
  StatusDayOffEnum,
  UnWorkingDayTypeEnum,
} from 'app/api/hr/models/approve-day-off'
import R from 'app/assets/R'
import {
  SvgArrowDownBlackIcon,
  SvgInfoBlackIcon,
  SvgStampApproveIcon,
  SvgStampRejectIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { notificationController } from 'app/controllers/notification-controller'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ActionButton, ReasonInput, useHook } from '../../hook'
import { DetailModalLayout } from '../../layouts'

interface IProps {
  data?: IUnWorkingDay
  typeLayout: 'full' | 'half'
  borderColor?: string
  backgroundColor?: string
  value: string
  colorValue?: string
}

export const DayOffItem = ({
  typeLayout,
  backgroundColor,
  borderColor,
  value,
  colorValue,
  data,
}: IProps) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [visibleReject, setVisibleReject] = useState(false)
  const [rejectReason, setRejectReason] = useState('')
  const { refetchDashboard, setRefreshData, refreshData, refetchTotal } =
    useHook()

  const isRejected = data?.status === StatusDayOffEnum?.REJECTED
  const showInfo =
    isRejected ||
    (data?.previousUnWorkingDays && data?.previousUnWorkingDays?.length > 0)
  const showMenu =
    !isRejected &&
    data?.unWorkingDayType &&
    data?.unWorkingDayType !== UnWorkingDayTypeEnum.DO

  const onChangeRejectReason = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    setRejectReason(value)
  }
  const handleApprove = async () => {
    try {
      if (!data?.linkedId) {
        return
      }

      const response = await approveLeave(data?.linkedId)

      if (isEqual(response?.msgcode, HrMessageCode.Leave.success)) {
        setRefreshData(refreshData + 1)
        refetchDashboard()
        refetchTotal()
        notificationController?.success({
          message: t(R.strings.update_successfully),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    } finally {
      setVisible(false)
    }
  }
  const handleReject = async () => {
    try {
      if (!data?.linkedId) {
        return
      }
      if (!rejectReason) {
        notificationController?.error({
          message: t(R.strings.reason_label_required),
        })
        return
      }

      const response = await rejectLeave({
        id: data?.linkedId,
        rejectReason,
      })

      if (isEqual(response?.msgcode, HrMessageCode.Leave.success)) {
        setRefreshData(refreshData + 1)
        refetchDashboard()
        refetchTotal()
        notificationController?.success({
          message: t(R.strings.update_successfully),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    } finally {
      setVisible(false)
    }
  }
  const showModal = () => {
    setVisible(true)
  }
  const hideModal = () => {
    setRejectReason('')
    setVisible(false)
    setVisibleReject(false)
  }
  const showModalReject = () => {
    setVisibleReject(true)
  }
  const handleCancelReject = () => {
    setRejectReason('')
    setVisibleReject(false)
  }

  return (
    <DayItemWrapper
      $width={isEqual(typeLayout, 'full') ? '100%' : '50%'}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
    >
      <BaseRow align={'middle'} justify={'center'} gutter={4}>
        <BaseCol>
          <BaseText
            children={value}
            fontWeight="semibold"
            style={{
              color: colorValue,
            }}
          />
        </BaseCol>

        {showInfo && (
          <BaseCol>
            <BasePopover
              trigger={'hover'}
              placement="bottom"
              content={
                isRejected ? (
                  <BaseSpace size={8}>
                    <BaseText
                      children={t(R.strings.reject_reason)}
                      opacity="0.5"
                    />
                    <BaseText
                      children={data?.rejectReason}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseSpace>
                ) : (
                  <BaseSpace size={8}>
                    <BaseText
                      children={t(R.strings.change_info)}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                    <BaseText
                      children={t(R.strings.approved_day_off)}
                      opacity="0.5"
                    />
                    <BaseText children={'T4 03/11 (Cả ngày)'} />
                    <BaseText
                      children={t(R.strings.new_day_off)}
                      opacity="0.5"
                    />
                    <BaseText
                      children={'T6 05/11 (Buổi chiều) & T7 06/11 (Buổi chiều)'}
                    />
                  </BaseSpace>
                )
              }
            >
              <SvgInfoBlackIcon style={{ marginTop: 4 }} />
            </BasePopover>
          </BaseCol>
        )}

        {showMenu && (
          <BaseCol>
            <Dropdown
              trigger={'click'}
              placement="bottom"
              content={
                <BaseSpace size={8}>
                  <DetailModalLayout
                    linkedId={data?.linkedId || ''}
                    showModal={showModal}
                    hideModal={hideModal}
                    handleApprove={handleApprove}
                    handleReject={handleReject}
                    showModalReject={showModalReject}
                    visible={visible}
                  />
                  <Divider style={{ margin: 0 }} />
                  {data?.status === StatusDayOffEnum.CREATED && (
                    <ActionButton align={'middle'} onClick={handleApprove}>
                      <SvgStampApproveIcon style={{ marginRight: 1 }} />
                      <ActionText>{t(R.strings.approve_furlough)}</ActionText>
                    </ActionButton>
                  )}

                  <BaseModalManagement
                    typeButtonOpen="custom"
                    buttonComponent={
                      <ActionButton align={'middle'} onClick={showModalReject}>
                        <SvgStampRejectIcon />
                        <ActionText>{t(R.strings.reject_furlough)}</ActionText>
                      </ActionButton>
                    }
                    content={
                      <BaseSpace size={2}>
                        <BaseText
                          children={t(R.strings.reject_reason)}
                          fontWeight="semibold"
                          fontSize="lg"
                          textAlign="center"
                        />
                        <BaseText
                          children={t(R.strings.content)}
                          fontWeight="medium"
                          fontSize="xxs"
                          opacity="0.5"
                        />
                        <ReasonInput
                          value={rejectReason}
                          placeholder={t(R.strings.enter_content)}
                          onChange={onChangeRejectReason}
                        />
                      </BaseSpace>
                    }
                    isShowLineBetweenArea={false}
                    showModal={showModalReject}
                    handleCancel={handleCancelReject}
                    confirmKeyI18n={R.strings.confirm}
                    handleSubmit={handleReject}
                    widthModal={600}
                    visible={visibleReject}
                  />
                </BaseSpace>
              }
            >
              <SvgArrowDownBlackIcon style={{ marginTop: 4 }} />
            </Dropdown>
          </BaseCol>
        )}
      </BaseRow>

      {/* {tag ? (
        <div className="tag">
          <BaseText
            children={tag}
            fontWeight="semibold"
            colorText="backgroundColor"
            fontSize="xxxs"
          />
        </div>
      ) : null} */}
    </DayItemWrapper>
  )
}

const Dropdown = styled(BasePopover)`
  cursor: pointer;
`
const DayItemWrapper = styled.div<{
  $width?: string
  $borderColor?: string
  $backgroundColor?: string
}>`
  position: relative;
  width: ${({ $width }) => $width ?? '100%'};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $borderColor }) =>
    $borderColor &&
    `
      border-left: 3px solid ${$borderColor};
    `};

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    `
      background-color: ${$backgroundColor};
    `};

  .tag {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: ${convertedVariables.collapseBackgroundColor};
    padding: 0px 6px;
    border-radius: ${PADDING.xs};
  }
`

const ActionText = styled(BaseText)`
  margin-left: 8px;
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: ${convertedVariables.primaryColor};
`
