import { IParamsGeneralInfo } from 'app/api/dashboard/model/bod'
import { IParamsDoctorMonthlyReport, IParamsMyAppointmentDashboard } from 'app/api/dashboard/model/doctor'

export const DashboardKeys = {
  allGeneralInfo: ['GeneralInfo'] as const,
  generalInfos: () => [...DashboardKeys.allGeneralInfo, 'GeneralInfo'] as const,
  generalInfo: (filters?: IParamsGeneralInfo) =>
    [...DashboardKeys.generalInfos(), { filters }] as const,

  allDoctorDashboard: ['DoctorDashboard'] as const,
  myAppointments: () => [...DashboardKeys.allDoctorDashboard, 'MyAppointment'] as const,
  myAppointment: (filters?: IParamsMyAppointmentDashboard) =>
    [...DashboardKeys.myAppointments(), { filters }] as const,

  doctorReportMonthlies: () => [...DashboardKeys.allDoctorDashboard, 'DoctorReportMonthly'] as const,
  doctorReportMonthly: (filters?: IParamsDoctorMonthlyReport) =>
    [...DashboardKeys.doctorReportMonthlies(), { filters }] as const,
}
