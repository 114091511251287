import { useGetDoctorReportMonthly } from 'app/react-query/hook/dashboard'
import moment from 'moment'
import { useMemo } from 'react'

export const useHook = () => {
  const { data: dataApi, isLoading } = useGetDoctorReportMonthly({
    month: `${moment().month() + 1}`,
    year: `${moment().year()}`,
  })

  const data = useMemo(() => {
    return dataApi?.data?.map(item => {
      return {
        ...item,
        isMain: !item?.children?.length,
      }
    })
  }, [dataApi])
  
  return {
    data: data,
    isLoading,
  }
}
