import { requestGetLeavesByLinkedId } from 'app/api/hr'
import { HrMessageCode } from 'app/api/hr/constant'
import {
  DefineStatusDayOffEnum,
  IRecordDayOff,
  StatusDayOffEnum,
} from 'app/api/hr/models/approve-day-off'
import R from 'app/assets/R'
import { SvgCalendarIcon, SvgCloseIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText, { ColorTextType } from 'app/components/common/BaseText'
import { notificationController } from 'app/controllers/notification-controller'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Documents, LeaveItem, ReferenceItems } from '../../components'
import { ActionButton } from '../../hook'

interface IColorProps {
  color: ColorTextType
  backgroundColor: string
}
interface IProps {
  linkedId: string
  showModal: () => void
  hideModal: () => void
  handleApprove: () => void
  handleReject: () => void
  showModalReject: () => void
  visible: boolean
}

const getStatusColor: (status?: StatusDayOffEnum) => IColorProps = (
  status?: StatusDayOffEnum,
) => {
  switch (status) {
    case StatusDayOffEnum.APPROVED:
      return {
        color: 'statesGreenColor',
        backgroundColor: convertedVariables.statesGreenLightColor,
      }
    case StatusDayOffEnum.REJECTED:
      return {
        color: 'statesRedColor',
        backgroundColor: convertedVariables.statesRedLightColor,
      }

    default:
      return {
        color: 'statesOrangeColor',
        backgroundColor: convertedVariables.statesOrangeLightColor,
      }
  }
}

export const DetailModalLayout = (props: IProps) => {
  const {
    showModal,
    hideModal,
    handleApprove,
    showModalReject,
    visible,
    linkedId,
  } = props
  const { t } = useTranslation()

  const [data, setData] = useState<IRecordDayOff>({})
  const { color, backgroundColor } = getStatusColor(data?.status)

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await requestGetLeavesByLinkedId(linkedId)
        if (isEqual(response?.msgcode, HrMessageCode.Leave.success)) {
          setData(response?.data)
        }
      } catch (error) {
        notificationController?.error({
          message: t(R.strings.error),
          description: t(R.strings.with_error_please_contact_with_admin),
        })
      }
    }
    getData()
  }, [])

  return (
    <Modal
      typeButtonOpen="custom"
      buttonComponent={
        <ActionButton align={'middle'} onClick={showModal}>
          <SvgCalendarIcon />
          <ActionText>{t(R.strings.view_detail_day_off)}</ActionText>
        </ActionButton>
      }
      content={
        <BaseSpace size={20}>
          <BaseSpace size={4}>
            <BaseText
              children={t(R.strings.status)}
              fontWeight="semibold"
              fontSize="xs"
              colorText="primaryColor"
            />
            <Status
              children={DefineStatusDayOffEnum[data?.status || '']?.text}
              fontWeight="semibold"
              fontSize="xxs"
              colorText={color}
              $backgroundColor={backgroundColor}
            />
          </BaseSpace>
          <BaseText
            children={t(R.strings.furlough_time)}
            fontWeight="semibold"
            fontSize="xs"
            colorText="primaryColor"
          />
          {data?.unWorkingDays?.map(i => (
            <LeaveItem data={i} />
          ))}
          {data?.references && data?.references?.length > 0 && (
            <BaseText
              children={t(R.strings.linked_un_working_days)}
              fontWeight="semibold"
              fontSize="xs"
              colorText="primaryColor"
            />
          )}
          {data?.references && <ReferenceItems data={data?.references} />}
          <Documents data={data?.documents || []} />
        </BaseSpace>
      }
      titleKeyI18n={R.strings.day_off_detail}
      showModal={showModal}
      handleCancel={hideModal}
      handleSubmit={handleApprove}
      widthModal={1000}
      visible={visible}
      footer={
        <BaseRow justify={'center'} align={'middle'}>
          <ButtonRejectModal onClick={showModalReject}>
            <SvgCloseIcon />
            {t(R.strings.reject)}
          </ButtonRejectModal>
          {data?.status === StatusDayOffEnum?.CREATED && (
            <ButtonConfirmModal onClick={handleApprove}>
              {t(R.strings.approve)}
            </ButtonConfirmModal>
          )}
        </BaseRow>
      }
    />
  )
}
const Status = styled(BaseText)<{ $backgroundColor: string }>`
  background: ${props => props?.$backgroundColor || 'white'};
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
`
const Modal = styled(BaseModalManagement)``
const ActionText = styled(BaseText)`
  margin-left: 8px;
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: ${convertedVariables.primaryColor};
`
export const ButtonRejectModal = styled(BaseButton)`
  min-width: 105px;
  font-size: ${FONT_SIZE.xs} !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: ${convertedVariables.primaryColor} !important;
`

export const ButtonConfirmModal = styled(BaseButton)`
  min-width: 105px;
  background-color: var(--other-blue-color);
  border-color: var(--other-blue-color);
  font-size: ${FONT_SIZE.xs} !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: var(--background-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`
