import { ENV_CONFIG } from 'app/common/config'
import { BASE_URL_LOCAL } from 'parkway-web-common'
import { ApiServiceClient } from './api-service-handle'

const ApiClient = ApiServiceClient({
  baseUrl: ENV_CONFIG.API_ENDPOINT,
})

const ApiClientHandle = ({
  type = 'parkway-api',
  localUrl,
  isShowNotificationError = true,
}: {
  type?: 'local' | 'parkway-api'
  localUrl?: string
  isShowNotificationError?: boolean
}) => {
  switch (type) {
    case 'local':
      return ApiServiceClient({
        baseUrl: localUrl ?? ENV_CONFIG.BASE_URL_LOCAL,
        isShowNotificationError,
      })
    case 'parkway-api':
      return ApiServiceClient({
        baseUrl: ENV_CONFIG.API_ENDPOINT,
        isShowNotificationError,
      })
  }
}

const getBaseUrl = (url?: string) => {
  return url || process.env.REACT_APP_API_ENDPOINT || BASE_URL_LOCAL
}

const ApiService = ({ baseUrl }: { baseUrl?: string }) =>
  ApiServiceClient({
    baseUrl: getBaseUrl(baseUrl),
  })

const ApiClientHr = ApiService({
  baseUrl: getBaseUrl(process.env.REACT_APP_HR_API_ENDPOINT),
})

const ApiClientAuth = ApiService({
  baseUrl: getBaseUrl(process.env.REACT_APP_AUTH_API_ENDPOINT),
})

const ApiClientAccount = ApiService({
  baseUrl: getBaseUrl(process.env.REACT_APP_ACCOUNT_API_ENDPOINT),
})

const ApiClientCRM = ApiService({
  baseUrl: getBaseUrl(process.env.REACT_APP_CRM_API_ENDPOINT),
})

const ApiClientTreatment = ApiService({
  baseUrl: getBaseUrl(process.env.REACT_APP_TREATMENT_API_ENDPOINT),
})

const ApiClientInventory = ApiService({
  baseUrl: getBaseUrl(process.env.REACT_APP_INVENTORY_API_ENDPOINT),
})

export {
  ApiClient,
  ApiClientAccount,
  ApiClientCRM,
  ApiClientHandle,
  ApiClientHr,
  ApiClientTreatment,
  ApiClientAuth,
  getBaseUrl,
  ApiClientInventory,
}
