import {
  requestGetDataExportBill,
  requestGetLocationLegalList,
} from 'app/api/accounting'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  IFilterGetExportBill,
  IGetExportBill,
  ResponseExportBill,
} from 'app/api/accounting/model/export-bill'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { AccountingKeys } from '../query-key/accounting'

export function useGetDataExportBill(
  params?: IFilterGetExportBill,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IGetExportBill[]>, undefined>(
    AccountingKeys.reportRequestExportBillList(params),
    async () => {
      try {
        const res: ResponseExportBill = await requestGetDataExportBill(params)

        if (
          isEqual(res?.msgcode, AccountingMessageCode.ExportBill.getDataSuccess)
        ) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetLocationLegalList(
  params?: IFilterGetExportBill,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IGetExportBill[]>, undefined>(
    AccountingKeys.reportRequestGetLocationLegalLists(),
    async () => {
      try {
        const res: ResponseExportBill = await requestGetLocationLegalList()
        if (
          isEqual(res?.msgcode, AccountingMessageCode.ExportBill.getDataSuccess)
        ) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
