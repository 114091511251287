/* eslint-disable @typescript-eslint/no-explicit-any */
import { approveDayOff, rejectDayOff } from 'app/api/hr'
import { HrMessageCode } from 'app/api/hr/constant'
import {
  IDataGetTotalDashboardDayOff,
  IParamsGetApproveDayOff,
  ResponseApproveDayOff,
  StatusDayOffEnum,
} from 'app/api/hr/models/approve-day-off'
import R from 'app/assets/R'
import {
  SvgMenuVerticalIcon,
  SvgStampApproveIcon,
  SvgStampRejectIcon,
} from 'app/assets/svg-assets'
import { BaseModalManagement } from 'app/components/common/BaseModalManagement'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IColumnTable } from 'app/components/tables/BaseTableReport/type'
import {
  getArray,
  randomMoney,
} from 'app/components/tables/common-table/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import {
  useGetDashboardDayOffList,
  useGetTotalDashboardDayOffList,
} from 'app/react-query/hook/day-off'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual, uniqBy } from 'lodash'
import {
  DateUtil,
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_DD_MM_YYYY,
  Pagination,
  ResponseType,
  initialPagination,
  moment,
} from 'parkway-web-common'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HeadDayOffTableComponent } from './components'
import { ColumnDate } from './components/ColumnDate'
import { IDataTable, IFilter } from './type'

export const useHook = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilter>({
    year: new Date().getFullYear().toString(),
    month: (new Date().getMonth() + 1).toString(),
  })

  const [refreshData, setRefreshData] = useState(0)
  const [visible, setVisible] = useState(false)
  const [rejectReason, setRejectReason] = useState('')

  const [pagination, setPagination] = useState<Pagination>(initialPagination)

  const params = useMemo(() => {
    const res: IParamsGetApproveDayOff = {
      startDate: moment(`${filter?.year}-${filter?.month}-01`)
        .locale('vi')
        .startOf('month')
        .toISOString(),
      endDate: moment(`${filter?.year}-${filter?.month}-01`)
        .locale('vi')
        .endOf('month')
        .toISOString(),
      status: [],
    }

    return res
  }, [filter])

  const { flattenDataList } = usePagination()

  const {
    data: dataTotal,
    isLoading: isLoadingTotal,
    refetch: refetchTotal,
  } = useGetTotalDashboardDayOffList(params)

  const {
    data: dataDayOff,
    isLoading: isLoadingDayOff,
    refetch: refetchDashboard,
  } = useGetDashboardDayOffList(params)

  const totalStatusList = useMemo(() => {
    const res: ResponseType<IDataGetTotalDashboardDayOff[]> =
      flattenDataList(dataTotal)
    return res?.data
  }, [dataTotal])

  const dataApi: ResponseApproveDayOff = useMemo(() => {
    return flattenDataList(dataDayOff)
  }, [dataDayOff])

  const data = useMemo(() => {
    return dataApi?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [dataApi])

  const handleCancelReject = () => {
    setVisible(false)
  }
  const showModal = () => {
    setVisible(true)
  }
  const onChangeRejectReason = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    setRejectReason(value)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setRefreshData(randomMoney())
    }, 500)

    return () => clearTimeout(timer)
  }, [data])

  const columns: IColumnTable<IDataTable>[] = useMemo(() => {
    let options: IColumnTable<IDataTable>[] = []

    const countDayOfMonth = new Date(
      Number(filter.year),
      Number(filter.month),
      0,
    ).getDate()

    const dates = getArray(countDayOfMonth).map(day => {
      const totalData = totalStatusList?.find(itm => isEqual(day, itm?._id))
      const dateString = `${filter.year}-${filter.month}-${day}`
      const dateMoment = moment(dateString).locale('vi')

      return {
        title: (
          <HeadDayOffTableComponent
            dateMoment={dateMoment}
            totalData={totalData}
            isLoadingTotal={isLoadingTotal}
          />
        ),
        dataIndex: `day${day}`,
        key: `day-${day}`,
        className: 'date-column',
        render: (_, record: IDataTable) => {
          const unWorkingDays =
            record?.unWorkingDays?.filter(item => {
              return isEqual(
                DateUtil.formatDDMMYYY(item?.unWorkingDateEnd ?? ''),
                dateMoment.format(FORMAT_DD_MM_YYYY),
              )
            }) || []
          return (
            <ColumnDate fetchData={refreshData} unWorkingDays={unWorkingDays} />
          )
        },
      } as IColumnTable<IDataTable>
    })

    options = [
      convertColumnTable({
        title: t(R.strings.employee),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record: IDataTable) => {
          return (
            <BaseSpace>
              <BaseText
                children={record?.name}
                fontWeight="semibold"
                textAlign="center"
              />
            </BaseSpace>
          )
        },
      }),
      convertColumnTable({
        title: t(R.strings.unit_field_name),
        key: 'workinfos',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IDataTable) => {
          // unit list removed duplicate items
          const unitList = uniqBy(record?.workinfos,(i)=>i?.unit?._id)
     
          return (
            <div>
              <BaseSpace>
                {unitList?.map((i, idx) => {
                  return (
                    <BaseText
                      children={i?.unit?.name}
                      fontWeight="medium"
                      opacity="0.7"
                      textAlign="center"
                      key={idx}
                    />
                  )
                })}
              </BaseSpace>
            </div>
          )
        },
      }),
      {
        title: 'Ngày nghỉ',
        key: 'key',
        children: dates,
      },
      convertColumnTable({
        title: '',
        key: 'updatedAt',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record: IDataTable) => {
          const approveDayOffFn = async () => {
            try {
              if (!record?.unWorkingDays) {
                return
              }
              const linkedId =
                record?.unWorkingDays?.filter(
                  i => i?.status === StatusDayOffEnum.CREATED,
                )[0]?.linkedId || ''
              const response = await approveDayOff(linkedId)

              if (isEqual(response?.msgcode, HrMessageCode.DayOff.success)) {
                setRefreshData(refreshData + 1)
                refetchDashboard()
                refetchTotal()
                notificationController?.success({
                  message: t(R.strings.update_successfully),
                })
              }
            } catch (error) {
              notificationController?.error({
                message: t(R.strings.error),
                description: t(R.strings.with_error_please_contact_with_admin),
              })
            }
          }
          const rejectDayOffFn = async () => {
            try {
              if (!record?.unWorkingDays) {
                return
              }
              if (!rejectReason) {
                notificationController?.error({
                  message: t(R.strings.reason_label_required),
                })
                return
              }
              const linkedId =
                record?.unWorkingDays?.filter(
                  i => i?.status === StatusDayOffEnum.APPROVED,
                )[0]?.linkedId || ''
              const response = await rejectDayOff({
                id: linkedId,
                rejectReason,
              })

              if (isEqual(response?.msgcode, HrMessageCode.DayOff.success)) {
                setRefreshData(refreshData + 1)
                refetchDashboard()
                refetchTotal()
                notificationController?.success({
                  message: t(R.strings.update_successfully),
                })
              }
            } catch (error) {
              notificationController?.error({
                message: t(R.strings.error),
                description: t(R.strings.with_error_please_contact_with_admin),
              })
            } finally {
              setVisible(false)
            }
          }

          return (
            <BasePopover
              trigger={'click'}
              placement="bottom"
              content={
                <BaseSpace>
                  <ActionButton align={'middle'} onClick={approveDayOffFn}>
                    <SvgStampApproveIcon />
                    <ActionText>{t(R.strings.approve_DO)}</ActionText>
                  </ActionButton>

                  <BaseModalManagement
                    typeButtonOpen="custom"
                    buttonComponent={
                      <ActionButton align={'middle'} onClick={showModal}>
                        <SvgStampRejectIcon />
                        <ActionText>{t(R.strings.reject_DO)}</ActionText>
                      </ActionButton>
                    }
                    content={
                      <BaseSpace size={2}>
                        <BaseText
                          children={t(R.strings.reject_reason)}
                          fontWeight="semibold"
                          fontSize="lg"
                          textAlign="center"
                        />
                        <BaseText
                          children={t(R.strings.content)}
                          fontWeight="medium"
                          fontSize="xxs"
                          opacity="0.5"
                        />
                        <ReasonInput
                          placeholder={t(R.strings.enter_content)}
                          onChange={onChangeRejectReason}
                          style={{ minHeight: 150 }}
                          aria-expanded="false"
                        />
                      </BaseSpace>
                    }
                    isShowLineBetweenArea={false}
                    showModal={showModal}
                    handleCancel={handleCancelReject}
                    confirmKeyI18n={R.strings.confirm}
                    handleSubmit={rejectDayOffFn}
                    widthModal={600}
                    visible={visible}
                  />
                </BaseSpace>
              }
            >
              <MenuActionWrapper>
                <SvgMenuVerticalIcon opacity={0.5} />
              </MenuActionWrapper>
            </BasePopover>
          )
        },
      }),
    ]

    return options
  }, [filter, totalStatusList, isLoadingTotal, refreshData, visible])

  const onChangeFilter = (value: IFilter) => {
    setFilter(value)

    setPagination({
      ...pagination,
      current: 1,
    })
  }

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    columns,
    filter,
    onChangeFilter,
    isLoadingTotal,
    isLoadingDayOff,
    pagination: {
      ...pagination,
      total: dataApi?.metadata?.totalItem ?? 0,
    },
    data: data ?? [],
    handleTableChange,
    refetchDashboard,
    setRefreshData,
    refreshData,
    refetchTotal,
  }
}

export const ReasonInput = styled.textarea`
  background: var(--white);
  width: 100%;
  font-family: Inter;
  font-size: ${FONT_SIZE.xs} !important;
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: ${convertedVariables.primaryColor} !important;
  border: 1px solid #f0f1f3;
  border-radius: 8px;
  padding: 8px 12px;
  min-height: 150px;
  opacity: 0.9;
  resize: none;
  &:focus-visible {
    outline: none;
  }
`
export const MenuActionWrapper = styled.div`
  cursor: pointer;
`
export const ActionButton = styled(BaseRow)`
  padding: 4px 0;
  cursor: pointer;
`
export const ActionText = styled(BaseText)`
  margin-left: 8px;
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  color: ${convertedVariables.primaryColor};
`
export const ItemWrapper = styled.div<{ $height: number }>`
  width: 100%;
  height: ${props => props.$height}px;
  display: flex;
`
