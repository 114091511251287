import { PrinterOutlined } from '@ant-design/icons'
import {
  ISurveyDynamic,
  StatusApproveEnum,
  TypeEnum,
  TypeSurveyEnum,
} from 'app/api/dental-treatment/survey-dynamic/type'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { ModalComponent } from 'app/components/common/ModalComponent'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import {
  DetailPatientLayout,
  MedicalHistoryLayout,
  TeethHealthLayout,
} from './layouts'

export const ModalDetail = ({
  item,
  status,
}: {
  item: ISurveyDynamic
  status?: StatusApproveEnum
}) => {
  const { t } = useTranslation()

  const {
    form,
    handleOpenPrint,
    isDisable,
    isLoadingApprovePatientInfo,
    isLoadingApproveSurveyDynamic,
    onConfirm,
    onReject,
    openModal,
    referral,
    setReferral,
    modalRef,
  } = useHook({ item })

  return (
    <ModalComponent
      ref={modalRef}
      renderContent={
        <BaseForm form={form}>
          {(() => {
            switch (item?.type) {
              case TypeEnum.INFO_PATIENT:
                return (
                  <DetailPatientLayout
                    item={item}
                    onChangeReferral={setReferral}
                    referral={referral}
                    isShowSource={isEqual(status, StatusApproveEnum.PENDING)}
                  />
                )
              case TypeEnum.SURVEY_DYNAMIC:
                switch (item?.typeSurvey) {
                  case TypeSurveyEnum.MEDICAL_HISTORY:
                    return <MedicalHistoryLayout item={item} />
                  case TypeSurveyEnum.TEETH_HEALTH:
                    return <TeethHealthLayout item={item} />
                }
            }
          })()}
        </BaseForm>
      }
      buttonOpenCustom={
        <BaseButton
          children={<BaseText children={t(R.strings.see)} />}
          type="default"
          onClick={openModal}
        />
      }
      showClose
      titleModal={(() => {
        switch (item?.type) {
          case TypeEnum.INFO_PATIENT:
            return t(R.strings.detail)
          case TypeEnum.SURVEY_DYNAMIC:
            switch (item?.typeSurvey) {
              case TypeSurveyEnum.MEDICAL_HISTORY:
                return 'Khảo sát tiền sử bệnh lý Y khoa và Nha khoa'
              case TypeSurveyEnum.TEETH_HEALTH:
                return 'Khảo sát sức khoẻ răng miệng'
              default:
                return ''
            }
          default:
            return ''
        }
      })()}
      footer={(() => {
        switch (status) {
          case StatusApproveEnum.PENDING:
            return (
              <BaseRow justify={'end'} style={{ paddingRight: 16 }}>
                <BaseCol>
                  <BaseRow gutter={[16, 16]} align={'middle'}>
                    <BaseCol>
                      <BaseButton
                        onClick={onReject}
                        children={t(R.strings.reject)}
                        loading={
                          isLoadingApproveSurveyDynamic ||
                          isLoadingApprovePatientInfo
                        }
                        disabled={isDisable}
                      />
                    </BaseCol>

                    <BaseCol>
                      <BaseButton
                        disabled={isDisable}
                        children={t(R.strings.confirm)}
                        onClick={onConfirm}
                        loading={
                          isLoadingApproveSurveyDynamic ||
                          isLoadingApprovePatientInfo
                        }
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              </BaseRow>
            )
          case StatusApproveEnum.APPROVED:
            switch (item?.type) {
              case TypeEnum.INFO_PATIENT:
                return (
                  <BaseRow justify={'end'} style={{ paddingRight: 16 }}>
                    <BaseCol>
                      <BaseRow gutter={[16, 16]} align={'middle'}>

                        <BaseCol>
                          <BaseButton
                            children={t(
                              R.strings
                                .print_the_consent_for_the_information_privacy_policy,
                            )}
                            onClick={handleOpenPrint}
                            icon={<PrinterOutlined rev={undefined} />}
                          />
                        </BaseCol>
                      </BaseRow>
                    </BaseCol>
                  </BaseRow>
                )
            }
        }
      })()}
      isShowConfirm={false}
      isShowCancel={false}
      paddingContent="0px 0px"
      widthModal={800}
      style={{ top: 10 }}
    />
  )
}
