import { Divider } from 'antd'
import { IDocuments } from 'app/api/hr/models/approve-day-off'
import R from 'app/assets/R'
import { SvgDownloadBlueIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { getDataByFileType } from 'app/containers/DentalTreatmentManagement/ProfilePatientRecord/layouts/TreatmentSchedule/layouts/TreatmentScheduleLayout/layouts/TreatmentPhaseLayout/ProfessionalExchangeWithPhysician/hook'
import { limitFileNameLength } from 'app/helpers/string.helper'
import { FONT_WEIGHT } from 'parkway-web-common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DOCX_TYPE, EXCEL_TYPE, IMAGE_TYPE, PDF_TYPE } from '../../type'

interface IProps {
  data: IDocuments[]
}
export const Documents = (props: IProps) => {
  const { data } = props
  const [visibleImage, setVisibleImage] = useState(false)
  const { t } = useTranslation()

  const cancelPreview = () => {
    setVisibleImage(false)
  }

  const listImage = data?.filter(i => IMAGE_TYPE.includes(i?.type || ''))
  const listDocument = data?.filter(
    i =>
      DOCX_TYPE.includes(i?.type || '') ||
      EXCEL_TYPE.includes(i?.type || '') ||
      PDF_TYPE.includes(i?.type || ''),
  )

  if (!data || data?.length <= 0) {
    return null
  }
  return (
    <BaseSpace size={16}>
      <Divider dashed style={{ margin: 0 }} />
      <BaseText
        children={t(R.strings.documents_images)}
        fontSize="xs"
        colorText="primaryColor"
        fontWeight="semibold"
      />
      {listImage?.length > 0 && (
        <BaseRow gutter={[16, 16]}>
          {listImage?.map((i, index) => {
            const onClick = () => {
              setVisibleImage(true)
            }
            return (
              <ImageWrapper key={index}>
                <Image
                  src={i?.url}
                  alt={i?.name}
                  width={88}
                  preview={false}
                  onClick={onClick}
                />
                {/* {index === 5 && <LastImageLayout>{'+8'}</LastImageLayout>} */}
              </ImageWrapper>
            )
          })}
          <DetailImageModal
            width={900}
            open={visibleImage}
            onCancel={cancelPreview}
            closeIcon={null}
            footer={<></>}
          >
            <ImageDetail
              src={
                'https://img.freepik.com/premium-photo/male-doctor-grey-background_629685-7939.jpg'
              }
              alt={'doctor'}
              width={900}
              preview={false}
            />
          </DetailImageModal>
        </BaseRow>
      )}
      {listDocument?.length > 0 && (
        <BaseRow gutter={[16, 16]}>
          {listDocument?.map(item => {
            const { icon } = getDataByFileType({
              uid: item?.url || '',
              name: item?.url || '',
            })
            const onClick = () => {
              window.open(item?.url, '_bank')
            }
            return (
              <BaseCol>
                <DocumentWrapper align={'middle'} onClick={onClick}>
                  {icon}
                  <DocumentText
                    children={limitFileNameLength({
                      name: item?.name,
                      maxLength: 30,
                    })}
                    fontSize="xxs"
                    colorText="primaryColor"
                    fontWeight="medium"
                  />
                  <SvgDownloadBlueIcon />
                </DocumentWrapper>
              </BaseCol>
            )
          })}
        </BaseRow>
      )}
    </BaseSpace>
  )
}
const DetailImageModal = styled(BaseModal)`
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
`
const ImageWrapper = styled(BaseCol)`
  padding-bottom: 0 !important;
  .ant-image {
    display: block;
  }
`
const LastImageLayout = styled.div`
  display: flex;
  width: 88px;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--white);
  background: rgba(9, 16, 29, 0.4);
  border-radius: 8px;
`
const ImageDetail = styled(BaseImage)`
  border-radius: 8px;
`
const Image = styled(BaseImage)`
  border-radius: 8px;
  cursor: pointer;
`
const DocumentText = styled(BaseText)`
  margin-left: 8px;
  margin-right: 16px;
`
const DocumentWrapper = styled(BaseRow)`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f8f8f8;
  background: #f8f8f8;
  width: fit-content;
  cursor: pointer;
`
