import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import React, { useState } from 'react'
import { useModalHook } from './hooks'
import {
  Cancel,
  CreateRequestExportBill,
  Exported,
  HeadTabComponent,
  NeedUpdate,
  WaitingForExport,
} from './layouts'
import { BaseTabsExportBill, HeadPageWrapper, RootWrapper } from './styles'

export const ExportBillContainer: React.FC = () => {
  const [tabKey, setTabKey] = useState(R.strings.waiting_for_export)
  const [reloadTable, setReloadTable] = useState(0)
  const {
    pendingCount,
    completeCount,
    needUpdateCount,
    canceledCount,
    handleExportExcel,
  } = useModalHook({ reloadCount: reloadTable })

  return (
    <RootWrapper>
      <HeadPageWrapper
        titleI18nKey={R.strings.export_bill}
        rightComponent={
          <CreateRequestExportBill setReloadTable={setReloadTable} />
        }
      />
      <BaseSpace>
        <BaseTabsExportBill
          defaultActiveKey={tabKey}
          tabPosition={'top'}
          onChange={setTabKey}
          items={[
            {
              label: (
                <HeadTabComponent
                  keyTitle={R.strings.waiting_for_export}
                  total={pendingCount}
                  currentTab={tabKey}
                />
              ),
              key: R.strings.waiting_for_export,
              children: (
                <WaitingForExport
                  reloadTable={reloadTable}
                  handleExportExcel={handleExportExcel}
                  setReloadTable={setReloadTable}
                />
              ),
            },
            {
              label: (
                <HeadTabComponent
                  keyTitle={R.strings.exported}
                  total={completeCount}
                  currentTab={tabKey}
                />
              ),
              key: R.strings.exported,
              children: (
                <Exported
                  reloadTable={reloadTable}
                  handleExportExcel={handleExportExcel}
                  setReloadTable={setReloadTable}
                />
              ),
            },
            {
              label: (
                <HeadTabComponent
                  keyTitle={R.strings.need_update}
                  total={needUpdateCount}
                  currentTab={tabKey}
                />
              ),
              key: R.strings.need_update,
              children: (
                <NeedUpdate
                  reloadTable={reloadTable}
                  handleExportExcel={handleExportExcel}
                  setReloadTable={setReloadTable}
                />
              ),
            },
            {
              label: (
                <HeadTabComponent
                  keyTitle={R.strings.cancel}
                  total={canceledCount}
                  currentTab={tabKey}
                />
              ),
              key: R.strings.cancel,
              children: (
                <Cancel
                  reloadTable={reloadTable}
                  handleExportExcel={handleExportExcel}
                />
              ),
            },
          ]}
        />
      </BaseSpace>
    </RootWrapper>
  )
}
