import { IParamsGetProfileManagement } from 'app/api/profile/model/management'
import { IPayLoadGetList } from 'app/common/interface'

export const ProfileKeys = {
  allMyVoucher: ['MyVoucher'] as const,
  allReferralVoucher: ['ReferralVoucher'] as const,
  listMyVouchers: () => [...ProfileKeys.allMyVoucher, 'MyVouchers'] as const,
  listMyVoucher: (filter?: IPayLoadGetList) =>
    [...ProfileKeys.listMyVouchers(), { filter }] as const,
  listReferralVouchers: () =>
    [...ProfileKeys.allReferralVoucher, 'ReferralVouchers'] as const,
  listReferralVoucher: (filter?: IPayLoadGetList) =>
    [...ProfileKeys.listReferralVouchers(), { filter }] as const,
  allItems: ['Profiles'] as const,
  listProfiles: () => [...ProfileKeys.allItems, 'listProfile'] as const,
  listProfile: (filter?: IParamsGetProfileManagement) =>
    [...ProfileKeys.listProfiles(), { filter }] as const,
}
