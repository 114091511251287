// Message code define
export const NotificationMessageCode = {
  History: {
    successGetData: '80000',
  },
}

// Endpoint
export const NotificationEndPoint = {
  History: {
    request: 'crm/v1/notification-history/of-user',
    read: 'crm/v1/notification-history/of-user/read',
  },
}
