import { MoreOutlined } from '@ant-design/icons'
import {
  DefineCampaignStatus,
  DefineDiscountCampaignType,
} from 'app/api/marketing/discount-campaign/constant'
import {
  CampaignStatusEnum,
  CampaignTypeEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  MARKETING_ADDITIONAL_BILL_DISCOUNT_CAMPAIGN_PATH,
  MARKETING_ADDITIONAL_SERVICE_CAMPAIGN_PATH,
  MARKETING_CLONE_CAMPAIGN_PATH,
  MARKETING_CREATE_CAMPAIGN_PATH,
  MARKETING_DETAIL_CAMPAIGN_PATH,
  MARKETING_UPDATE_CAMPAIGN_PATH,
  MARKETING_VOUCHER_PATH,
} from 'app/components/router/route-path'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { useDebounce, usePagination } from 'app/hook'
import { useGetCampaignList } from 'app/react-query/hook/campaign'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  DateUtil,
  PADDING,
  Pagination,
  ResponseType,
  formatMoney,
  initialPagination,
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { DeActivatedCampaign, FinishCampaign, ReActivated } from '..'
import { IDataTable, IFilterDataTableReport } from '../../type'
import { isEqual } from 'lodash'

export const useHookTable = ({
  filter,
  campaignGroupCode,
}: {
  filter: IFilterDataTableReport
  campaignGroupCode?: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState(initialPagination)

  const { user, isHavePermissionFunction, isHavePermissionFunctionAndGoBack } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.READ_DISCOUNT_CAMPAIGN,
      R.strings.discount_campaign_management,
    )
  }, [user])

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IFilterDataTableReport>(filter, 500)

  const { data: dataApi, isLoading } = useGetCampaignList({
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    campaignType: filterDebounce?.campaignType,
    keyword: filterDebounce?.name,
    clinics: filterDebounce?.clinics,
    priority: filterDebounce?.priority,
    isStackedDiscount:
      filterDebounce?.applyWithOtherCampaign !== undefined
        ? filterDebounce?.applyWithOtherCampaign
          ? 1
          : 0
        : undefined,
    start: filterDebounce?.startDate,
    end: filterDebounce?.endDate,
    campaignStatus: filterDebounce?.campaignStatus,
    campaignGroupCode: campaignGroupCode,
  })

  const dataFlatten = useMemo(() => {
    const res: ResponseType<IDiscountCampaign[]> = flattenDataList(dataApi)
    return res
  }, [dataApi])

  const navigateUpdateItem = (item?: IDiscountCampaign) => {
    if (!item) return
    navigate(`${MARKETING_UPDATE_CAMPAIGN_PATH}`, {
      state: { item },
    })
  }

  const navigateAdditionalServiceItem = (item?: IDiscountCampaign) => {
    if (!item) return
    navigate(`${MARKETING_ADDITIONAL_SERVICE_CAMPAIGN_PATH}`, {
      state: { item },
    })
  }

  const navigateAdditionalBillDiscountItem = (item?: IDiscountCampaign) => {
    if (!item) return
    navigate(`${MARKETING_ADDITIONAL_BILL_DISCOUNT_CAMPAIGN_PATH}`, {
      state: { item },
    })
  }

  const navigateCloneItem = (item?: IDiscountCampaign) => {
    if (!item) return
    navigate(`${MARKETING_CLONE_CAMPAIGN_PATH}`, {
      state: { item },
    })
  }

  const navigateDetailCampaign = (item?: IDiscountCampaign) => {
    if (!item) return
    navigate(`${MARKETING_DETAIL_CAMPAIGN_PATH}`, {
      state: { item },
    })
  }

  const navigateVoucherCampaign = (item?: IDiscountCampaign) => {
    if (!item) return
    navigate(`${MARKETING_VOUCHER_PATH}`, {
      state: { item },
    })
  }

  const isHavePermission = useMemo(() => {
    const isHavePermissionCreate = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.CREATE_DISCOUNT_CAMPAIGN,
    )
    const isHavePermissionFinish = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.FINISH_DISCOUNT_CAMPAIGN,
    )
    const isHavePermissionCancel = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.CANCEL_DISCOUNT_CAMPAIGN,
    )
    const isHavePermissionUpdate = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.UPDATE_DISCOUNT_CAMPAIGN,
    )
    const isHavePermissionReadVoucher = isHavePermissionFunction(
      ModulePermissionEnum.MARKETING,
      FunctionPermissionEnum.READ_DISCOUNT_VOUCHER,
    )

    return {
      isHavePermissionCreate,
      isHavePermissionFinish,
      isHavePermissionCancel,
      isHavePermissionUpdate,
      isHavePermissionReadVoucher,
    }
  }, [user])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.campaign_name),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return <BaseText children={record?.name} fontWeight="semibold" />
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.type_of_offer),
        key: 'campaignType',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.campaignType
                  ? t(DefineDiscountCampaignType[record?.campaignType]?.keyI18n)
                  : '-'
              }
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.priority),
        key: 'priority',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.priority
                  ? t(R.strings.priority_number, { number: record?.priority })
                  : '-'
              }
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.total_voucher_template),
        key: 'totalVoucherTemplate',
        render: (value?: number) => {
          return (
            <BaseText
              children={formatMoney(value ?? 0, '')}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_with_other_campaign),
        key: 'isStackedDiscount',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.isStackedDiscount ? t(R.strings.yes) : t(R.strings.no)
              }
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.start_date),
        key: 'start',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.start ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.end_date),
        key: 'end',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.end ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.created_at),
        key: 'createdAt',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.createdAt ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.updated_at),
        key: 'updatedAt',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.updatedAt ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_clinic),
        key: 'clinics',
        render: (_, record) => {
          return (
            <BasePopover
              content={
                <BaseSpace>
                  {record?.clinics?.map((item, index) => {
                    return (
                      <BaseText
                        children={item?.name}
                        key={index}
                        fontWeight="medium"
                      />
                    )
                  })}
                </BaseSpace>
              }
            >
              <NumberWrapper
                type="text"
                children={
                  <BaseText
                    children={t(R.strings.number_clinic, {
                      number: `${record?.clinics?.length}`,
                    })}
                    fontWeight="medium"
                    colorText="collapseBackgroundColor"
                  />
                }
              />
            </BasePopover>
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'campaignStatus',
        fixed: 'right',
        className: 'status-column',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          if (!record?.campaignStatus) return
          return (
            <BaseStatusWrapper
              $backgroundColor={
                DefineCampaignStatus[record?.campaignStatus].backgroundColor
              }
            >
              <BaseText
                children={t(
                  DefineCampaignStatus[record?.campaignStatus].keyI18n,
                )}
                fontWeight="medium"
                colorText="backgroundColor"
                textAlign="center"
              />
            </BaseStatusWrapper>
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: '',
        key: 'deactivated',
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'right',
        render: (_, record) => {
          return (
            <BasePopover
              trigger={'click'}
              content={
                <BaseSpace>
                  {(() => {
                    if (!isHavePermission?.isHavePermissionUpdate) {
                      return null
                    }

                    switch (record?.campaignStatus) {
                      case CampaignStatusEnum.UpComing:
                        return (
                          <BaseButtonWrapper
                            type="text"
                            onClick={() => navigateUpdateItem(record)}
                          >
                            {t(R.strings.update_campaign)}
                          </BaseButtonWrapper>
                        )
                      case CampaignStatusEnum.OnGoing:
                        if (
                          isEqual(
                            record?.campaignType,
                            CampaignTypeEnum.Voucher,
                          )
                        )
                          return null

                        return (
                          <BaseButtonWrapper
                            type="text"
                            onClick={() => {
                              switch (record?.campaignType) {
                                case CampaignTypeEnum.TotalBill:
                                  navigateAdditionalBillDiscountItem(record)
                                  break
                                default:
                                  navigateAdditionalServiceItem(record)
                                  break
                              }
                            }}
                          >
                            {(() => {
                              switch (record?.campaignType) {
                                case CampaignTypeEnum.TotalBill:
                                  return t(R.strings.additional_bill_discount)
                                default:
                                  return t(R.strings.additional_campaign)
                              }
                            })()}
                          </BaseButtonWrapper>
                        )

                      default:
                        return null
                    }
                  })()}

                  <BaseButtonWrapper
                    type="text"
                    onClick={() => navigateDetailCampaign(record)}
                  >
                    {t(R.strings.detail_campaign)}
                  </BaseButtonWrapper>

                  {isHavePermission?.isHavePermissionReadVoucher ? (
                    <BaseButtonWrapper
                      type="text"
                      onClick={() => navigateVoucherCampaign(record)}
                    >
                      {t(R.strings.voucher_management)}
                    </BaseButtonWrapper>
                  ) : null}

                  {isHavePermission?.isHavePermissionCreate ? (
                    <BaseButtonWrapper
                      type="text"
                      onClick={() => navigateCloneItem(record)}
                    >
                      {t(R.strings.clone_campaign)}
                    </BaseButtonWrapper>
                  ) : null}

                  {(() => {
                    switch (record?.campaignStatus) {
                      case CampaignStatusEnum.OnGoing: {
                        if (!isHavePermission?.isHavePermissionFinish)
                          return null

                        return (
                          <FinishCampaign
                            id={record?._id}
                            name={record?.name}
                          />
                        )
                      }
                      default: {
                        if (!isHavePermission?.isHavePermissionCancel)
                          return null

                        return record?.deactivated ? (
                          <ReActivated id={record?._id} name={record?.name} />
                        ) : (
                          <DeActivatedCampaign
                            id={record?._id}
                            name={record?.name}
                          />
                        )
                      }
                    }
                  })()}
                </BaseSpace>
              }
            >
              <BaseButton type="text" icon={<MoreOutlined rev={undefined} />} />
            </BasePopover>
          )
        },
      }),
    ]
    return option
  }, [t, isHavePermission])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const navigateCreate = () => {
    navigate(MARKETING_CREATE_CAMPAIGN_PATH)
  }

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [dataFlatten?.data])

  return {
    data,
    columns,
    isLoading,
    filter,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.metadata?.totalItem ?? 0,
    },
    navigateCreate,
    isHavePermission,
  }
}

const BaseStatusWrapper = styled.div<{
  $backgroundColor: string
}>`
  padding: 4px 8px;
  border-radius: ${PADDING.xl};
  background-image: ${props => {
    return props.$backgroundColor
  }};
  width: fit-content;
`

const NumberWrapper = styled(BaseButton)`
  padding: 4px 8px;
  border-radius: ${PADDING.xl};
  background-color: ${convertedVariables.neutralBlack9Color};
`

const BaseButtonWrapper = styled(BaseButton)`
  width: 100%;
  justify-content: flex-start;
`
