import R from 'app/assets/R'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  CUSTOMER_SURVEY_REPORT_PATH,
  REPORT_001_FULL_PATH,
  REPORT_002_FULL_PATH,
  REPORT_003_FULL_PATH,
  REPORT_004_FULL_PATH,
  REPORT_005_FULL_PATH,
  REPORT_006_FULL_PATH,
  REPORT_007_FULL_PATH,
  REPORT_008_FULL_PATH,
  REPORT_009_FULL_PATH,
  REPORT_010_FULL_PATH,
  REPORT_011_FULL_PATH,
  REPORT_012_FULL_PATH,
  REPORT_014_FULL_PATH,
  REPORT_015_FULL_PATH,
  REPORT_016_FULL_PATH,
  REPORT_018_FULL_PATH,
  REPORT_020_FULL_PATH,
  REPORT_021_FULL_PATH,
  REPORT_023_FULL_PATH,
  REPORT_024_FULL_PATH,
  REPORT_REFERER_FULL_PATH,
} from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'

export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.report_group_marketing,
    reports: [
      {
        id: 11,
        label: '001',
        descriptionI18nKey: R.strings.report_001_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_001_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_1,
      },
      {
        id: 12,
        label: '006',
        descriptionI18nKey: R.strings.report_006_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_006_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_6,
      },
      {
        id: 13,
        label: '007',
        descriptionI18nKey: R.strings.report_007_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_007_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_7,
      },
      {
        id: 14,
        label: '008',
        descriptionI18nKey: R.strings.report_008_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_008_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_8,
      },
      {
        id: 15,
        label: '009',
        descriptionI18nKey: R.strings.report_009_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_009_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_9,
      },
      {
        id: 16,
        label: '010',
        descriptionI18nKey: R.strings.report_010_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_010_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_10,
      },
      {
        id: 17,
        label: '011',
        descriptionI18nKey: R.strings.report_011_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_011_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_11,
      },
      {
        id: 18,
        label: '017',
        descriptionI18nKey: R.strings.referer_report,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_REFERER_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_17,
      },
      {
        id: 45,
        label: '021',
        descriptionI18nKey: R.strings.report_21,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_021_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_21,
      },
      {
        id: 19,
        label: '023',
        descriptionI18nKey: R.strings.report_23_title,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_023_FULL_PATH,
        permissions: [
          FunctionPermissionEnum.REPORT_READ_REPORT_23,
          FunctionPermissionEnum.REPORT_READ_REPORT_23_YOU_MANAGEMENT,
        ],
      },
    ],
  },
  {
    id: 2,
    titleI18nKey: R.strings.report_group_customer_care,
    reports: [
      {
        id: 21,
        label: '002',
        descriptionI18nKey: R.strings.report_002_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_002_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_2,
      },
      {
        id: 22,
        label: '003',
        descriptionI18nKey: R.strings.report_003_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_003_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_3,
      },
      {
        id: 23,
        label: '013',
        descriptionI18nKey: R.strings.survey_report,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: CUSTOMER_SURVEY_REPORT_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_4,
      },
    ],
  },
  {
    id: 3,
    titleI18nKey: R.strings.report_group_operation,
    reports: [
      {
        id: 31,
        label: '004',
        descriptionI18nKey: R.strings.report_004_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_004_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_4,
      },
      {
        id: 32,
        label: '005',
        descriptionI18nKey: R.strings.report_005_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_005_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_5,
      },
      {
        id: 33,
        label: '014',
        descriptionI18nKey: R.strings.report_14_content_title,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_014_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_14,
      },
      {
        id: 34,
        label: '015',
        descriptionI18nKey: R.strings.report_15,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_015_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_15,
      },
      {
        id: 35,
        label: '016',
        descriptionI18nKey: R.strings.report_16,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_016_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_16,
      },
      {
        id: 44,
        label: '020',
        descriptionI18nKey: R.strings.report_20,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_020_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_20,
      },
    ],
  },
  {
    id: 4,
    titleI18nKey: R.strings.report_group_accounting,
    reports: [
      {
        id: 41,
        label: '012',
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        descriptionI18nKey: R.strings.report_012_description,
        path: REPORT_012_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_12,
      },
      {
        id: 42,
        label: '018',
        descriptionI18nKey: R.strings.report_18_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_018_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_18,
      },
      {
        id: 45,
        label: '024',
        descriptionI18nKey: R.strings.report_24,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_024_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_24,
      },
    ],
  },
]
