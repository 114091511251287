import { ParamsList, ResponseType } from 'parkway-web-common'

export enum StatusAppointment {
  cancel = 'cancel',
  closed = 'closed',
  done = 'done',
  open = 'open',
  treating = 'treating',
}

export interface IMyAppointmentDashboard {
  _id?: string
  note?: string
  deactivated?: boolean
  status?: StatusAppointment
  totalWaitTime?: number
  location?: string
  title?: string
  start?: string
  end?: string
  pid?: string
  patient?: string
  contact?: string
  doctor?: {
    _id?: string
    name?: string
    dob?: string
    contact?: string
    role?: string
    location?: string
    eID?: string
    email?: string
  }
  treatment?: {
    _id?: string
    name?: string
  }
  createdBy?: string
  treatmentId?: string
  doctorId?: string
  clinic: {
    _id?: string
    name?: string
    nameid?: string
  }
}

export type ResponseMyAppointmentDashboard = ResponseType<
  IMyAppointmentDashboard[]
>

export interface IParamsMyAppointmentDashboard extends ParamsList {
  date?: string // format: yyyy-MM-dd
}

export enum DoctorMonthlyReportStatusEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Warning = 'warning',
  Danger = 'danger',
}

export enum DoctorMonthlyMeasureUnitsEnum {
  Case = 'case',
  Percentage = 'percentage',
}

export interface IDoctorMonthlyReport {
  name?: string
  total?: number
  status?: DoctorMonthlyReportStatusEnum
  measureUnit?: DoctorMonthlyMeasureUnitsEnum

  children?: IDoctorMonthlyReport[]

  isMain?: boolean
}

export type ResponseDoctorReportMonthly = ResponseType<IDoctorMonthlyReport[]>

export interface IParamsDoctorMonthlyReport {
  year?: string
  month?: string
}
