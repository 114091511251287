import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { Report23Container } from 'app/containers/Report/Report023'
import { ModulePermissionEnum } from 'app/common/permission-module'

const Report23PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_23)}</PageTitle>
      <Report23Container module={ModulePermissionEnum.REPORT} />
    </>
  )
}
const Report23Page = memo(Report23PageCpn, isEqual)

export default Report23Page
