import { IParamsGetSurveyDynamic } from 'app/api/dental-treatment/survey-dynamic/type'

export const MedicalRecordAndInformationKeys = {
  all: ['all'] as const,
  allSurveyDynamics: ['allSurveyDynamics'] as const,
  allQuestionTeethHealth: ['allQuestionTeethHealth'] as const,
  allQuestionMedicalHistory: ['allQuestionMedicalHistory'] as const,
  listSurveyDynamics: () => [...MedicalRecordAndInformationKeys.allSurveyDynamics, 'listSurveyDynamics'] as const,
  getListSurveyDynamic: (filters?: IParamsGetSurveyDynamic) =>
    [...MedicalRecordAndInformationKeys.listSurveyDynamics(), { filters }] as const,
}
