import { EditOutlined } from '@ant-design/icons'
import {
  CustomerSourceEnum,
  EVisitReason,
  ISurveyDynamic,
} from 'app/api/dental-treatment/survey-dynamic/type'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText, { FontWeightType } from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { isEmpty, isEqual } from 'lodash'
import {
  BORDER_RADIUS,
  DateUtil,
  normalizePhone,
  PADDING,
} from 'parkway-web-common'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const InfoWrapper = ({
  children,
  title,
}: {
  title?: string
  children: ReactNode
}) => {
  return (
    <BaseSpace>
      <BaseText children={title} fontWeight="medium" />

      <BaseSpace style={{ paddingLeft: 16 }}>{children}</BaseSpace>
    </BaseSpace>
  )
}

const ItemInfo = ({
  label,
  value,
  fontWeight,
}: {
  label?: string
  value?: string
  fontWeight?: FontWeightType
}) => {
  return (
    <BaseRow gutter={[16, 16]} align={'middle'}>
      <BaseCol xl={6}>
        <BaseText children={label} style={{ color: '#858C94' }} />
      </BaseCol>
      <BaseCol xl={18}>
        <BaseText
          children={!isEmpty(value) ? value : '-'}
          fontWeight={fontWeight}
        />
      </BaseCol>
    </BaseRow>
  )
}

const ItemCheckbox = ({
  listKey,
  keyEnum,
}: {
  keyEnum: EVisitReason
  listKey: EVisitReason[]
}) => {
  const checked = useMemo(() => {
    return !!listKey?.find(item => isEqual(item, keyEnum))
  }, [listKey, keyEnum])

  return (
    <BaseCheckbox disabled checked={checked}>
      <BaseText children={keyEnum} />
    </BaseCheckbox>
  )
}

export const DetailPatientLayout = ({
  item,
  onChangeReferral,
  referral,
  isShowSource = true,
}: {
  item: ISurveyDynamic
  onChangeReferral?: (referral?: CustomerSourceEnum) => void
  referral?: CustomerSourceEnum
  isShowSource?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <RootWrapper>
      <ContentWrapper>
        <BaseRow justify={'space-between'} align={'middle'} gutter={[16, 16]}>
          <BaseCol>
            <BaseText
              children={'Thông tin khách hàng'}
              fontSize="md"
              fontWeight="semibold"
            />
          </BaseCol>

          {isShowSource ? (
            <BaseCol>
              <BaseButton
                children={'Chỉnh sửa'}
                icon={<EditOutlined rev={undefined} />}
                type="default"
              />
            </BaseCol>
          ) : null}
        </BaseRow>

        <InfoWrapper title="1. Thông tin cá nhân">
          <ItemInfo
            label="Họ và tên"
            value={item?.patient?.name}
            fontWeight="semibold"
          />
          <ItemInfo
            label="Ngày sinh"
            value={DateUtil?.formatDDMMYYY(item?.patient?.dob ?? '')}
          />
          <ItemInfo
            label="Số điện thoại"
            value={normalizePhone(item?.patient?.contact ?? '') ?? ''}
          />
          <ItemInfo label="Email" value={item?.patient?.email} />
          <ItemInfo label="Địa chỉ" value={item?.patient?.address} />
        </InfoWrapper>

        <BaseDivider className="divider" type="horizontal" />

        <InfoWrapper title="2. Liên hệ trong trường hợp khẩn cấp">
          <ItemInfo
            label="Tên thân nhân"
            value={item?.patient?.emergency?.name}
          />
          <ItemInfo
            label="Số điện thoại"
            value={
              normalizePhone(item?.patient?.emergency?.contact ?? '') ?? ''
            }
          />
        </InfoWrapper>
        <BaseDivider className="divider" type="horizontal" />
        <InfoWrapper title="3. Nguyên nhân đến khám">
          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol xl={8}>
              <ItemCheckbox
                keyEnum={EVisitReason.GeneralCheckup}
                listKey={item?.patient?.reason ?? []}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemCheckbox
                keyEnum={EVisitReason.WisdomToothExtraction}
                listKey={item?.patient?.reason ?? []}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemCheckbox
                keyEnum={EVisitReason.TeethWhitening}
                listKey={item?.patient?.reason ?? []}
              />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol xl={8}>
              <ItemCheckbox
                keyEnum={EVisitReason.Orthodontics}
                listKey={item?.patient?.reason ?? []}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemCheckbox
                keyEnum={EVisitReason.Implant}
                listKey={item?.patient?.reason ?? []}
              />
            </BaseCol>
            <BaseCol xl={8}>
              <ItemCheckbox
                keyEnum={EVisitReason.CosmeticDentistry}
                listKey={item?.patient?.reason ?? []}
              />
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={[4, 4]} align={'middle'}>
            <BaseCol>
              <BaseText
                children={`${t(R.strings.other)}:`}
                fontWeight="semibold"
              />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={`${item?.patient?.reason_other ?? ''}`}
                style={{ fontStyle: 'italic' }}
              />
            </BaseCol>
          </BaseRow>
        </InfoWrapper>

        {!isEmpty(item?.patient?.signatureUrl) ? (
          <>
            <BaseDivider className="divider" type="horizontal" />

            <BaseRow align={'middle'} justify={'end'} gutter={16}>
              <BaseCol>
                <BaseSpace>
                  <BaseText
                    children={'Khách hàng/ người dám hộ'}
                    fontWeight="medium"
                    textAlign="center"
                  />
                  <BaseImage
                    src={item?.patient?.signatureUrl}
                    preview={false}
                    width={150}
                    height={100}
                  />

                  <BaseText
                    children={item?.patient?.name}
                    fontWeight="semibold"
                    textAlign="center"
                  />
                </BaseSpace>
              </BaseCol>
            </BaseRow>
          </>
        ) : null}
      </ContentWrapper>

      {isShowSource ? (
        <BaseForm.Item label={'Nguồn giới thiệu'} required>
          <BaseSelect
            options={Object.values(CustomerSourceEnum)?.map(item => ({
              label: item,
              value: item,
            }))}
            placeholder={t(R.strings.select)}
            filterSort={() => 0}
            value={referral}
            onChange={value => onChangeReferral?.(value as CustomerSourceEnum)}
          />
        </BaseForm.Item>
      ) : null}
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: 0px ${PADDING.md};
`

const ContentWrapper = styled(BaseSpace)`
  background-color: #fcfcfc;
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxs};
  border-color: 1px solid #f4f6f9;

  .divider {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    border-color: #a5abb3 !important;
    background-color: #a5abb3 !important;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #ebeef2;
    background-color: #ebeef2;
  }
`
