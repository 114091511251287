import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import { IPayloadCreatedExportBill } from 'app/api/accounting/model/export-bill'
import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseModal } from 'app/components/common/BaseModal'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { useModalHook } from './hook'
import {
  ButtonConfirmModal,
  ButtonCreateNew,
  ButtonFooterModal,
  EmptyList,
  FooterModalWrapper,
  FormContainer,
  FormInput,
  FormItem,
  ServiceContainer,
  ServiceRow,
  ServiceTitleRow,
  ServiceTitleText,
  TitleText,
} from './styles'

export const initValues: IPayloadCreatedExportBill =
  {} as IPayloadCreatedExportBill

enum StatusSkillOptionsEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
}

export type StatusSkillType = keyof typeof StatusSkillOptionsEnum

export interface IPropsCreateSkill {
  fetchTable: () => void
}

export const CreateRequestExportBill = ({
  setReloadTable,
}: {
  setReloadTable: React.Dispatch<React.SetStateAction<number>>
}) => {
  const {
    usedServices,
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    onTaxCodeBlur,
    onTaxCodeChange,
    onPaymentCodeChange,
    onCheckAllPress,
    onCheckBoxPress,
    selectedServices,
    onBlur,
  } = useModalHook({ setReloadTable })

  return (
    <>
      <ButtonCreateNew
        icon={
          <PlusOutlined rev={undefined} color="white" width={20} height={20} />
        }
        onClick={showModal}
      >
        {t(R.strings.create_new)}
      </ButtonCreateNew>

      <BaseModal
        open={visible}
        width={800}
        footer={
          <FooterModalWrapper justify={'end'} align={'middle'}>
            <ButtonFooterModal onClick={handleCancel}>
              {t(R.strings.cancel)}
            </ButtonFooterModal>
            <ButtonConfirmModal onClick={form?.submit} loading={isLoading}>
              {t(R.strings.confirm)}
            </ButtonConfirmModal>
          </FooterModalWrapper>
        }
        onCancel={handleCancel}
        closeIcon={<CloseOutlined rev={undefined} onClick={handleCancel} />}
      >
        <TitleText children={t(R.strings.bill_information)} />
        <FormContainer
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
          form={form}
        >
          <FormItem
            name="paymentCode"
            label={t(R.strings.payment_code)}
            rules={rules.paymentId}
            required
          >
            <FormInput
              placeholder={t(R.strings.enter_payment_code, {
                field: t(R.strings.payment_code),
              })}
              onBlur={onBlur}
              onChange={onPaymentCodeChange}
            />
          </FormItem>
          <FormItem
            name="taxId"
            label={t(R.strings.tax_id)}
            // rules={rules.taxCode}
          >
            <FormInput
              onChange={onTaxCodeChange}
              onBlur={onTaxCodeBlur}
              placeholder={t(R.strings.enter_tax_code, {
                field: t(R.strings.tax_id),
              })}
            />
          </FormItem>
          <FormItem
            name="companyName"
            label={t(R.strings.company_name_customer_name)}
            rules={rules.customerName}
            required
          >
            {isLoading ? <Loading isAbsolute size="default" /> : <FormInput />}
          </FormItem>
          <FormItem
            name="address"
            label={t(R.strings.address)}
            rules={rules.address}
            required
          >
            {isLoading ? <Loading isAbsolute size="default" /> : <FormInput />}
          </FormItem>
          <FormItem
            name="email"
            label={t(R.strings.email)}
            rules={rules.email}
            required
          >
            <FormInput
              placeholder={t(R.strings.enter_email, {
                field: t(R.strings.email),
              })}
            />
          </FormItem>

          <Divider dashed />

          <ServiceTitleText children={t(R.strings.service_export_bill)} />

          {isLoading ? (
            <Loading isAbsolute size="default" />
          ) : usedServices?.length > 0 ? (
            <ServiceContainer size={0}>
              <ServiceTitleRow align={'middle'}>
                <BaseText children={t(R.strings?.service)} />
                <BaseCheckbox
                  checked={selectedServices?.length === usedServices?.length}
                  onChange={onCheckAllPress}
                />
              </ServiceTitleRow>
              {usedServices?.map((item, index) => {
                const selected = !!selectedServices?.find(
                  i => i?._id === item?._id,
                )
                return (
                  <ServiceRow align={'middle'} key={`${index}`}>
                    <BaseText children={item?.name} />
                    <BaseCheckbox
                      checked={selected}
                      onChange={e => onCheckBoxPress(e, item)}
                    />
                  </ServiceRow>
                )
              })}
            </ServiceContainer>
          ) : (
            <EmptyList>{t(R.strings.no_service_info)}</EmptyList>
          )}
        </FormContainer>
      </BaseModal>
    </>
  )
}
