import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { IProfileUser } from 'app/api/profile/model/profile'
import { useTranslation } from 'react-i18next'
import {
  ClassificationWrapper,
  Divider,
  SectionBaseWrapper,
  SectionDescription,
  SectionTitle,
  TextClassification,
  TextDegreeName,
  TextStartDateDegree,
} from './styles'
import R from 'app/assets/R'
import { DateUtil } from 'parkway-web-common'
import { getDegreeClassification } from 'app/utils/degreeClassification.util'

interface IProps {
  profile?: IProfileUser
}

export const Degree = (props: IProps) => {
  const { profile } = props
  const { t } = useTranslation()
  const DegreeList = () => {
    return (
      <BaseSpace>
        {profile?.degrees?.map((item, index) => {
          console.log('===============================================')
          console.log('degreeClassification', item?.degreeClassification)
          console.log('===============================================')
          return (
            <BaseRow key={index} align={'middle'} justify={'space-between'}>
              <BaseSpace direction="vertical" size={10}>
                <TextDegreeName>{item?.degree?.name}</TextDegreeName>
                <BaseRow gutter={[20, 20]} align={'middle'}>
                  <BaseCol>
                    <TextStartDateDegree>
                      {`${t(
                        R.strings.degree_content_start,
                      )}: ${DateUtil.formatDDMMYYY(item?.start ?? '')}`}
                    </TextStartDateDegree>
                  </BaseCol>
                  {item?.end ? (
                    <BaseCol>
                      <TextStartDateDegree>
                        {`${t(
                          R.strings.degree_content_end,
                        )}: ${DateUtil.formatDDMMYYY(item?.end ?? '')}`}
                      </TextStartDateDegree>
                    </BaseCol>
                  ) : null}
                  {item?.degreeClassification ? (
                    <BaseCol>
                      <BaseRow gutter={[10, 10]} align={'middle'}>
                        <BaseCol>
                          <TextStartDateDegree>
                            {`${t(
                              R.strings.degree_field_degree_classification,
                            )}:`}
                          </TextStartDateDegree>
                        </BaseCol>
                        <BaseCol>
                          <ClassificationWrapper>
                            <TextClassification
                              className={`${item?.degreeClassification?.key}`}
                            >
                              {getDegreeClassification(
                                item?.degreeClassification?.key,
                              )}
                            </TextClassification>
                          </ClassificationWrapper>
                        </BaseCol>
                      </BaseRow>
                    </BaseCol>
                  ) : null}
                </BaseRow>
              </BaseSpace>
            </BaseRow>
          )
        })}
      </BaseSpace>
    )
  }

  if (!profile?.degrees || profile?.degrees?.length <= 0) {
    return null
  }

  return (
    <SectionBaseWrapper>
      <SectionTitle>{t(R.strings.degree_field_degree)}</SectionTitle>
      <SectionDescription>
        {t(R.strings.certification_description)}
      </SectionDescription>
      <Divider />
      <DegreeList />
    </SectionBaseWrapper>
  )
}
