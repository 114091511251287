import { CheckboxChangeEvent } from 'antd/es/checkbox'
import {
  checkValidPaymentCode,
  requestCreateExportBill,
  requestGetInfoByTaxCode,
  requestGetUsedService,
} from 'app/api/accounting'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import {
  IPayloadCreatedExportBill,
  IValuesCreatedExportBill,
} from 'app/api/accounting/model/export-bill'
import { IPatient } from 'app/api/patient/model/patient'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import {
  EInvoiceStatus,
  IService,
} from 'app/containers/CustomerCare/AppointmentPage/type'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormRule } from './type'

export const useModalHook = ({
  setReloadTable,
}: {
  setReloadTable: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [taxCode, setTaxCode] = useState('')
  const [paymentCode, setPaymentCode] = useState('')
  const [customerInfo, setCustomerInfo] = useState<IPatient>({})
  const [selectedServices, setSelectedServices] = useState<IService[]>([])
  const [usedServices, setUsedServices] = useState<IService[]>([])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const onTaxCodeChange = (e: any) => {
    setTaxCode(e?.target?.value)
  }
  const onPaymentCodeChange = (e: any) => {
    setUsedServices([])
    setPaymentCode(e?.target?.value)
  }
  const onCheckAllPress = (e: CheckboxChangeEvent) => {
    const checked = e?.target?.checked //  checkbox = true

    if (checked) {
      setSelectedServices(usedServices)
    } else {
      setSelectedServices([])
    }
  }
  const onCheckBoxPress = (e: CheckboxChangeEvent, item: IService) => {
    const checked = e?.target?.checked // checkbox = true

    if (checked) {
      setSelectedServices([...selectedServices, item])
    } else {
      setSelectedServices(selectedServices?.filter(el => el?._id !== item?._id))
    }
  }

  const handleSubmit = async (values: IValuesCreatedExportBill) => {
    setIsLoading(true)
    try {
      const body: IPayloadCreatedExportBill = {
        paymentCodes: [values?.paymentCode],
        companyName: values?.companyName,
        taxId: values?.taxId,
        email: values?.email,
        address: values?.address,
        selectedServices: selectedServices?.map(
          i =>
            ({
              treatmentId: i?._id,
              treatmentGroupId: i?.treatmentGroupId,
              paymentId: i?.paymentId,
            } as IService),
        ),
      }

      const response: ResponseType<string> = await requestCreateExportBill(body)
      if (
        isEqual(
          response?.msgcode,
          AccountingMessageCode.ExportBill.createSuccess,
        )
      ) {
        setSelectedServices([])
        handleCancel()
        setReloadTable(prev => prev + 1)
        notificationController.success({
          message: t(R.strings.created_successfully),
        })
      } else {
        notificationController.error({
          message: t(R.strings.error),
          description: response?.message,
        })
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const getInfoFn = async () => {
    try {
      if (!taxCode) {
        form?.setFieldValue('companyName', '')
        form?.setFieldValue('address', '')
        return
      }

      setIsLoading(true)
      const response = await requestGetInfoByTaxCode(taxCode)
      setCustomerInfo(response?.data)
      form?.setFieldValue('companyName', response?.data?.name || '')
      form?.setFieldValue('address', response?.data?.address || '')
    } catch (error) {
      //error
    } finally {
      setIsLoading(false)
    }
  }

  const onTaxCodeBlur = () => {
    getInfoFn()
  }

  const checkValidAndGetDataFn = async () => {
    try {
      setIsLoading(true)
      if (!paymentCode) {
        return
      } else {
        const response = await checkValidPaymentCode({
          paymentCodes: [paymentCode],
        })
        if (
          response?.msgcode ===
          AccountingMessageCode.ExportBill.getTreatmentSuccess
        ) {
          const responseGetData = await requestGetUsedService(paymentCode, {
            tInvoiceStatus: EInvoiceStatus.INVOICE,
          })
          if (
            responseGetData?.msgcode ===
            AccountingMessageCode.ExportBill.getTreatmentSuccess
          ) {
            setUsedServices(responseGetData?.data)
            setSelectedServices(responseGetData?.data)
          } else {
            setUsedServices([])
          }
        } else {
          setUsedServices([])
        }
      }
    } catch (error) {
      setUsedServices([])
    } finally {
      setIsLoading(false)
    }
  }

  const onBlur = async () => {
    checkValidAndGetDataFn()
  }

  const rules: IFormRule = useMemo(() => {
    return {
      paymentId: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.payment_code),
          }),
        },
      ],
      taxCode: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.tax_id),
          }),
        },
      ],
      companyName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.company_name_customer_name),
          }),
        },
      ],
      address: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.address),
          }),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
      ],
    }
  }, [t])

  return {
    usedServices,
    form,
    visible,
    t,
    isLoading,
    showModal,
    handleSubmit,
    handleCancel,
    rules,
    taxCode,
    paymentCode,
    onPaymentCodeChange,
    onTaxCodeChange,
    customerInfo,
    selectedServices,
    onCheckBoxPress,
    onCheckAllPress,
    onBlur,
    onTaxCodeBlur,
  }
}
