import React from 'react'
import styled from 'styled-components'
import { BaseSpin } from '../BaseSpin'

interface LoadingProps {
  size?: 'small' | 'default' | 'large'
  color?: string
  isAbsolute?: boolean
  top?: number
}

export const Loading: React.FC<LoadingProps> = ({
  size = 'large',
  isAbsolute,
  top,
}) => {
  return (
    <SpinnerContainer $isAbsolute={isAbsolute} $top={top}>
      <BaseSpin size={size} />
    </SpinnerContainer>
  )
}

const SpinnerContainer = styled.div<{ $isAbsolute?: boolean; $top?: number }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => {
    if (props?.$isAbsolute) {
      return `
        position: absolute;
        top: 0px;
        left: 0px;
      `
    }
  }}
`
