import {
    ITotalSurveyDynamic,
    StatusApproveEnum,
} from 'app/api/dental-treatment/survey-dynamic/type'
import { usePagination } from 'app/hook'
import { useGetSurveyDynamicTotal } from 'app/react-query/hook/medical-record-and-information'
import { isEqual } from 'lodash'
import { useMemo } from 'react'

export const useHook = () => {
  const { data, isLoading } = useGetSurveyDynamicTotal()

  console.log({ data })
  const { flattenDataList } = usePagination()

  const dataFlatten: ITotalSurveyDynamic[] = useMemo(() => {
    return flattenDataList(data)
  }, [data])

  const dataTotal = useMemo(() => {
    return {
      [StatusApproveEnum.PENDING]: dataFlatten?.find(item =>
        isEqual(item?._id, StatusApproveEnum.PENDING),
      ),
      [StatusApproveEnum.APPROVED]: dataFlatten?.find(item =>
        isEqual(item?._id, StatusApproveEnum.APPROVED),
      ),
    }
  }, [dataFlatten])

  return {
    isLoading,
    dataTotal,
  }
}
