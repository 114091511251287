import { IProfileUser } from 'app/api/profile/model/profile'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useTranslation } from 'react-i18next'
import { IndirectSubordinateModal } from './ISModal'
import { SectionBaseWrapper, SectionDescription, SectionTitle } from './styles'

interface IProps {
  profile?: IProfileUser
}

export const IndirectSubordinate = (props: IProps) => {
  const { profile } = props
  const { t } = useTranslation()

  return (
    <SectionBaseWrapper>
      <BaseRow align={'middle'} justify={'space-between'}>
        <BaseCol>
          <SectionTitle>{t(R.strings.indirect_subordinate)}</SectionTitle>
          <SectionDescription>
            {t(R.strings.indirect_subordinate_description)}
          </SectionDescription>
        </BaseCol>
        <BaseCol>
          <IndirectSubordinateModal
            listIndirectSubordinate={Array(20)?.fill(profile) || []}
          />
        </BaseCol>
      </BaseRow>
    </SectionBaseWrapper>
  )
}
