/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, InboxOutlined } from '@ant-design/icons'
import { importEmployee, updateEmployee } from 'app/api/hr/index'
import { LimitDiscountMessageCode } from 'app/api/marketing/limit-discount/constant'
import R from 'app/assets/R'
import { SvgUploadIcon } from 'app/assets/svg-assets'
import { getDetailPatientUrl } from 'app/common/helpers'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import {
  DateUtil,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  ResponseType,
  colors,
  normFile,
} from 'parkway-web-common'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PopoverButton } from '../../styles'
import { IBodyImport, IDataTable, IResponseImportFile } from './type'
import { JobTitleAka } from '../JobTitleAka'

export const ImportFileModal = () => {
  const refModal = useRef<IRefModal>({})
  const { t } = useTranslation()
  const [fileSelected, setFileSelected] = useState<File | null | undefined>(
    null,
  )
  const [isLoading, setIsLoading] = useState(false)

  const [listEmployee, setListEmployee] = useState<IResponseImportFile[]>([])

  const onSuccessImportService = async (body: IBodyImport) => {
    try {
      const response = await updateEmployee(body)
      if (
        isEqual(
          response?.msgcode,
          LimitDiscountMessageCode.LimitDiscount.successUpdate,
        )
      ) {
        notificationController?.success({
          message: response?.message,
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    } finally {
      refModal?.current?.hide?.()
    }
  }

  const _onConfirmModal = async () => {
    if (successData?.length) {
      onSuccessImportService?.({ data: successData })
    }
    setFileSelected(null)
    setListEmployee([])
  }

  const onRemoveFile = () => {
    setFileSelected(null)
    setListEmployee([])
  }

  const onChangeFile = async (values: { file: any }) => {
    const { file } = values
    const { lastModified, name, type, originFileObj } = file
    const newFile = new File([originFileObj], name, { type, lastModified })
    if (!newFile) return

    setFileSelected(newFile)

    try {
      setIsLoading(true)
      const formData = new FormData()

      formData.append('file', newFile)

      const res: ResponseType<IResponseImportFile[]> = await importEmployee(
        formData,
      )
      if (
        isEqual(
          res?.msgcode,
          LimitDiscountMessageCode.LimitDiscount.successImportFile,
        )
      ) {
        setListEmployee(res.data)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onOpenModal = () => {
    refModal.current?.open?.()
  }

  const dataEmployee = useMemo(() => {
    return listEmployee?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [listEmployee])

  const successData = useMemo(() => {
    return listEmployee?.filter(item => !item?.error)
  }, [listEmployee])

  const errorData = useMemo(() => {
    return listEmployee?.filter(item => item?.error)
  }, [listEmployee])

  const handleCancel = () => {
    setFileSelected(null)
    setListEmployee([])
  }

  return (
    <ModalComponent
      titleModal={R.strings.import_file}
      widthModal={1000}
      buttonOpenCustom={
        <BaseCol>
          <PopoverButton onClick={onOpenModal}>
            {t(R.strings.import_file)}
          </PopoverButton>
        </BaseCol>
      }
      ref={refModal}
      handleSubmit={_onConfirmModal}
      handleCancel={handleCancel}
      renderContent={
        <BaseSpace size={8}>
          <DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
              accept=".xls, .xlsx"
            >
              <DraggerWrapper>
                <ImportButton icon={<SvgUploadIcon />}>
                  {t(R.strings.choose_import_file)}
                </ImportButton>
              </DraggerWrapper>
            </BaseUpload.Dragger>
          </DraggerInput>
          {fileSelected && (
            <FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <BaseText
                  fontSize="xs"
                  fontWeight="medium"
                  colorText="statesGreenColor"
                >
                  {fileSelected?.name}
                </BaseText>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                  size="small"
                />
              </BaseCol>
            </FileNameDisplayWrapper>
          )}

          {fileSelected ? (
            <BaseSpace size={8}>
              <BaseRow
                align={'middle'}
                justify={'space-between'}
                gutter={[8, 8]}
              >
                <BaseCol>
                  <BaseText
                    children={t(R.strings.employee_list)}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                </BaseCol>
                <BaseCol>
                  <BaseRow gutter={8} align={'middle'}>
                    <BaseCol>
                      <BaseText
                        children={`${successData?.length} ${t(
                          R.strings.success,
                        )}`}
                        fontWeight="medium"
                        colorText="statesGreenColor"
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={`${errorData?.length} ${t(
                          R.strings.error_vn_text,
                        )}`}
                        fontWeight="medium"
                        colorText="statesRedColor"
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              </BaseRow>
              <BaseTable
                pagination={{
                  pageSize: 5,
                }}
                columns={[
                  convertColumnTable<IResponseImportFile>({
                    key: 'row',
                    title: t(R.strings.row),
                    classNameWidthColumnOverwrite: 'small-column',
                    render: (_, record: IResponseImportFile) => {
                      return (
                        <BaseText
                          children={record?.row}
                          fontWeight="medium"
                          fontSize="xs"
                          opacity="0.5"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'name',
                    title: t(R.strings.name),
                    classNameWidthColumnOverwrite: 'big-column',
                    render: (_, record: IResponseImportFile) => {
                      return (
                        <RenderValueTable
                          value={record?.name ?? '-'}
                          type="OtherLink"
                          hrefOtherLink={getDetailPatientUrl(record?._id)}
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'gender',
                    title: t(R.strings.gender),
                    classNameWidthColumnOverwrite: 'medium-column',

                    render: (_, record: IResponseImportFile) => {
                      return (
                        <BaseText
                          children={record?.gender}
                          fontWeight="medium"
                          fontSize="xs"
                          opacity="0.5"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'dob',
                    title: t(R.strings.birthday),
                    classNameWidthColumnOverwrite: 'medium-column',

                    render: (_, record: IResponseImportFile) => {
                      return (
                        <BaseText
                          children={
                            record?.dob
                              ? DateUtil?.formatDate(record?.dob, 'DD/MM/YYYY')
                              : '-'
                          }
                          fontWeight="medium"
                          fontSize="xs"
                          opacity="0.5"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'employeeid',
                    title: t(R.strings.employee_id),
                    classNameWidthColumnOverwrite: 'normal-column',

                    render: (_, record: IResponseImportFile) => {
                      return (
                        <BaseText
                          children={record?.employeeid}
                          fontWeight="medium"
                          fontSize="xs"
                          opacity="0.5"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'mobile',
                    title: t(R.strings.phone),
                    classNameWidthColumnOverwrite: 'normal-column',

                    render: (_, record: IResponseImportFile) => {
                      return (
                        <BaseText
                          children={record?.mobile}
                          fontWeight="medium"
                          fontSize="xs"
                          opacity="0.5"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'email',
                    title: t(R.strings.email),
                    classNameWidthColumnOverwrite: 'normal-column',

                    render: (_, record: IResponseImportFile) => {
                      return (
                        <BaseText
                          children={record?.email}
                          fontWeight="medium"
                          fontSize="xs"
                          opacity="0.5"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'jobTitleName',
                    title: t(R.strings.profile_content_job_title_main_column),
                    classNameWidthColumnOverwrite: 'big-column',

                    render: (_, record: IResponseImportFile) => {
                      const mainJobTitle = record?.workinfos?.find(
                        i => i?.isMain,
                      )
                      return mainJobTitle?.jobtitle ? (
                        <BaseJobTitleText>
                          {mainJobTitle?.jobtitle}
                        </BaseJobTitleText>
                      ) : (
                        <BaseText children={''} />
                      )
                    },
                  }),
                  convertColumnTable<any>({
                    key: 'jobTitleAka',
                    title: t(
                      R.strings.profile_content_job_title_secondary_column,
                    ),
                    classNameWidthColumnOverwrite: 'very-big-column',
                    render: (_, record: IResponseImportFile) => {
                      const jobTitleAkaList =
                        record?.workinfos?.filter(i => !i?.isMain) || []
                      return <JobTitleAka data={jobTitleAkaList} />
                    },
                  }),

                  convertColumnTable<IResponseImportFile>({
                    key: 'status',
                    classNameWidthColumnOverwrite: 'normal-column',
                    title: t(R.strings.status),
                    render: (_, record: IResponseImportFile) => {
                      return (
                        <BaseText
                          children={
                            record?.error
                              ? record?.error?.message
                              : t(R.strings.success)
                          }
                          fontWeight="medium"
                          style={
                            record?.error
                              ? {
                                  color: convertedVariables.statesRedColor,
                                }
                              : {
                                  color: convertedVariables.statesGreenColor,
                                }
                          }
                          textAlign="right"
                        />
                      )
                    },
                  }),
                ]}
                dataSource={dataEmployee}
                loading={isLoading}
                scroll={{ x: true }}
              />
            </BaseSpace>
          ) : null}
        </BaseSpace>
      }
    />
  )
}

const DraggerInput = styled(BaseButtonsForm.Item)``

const DraggerWrapper = styled.div`
  padding: ${PADDING.xxl} 0px;
  display: flex;
  justify-content: center;
`
export const BaseJobTitleText = styled.div`
  padding: 6px 12px;
  border-radius: 100px;
  font-weight: ${FONT_WEIGHT.medium} !important;
  font-size: ${FONT_SIZE.xxs} !important;
  background: #f8f8f8 !important;
  width: max-content !important;
  border: 1px solid #f4f6f9;
`

const FileNameDisplayWrapper = styled(BaseRow)`
  color: var(--primary-color);
  padding-top: ${PADDING.xxs};
  & svg {
    fill: red;
  }
`
const BaseTable = styled(BaseTableManagement)`
  .ant-table-thead > tr > th {
    border: none !important;
  }
  .ant-table-thead > tr > th:last-child {
    text-align: right !important;
  }
`
const ImportButton = styled(BaseButton)`
  color: ${colors.primary};
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
  opacity: 0.7;
  gap: 0;
  &:hover {
    color: ${colors.primary}!important;
    font-weight: ${FONT_WEIGHT.medium}!important;
    font-size: ${FONT_SIZE.xs}!important;
    opacity: 0.7 !important;
  }
`
