import { IFilterGetExportBill } from 'app/api/accounting/model/export-bill'
import { ParamsList } from 'parkway-web-common'

export const AccountingKeys = {
  allRequestExportBill: ['RequestExportBill'] as const,
  reportRequestExportBillLists: () =>
    [...AccountingKeys.allRequestExportBill, 'ListRequestExportBill'] as const,
  reportRequestExportBillList: (
    filters?: IFilterGetExportBill & { [key: string]: any },
  ) => [...AccountingKeys.reportRequestExportBillLists(), { filters }] as const,
  allRequestUsedService: ['RequestUsedService'] as const,
  reportRequestUsedServiceLists: () =>
    [
      ...AccountingKeys.allRequestUsedService,
      'ListRequestUsedService',
    ] as const,
  reportRequestUsedServiceList: (
    filters?: { id: string } & { [key: string]: any },
  ) =>
    [...AccountingKeys.reportRequestUsedServiceLists(), { filters }] as const,
  allRequestGetLocationLegal: ['RequestGetLocationLegal'] as const,
  reportRequestGetLocationLegalLists: () =>
    [
      ...AccountingKeys.allRequestGetLocationLegal,
      'ListRequestGetLocationLegal',
    ] as const,
}
