import R from 'app/assets/R'
import BaseText, { ColorTextType } from 'app/components/common/BaseText'

import { CloseOutlined } from '@ant-design/icons'
import { requestExportExcelReport23 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IResponseReport23 } from 'app/api/report/model/report-23'
import { getDetailPatientUrl } from 'app/common/helpers'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTooltip } from 'app/components/common/BaseTooltip'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce } from 'app/hook'
import { useGetFilterReport23 } from 'app/react-query/hook/report'
import { IResponseQueryList } from 'app/react-query/model/common'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { DateUtil, Pagination, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FeedbackSurveyReport23Enum, IFormFilter, IProps } from './type'

function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber || phoneNumber.length !== 11) {
    return 'Invalid phone number'
  }

  const countryCode = phoneNumber.slice(0, 2)
  const firstPart = phoneNumber.slice(2, 4)
  const secondPart = phoneNumber.slice(4, 7)
  const thirdPart = phoneNumber.slice(7)

  return `+${countryCode} ${firstPart} ${secondPart} ${thirdPart}`
}

const getTextFeedback = (rate?: number) => {
  switch (rate) {
    case 1:
    case 2:
      return R.strings.need_to_improve
    case 3:
      return R.strings.need_to_improve_more

    case 4:
      return R.strings.satisfied
    case 5:
      return R.strings.best_satisfied
    default:
      return ''
  }
}

const geColorFeedback = (rate?: number, isText?: boolean) => {
  switch (rate) {
    case 1:
      return 'znsColorText1Star' as ColorTextType
    case 2:
      return 'znsColorText2Star' as ColorTextType
    case 3:
      return 'znsColorText3Star' as ColorTextType
    case 4:
    case 5:
      if (isText) return 'primaryColor' as ColorTextType
      return 'primaryColor' as ColorTextType
    default:
      return 'znsColorText5Star' as ColorTextType
  }
}

export const useTable = ({ module }: IProps) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 100,
  })

  const { isHavePermissionFunction, userData } = useVerifyPermission()

  const [filter, setFilter] = useState<IFormFilter>({})

  const filterDebounce = useDebounce<IFormFilter>(filter, 500)

  const isJustHavePermissionsReadReport23ByClinicsManagement = useMemo(() => {
    return isHavePermissionFunction(
      module,
      FunctionPermissionEnum.REPORT_READ_REPORT_23_YOU_MANAGEMENT,
    )
  }, [userData])

  const isHavePermissionExport = useMemo(() => {
    if (
      isHavePermissionFunction(
        module,
        FunctionPermissionEnum.REPORT_EXPORT_REPORT_23,
      )
    )
      return true

    return isHavePermissionFunction(
      module,
      FunctionPermissionEnum.REPORT_EXPORT_REPORT_23_YOU_MANAGEMENT,
    )
  }, [userData])

  const params = useMemo(() => {
    let newParams = {
      keyword: filterDebounce?.keyword ?? '',
      page: pagination.current,
      pageSize: pagination.pageSize,
      pagesize: pagination.pageSize,
      locations: filterDebounce?.locations,
      startDate: filterDebounce?.startDate?.toString(),
      endDate: filterDebounce?.endDate?.toString(),
      stars: filter?.stars,
    }

    if (
      isHavePermissionFunction(
        module ?? ModulePermissionEnum.REPORT,
        FunctionPermissionEnum.REPORT_READ_REPORT_23,
      )
    ) {
      return newParams
    }

    if (isJustHavePermissionsReadReport23ByClinicsManagement) {
      newParams = {
        ...newParams,
        locations:
          userData?.managedClinics?.map(item => item?.oldid ?? '') ?? [],
      }
    }

    return newParams
  }, [
    filterDebounce,
    pagination,
    userData,
    isJustHavePermissionsReadReport23ByClinicsManagement,
  ])

  const hideClinicFilter = useMemo(() => {
    if (
      isHavePermissionFunction(
        module ?? ModulePermissionEnum.REPORT,
        FunctionPermissionEnum.REPORT_READ_REPORT_23,
      )
    ) {
      return false
    }

    return isJustHavePermissionsReadReport23ByClinicsManagement
  }, [isJustHavePermissionsReadReport23ByClinicsManagement, userData])

  const { data: dataInfinity, isLoading } = useGetFilterReport23(params)

  const dataFlatten: IResponseQueryList<IResponseReport23[]> = useMemo(() => {
    return dataInfinity ?? {data: [], total: 0}
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IResponseReport23
    }) as IResponseReport23[]
  }, [dataFlatten])

  const handleExportExcel = async () => {
    try {
      const response = await requestExportExcelReport23({
        keyword: filterDebounce?.keyword ?? '',
        locations: filterDebounce?.locations,
        startDate: filterDebounce?.startDate?.toString(),
        endDate: filterDebounce?.endDate?.toString(),
        stars: filter?.stars,
      })
      if (
        isEqual(response?.msgcode, ReportMessageCode.Report23.successExportData)
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')

        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IResponseReport23>({
        title: t(R.strings.date_survey),
        key: 'updatedAt',
        fixed: 'left',
        className: 'date-time-column',

        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.updatedAt ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
              textAlign="left"
              colorText={geColorFeedback(record?.rate, true)}
            />
          )
        },
      }),

      convertColumnTable<IResponseReport23>({
        title: t(R.strings.phone),
        key: 'phone',
        fixed: 'left',
        className: 'phone-column',
        render: (_, record: IResponseReport23) => {
          return (
            <BaseText
              children={formatPhoneNumber(record?.phone)}
              fontWeight="medium"
              opacity="0.7"
              textAlign="left"
              colorText={geColorFeedback(record?.rate, true)}
            />
          )
        },
      }),

      convertColumnTable<IResponseReport23>({
        title: t(R.strings.patient_name),
        key: 'patientName',
        classNameWidthColumnOverwrite: 'big-column',
        fixed: 'left',
        render: (_, record: IResponseReport23) => {
          return (
            <RenderValueTable
              value={record?.patientName ?? '-'}
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record.patientId)}
              styleText={{
                color: convertedVariables?.[geColorFeedback(record?.rate)],
              }}
            />
          )
        },
      }),

      convertColumnTable<IResponseReport23>({
        title: t(R.strings.patient_code),
        key: 'patientCode',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IResponseReport23) => {
          return (
            <BaseText
              children={record?.patientCode}
              fontWeight="medium"
              opacity="0.7"
              textAlign="left"
              colorText={geColorFeedback(record?.rate, true)}
            />
          )
        },
      }),

      convertColumnTable<IResponseReport23>({
        title: t(R.strings.appointment_code),
        key: 'appointmentCode',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IResponseReport23) => {
          return (
            <BaseText
              children={record?.appointmentCode}
              fontWeight="medium"
              opacity="0.7"
              textAlign="left"
              colorText={geColorFeedback(record?.rate, true)}
            />
          )
        },
      }),

      convertColumnTable<IResponseReport23>({
        title: t(R.strings.clinic),
        key: 'location_name',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IResponseReport23) => {
          return (
            <BaseText
              children={record?.location_name}
              fontWeight="medium"
              opacity="0.7"
              textAlign="left"
              colorText={geColorFeedback(record?.rate, true)}
            />
          )
        },
      }),

      convertColumnTable<IResponseReport23>({
        title: t(R.strings.appointment_date_zns),
        key: 'appointmentDate',
        className: 'date-time-column',
        render: (_, record: IResponseReport23) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.appointmentDate ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
              textAlign="left"
              colorText={geColorFeedback(record?.rate, true)}
            />
          )
        },
      }),

      convertColumnTable<IResponseReport23>({
        title: t(R.strings.star_survey),
        key: 'rate',
        className: 'star-column',
        render: (_, record: IResponseReport23) => {
          return (
            <BaseText
              children={record?.rate}
              fontWeight="medium"
              opacity="0.7"
              textAlign="center"
              colorText={geColorFeedback(record?.rate, true)}
            />
          )
        },
      }),

      convertColumnTable<IResponseReport23>({
        title: t(R.strings.content),
        key: 'note',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record: IResponseReport23) => {
          return (
            <BaseText
              children={record?.note}
              fontWeight="medium"
              opacity="0.7"
              textAlign="left"
              colorText={geColorFeedback(record?.rate, true)}
            />
          )
        },
      }),
      convertColumnTable<IResponseReport23>({
        title: t(R.strings.professional_competence),
        key: '_id',
        className: 'professional-competence-column',
        render: (_, record: IResponseReport23) => {
          const isExist = record?.feedbacks?.find(itm =>
            isEqual(
              itm?.title,
              FeedbackSurveyReport23Enum?.PROFESSIONAL_COMPETENCE,
            ),
          )
          return (
            <BaseRow justify={'center'} align={'middle'}>
              {isExist ? (
                <BaseTooltip
                  children={
                    <CloseOutlined
                      rev={undefined}
                      style={{
                        color:
                          convertedVariables?.[geColorFeedback(record?.rate)],
                      }}
                    />
                  }
                  title={t(getTextFeedback(record?.rate))}
                  trigger={'hover'}
                />
              ) : (
                <BaseCol />
              )}
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IResponseReport23>({
        title: t(R.strings.service_experience),
        key: '_id',
        className: 'service-experience-column',
        render: (_, record: IResponseReport23) => {
          const isExist = record?.feedbacks?.find(itm =>
            isEqual(itm?.title, FeedbackSurveyReport23Enum?.SERVICE_EXPERIENCE),
          )
          return (
            <BaseRow justify={'center'} align={'middle'}>
              {isExist ? (
                <BaseTooltip
                  children={
                    <CloseOutlined
                      rev={undefined}
                      style={{
                        color:
                          convertedVariables?.[geColorFeedback(record?.rate)],
                      }}
                    />
                  }
                  title={t(getTextFeedback(record?.rate))}
                  trigger={'hover'}
                />
              ) : (
                <BaseCol />
              )}
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IResponseReport23>({
        title: t(R.strings.facilities),
        key: '_id',
        className: 'facilities-column',
        render: (_, record: IResponseReport23) => {
          const isExist = record?.feedbacks?.find(itm =>
            isEqual(itm?.title, FeedbackSurveyReport23Enum?.FACILITIES),
          )
          return (
            <BaseRow justify={'center'} align={'middle'}>
              {isExist ? (
                <BaseTooltip
                  children={
                    <CloseOutlined
                      rev={undefined}
                      style={{
                        color:
                          convertedVariables?.[geColorFeedback(record?.rate)],
                      }}
                    />
                  }
                  title={t(getTextFeedback(record?.rate))}
                  trigger={'hover'}
                />
              ) : (
                <BaseCol />
              )}
            </BaseRow>
          )
        },
      }),
    ]
    return option
  }, [t, filterDebounce])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFormFilter) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    handleChangeFilter,
    handleExportExcel,
    isHavePermissionExport,
    isJustHavePermissionsReadReport23ByClinicsManagement,
    hideClinicFilter,
  }
}
