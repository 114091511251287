export default {
  see_more: 'Xem thêm',
  choose: 'Chọn',
  find: 'Tìm kiếm',
  address: 'Địa chỉ',
  language: 'Ngôn ngữ',
  vietnamese: 'Tiếng việt',
  english: 'English',
  foreign_language: 'Ngoại ngữ',
  home: 'Home',
  phone: 'Số điện thoại',
  email: 'Email',
  send: 'Gửi',
  next: 'Tiếp theo',
  back: 'Quay lại',
  send_code: 'Gửi mã',
  login: 'Đăng nhập',
  user_name: 'Tên đăng nhập',
  password: 'Mật khẩu',
  login_success: 'Đăng nhập thành công',
  forgot_password: 'Quên mật khẩu',
  require_field: 'Vui lòng nhập "{{field}}", đây là trường bắt buộc',
  required: 'Bắt buộc',
  minimum_field: 'Vui lòng nhập tối thiểu {{count}} kí tự',
  maximum_field: 'Vui lòng nhập tối đa {{count}} kí tự',
  register: 'Đăng kí',
  confirm_password: 'Xác nhận mật khẩu',
  verify_by_phone: 'Xác thực bằng số điện thoại',
  verify_by_email: 'Xác thực bằng số email',
  code_verify: 'Mã xác thực',
  message_verify_phone:
    'Bạn vui lòng kiểm tra trong tin nhắn Zalo của số điện thoại {{phone}} để lấy mã xác thực.',
  message_verify_email:
    'Bạn vui lòng kiểm tra trong hộp thư (bao gồm cả thư rác) của email "{{email}}" để lấy mã xác thực.',
  re_send_verify_code: 'Gửi lại mã xác thực',
  resend: 'Gửi lại',
  time_resend_verify_code: 'Gửi lại sau {{timer}} giây',
  verify: 'Xác thực',
  logout: 'Đăng xuất',
  full_name: 'Họ và tên',
  regex_name: 'Tên cần đúng định dạng',
  regex_user_name: 'User name cần đúng định dạng (Không bao gồm dấu "cách")',
  using_by: 'Được sử dụng bởi',
  require_email: 'Vui lòng nhập email',
  require_contact_name: 'Vui lòng nhập tên người liên hệ',
  regex_email: 'Vui lòng nhập đúng định dạng email',
  regex_phone: 'Vui lòng nhập đúng định dạng số điện thoại',
  send_contact_success:
    'Đã gửi thông tin liên hệ thành công, cảm ơn quý khách.',
  please_enter_correct_format: 'Vui lòng  định dạng',
  medical_examination: 'Khám bệnh',
  hr: 'Nhân sự',
  finance: 'Tài chính',
  inventory_management: 'Quản lý kho',
  purchasing_management: 'Quản lý mua hàng',
  customer_care: 'Chăm sóc khách hàng',
  clinic_management: 'Quản lý phòng khám',
  management: 'Quản lý',
  doctor: 'Bác sĩ',
  company: 'Công ty',
  organizational_chart: 'Sơ đồ tổ chức',
  announcement: 'Thông báo',
  internal_news: 'Tin nội bộ',
  onboarding: 'OnBoard',
  report: 'Báo cáo',
  marketing: 'Marketing',
  accounting: 'Kế toán',
  work_schedule: 'Lịch làm việc',
  attendance_record: 'Chấm công',
  mailbox: 'Hộp thư',
  my_requests: 'Yêu cầu của tôi',
  my_tasks: 'Công việc của tôi',
  my_income: 'Thu nhập của tôi',
  dashboard: 'Dashboard',
  profile: 'Thông tin cá nhân',
  read_all: 'Đánh dấu đọc tất cả',
  view_all: 'Xem tất cả',
  remember_me: 'Ghi nhớ',
  parkway_dental: 'Nha khoa Parkway',
  description_parkway_auth:
    'Nha khoa Parkway là hệ thống nha khoa toàn diện và chuyên nghiệp, đặc biệt về chỉnh nha và implant, đáp ứng trọn vẹn nhu cầu nha khoa của khách hàng.',
  development: 'Đang phát triển',
  customers: 'Khách hàng',
  region: 'Khu vực',
  revenue: 'Doanh thu',
  total_revenue: 'Tổng doanh thu',
  pending_revenue: 'Doanh thu ước đoán',
  actual_revenue: 'Thực thu',
  compared_to_last_month: 'So với tháng trước',
  today_revenue: 'Doanh thu hôm nay',
  branch_name: 'Tên chi nhánh',
  overall_today_summary: 'Tổng quan hôm nay',
  overview: 'Tổng quan',
  area: 'Khu vực',
  clinic: 'Phòng khám',
  department: 'Phòng ban',
  ordinal_number: 'STT',
  notification: 'Thông báo',
  not_done: 'Chưa làm',
  done: 'Hoàn thành',
  in_progress: 'Đang làm',
  see_detail: 'Xem chi tiết',
  mail: 'Thư',
  new_mail: 'Thư mới',
  important: 'Quan trọng',
  show_less: 'Đọc tiếp',
  collapse: 'Thu gọn',
  last_time_update: 'Cập nhật gần nhất',
  service: 'Dịch vụ',
  report_001: 'Report 001 - Đo lường chuyển đổi từ số lượng Lead sang Check in',
  report_002: 'Report 002 - Đo lường hiệu quả tư vấn của CSKH mới (Team Call)',
  report_003: 'Report 003 - Đo lường hiệu quả tư vấn của CSKH cũ (Team Call)',
  report_004: 'Report 004 - Đo lường hiệu quả chốt của phòng khám',
  report_005:
    'Report 005 - Báo cáo doanh thu từng chi nhánh theo ngày-tuần-tháng',
  report_006:
    'Report 006 - Báo cáo doanh thu từng chi nhánh theo nhóm sản phẩm',
  report_007: 'Report 007 - Báo cáo doanh thu theo nguồn khách hàng',
  report_008: 'Report 008 - Top 10 sản phẩm cả hệ thống',
  report_009: 'Report 009 - Báo cáo nhóm người dùng theo sản phẩm',
  report_010: 'Report 010 - Báo cáo nhóm người dùng theo khu vực',
  report_011: 'Report 011 - Top người dùng chi tiêu nhiều',
  report_012: 'Report 012 - P&L',

  report_001_description:
    'Báo cáo đo lường chuyển đổi từ số lượng Lead sang Check in',
  report_002_description:
    'Báo cáo đo lường hiệu quả tư vấn của CSKH mới (Team Call)',
  report_003_description:
    'Báo cáo đo lường hiệu quả tư vấn của CSKH cũ (Team Call)',
  report_004_description: 'Báo cáo đo lường hiệu quả chốt của phòng khám',
  report_005_description:
    'Báo cáo doanh thu từng chi nhánh theo ngày-tuần-tháng',
  report_006_description: 'Báo cáo doanh thu từng chi nhánh theo nhóm sản phẩm',
  report_007_description: 'Báo cáo doanh thu theo nguồn khách hàng',
  report_008_description: 'Báo cáo Top 10 sản phẩm cả hệ thống',
  report_009_description: 'Báo cáo nhóm người dùng theo sản phẩm',
  report_010_description: 'Báo cáo nhóm người dùng theo khu vực',
  report_011_description: 'Báo cáo Top người dùng chi tiêu nhiều',
  report_012_description: 'Báo cáo P&L',

  budget: 'Ngân sách',
  spent_actual: 'Thực chi',
  percent_budget_using: '% Đã chi',
  planing: 'Kế hoạch',
  actuals: 'Thực đạt',
  cpql: 'CPQL',
  price: 'Giá',
  percent_complete_target_api: '% Đạt KPI',
  number_checkin: 'Số check in',
  percent_achievement: '% Đạt',
  number_q_lead: 'Số Qlead',
  see_with: 'Xem theo',
  week: 'Tuần',
  choose_date: 'Chọn ngày',
  choose_week: 'Chọn tuần',
  document: 'Tài liệu tập tin',
  name_employee: 'Tên nhân viên',
  number_date_re_cared: 'Số data chăm sóc lại',
  number_off_appointments_scheduled: 'Số đặt lịch hẹn',
  number_check_in_achieved: 'Số check in thực đạt',
  crv_1: 'CVR1',
  crv_1_period: 'CRV1 cùng kì',
  crv_2: 'CVR2',
  crv_2_period: 'CRV2 cùng kì',
  crv_1_growth_in_period: 'Tỉ lệ tăng trưởng CVR1 so với cùng kỳ',
  crv_2_growth_in_period: 'Tỉ lệ tăng trưởng CVR2 so với cùng kỳ',
  number_of_cases_closed: 'Số ca chốt',
  crv3: 'CVR3 hiện tại',
  crv3_with_period: 'CVR3 cùng kỳ',
  percent_crv3_growth_in_period: 'Tỉ lệ tăng trưởng CVR3 so với cùng kỳ',
  number_of_check_ins: 'Số lượt check in',
  week1: 'Tuần 1',
  week2: 'Tuần 2',
  week3: 'Tuần 3',
  week4: 'Tuần 4',
  week_no: 'Tuần {{week}}',
  week_no_of_month: 'Tuần {{week}} tháng {{month}}',
  total_revenue_month: 'Tổng doanh thu tháng {{month}}',
  total_revenue_target: 'Tổng doanh thu kế hoạch',
  percent_of_target_complete: '% Đạt được',
  month1: 'Tháng 1',
  month2: 'Tháng 2',
  month3: 'Tháng 3',
  month4: 'Tháng 4',
  month5: 'Tháng 5',
  month6: 'Tháng 6',
  month7: 'Tháng 7',
  month8: 'Tháng 8',
  month9: 'Tháng 9',
  month10: 'Tháng 10',
  month11: 'Tháng 11',
  month12: 'Tháng 12',
  month_no: 'Tháng {{month}}',
  total_revenue_year: 'Tổng doanh thu năm {{year}}',
  group_product: 'Nhóm sản phẩm',
  a1_implant: 'A1 - Implant',
  a2_implant: 'A2 - Implant',
  a3_implant: 'A3 - Implant',
  total_revenue_actual: 'Tổng doanh thu thực tế',
  total_revenue_projected: 'Tổng doanh thu ước đoán',
  total_revenue_planned: 'Tổng doanh thu kế hoạch',
  total_revenue_achieved: 'Tổng doanh thu thực hiện được',
  group_customer: 'Nhóm khách hàng',
  revenue_week: 'Doanh thu tuần {{week}}',
  hcm_area: 'Khu vực Hồ Chí Minh',
  hanoi_area: 'Khu vực Hà Nội',
  digital_ad: 'Quảng cáo digital',
  percentage_of_total_revenue: '% Trên tổng doanh thu',
  system_wide: 'Cả hệ thống',
  product: 'Sản phẩm',
  quarter_in_year: 'Quý trong năm',
  gender: 'Giới tính',
  age: 'Độ tuổi',
  cases_in_product_group: 'Số ca thuộc nhóm sản phẩm',
  fixed_braces: 'Chỉnh nha cố định',
  other_products: 'Sản phẩm khác',
  revenue_of_product_group: 'Doanh thu nhóm sản phẩm',
  cases_in_area: 'Số ca thuộc khu vực',
  revenue_of_area: 'Doanh thu thuộc khu vực',
  province: 'Tỉnh',
  telesales_cared: 'Telesales chăm sóc',
  other_customer: 'Khách hàng khác',
  old_customer: 'Khách hàng cũ',
  import_budget_week: 'Import ngân sách theo tuần',
  import_budget_month: 'Import ngân sách theo tháng',
  import_qlead_actual_week: 'Import Số QLead Actuals theo tuần',
  import_qlead_actual_month: 'Import Số QLead Actuals theo tháng',
  import_cpql_numbers_week: 'Import số CPQL theo tuần',
  import_cpql_numbers_month: 'Import số CPQL theo tháng',
  import_weekly_appointment_schedule: 'Import Số lịch hẹn planning theo tuần',
  appointment_schedule: 'Số lịch hẹn kế hoạch',
  check_in_schedule: 'Số kế hoạch check in',
  import_monthly_appointment_schedule: 'Import Số lịch hẹn planning theo tháng',
  import_cost_of_goods_sold_and_selling_expenses_weekly:
    'Giá vốn hàng bán và chi phí bán hàng theo tuần',
  cost_of_goods_sold_and_selling_expenses:
    'Giá vốn hàng bán và chi phí bán hàng',
  marketing_plan: 'Kế hoạch Marketing',
  import_cost_of_goods_sold_and_selling_expenses_monthly:
    'Import giá vốn hàng bán và chi phí bán hàng theo tháng',
  import_weekly_revenue: 'Kế hoạch doanh thu theo tuần',
  import_monthly_revenue: 'Kế hoạch doanh thu theo tháng',
  import: 'Import',
  from_date: 'Từ ngày',
  to_date: 'Đến ngày',
  start_date: 'Ngày bắt đầu',
  end_date: 'Ngày kết thúc',
  number_planned: 'Số Plan',
  number_actual: 'Số Actual',
  export_excel: 'Xuất Excel',
  download_example_import_file: 'Tải mẫu file import',
  upload: 'Tải lên',
  cp_nvl_live: 'CP NVL Trực tiếp',
  doctor_department: 'Bộ phận bác sĩ',
  nursing_department: 'Bộ phận y tá',
  advisory_department: 'Bộ phận tư vấn',
  x_ray_technical_department: 'Bộ phận X-ray technical',
  reception_department: 'Bộ phận lễ tân',
  security_and_housekeeping_department: 'Bộ phận tạp vụ bảo vệ',
  initial_construction: 'Xây dựng ban đầu',
  machinery_and_equipment: 'Máy móc thiết bị',
  rental_expenses: 'Chi phí tiền thuê',
  location_rental_tax_expenses: 'Chi phí thuế thuê địa điểm',
  other_operating_expenses: 'Các chi phí vận hành khác',
  card_swipe_installment_fees: 'Phí quẹt thẻ - trả góp',
  others_cosmetics: 'Khác (thẩm mỹ)',
  plan_number: 'Số plan',
  performance: 'Performance',
  branch: 'Chi nhánh',
  call_center: 'Call center',
  ccdc: 'CCDC',
  location_rental_expenses: 'Chi phí thuê địa điểm',
  depreciation_expenses: 'Chi phí khấu hao TSCĐ/CCDC',
  salary_expenses_allowances: 'Chi phí lương, phụ cấp...',
  cost_of_goods_sold: 'Giá vốn hàng bán',
  selling_expenses: 'Chi phí bán hàng',
  download_successful: 'Download thành công',
  import_file_successful: 'Import file thành công',
  import_file_error: 'Import file lỗi',
  month: 'Tháng',
  import_file: 'Import file',
  created: 'Mới',
  requested: 'Đã giao',
  recall: 'Gọi lại',
  confirm_customer: 'Xác nhận khách hàng',
  confirmed: 'Đã xác nhận',
  cancel: 'Hủy',
  orthodontic: 'Mắc cài',
  general_treatment: 'Điều trị tổng quát',
  treatments: 'Dịch vụ',
  orthodontic_correction: 'Chỉnh nha',
  invisalign: 'Invisalign',
  implant: 'Implant',
  number_of_cases_in_the_region: 'Số ca thuộc khu vực',
  revenue_in_the_region: 'Doanh số thuộc khu vực',
  hochiminh: 'Hồ Chí Minh',
  hanoi: 'Hà Nội',
  annual_expenditure_amount: 'Số tiền chi tiêu trong năm',
  number_of_purchases: 'Số lần mua hàng',
  average_order_value: 'Giá trị trung bình đơn hàng',
  groupA1: 'Nhóm A1',
  groupA2: 'Nhóm A2',
  groupA3: 'Nhóm A3',
  groupA4: 'Nhóm A4',
  number_of_service_uses: 'Số lần sử dụng dịch vụ',
  number_new_lead: 'Số lead mới',
  compared_to_last_day: 'So với hôm qua',
  year: 'Năm',
  from_then_util_now: 'Từ trước đến nay',
  quarter: 'Quý',
  quarter_num: 'Quý {{quarter}}',
  male: 'Nam',
  female: 'Nữ',
  other: 'Khác',
  all_system: 'Toàn hệ thống',
  porcelain_cosmetic_dentistry: 'Răng sứ thẩm mỹ',
  customer_care_plan: 'Kế hoạch chăm sóc khách hàng',
  number_customer_re_care: 'Số khách hàng chăm sóc lại',
  number_qlead_actual: 'Số QLead Actuals',
  account_management: 'Quản lý tài khoản',
  employee_id: 'Mã nhân viên',
  account_name: 'Tên tài khoản',
  status: 'Trạng thái',
  active: 'Đang hoạt động',
  deactive: 'Ngừng hoạt động',
  deleted: 'Đã xoá',
  error: 'Lỗi',
  search: 'Tìm kiếm',
  all: 'Tất cả',
  delete: 'Xoá',
  update: 'Cập nhật',
  add_account: 'Thêm tài khoản',
  first_name: 'Họ',
  last_name: 'Tên',
  enter_field: 'Nhập {{field}}',
  please_select_status: 'Vui lòng chọn trạng thái',
  inactive: 'Không hoạt động',
  update_account: 'Cập nhật tài khoản',
  action: 'Hành động',
  update_account_id_success: 'Cập nhật tài khoản {{account}} thành công',
  delete_account: 'Xoá tài khoản',
  are_you_want_delete_account: `Bạn xác nhận xoá tài khoản`,
  profile_title: 'Profile',
  please_select_profile: 'Chọn profile',
  once_the_account_is_locked_it_cannot_be_recovered:
    'Tài khoản sau khi khoá sẽ không thể khôi phục lại',
  please_select_role: 'Vui lòng chọn cấp bậc',
  job_title: 'Quản lý chức danh',
  update_time: 'Thời gian cập nhật',
  job_title_content: {
    management: 'Quản lý chức danh',
    description: 'Mô tả',
    name: 'Tên',
    add_job_title: 'Thêm chức danh',
    job_title_management: 'Quản lý trực tiếp',
    id: 'ID Chức danh',
    name_create: 'Tên chức danh',
    please_select_job_title_management: 'Chọn quản lý',
    are_you_want_delete_job_title: 'Bạn xác nhận xoá chức danh',
    once_the_job_title_is_locked_it_cannot_be_recovered:
      'Chức danh sau khi khoá sẽ không thể khôi phục lại',
  },
  job_title_field: {
    job_title_string_id: 'code',
    name: 'name',
    description: 'description',
    status: 'status',
    role: 'role',
    job_title_management: 'jobTitleManagement',
  },
  job_title_level: 'Quản lý cấp bậc',
  job_title_level_content: {
    title: 'Cấp bậc',
    management: 'Quản lý cấp bậc',
    description: 'Mô tả',
    name: 'Tên',
    add: 'Thêm cấp bậc',
    id: 'ID Cấp bậc',
    name_create: 'Tên cấp bậc',
    are_you_want_delete: 'Bạn xác nhận xoá cấp bậc',
    once_the_job_title_level_is_locked_it_cannot_be_recovered:
      'Cấp bậc sau khi khoá sẽ không thể khôi phục lại',
  },
  job_title_level_field: {
    job_title_level_string_id: 'code',
    name: 'name',
    description: 'description',
    status: 'status',
    job_title_level_id: 'jobTitleLevelId',
  },
  unit: 'Quản lý phòng ban',
  unit_content: {
    title: 'phòng ban',
    create_unit_child: 'Tạo phòng ban con',
    unit_parent: 'Phòng ban trực thuộc',
    management: 'Quản lý phòng ban',
    unit_department: 'Khối',
    job_title_management: 'Quản lý trực tiếp',
    unitTypeId: 'Loại phòng ban',
    description: 'Mô tả',
    name: 'Tên',
    add: 'Thêm phòng ban',
    id: 'ID Phòng ban',
    please_select_unit_department: 'Chọn khối',
    please_select_unitTypeId: 'Chọn loại phòng ban',
    please_select_unit_job_title_management: 'Chọn quản lý',
    name_create: 'Tên phòng ban',
    are_you_want_delete: 'Bạn xác nhận xoá phòng ban',
    once_the_unit_is_locked_it_cannot_be_recovered:
      'Phòng ban sau khi khoá sẽ không thể khôi phục lại',
  },
  unit_field: {
    name: 'Tên phòng ban',
    description: 'Mô tả',
    status: 'Trạng thái',
    unit_string_id: 'Mã',
    unit_department_string_id: 'Khối',
    unitTypeId: 'Loại',
    job_title_management: 'Quản lý trực tiếp',
  },
  account: 'Tài khoản',
  create_profile: 'Thêm nhân viên',
  update_profile: 'Cập nhật nhân viên',
  profile_content: {
    title: 'nhân viên',
    management: 'Quản lý nhân viên',
    profile_department: 'Khối',
    job_title: 'Chức danh',
    description: 'Mô tả',
    firstname: 'Họ',
    lastname: 'Tên',
    email: 'Email',
    phone: 'Số điện thoại',
    address: 'Địa chỉ',
    add: 'Thêm nhân viên',
    name: 'Tên',
    id: 'ID nhân viên',
    mobile: 'Số điện thoại',
    employeeid: 'Mã nhân viên',
    employee_old_id: 'Mã nhân viên cũ',
    job_title_main_column: 'Chức danh chính',
    job_title_secondary_column: 'Chức danh kiêm nhiệm',
    job_title_main: '1. Chức danh chính',
    job_title_secondary: '2. Chức danh kiêm nhiệm',
    job_title_secondary_child: 'Chức danh kiêm nhiệm',
    please_select_department: 'Chọn phòng ban',
    please_select_job_title: 'Chọn chức danh',
    please_select_account: 'Chọn tài khoản',
    name_create: 'Tên nhân viên',
    info_section: 'Thông tin nhân viên',
    job_title_section: 'Chức danh nhân viên',
    are_you_want_delete: 'Bạn xác nhận xoá nhân viên',
    create_profile_success: 'Thêm nhân viên thành công',
    once_the_profile_is_locked_it_cannot_be_recovered:
      'Nhân viên sau khi khoá sẽ không thể khôi phục lại',
    add_work_info_main: 'Thêm chức danh chính',
    add_work_info_aka: 'Thêm chức danh kiêm nhiệm',
    option_create: 'Tạo tài khoản',
    option_select_id: 'Đã có tài khoản',
    option_non: 'Không tạo tài khoản',
    skill: 'Kỹ năng nhân viên',
    add_skill: 'Thêm kỹ năng',
    please_select_skill: 'Chọn kỹ năng',
    please_fill_skill: 'Nhập kỹ năng',
    industry: 'Loại',
    add_industry: 'Thêm loại',
    please_select_industry: 'Chọn loại',
    please_fill_industry: 'Nhập loại',
    level: 'Cấp độ',
    add_level: 'Thêm cấp độ',
    please_select_level: 'Chọn cấp độ',
    please_fill_level: 'Nhập cấp độ',
    degree: 'Bằng cấp nhân viên',
    add_degree: 'Thêm bằng cấp',
    please_select_degree: 'Chọn bằng cấp',
    degree_classification: 'Xếp loại bằng cấp',
    add_degree_classification: 'Thêm xếp loại bằng cấp',
    please_select_degree_classification: 'Chọn xếp loại',
  },
  profile_field: {
    name: 'name',
    email: 'email',
    mobile: 'mobile',
    status: 'status',
    account: 'accountid',
    last_name: 'lastname',
    first_name: 'firstname',
    employeeid: 'employeeid',
    employee_old_id: 'employee_old_id',
    description: 'description',
    profileTypeId: 'profileTypeId',
    profile_string_id: 'profileStringId',
    profile_department_string_id: 'profileDepartmentStringId',
    skill: 'skill',
    level: 'level',
    industry: 'industry',
    classification: 'classification',
    workinfo_unitid: 'workinfo_unitid',
    workinfo_job_title: 'workinfo_jobtitleid',
    workinfo_start_date: 'workinfo_startdate',
    workinfo_end_date: 'workinfo_enddate',
    workinfo_status: 'workinfo_status',
  },
  projected_revenue: 'Doanh thu ước đoán',
  survey_report: 'Báo cáo đánh giá chất lượng dịch vụ',
  survey_report_content: {
    star: 'sao',
    by_day: 'Đánh giá chất lượng dịch vụ trung bình theo ngày',
    review_type_need_to_impove: 'Dịch vụ cần cải thiện',
    next_promotor_score: 'Đánh giá điểm NPS (Net Promotor Score)',
    chart_by_day_label_header: '/ Trung bình các phòng khám tại khu vực',
    chart_by_day_label_footer: 'Tổng lượt đánh giá',
    excellent: 'Xuất sắc',
    good: 'Tốt',
    middle: 'Tạm ổn',
    bad: 'Kém',
    select_area: 'Tất cả khu vực',
    select_clinic: 'Tất cả phòng khám',
    review: 'Đánh giá',
    unReview: 'Không đánh giá',
    nps_average: 'Đánh giá trung bình',
    nps_choosen: 'Khu vực đã chọn',
    date: 'Ngày',
    start_no: 'Số sao',
  },
  marketing_bp: 'BP Marketing',
  operation_bp: 'BP vận hành',
  training_bp: 'BP đào tạo',
  hr_bp: 'BP nhân sự',
  accounting_bp: 'BP kế toán',
  purchasing_bp: 'BP mua hàng',
  other_expenses: 'Chi phí khác',
  other_income: 'Thu nhập khác',
  interest_on_deposits: 'Lãi tiền gửi',
  other_costs: 'Chi phí khác',
  business_management_expenses: 'Chi phí quản lý doanh nghiệp',
  other_profits: 'Lợi nhuận khác',
  download_excel_success: 'Tải file excel thành công',
  skill: 'Quản lý kỹ năng',
  skill_content: {
    title: 'Kỹ năng',
    management: 'Quản lý kỹ năng',
    description: 'Mô tả',
    industry: 'Loại',
    levelSkill: 'Cấp độ',
    rangeExperience: 'Năm kinh nghiệm',
    name: 'Tên',
    add: 'Thêm kỹ năng',
    name_create: 'Tên kỹ năng',
    are_you_want_delete: 'Bạn xác nhận xoá kỹ năng',
    exists_skill: 'Kỹ năng đã tồn tại',
    once_the_skill_is_locked_it_cannot_be_recovered:
      'Kỹ năng sau khi xoá sẽ không thể khôi phục lại',
    select_industry: 'Chọn loại',
    select_level_skill: 'Chọn vị trí',
    select_range_experience: 'Chọn số năm',
  },
  skill_field: {
    name: 'name',
    industry: 'Loại',
    levelSkill: 'Cấp độ',
    rangeExperience: 'Năm kinh nghiệm',
    status: 'status',
  },
  degree: 'Quản lý bằng cấp',
  degree_content: {
    title: 'Bằng cấp',
    management: 'Quản lý bằng cấp',
    see_degree: 'Xem bằng cấp',
    description: 'Mô tả',
    industry: 'Loại',
    start: 'Ngày cấp',
    end: 'Ngày hết hạn',
    has_end: 'Có thời hạn',
    no_end: 'Vô thời hạn',
    name: 'Tên',
    add: 'Thêm bằng cấp',
    name_create: 'Tên bằng cấp',
    placehoder_upload_document:
      'Tải lên file của bạn ( Định dạng file pdf,docx. Kích thước tối đa 20 MB )',
    placeholder_upload_document:
      'Tải lên file của bạn ( Định dạng file pdf,docx. Kích thước tối đa 20 MB )',
    are_you_want_delete: 'Bạn xác nhận xoá bằng cấp',
    once_the_degree_is_locked_it_cannot_be_recovered:
      'Bằng cấp sau khi xoá sẽ không thể khôi phục lại',
    select_industry: 'Chọn loại',
  },
  degree_field: {
    name: 'Tên',
    degree: 'Bằng cấp',
    industry: 'Loại',
    status: 'Trạng thái',
    degree_classification: 'Xếp loại',
  },
  degree_classification: {
    excellent: 'Giỏi',
    good: 'Khá',
    average_good: 'Trung bình khá',
    average: 'Trung bình',
    below_average: 'Dưới trung bình',
    weak: 'Yếu',
    unknown: 'Không rõ',
  },
  check_in_planing: 'Check in kế hoạch',
  report_group: {
    customer_care: 'Nhóm chăm sóc khách hàng',
    marketing: 'Nhóm marketing',
    operation: 'Nhóm vận hành',
    accounting: 'Nhóm kế toán',
    import_data: 'Nhập dữ liệu',
    debt_management: 'Quản lý công nợ',
    report: 'Báo cáo',
  },
  check_in_customer_plan: 'Kế hoạch số khách hàng check in',
  manage_customer_debt: 'Quản lý công nợ khách hàng',
  manage_customer_debt_remain: 'Còn lại',
  manage_customer_patient_code: 'Mã khách hàng',
  manage_customer_patient_name: 'Tên',
  manage_customer_debt_payment_total: 'Tổng phải thanh toán',
  manage_customer_debt_deposit_total: 'Tổng cọc',
  manage_customer_debt_refund_total: 'Tổng hoàn tiền',
  manage_customer_debt_paid_total: 'Tổng đã thanh toán',
  manage_customer_debt_remain_total: 'Còn lại',
  manage_customer_payment_type: 'Loại thanh toán',
  manage_customer_clinic_name: 'Phòng khám',
  manage_customer_debt_treatment: 'Tên gói',
  manage_customer_debt_treatment_total: 'Số tiền của gói',
  manage_customer_debt_treatment_date: 'Ngày phát sinh gói',
  manage_customer_debt_tip_end_name: 'Tên thủ thuật kết thúc',
  manage_customer_debt_tip_end_date: 'Ngày kết thúc',
  manage_customer_choose_paid_type: 'Chọn loại thanh toán',
  manage_customer_debt_choose_treatment: 'Chọn gói',
  manage_customer_debt_choose_tip: 'Chọn Thủ thuật',
  manage_customer_tip_start_date: 'Ngày bắt đầu',
  deposit: 'Cọc',
  installment: 'Trả góp',
  one_time_payment: 'Thanh toán 1 lần',
  no_paid: 'Chưa phát sinh',
  booking: 'Xử lý yêu cầu đặt lịch hẹn của quản lý Call Center',
  booking_content: {
    title: 'Call center',
    management: 'Xử lý yêu cầu đặt lịch hẹn của quản lý Call Center',
    description: 'Mô tả',
    name: 'Tên',
    phone: 'Số điện thoại',
    treatments: 'Dịch vụ',
    area: 'Khu vực',
    clinic: 'Phòng khám',
    doctor: 'Bác sĩ',
    date: 'Ngày khám',
    time: 'Giờ khám',
    note: 'Ghi chú',
    callCenter: 'Nhân viên call center',
    calling: 'Bạn đang gọi cho {{name}}',
    add: 'Tạo lịch hẹn',
    name_create: 'Tên lịch hẹn',
    are_you_want_delete: 'Bạn xác nhận xoá lịch hẹn',
    exists_booking: 'Call center đã tồn tại',
    once_the_booking_is_locked_it_cannot_be_recovered:
      'Call center sau khi xoá sẽ không thể khôi phục lại',
    profile_call_center: 'Nhân viên call center',
    select_profile_call_center: 'Chọn nhân viên',
  },
  booking_field: {
    name: 'name',
    phone: 'Số điện thoại',
    treatments: 'Dịch vụ',
    clinic: 'Phòng khám',
    doctor: 'Bác sĩ',
    callCenter: 'Nhân viên call center',
    status: 'status',
    note: 'Ghi chú',
    area: 'Khu vực',
    date: 'Ngày khám',
    time: 'Giờ khám',
  },
  process_appointment_requests: 'Xử lý yêu cầu đặt lịch hẹn',
  create_appointment: 'Tạo lịch hẹn',
  BS: 'Bs.',
  day: 'Ngày',
  appointment_note: 'Chú thích',
  confirm: 'Xác nhận',
  guest_cancels_appointment: 'Khách hủy đặt lịch',
  confirm_guest_cancels_appointment: 'Xác nhận huỷ thông tin đặt lịch ',
  confirm_describe_guest_cancels_appointment:
    ' . Thông tin đặt lịch sẽ bị huỷ. (Hủy có thể khôi phục lại)',
  schedule_booking: 'Đặt lịch hẹn',
  patient_name: 'Tên bệnh nhân',
  patient_name_required: 'Vui lòng nhập tên bệnh nhân',
  patient_name_placeholder: 'Nhập họ tên',

  phone_label: 'Số điện thoại',
  phone_label_required: 'Vui lòng nhập số điện thoại',
  phone_label_placeholder: 'Nhập số điện thoại',

  area_label: 'Khu vực',
  area_label_required: 'Vui lòng chọn khu vực',
  area_label_placeholder: 'Chọn khu vực',

  clinic_label: 'Phòng khám',
  clinic_label_required: 'Vui lòng chọn phòng khám',
  clinic_label_placeholder: 'Chọn phòng khám',

  service_label: 'Dịch vụ',
  service_label_required: 'Vui lòng chọn dịch vụ',
  service_label_placeholder: 'Chọn dịch vụ',

  treatment_label: 'Thủ thuật',
  treatment_label_required: 'Vui lòng chọn thủ thuật',
  treatment_label_placeholder: 'Chọn thủ thuật',

  reason_label: 'Lý do',
  reason_label_required: 'Vui lòng nhập lý do',
  reason_label_placeholder: 'Nhập lý do',

  duration_label: 'Thời lượng (Phút)',
  duration_label_required: 'Vui lòng chọn thời lượng',
  duration_label_placeholder: 'Chọn thời lượng',

  note_max_length: 'Vượt quá ký tự',
  number_of_character: 'Số ký tự',

  doctor_label: 'Bác sĩ',
  doctor_label_required: 'Vui lòng chọn bác sĩ',
  doctor_label_placeholder: 'Chọn bác sĩ',

  time_label: 'Giờ khám',
  time_label_required: 'Vui lòng chọn giờ khám',
  time_label_placeholder: 'Chọn giờ khám',

  day_label: 'Ngày khám',
  day_label_required: 'Vui lòng chọn ngày khám',
  day_label_placeholder: 'Chọn ngày khám',

  status_label: 'Trạng thái',
  status_label_required: 'Vui lòng chọn trạng thái',
  status_label_placeholder: 'Chọn trạng thái',

  SA: '(Sáng)',
  CH: '(Chiều)',

  morning: 'Sáng',
  afternoon: 'Chiều',

  note_label: 'Ghi chú',
  note_label_placeholder: 'Nhập nội dung',
  customer_cancel: 'Khách hủy',

  created_by: 'Tạo bởi',
  schedule_info: 'Thông tin lịch hẹn',
  update_info: 'Thông tin cập nhật',
  today: 'Hôm nay',

  search_doctor: 'Tìm bác sĩ',
  all_doctor: 'Tất cả bác sĩ',
  BOOKING_EXISTS: 'Lịch hẹn đã tồn tại',
  BOOKING_DOES_NOT_EXIST: 'Lịch hẹn không tồn tại',
  schedule_appointment: 'Đặt lịch hẹn',
  manage_appointment: 'Quản lý lịch hẹn',
  limit_date: 'Giới hạn 4 tháng',
  select_range_date_required: 'Vui lòng chọn ngày bắt đầu và ngày kết thúc',
  num_kpi: 'KPI',
  check_in_service_plan: 'Kế hoạch số khách hàng check in bởi dịch vụ',
  kpi_plan: 'KPI kế hoạch',
  kpi_actual: 'KPI thực tế',
  search_appointment: 'Tìm kiếm lịch hẹn',
  search_appointment_s_label: 'Nhập họ tên, SĐT bênh nhân....',
  search_appointment_choose_clinic: 'Tất cả phòng khám',
  search_appointment_date_time: 'Ngày & Giờ',
  search_appointment_patient: 'Bệnh nhân',
  search_appointment_dob: 'Ngày sinh',
  search_appointment_doctor: 'Bác sĩ phụ trách buổi hẹn',
  search_appointment_location: 'Địa điểm',
  search_appointment_treatment: 'Điều trị',
  search_appointment_send_sms: 'Gửi SMS (lần)',
  search_appointment_in: 'In',
  add_schedule: 'Thêm lịch hẹn',
  update_schedule: 'Cập nhật lịch hẹn',
  total_schedule: 'Tổng lịch hẹn: ',
  search_appointment_send_all: 'Gửi SMS nhắc lịch cho tất cả danh sách',
  search_appointment_updated_by: 'Cập nhật bởi',
  sms_successfully: 'Gửi tin nhắn thành công',
  search_appointment_doctor_shift: 'Bác sĩ phụ trách ca',
  created_at: 'Ngày tạo',
  report_18: 'Report 018 - Báo cáo tình hình khách hàng hưởng khuyến mãi',
  report_18_description: 'Báo cáo tình hình khách hàng hưởng khuyến mãi',
  enter_keyword_search: 'Nhập từ khóa tìm kiếm',
  apply_date: 'Thời gian áp dụng',
  discount_amount: 'Số lượng giảm giá',
  customer_name: 'Tên khách hàng',
  campaign: 'Chiến dịch',
  percent: '%',
  limit_discount_management: 'Quản lý giới hạn khuyến mãi',
  limit_discount: 'Giới hạn khuyến mãi',
  retail_price: 'Giá niêm yết',
  discounted_total: 'Giá sau giảm',
  report_20: 'Report 020 - Thống kê lịch hẹn bác sĩ',
  report_20_description: 'Thống kê lịch hẹn bác sĩ',
  referral_code: 'Mã giới thiệu',
  employee_voucher: 'Voucher nhân viên',
  export_excel_success: 'Xuất excel thành công !',
  patient_search: 'Tra cứu bệnh nhân',
  service_used: 'Dịch vụ khám',
  change_info: 'Thông tin thay đổi',
  approved_day_off: 'Ngày nghỉ đã duyệt',
  new_day_off: 'Ngày nghỉ mới',
  reject_reason: 'Lý do từ chối',
  approve_DO: 'Duyệt DO',
  reject_DO: 'Từ chối DO',
  approve_change_DO: 'Duyệt đổi DO',
  reject_change_DO: 'Từ chối đổi DO',
  update_successfully: 'Cập nhật thành công',
  approve: 'Duyệt',
  furlough_time: 'Thời gian nghỉ',
  _day: 'ngày',
  linked_un_working_days: 'Đơn nghỉ phép liên quan',
  documents_images: 'Tài liệu & hình ảnh đính kèm',
  report_21: 'Report 021 - Khách hàng Referral',
  referral_source: 'Nguồn KH',
  payment_paid: 'Số tiền đã thanh toán',
  referral_to_code: 'Mã người được giới thiệu ',
  referral_from_code: 'Mã người giới thiệu',
  referral_from_name: 'Tên người giới thiệu',
  package: 'Gói dịch vụ',
  package_amount: 'Giá dịch vụ',
  change_avatar: 'Đổi ảnh đại diện',
  avatar: 'Ảnh đại diện',
  avatar_description: 'Tải lên và thay đổi ảnh đại diện cá nhân.',
  personal_info: 'Thông tin cá nhân',
  personal_info_description_update:
    'Hiển thị họ & tên, SĐT, email, địa chỉ của nhân viên cho phép cập nhật, thay đổi.',
  personal_info_description:
    'Hiển thị họ & tên, SĐT, email, địa chỉ của nhân viên.',
  _skill: 'Kỹ năng ',
  skill_description_update: 'Cập nhật, bổ sung kỹ năng của nhân viên.',
  skill_description: 'Danh sách kỹ năng của nhân viên',
  certification: 'Bằng cấp',
  certification_description: 'Bổ sung, cập nhật bằng cấp của nhân viên.',
  skill_list: 'Danh sách kỹ năng',
  certification_list: 'Danh sách bằng cấp',
  section_job_title: 'Chức danh chính & Chức danh kiêm nhiệm',
  job_title_description:
    'Hiển thị chức danh chính và chức dạnh kiêm nhiệm của nhân viên khi làm việc.',
  job_title_main_description:
    'Hiển thị chức danh chính của nhân viên khi làm việc.',
  job_title_secondary_description:
    'Hiển thị chức danh kiêm nhiệm của nhân viên khi làm việc.',
  office_manager: 'Quản lý hành chính',
  tech_manager: 'Quản lý chuyên môn',
  responsibility_manager: 'Quản lý trách nhiệm',
  office_manager_description:
    'Quản lý các vấn đề liên quan tới hành chính của nhân viên.',
  tech_manager_description: 'Quản lý về kỹ năng, chuyên môn của nhân viên.',
  responsibility_manager_description: 'Quản lý về trách nhiệm của nhân viên.',
  please_choose_require_fields: 'Vui lòng chọn tất cả trường bắt buộc',
  choose_manager: 'Chọn quản lý',
  direct_subordinate: 'Cấp dưới trực tiếp',
  indirect_subordinate: 'Cấp dưới gián tiếp',
  direct_subordinate_description:
    'Cấp dưới trực tiếp (về chuyên môn và trách nhiệm)',
  indirect_subordinate_description:
    'Cấp dưới gián tiếp (về chuyên môn và trách nhiệm)',
  direct_manager_description:
    'Quản lý trực tiếp về hành chính & chuyên môn & trách nhiệm',
  skill_group: 'Nhóm kỹ năng ',
  using_date: 'Thời hạn sử dụng',
  out_date: 'Hết hạn',
  no_expired_date: 'Vô thời hạn',
  _used: 'Đã sử dụng',
  referral_to_info: 'Thông tin người được giới thiệu (KH A)',
  referral_from_info: 'Thông tin người giới thiệu (KH B)',
  customer_A_service_used_info: 'Thông tin gói dịch vụ sử dụng của KH A',
  release_voucher_status: 'Theo dõi tình hình phát hành VOUCHER / Chi tiền',
  voucher_applied_date: 'Ngày apply mã VC',
  voucher_applied_package_name: 'Tên gói DV được apply VC',
  total_value_contract: 'Tổng giá trị hợp đồng',
  start_date_using_service: 'Ngày bắt đầu sử dụng DV',
  treatment_name_start_service: 'Tên thủ thuật ghi nhận bắt đầu DV',
  add_new_profile: 'Thêm hồ sơ mới',
  please_choose_import_file: 'Vui lòng chọn file import',
  choose_import_file: 'Chọn file import',
  enter_name_customer_code: 'Nhập tên/ mã KH để tìm kiếm',
  found: 'Đã tìm thấy',
  _result: 'kết quả',
  remain_debt: 'Công nợ còn lại của KH B',
  request_export_bill_management: 'Quản lý yêu cầu xuất hóa đơn',
  export_bill: 'Xuất hóa đơn',
  waiting_for_export: 'Chờ xuất',
  exported: 'Đã xuất',
  need_update: 'Cần cập nhật',
  show_full_list: 'Hiển thị danh sách đầy đủ',
  create_new: 'Tạo mới',
  content_reset: 'Reset',
  content_apply: 'Áp dụng',
  request_export_bill_date: 'Ngày yêu cầu',
  _patient_code: 'Mã số bệnh nhân',
  company_by_branch: 'Công ty theo chi nhánh',
  customer_info: 'THÔNG TIN KHÁCH HÀNG',
  service_use: 'Dịch vụ thực hiện',
  service_use_date: 'Ngày phát sinh dịch vụ',
  total_amount_receivable: 'Tổng số tiền phải thu',
  tax_code: 'MST',
  company_name_customer_name: 'Tên công ty/ tên KH',
  email_receive_bill: 'Email nhận hoá đơn',
  service_request_export_bill: 'DV yêu cầu xuất HĐ',
  total_amount_export_bill: 'Tổng tiền xuất HĐ',
  status_export_bill: 'TRẠNG THÁI XUẤT HOÁ ĐƠN',
  exported_bill: 'Đã xuất HĐ',
  sent_mail: 'Đã gửi email',
  complete: 'Hoàn thành',
  enter_payment_code: 'Nhập mã thanh toán',
  enter_tax_code: 'Nhập mã số thuế',
  no_service_info: 'Chưa có thông tin dịch vụ',
  service_export_bill: 'Dịch vụ xuất hoá đơn',
  update_info_bill: 'Cập nhật thông tin hoá đơn',
  bill_information: 'Thông tin hoá đơn',
  export_bill_info: 'THÔNG TIN XUẤT HOÁ ĐƠN',
}
