/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import { IParamsGetReport24 } from 'app/api/report/model/report-24'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { notificationController } from 'app/controllers/notification-controller'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { Dates, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React from 'react'
import styled from 'styled-components'
import { AdvanceFilterLayout } from './layouts'

interface IProps {
  onChangeFilter?: (value: IParamsGetReport24) => void
  filter?: IParamsGetReport24
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const onChangeKeyword = (keyword?: string) => {
    onChangeFilter?.({ ...filter, keyword })
  }

  const onChangeBeginOrthodonticStartDate = (day?: Dayjs) => {
    if (
      day &&
      filter?.beginOrthodonticEndDate &&
      day.isAfter(filter?.beginOrthodonticEndDate)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChangeFilter?.({ ...filter, beginOrthodonticStartDate: value })
  }

  const onChangeBeginOrthodonticEndDate = (day?: Dayjs) => {
    if (
      day &&
      filter?.beginOrthodonticStartDate &&
      day.isBefore(filter?.beginOrthodonticStartDate)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChangeFilter?.({ ...filter, beginOrthodonticEndDate: value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 4]} align={'middle'}>
        <BaseCol style={{ flex: 1 }}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={filter?.keyword}
              placeholder={t(R.strings.enter_keyword_search)}
              onChange={e => onChangeKeyword(e?.target?.value)}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.start_date)}
              $prefixWidth={180}
              picker="date"
              value={
                filter?.beginOrthodonticStartDate
                  ? Dates.getDate(filter?.beginOrthodonticStartDate)
                  : null
              }
              onChange={day => onChangeBeginOrthodonticStartDate(day as Dayjs)}
              allowClear
              format={'DD-MM-YYYY'}
              placeholder={t(R.strings.all)}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.end_date)}
              $prefixWidth={190}
              value={
                filter?.beginOrthodonticEndDate
                  ? Dates.getDate(filter?.beginOrthodonticEndDate)
                  : null
              }
              picker="date"
              onChange={day => onChangeBeginOrthodonticEndDate(day as Dayjs)}
              allowClear
              format={'DD-MM-YYYY'}
              placeholder={t(R.strings.all)}
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol>
          <AdvanceFilterLayout
            filter={filter}
            onChangeFilter={onChangeFilter}
          />
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const Picker = styled(BaseDatePicker)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    width: ${props => props?.$prefixWidth ?? 40}px;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
  }
  .ant-picker-input > input {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
  }
`
