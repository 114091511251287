import {
  ICampaignGroup,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { IRefModal } from 'app/components/common/ModalComponent'
import { usePagination } from 'app/hook'
import { useGetCampaignGroupList } from 'app/react-query/hook/campaign'
import { isEqual } from 'lodash'
import { initialPagination, Pagination, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateCampaignContext } from '../../../../context'
import { IStackedWithCampaign } from '../../../../type'

export const useHook = () => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})
  const { stackedWithCampaignGroups, onChangeStackedWithCampaignsGroup, info } =
    useCreateCampaignContext()

  const [selectedStackedCampaign, setSelectedStackedCampaign] = useState<
    IStackedWithCampaign[]
  >([])

  const [pagination, setPagination] = useState<Pagination>({
    current: initialPagination.current,
    pageSize: 100,
  })

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const [searchCampaign, setSearchCampaign] = useState<string>('')
  const { flattenDataList } = usePagination()

  const { data: dataApi, isLoading } = useGetCampaignGroupList(
    {
      page: pagination?.current,
      pageSize: pagination?.pageSize,
      pagesize: pagination?.pageSize,
      keyword: searchCampaign,
    },
    true,
    {
      isFetch: true,
      params: {
        start: info?.startDate?.toISOString(),
        end: info?.endDate?.toISOString(),
        clinics: info?.clinics,
      },
    },
  )

  const dataFlatten = useMemo(() => {
    const res: ResponseType<ICampaignGroup[]> = flattenDataList(dataApi)

    return {
      ...res,
      data: res?.data
        ?.filter(item => item?.totalCampaign)
        ?.map((item, index) => ({
          ...item,
          key: index + 1,
        })),
    }
  }, [dataApi])

  const openModal = () => {
    modalRef.current?.open?.()
  }

  const hideModal = () => {
    modalRef.current?.hide?.()
  }

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const handleSubmit = () => {
    onChangeStackedWithCampaignsGroup?.(selectedStackedCampaign)
    hideModal()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setSelectedStackedCampaign(stackedWithCampaignGroups ?? [])
    }, 100)
    return () => clearTimeout(timer)
  }, [stackedWithCampaignGroups])

  const onChangePagination = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const onChangeSearchCampaign = (value: string) => {
    setSearchCampaign(value)
    setPagination({ ...pagination, current: 1 })
  }

  const onTabSelectCampaign = ({
    campaign,
    group,
  }: {
    group: ICampaignGroup
    campaign: IDiscountCampaign
  }) => {
    // check group exist

    const groupExist = selectedStackedCampaign.find(item =>
      isEqual(item.group?.code, group?.code),
    )

    if (groupExist) {
      if (groupExist?.isSelectedAllRemember) {
        const itemExcludesExist = groupExist?.excludes?.find(item =>
          isEqual(item?._id, campaign._id),
        )

        // nếu có trong item excludes thì xoá ra
        if (itemExcludesExist) {
          const newStackedCampaign = selectedStackedCampaign?.map(item => {
            if (isEqual(item?.group?.code, groupExist?.group?.code)) {
              const newExcludeSelected = groupExist?.excludes?.filter(
                itm => !isEqual(itm?._id, campaign?._id),
              )

              return {
                ...item,
                excludes: newExcludeSelected,
                isSelectedAll: !newExcludeSelected?.length,
              }
            }

            return item
          })

          if (
            newStackedCampaign?.filter(itm => itm?.isSelectedAll).length >=
            dataFlatten.data?.length
          ) {
            setIsSelectedAll(true)
          }

          setSelectedStackedCampaign(newStackedCampaign)
        }
        // nếu chưa có thì thêm vào
        else {
          setSelectedStackedCampaign(prev =>
            prev?.map(item => {
              if (isEqual(item?.group?.code, groupExist?.group?.code)) {
                return {
                  ...item,
                  excludes: groupExist?.excludes?.concat(campaign),
                  isSelectedAll: false,
                }
              }

              return item
            }),
          )
        }
      } else {
        const itemIncludesExist = groupExist?.includes?.find(item =>
          isEqual(item?._id, campaign._id),
        )

        if (itemIncludesExist) {
          const newIncludes = groupExist?.includes?.filter(
            itm => !isEqual(itm?._id, campaign?._id),
          )
          if (newIncludes?.length) {
            const newStackedCampaign = selectedStackedCampaign?.map(item => {
              if (isEqual(item?.group?.code, groupExist?.group?.code)) {
                return {
                  ...item,
                  includes: newIncludes,
                  isSelectedAll: false,
                }
              }

              return item
            })
            setSelectedStackedCampaign(newStackedCampaign)
            return
          } else {
            const newStackedCampaign = selectedStackedCampaign?.filter(
              item => !isEqual(item?.group?.code, groupExist?.group?.code),
            )
            setSelectedStackedCampaign(newStackedCampaign)

            if (!newStackedCampaign?.length) {
              setIsSelectedAll(false)
            }
          }
        } else {
          const newStackedCampaign = selectedStackedCampaign?.map(item => {
            if (isEqual(item?.group?.code, groupExist?.group?.code)) {
              const includes = item?.includes?.concat(campaign)
              const isSelectedAll = isEqual(
                includes?.length,
                item?.group?.totalCampaign,
              )
              return {
                ...item,
                includes,
                isSelectedAll,
              }
            }

            return item
          })

          setSelectedStackedCampaign(newStackedCampaign)
        }
      }
    } else {
      const newStackedCampaign = selectedStackedCampaign.concat([
        {
          group,
          isSelectedAll: false,
          isSelectedAllRemember: false,
          includes: [campaign],
          excludes: [],
        },
      ])

      setSelectedStackedCampaign(newStackedCampaign)
      return
    }
  }

  const [isSelectedAll, setIsSelectedAll] = useState(false)

  const onChangeSelectedAll = (value: boolean) => {
    if (value) {
      setSelectedStackedCampaign(
        dataFlatten?.data?.map(item => ({
          group: item,
          excludes: [],
          includes: [],
          isSelectedAll: true,
          isSelectedAllRemember: true,
        })),
      )
    } else {
      setSelectedStackedCampaign([])
    }

    setIsSelectedAll(value)
  }

  const indeterminateSelectedAll = useMemo(() => {
    if (!selectedStackedCampaign?.length) return false

    if (dataFlatten?.data?.length > selectedStackedCampaign?.length) {
      return true
    }
    return false
  }, [dataFlatten?.data, selectedStackedCampaign])

  const onChangeGroupSelected = ({ group }: { group: ICampaignGroup }) => {
    const itemExist = selectedStackedCampaign?.find(item =>
      isEqual(item?.group?.code, group?.code),
    )

    if (itemExist) {
      setSelectedStackedCampaign(prev =>
        prev?.filter(item => !isEqual(item?.group?.code, group?.code)),
      )

      setIsSelectedAll(false)
      return
    }

    const newStackedCampaign = selectedStackedCampaign.concat([
      {
        group: group,
        excludes: [],
        includes: [],
        isSelectedAll: true,
        isSelectedAllRemember: true,
      },
    ])

    if (
      newStackedCampaign?.filter(itm => itm?.isSelectedAll)?.length >=
      dataFlatten?.data?.length
    ) {
      setIsSelectedAll(true)
    }

    setSelectedStackedCampaign(newStackedCampaign)

    return
  }

  return {
    t,
    modalRef,
    pagination,
    searchCampaign,
    setSearchCampaign,
    isLoading,
    dataFlatten,
    openModal,
    handleSubmit,
    onChangePagination,
    onChangeSearchCampaign,
    onTabSelectCampaign,
    expandedRowKeys,
    handleExpand,
    selectedStackedCampaign,
    isSelectedAll,
    onChangeSelectedAll,
    indeterminateSelectedAll,
    onChangeGroupSelected,
  }
}
