import {
  IRecordDayOff,
  StatusDayOffEnum,
} from 'app/api/hr/models/approve-day-off'

export interface IDataTable extends IRecordDayOff {
  key: number
  index: number
}

export interface IFilter {
  year: string
  month: string
  keyword?: string
  clinicIds?: string[]
  jobTitleIds?: string[]
  status?: StatusDayOffEnum[]
}
export const DOCX_TYPE = [
  'application/msword',
  'com.microsoft.word.doc',
  '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'org.openxmlformats.wordprocessingml.document',
  '.docx',
]
export const EXCEL_TYPE = [
  'application/vnd.ms-excel',
  'com.microsoft.excel.xls',
  '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'org.openxmlformats.spreadsheetml.sheet',
  '.xlsx',
]

export const PDF_TYPE = ['application/pdf', 'com.adobe.pdf', '.pdf']

export const IMAGE_TYPE = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/svg+xml',
  'image/x-icon',
  'image/tiff',
  'image/vnd.microsoft.icon',
  'image/vnd.djvu',
  'image/vnd.wap.wbmp',
]
