/* eslint-disable @typescript-eslint/no-explicit-any */

import { IProfile } from 'app/api/profile/model/management'
import { ResponseType } from 'parkway-web-common'

export interface IPayloadReadNotification {
  notificationId?: string
  profileId?: string
  isRead?: 1 | 0
}

export interface IParamsGetNotificationHistory {
  keyword?: string
  startDate?: string
  endDate?: string
  isRead?: boolean
  page?: number
  pageSize?: number
  pagesize?: number
  profileId?: string
}

export interface INotificationHistory {
  _id?: string
  label: string
  notification: string
  type: NotificationHistoryEnum
  createdBy?: { _id?: string; name?: string, description?: string }
  updatedBy?: { _id?: string; name?: string, description?: string }
  sendBy: { _id?: string; name?: string, description?: string }
  notificationRecipient: NotificationRecipientEnum
  createdAt?: Date
  updatedAt?: Date
  notificationHtml?: string
}

export enum NotificationHistoryEnum {
  ZNS = 'zns',
  BOOKING = 'booking',
  OTHER = 'other',
}

export enum NotificationRecipientEnum {
  ALL = 'all',
  APPOINT = 'appoint',
}

export interface INotificationHistoryOfUser {
  _id?: string
  user: IProfile
  notification: INotificationHistory
  isRead: boolean
  createdAt?: Date
  updatedAt?: Date
}

export type ResponseGetNotificationHistoryOfUserType = ResponseType<
  INotificationHistoryOfUser[]
>
